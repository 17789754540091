import React from "react";
import { List, Button } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

import Typography from "../../atoms/Typography/Typography";
import { definedTypographyTypes } from "../../atoms/Typography/TypographyStyles";
import { IAdminKeyRow } from "../../pages/KeywordPage";

interface Props {
  selected: IAdminKeyRow[];
  loading: boolean;
  removeItem: (idx: number) => void;
  moveUpItem: (idx: number) => void;
  moveDownItem: (idx: number) => void;
}

const KeywordList: React.FC<Props> = ({
  selected,
  loading,
  removeItem,
  moveUpItem,
  moveDownItem,
}) => {
  const { t } = useTranslation();

  return (
    <List divided verticalAlign="middle" ordered>
      {selected.map((row, idx) => {
        return (
          <List.Item key={row.keyword}>
            <List.Content floated="right">
              <Button
                icon="angle down"
                onClick={() => moveDownItem(idx)}
                disabled={loading}
              />
              <Button
                icon="angle up"
                onClick={() => moveUpItem(idx)}
                disabled={loading}
              />
              <Button
                icon="trash alternate outline"
                onClick={() => removeItem(idx)}
                disabled={loading}
              />
            </List.Content>
            <List.Content>
              <List.Header>{t(row.localeKeyword || "-")}</List.Header>
              <Typography type={definedTypographyTypes.smallCaption}>
                Kategorija: {t(row.localeCategory || "-")}
              </Typography>
            </List.Content>
          </List.Item>
        );
      })}
    </List>
  );
};

export default KeywordList;

import React from "react";

interface Props {
  cell: { value?: Array<{slug: string, type: string}> };
}

const ResourceCell: React.FC<Props> = ({ cell: { value } }) => {
  if (!value || !value.length) return <>-</>;

  const {type, slug} = value[0];
  const entryCount = value.length;


  const Link = (
    <a href={`https://www.latviesi.com/${type}/${slug}`} target="_blank" rel="noopener noreferrer">
      {slug}
    </a>
  );

  if (entryCount === 1) return Link;

  return <>{Link}{` (+${entryCount - 1} more)`}</>;
};

export default ResourceCell;

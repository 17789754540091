import { EditorState, RichUtils } from "draft-js";
import React, { SyntheticEvent, useCallback } from "react";
import { Button, ButtonGroup } from "semantic-ui-react";

const INLINE_STYLE_BUTTONS = [
  { style: "UNDERLINE", icon: "underline" },
  { style: "BOLD", icon: "bold" },
  { style: "ITALIC", icon: "italic" },
];

interface Props {
  editorState: EditorState;
  onChange: (val: EditorState) => void;
}

const InlineStyleToolbar: React.FC<Props> = ({ onChange, editorState }) => {
  const currentStyle = editorState.getCurrentInlineStyle();

  const onInlineStyleClick = useCallback(
    (style: string, state: EditorState) => (event: SyntheticEvent) => {
      event.preventDefault();
      onChange(RichUtils.toggleInlineStyle(state, style));
    },
    [onChange],
  );

  return (
    <ButtonGroup>
      {INLINE_STYLE_BUTTONS.map((btt) => (
        <Button
          active={currentStyle.has(btt.style)}
          onMouseDown={onInlineStyleClick(btt.style, editorState)}
          icon={btt.icon}
          key={btt.icon}
        />
      ))}
    </ButtonGroup>
  );
};

export default InlineStyleToolbar;

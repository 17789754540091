import { EditorState } from "draft-js";
import React, { Component, SyntheticEvent, ChangeEvent } from "react";
import { Button, ButtonGroup, Form, Input, Popup } from "semantic-ui-react";
import { WithTranslation, withTranslation } from "react-i18next";
import styled from "styled-components";

const StyledWrapper = styled(Form)({
  "&&&": {
    display: "flex",
    wrap: "no-wrap",
    alignItems: "stretch",
    ".input": {
      marginRight: "5px",
    },
  },
});

interface Props {
  editorState: EditorState;
  onChange: (editorState: EditorState) => void;
  addLink: (editorState: EditorState, opts: { url: string }) => EditorState;
  removeLink: (editorState: EditorState) => EditorState;
  isLinkSelected: (editorState: EditorState) => boolean;
}

interface State {
  url: string;
  open: boolean;
}

class LinkToolbar extends Component<Props & WithTranslation, State> {
  state = {
    url: "",
    open: false,
  };

  openPopover = () => {
    this.setState({
      open: true,
    });
  };

  closePopover = () => {
    this.setState({
      open: false,
      url: "",
    });
  };

  addLink = (e: SyntheticEvent) => {
    e.preventDefault();
    const { editorState, onChange, addLink } = this.props;
    const { url } = this.state;

    onChange(addLink(editorState, { url }));
    this.closePopover();
  };

  removeLink = (e: SyntheticEvent) => {
    e.preventDefault();
    const { editorState, onChange, removeLink } = this.props;

    onChange(removeLink(editorState));
  };

  undoLink = (e: SyntheticEvent) => {
    e.preventDefault();
    const { editorState, onChange } = this.props;
    onChange(
      EditorState.forceSelection(editorState, editorState.getSelection()),
    );
    this.closePopover();
  };

  changeUrl = (evt: ChangeEvent<HTMLInputElement>) => {
    this.setState({ url: evt.target.value });
  };

  render() {
    const { isLinkSelected, editorState, t } = this.props;
    const { open, url } = this.state;

    const hasSelection = !editorState.getSelection().isCollapsed();
    const isCursorOnLink = isLinkSelected(editorState);

    return (
      <ButtonGroup>
        <Popup
          // this fixes jumping autofocus issue! leave it be!
          positionFixed
          trigger={
            <Button
              active={open}
              icon="linkify"
              disabled={isCursorOnLink || !hasSelection}
            />
          }
          on="click"
          open={open}
          onClose={this.undoLink}
          onOpen={this.openPopover}
          position="bottom left"
        >
          <StyledWrapper onSubmit={this.addLink}>
            <Input
              type="text"
              placeholder={t("CommonComponents.PasteUrlPlaceholder")}
              onChange={this.changeUrl}
              value={url}
              autoFocus
            />
            <ButtonGroup>
              <Button onClick={this.addLink} icon="checkmark" />
              <Button onClick={this.undoLink} icon="close" />
            </ButtonGroup>
          </StyledWrapper>
        </Popup>
        <Button
          icon="unlink"
          onMouseDown={this.removeLink}
          disabled={!isCursorOnLink}
        />
      </ButtonGroup>
    );
  }
}
export default withTranslation()(LinkToolbar);

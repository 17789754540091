import { EditorState, RichUtils } from "draft-js";

import getEntityRange from "../../../../utils/getEntityRange";
import getSelectionEntity from "../../../../utils/getSelectionEntity";

function removeLink(editorState: EditorState): EditorState {
  let selection = editorState.getSelection();
  const currentEntity = getSelectionEntity(editorState);

  if (currentEntity) {
    const entityRange = getEntityRange(editorState, currentEntity);
    const isBackward = selection.getIsBackward();
    if (isBackward) {
      selection = selection.merge({
        anchorOffset: entityRange.end,
        focusOffset: entityRange.start,
      });
    } else {
      selection = selection.merge({
        anchorOffset: entityRange.start,
        focusOffset: entityRange.end,
      });
    }
  }

  let newEditorState = RichUtils.toggleLink(editorState, selection, null);

  newEditorState = EditorState.forceSelection(
    newEditorState,
    editorState.getSelection(),
  );

  return newEditorState;
}

export default removeLink;

import React from "react";
import { Form, Divider } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import styled from "styled-components";
import { convertToRaw, ContentState } from "draft-js";
import useAuthorizedContext from "hooks/useAuthorizedContext";

import { getSingleData, postData, updateData } from "../../../api/DynamicApi";
import FormTextInput from "../../molecules/FormFields/FormTextInput";
import FormSelect from "../../molecules/FormFields/FormSelect";
import useForm from "../../../hooks/useForm";
import FormGrid from "../../templates/FormGrid";
import FormImageZone from "../../molecules/FormFields/FormImageZone";
import FormGeolocationFields from "../../molecules/FormFields/FormGeolocationInput";
import { definedRouteTypes, definedErrors } from "../../../api/api";
import EntityPostModel from "../../../models/EntityPostModel";
import FormTextArray from "../../molecules/FormFields/FormArrayField";
import Typography from "../../atoms/Typography/Typography";
import { definedTypographyTypes } from "../../atoms/Typography/TypographyStyles";
import SimpleButton, {
  definedButtonTypes,
} from "../../atoms/Buttons/SimpleButton/SimpleButton";
import { ISelfEmployedForm } from "../../../api";
import FormTextArea from "../../molecules/FormFields/FormTextArea";
import FormSocialFields from "../../molecules/FormFields/FormSocialFields";
import FormMultiSelect from "../../molecules/FormFields/FormMultiSelect";
import FormRichTextArea from "../../molecules/FormFields/FormRichTextArea";
import useLocalizedCountryOpts from "../../../hooks/useLocalizedCountryOpts";
import { EntityCategoryOpts } from "../../../constants/entityCategories";
import { slugifyWord } from "../../../helpers/globalFunctions";
import { IBaseFormProps } from "../../../interfaces/forms";
import useTypeTableContext, {
  definedTableActions,
} from "../../../hooks/useTypeTableContext";
import FormRadioButton from "../../molecules/FormFields/FormRadioButton";

const CenterDiv = styled.div<{ top?: boolean }>({
  display: "flex",
  justifyContent: "center",
  margin: "2em",
});

interface Props extends IBaseFormProps {
  update?: boolean;
  initialValues?: ISelfEmployedForm;
  // onFormClose?: (event?: MouseEvent) => void;
  type?: definedRouteTypes.organizations;
}

const defaultInitValues: ISelfEmployedForm = {
  title: "",
  description: convertToRaw(ContentState.createFromText("")),
  imagePointer: undefined,
  imageUrl: "",
  category: undefined,
  slug: "",
  shortSlug: "",
  countries: [],
  address: undefined,
  type: definedRouteTypes.selfEmployed,
  email: [],
  phone: [],
  media: {},
  show: false,
  highlighted: false,
  highlightedInCountry: false,
  displayInHomePage: false,
  name: "",
  surname: "",
};

const SelfEmployedForm: React.FC<Props> = ({
  initialValues,
  onFormClose,
  update,
}) => {
  const { t } = useTranslation();
  const { user, token } = useAuthorizedContext();
  const typeTableContext = useTypeTableContext();

  const CountryOptions = useLocalizedCountryOpts();

  const submitFormQuery = async (form: ISelfEmployedForm) => {
    try {
      const act = update ? definedTableActions.update : definedTableActions.add;
      const postForm = await EntityPostModel(form, user);

      const idToken = await token();

      let res: any;
      if (update) {
        res = await updateData(
          idToken,
          definedRouteTypes.selfEmployed,
          postForm,
        );
      } else {
        const newOrg = await getSingleData(
          definedRouteTypes.selfEmployed,
          postForm.slug,
        );
        if (newOrg.slug) throw new Error(definedErrors.slugExists);
        res = await postData(idToken, definedRouteTypes.selfEmployed, postForm);
      }
      if (res.status !== 200) throw new Error(JSON.stringify(res.body));
      if (typeTableContext) typeTableContext.updateTableEntry(postForm, act);
      toast.success(
        t(
          update
            ? "Forms.Messages.EditSelfEmployedSuccess"
            : "Forms.Messages.PostSelfEmployedSuccess",
        ),
      );
      if (onFormClose) onFormClose();
    } catch (err) {
      if (err.message.includes(definedErrors.slugExists)) {
        toast.error(t("Profesionāļa profils ar šādu vārdu jau eksistē."));
      } else {
        toast.error(t("Forms.GenericError"));
      }
      console.error("selfEmployed Form returned error with result", err);
    }
  };

  const {
    values,
    submitForm,
    changeFormValue,
    isLoading,
    changeFormImageValue,
  } = useForm(initialValues || defaultInitValues, submitFormQuery);
  const {
    surname,
    name,
    description,
    phone,
    email,
    website,
    category,
    address,
    countries,
    bankCredentials,
    imageUrl,
    show,
    displayInHomePage,
    highlighted,
    highlightedInCountry,
    subCategories,
  } = values;
  const title = [name, surname].filter((data) => data).join(" ");

  const media = values.media || {};

  const validateSlug = async () => {
    if (update) return;
    const newSlug = slugifyWord(title);
    const newOrg = await getSingleData(definedRouteTypes.selfEmployed, newSlug);
    if (newOrg.slug)
      toast.error(t("Profesionāļa profils ar šādu vārdu jau eksistē."));
  };

  const convertedShow = Boolean(Number(show));
  const convertedDisplay = Boolean(Number(displayInHomePage));
  return (
    <Form name="selfEmployedForm" id="selfEmployedForm" loading={isLoading}>
      <Typography type={definedTypographyTypes.h1}>
        {update
          ? t("Rediģēt publisko profesionāļa profilu")
          : t("Pievienot publisku profesionāļa profilu")}
      </Typography>
      <Divider />
      <FormGrid label={t("Administrēšana")}>
        <FormRadioButton
          id="show"
          label="Redzams portālā"
          value={convertedShow}
          onChange={changeFormValue}
        />
        <FormRadioButton
          id="displayInHomePage"
          label="Rādīt Sākumlapā"
          value={convertedDisplay}
          onChange={changeFormValue}
        />
        <FormRadioButton
          id="highlighted"
          label="Izcelts"
          value={highlighted}
          onChange={changeFormValue}
        />
        <FormRadioButton
          id="highlightedInCountry"
          label="Izcelts valstī"
          value={highlightedInCountry}
          onChange={changeFormValue}
        />
      </FormGrid>
      <FormGrid label={t("Galvenie dati")}>
        <Form.Group widths="equal">
          <FormTextInput
            id="name"
            required
            value={name}
            label={t("Vārds")}
            onChange={changeFormValue}
            onBlur={validateSlug}
            // !NB reenable once you add cognito update as well
            disabled={Boolean(update && initialValues?.creator)}
          />
          <FormTextInput
            id="surname"
            required
            value={surname}
            label={t("Uzvārds")}
            onChange={changeFormValue}
            onBlur={validateSlug}
            // !NB reenable once you add cognito update as well
            disabled={Boolean(update && initialValues?.creator)}
          />
        </Form.Group>
        {/* {isFormEdit || (
          <FormTextInput
            id="shortSlug"
            value={shortSlug || ""}
            label={t("Forms.Titles.OrgShortSlug")}
            placeholder="mana-saite"
            onChange={changeFormValue}
            disabled={isFormEdit}
            required
          />
        )} */}
        <FormRichTextArea
          id="description"
          toolbarHidden
          required
          label={t("Piedāvājuma apraksts")}
          value={description}
          onChange={changeFormValue}
          description={t(
            "Pirmie 280 simboli tiks attēloti kā profesionāļa apraksts portāla uzņēmumu sadaļā. Pilnais teksts atspoguļosies personas publiskajā profilā.",
          )}
        />
        <FormImageZone
          id="imageUrl"
          required
          initialImgUrl={imageUrl}
          label={t("Profila attēls")}
          onImageChange={changeFormImageValue}
          onChange={changeFormValue}
          // !NB reenable once you add cognito update as well
          disabled={Boolean(update && initialValues?.creator)}
        />
      </FormGrid>
      <FormGrid label={t("Forms.Titles.Category")}>
        <FormSelect
          id="category"
          value={category}
          options={EntityCategoryOpts}
          label={t("Forms.Titles.Category")}
          placeholder={t("Forms.Titles.CategoryPlaceholder")}
          onChange={changeFormValue}
          required
          search
        />
        <FormMultiSelect
          id="countries"
          label={t("Forms.Fields.countries")}
          options={CountryOptions}
          value={countries || []}
          onChange={changeFormValue}
          search
        />
        {/* // subCategories HACK sorry :( */}
        <FormRadioButton
          id="subCategories"
          label="Latvijas pēdas pasaulē"
          value={(subCategories?.includes("latvijas-pedas-pasaule") || 0) > 0}
          onChange={(id, data) => {
            const cat = "latvijas-pedas-pasaule";
            const initial = (initialValues?.subCategories || []).filter(
              (item) => item !== cat,
            );
            changeFormValue(id, data ? [...initial, cat] : initial);
          }}
        />
      </FormGrid>
      <FormGrid label={t("Forms.Titles.Place")}>
        <FormGeolocationFields
          id="address"
          value={address}
          onChange={changeFormValue}
        />
      </FormGrid>
      <FormGrid label={t("Forms.Titles.Contacts")}>
        <FormTextInput
          id="website"
          label={t("Forms.Titles.Website")}
          value={website || ""}
          onChange={changeFormValue}
        />
        <FormTextArray
          id="email"
          label={t("Forms.Titles.Email")}
          value={email}
          onChange={changeFormValue}
        />
        <FormTextArray
          id="phone"
          label={t("Forms.Titles.Phone")}
          value={phone}
          onChange={changeFormValue}
        />
        <FormSocialFields id="media" value={media} onChange={changeFormValue} />
        <FormTextArea
          id="bankCredentials"
          label={t("Forms.Titles.BankCredentials")}
          value={bankCredentials}
          onChange={changeFormValue}
        />
      </FormGrid>
      <CenterDiv>
        {onFormClose && (
          <SimpleButton
            id="FormCancel"
            variant={definedButtonTypes.secondary}
            onClick={onFormClose}
          >
            {t("cancel")}
          </SimpleButton>
        )}
        <SimpleButton
          onClick={(e) => submitForm(e)}
          variant={definedButtonTypes.primary}
          id="FormSubmit"
        >
          {t("submit")}
        </SimpleButton>
      </CenterDiv>
    </Form>
  );
};

export default SelfEmployedForm;

import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { Menu } from "semantic-ui-react";

import { definedUnapprovedRoutes } from "../../api/api";
import TypeDataTable from "../organisms/Tables/TypeDataTable";
import TypeTableContextProvider from "../../context/TypeTableContextProvider";
import Typography from "../atoms/Typography/Typography";
import { definedTypographyTypes } from "../atoms/Typography/TypographyStyles";

interface Props {}

interface IParams {
  type: definedUnapprovedRoutes;
}

const UnapprovedTablePage: React.FC<Props> = () => {
  const params = useParams<IParams>();
  const history = useHistory();
  const type = params.type || definedUnapprovedRoutes.news;
  return (
    <>
      <Typography type={definedTypographyTypes.h1}>
        Portālā nerādītie dati
      </Typography>
      <Menu pointing secondary>
        <Menu.Item
          name="Jaunumi"
          active={type === definedUnapprovedRoutes.news}
          onClick={() =>
            history.push(`/unapproved/${definedUnapprovedRoutes.news}`)
          }
        />
        <Menu.Item
          name="Notikumi"
          active={type === definedUnapprovedRoutes.events}
          onClick={() =>
            history.push(`/unapproved/${definedUnapprovedRoutes.events}`)
          }
        />
        <Menu.Item
          name="Organizācijas"
          active={type === definedUnapprovedRoutes.organizations}
          onClick={() =>
            history.push(`/unapproved/${definedUnapprovedRoutes.organizations}`)
          }
        />
        <Menu.Item
          name="Mediju saites"
          active={type === definedUnapprovedRoutes.links}
          onClick={() =>
            history.push(`/unapproved/${definedUnapprovedRoutes.links}`)
          }
        />
      </Menu>
      <TypeTableContextProvider key={type} type={type}>
        <TypeDataTable type={type} />
      </TypeTableContextProvider>
    </>
  );
};

export default UnapprovedTablePage;

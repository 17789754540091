import React from "react";
import styled from "styled-components";

import { IRow } from "../../../interfaces/tables";

interface Props {
  cell: { row: IRow<any> };
}

const StyledAnchor = styled.span({
    maxWidth: "250px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "inline-block",
    fontSize: "1em",
    lineHeight: 1,
})
const StyledSpan = styled.span({
    whiteSpace: "nowrap",
    display: "inline-block",
    height: "1em",
    fontSize: "1em",
    lineHeight: 1,
})

const FileNameCell: React.FC<Props> = ({ cell: { row } }) => {
  const { key, url } = row.original;

  if (!key) return <>-</>;

  const file = (key as string).split(".");
  const ext = file.pop();

  return (
    // This is internal site
    <a href={url} target="_blank" rel="noopener noreferrer" style={{
      display:"inline-block",
      whiteSpace: "nowrap",
      height: "1em",
    }}>
      <StyledAnchor>
      {file.join(".")}
      </StyledAnchor>
      <StyledSpan>
        {`.${ext}`}
      </StyledSpan>
    </a>
  );
};

export default FileNameCell;

/* eslint-disable camelcase */

import isUrl from "helpers/isUrl";

import { handleApiGet } from "./api";
import { BackendUrl } from "./newBackendApi";

const OEMBED_LAMBDA = `${BackendUrl}/getOEmbedHTML`;

export type OEmbedProviders =
  | "Instagram"
  | "Facebook"
  | "Twitter"
  | "TikTok"
  | "Flickr" // issue with mobile responsiveness
  | "reddit"
  | "CodePen"
  | "LottieFiles"
  | "CodeSandbox"
  | "YouTube"
  | "Vimeo"
  | "TED"
  | "SoundCloud"
  | "Pinterest"
  | "Spotify";

/**
 * @interface IOEmbed
 * Everyone follows this standard https://oembed.com/
 */
export interface IOEmbed {
  type: "video" | "photo" | "rich"; // excluding "link" type
  version: "1.0";
  title?: string;
  author_name?: string;
  author_url?: string;
  provider_name?: OEmbedProviders | string;
  provider_url?: string;
  cache_age?: number;
  thumbnail_url?: string;
  thumbnail_width?: number;
  thumbnail_height?: number;

  // Not applicable to link type, but we don't support those anyway as we expect html at all times.
  width: string;
  height: string;
  html: string;

  // custom
  originalUrl: string;
}

const getUrlFromString = (url: string) => {
  const urlText = url.trim();

  // Is regular URL
  if (isUrl(urlText)) return urlText;

  // Is copy-paste iframe code
  const regex = /<iframe.*?s*src="(http.*?)".*?<\/iframe>/;
  const match = regex.exec(url);
  const [, group] = match || [];

  return group;
};

async function getOEmbed(
  idToken: string,
  url: string,
): Promise<IOEmbed | undefined> {
  const urlText = getUrlFromString(url);

  if (!urlText) return;

  const urlParam = encodeURIComponent(urlText);

  if (!idToken) {
    console.error("Cannot get oEmbed without token.");
    return;
  }

  let response;
  try {
    response = await handleApiGet(`${OEMBED_LAMBDA}?url=${urlParam}`, {
      Authorization: `Bearer ${idToken}`,
    });
  } catch (err) {
    console.error("Failed to fetch embed", err);
  }

  // eslint-disable-next-line consistent-return
  return response;
}

export default getOEmbed;

import * as React from "react";
import { List } from "semantic-ui-react";
import moment, { Moment } from "moment";
import { useTranslation } from "react-i18next";

import FlagLabel from "../../atoms/Labels/FlagLabel";
import Typography, {
  definedTypography,
} from "../../atoms/Typography/Typography";
import { COUNTRY } from "../../../constants/country";
import { IOrganizationDisplay } from "../../../api";
import { definedCardSizes } from "../Cards/EventGridCard";
import { ILocation } from "../../../interfaces";

export interface IEventDetailListProps {
  date?: Moment | null;
  countries?: COUNTRY[];
  address?: ILocation | null;
  organizationId?: string;
  organizationInfo?: IOrganizationDisplay;
  cardSize?: definedCardSizes;
}

const EventDetailList: React.FC<IEventDetailListProps> = ({
  date,
  countries,
  cardSize,
}) => {
  let captionType = definedTypography.caption;
  if (cardSize === definedCardSizes.medium) {
    captionType = definedTypography.smallCaption;
  }
  const { t } = useTranslation();

  return (
    <List divided horizontal>
      {countries && countries.length && (
        <List.Item>
          <List.Content>
            {countries.map((country) => (
              <FlagLabel key={country} flag={country} />
            ))}
            {countries.length === 1 && (
              <Typography type={captionType}>
                {t(`country:${countries[0]}`)}
              </Typography>
            )}
          </List.Content>
        </List.Item>
      )}
      {date && (
        <List.Item>
          <List.Content>
            <List.Icon name="clock outline" id="time" />
            <Typography type={captionType}>
              {moment(date).format("DD. MMM. YYYY HH.mm")}
            </Typography>
          </List.Content>
        </List.Item>
      )}
    </List>
  );
};

export default EventDetailList;

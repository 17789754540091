import React from "react";
import { useTranslation } from "react-i18next";

import { StyledSelect } from "../../organisms/Menus/FilterMenu/FilterMenuStyles";
import useLocalizedCountryOpts from "../../../hooks/useLocalizedCountryOpts";
import { IColumnProps } from "../../../interfaces/tables";

interface Props {
  column: IColumnProps<{}>;
}

const CountriesSelectFilter: React.FC<Props> = ({ column: { setFilter } }) => {
  const { t } = useTranslation();
  const CountryOptions = useLocalizedCountryOpts();
  return (
    <StyledSelect
      placeholder={t("Filter.Countries")}
      fluid
      search
      selection
      multiple
      options={CountryOptions}
      onChange={(e: any, data: any) => setFilter(data.value)}
    />
  );
};

export default CountriesSelectFilter;

/* eslint-disable no-param-reassign */ // Because we are using immer
import React, { memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useImmerReducer } from "use-immer";
import { toast } from "react-toastify";
import { FormField } from "semantic-ui-react";
import uploadImageFromFile from "api/ImageApi";
import Cognito from "api/cognito/Cognito";

import PictureSearchZone, {
  SearchImageZoneValues,
} from "./PictureFields/PictureSearchZone";

import { IBaseFormFieldProps } from "../../../interfaces/forms";
import SingleImageDropzone from "../../atoms/ImageDropzone/SingleImageDropzone";
import Typography from "../../atoms/Typography/Typography";
import { definedTypographyTypes } from "../../atoms/Typography/TypographyStyles";
// TODO: controlled @Agnese

export interface FormImageZoneProps
  extends IBaseFormFieldProps<File | string | undefined> {
  initialImgUrl?: string;
  type?: "upload" | "get-pointer";
}

interface IDropzoneState {
  error: string;
  isLoading: boolean;
  imgUrl: string | undefined;
}
enum actionTypes {
  submit = "submit",
  success = "success",
  error = "error",
  reset = "reset",
}

// TODO probably this deserves a helper, revisit later
function dropzoneReducer(
  draft: IDropzoneState,
  action: { type: actionTypes; imgUrl?: string },
) {
  switch (action.type) {
    case "reset": {
      draft.error = "";
      draft.isLoading = false;
      return;
    }
    case "submit": {
      draft.error = "";
      draft.isLoading = true;
      return;
    }
    case "success": {
      draft.isLoading = false;
      draft.imgUrl = action.imgUrl;
      return;
    }
    case "error": {
      draft.error = "Form submission failed!!";
      draft.isLoading = false;
      return;
    }
    default: {
      draft.error = "Unrecognised action type used!!";
      draft.isLoading = false;
    }
  }
}

const FormImageZone: React.FC<FormImageZoneProps> = ({
  id,
  label,
  required,
  initialImgUrl,
  description,
  onChange,
  onImageChange,
  disabled,
  type = "upload",
}) => {
  const { t } = useTranslation();

  const [dropzoneState, dispatchDropzone] = useImmerReducer(dropzoneReducer, {
    error: "",
    isLoading: false,
    imgUrl: initialImgUrl,
  });

  const setImgUrl = (values: SearchImageZoneValues) => {
    if (onImageChange) {
      dispatchDropzone({
        type: actionTypes.success,
        imgUrl: values.url,
      });
      onImageChange(values);
    }
  };

  const changeFn = useCallback(
    async (acceptedFiles: File[]) => {
      try {
        // 1. Get the file
        const file = acceptedFiles[0];
        if (!file) {
          dispatchDropzone({ type: actionTypes.reset });
          if (onChange) onChange(id, undefined);
          return;
        }

        // 2. Update form value
        dispatchDropzone({ type: actionTypes.submit });
        if (type === "get-pointer") {
          if (onChange) onChange(id, file);

          // 3. Generate preview
          dispatchDropzone({
            type: actionTypes.success,
            imgUrl: URL.createObjectURL(file),
          });
        } else {
          const token = Cognito.getIdTokenFromStorage();
          const url = await uploadImageFromFile(token, file);
          if (onChange) onChange(id, url);

          // 3. Generate preview
          dispatchDropzone({
            type: actionTypes.success,
            imgUrl: url,
          });
        }
      } catch (err) {
        dispatchDropzone({ type: actionTypes.error });
        console.error("Failed to upload image!", err);
        toast.error(t("Toasts.GenericError"));
      }
    },
    [dispatchDropzone, onChange, t, type, id],
  );
  const { isLoading, imgUrl } = dropzoneState;

  return (
    <>
      <PictureSearchZone setImage={setImgUrl} disabled={disabled} />
      <FormField required={required}>
        {label && <label htmlFor={id}>{label}</label>}
        <SingleImageDropzone
          isLoading={isLoading}
          onDrop={changeFn}
          exampleImgUrl={imgUrl}
          disabled={disabled}
        />
        {description && (
          <Typography type={definedTypographyTypes.smallCaption}>
            {description}
          </Typography>
        )}
      </FormField>
    </>
  );
};

export default memo(FormImageZone);

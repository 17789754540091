import React from "react";
import { Accordion, AccordionPanelProps } from "semantic-ui-react";
import styled from "styled-components";

import EditAdPanel from "../organisms/Panels/EditAdPanel";
import { definedAdTypes } from "../../api/AdApi";

interface Props {}

const StyledPage = styled.div`
  &&& {
    margin-top: 2em;
    width: 100%;
  }
`;

const AccordionItems: AccordionPanelProps[] = [
  {
    key: 1,
    title: "Lielais Baneris",
    content: { content: <EditAdPanel type={definedAdTypes.big} /> },
  },
  {
    key: 2,
    title: "Mazais Baneris",
    content: { content: <EditAdPanel type={definedAdTypes.small} /> },
  },
  {
    key: 3,
    title: "Teksta reklāma",
    content: { content: <EditAdPanel type={definedAdTypes.text} /> },
  },
];

const AdConfigurationPage: React.FC<Props> = () => {
  return (
    <StyledPage>
      <Accordion panels={AccordionItems} styled fluid />
    </StyledPage>
  );
};

export default AdConfigurationPage;

import React from "react";

import UserTable from "../organisms/Tables/UserTable";
import Typography from "../atoms/Typography/Typography";
import { definedTypographyTypes } from "../atoms/Typography/TypographyStyles";

interface Props {}

const UserListPage: React.FC<Props> = () => {
  return (
    <div>
      <Typography type={definedTypographyTypes.h1}>Satura licēji</Typography>
      <UserTable />
    </div>
  );
};

export default UserListPage;

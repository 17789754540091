import React, { memo, useCallback } from "react";
import { RawDraftContentState } from "draft-js";
import { FormField } from "semantic-ui-react";
import uploadImageFromFile from "api/ImageApi";
import RichTextEditor from "components/atoms/NewRichText/RichText/RichTextEditor/RichTextEditor";
import getOEmbed from "api/getOEmbed";
import useAuthorizedContext from "hooks/useAuthorizedContext";

import { IBaseFormFieldProps } from "../../../interfaces/forms";
import Typography from "../../atoms/Typography/Typography";
import { definedTypographyTypes } from "../../atoms/Typography/TypographyStyles";

export interface FormTextFieldProps
  extends IBaseFormFieldProps<RawDraftContentState> {
  onChange: (id: string, data: RawDraftContentState) => void;
  toolbarHidden?: boolean;
}

const FormRichTextArea: React.FC<FormTextFieldProps> = ({
  children,
  id,
  label,
  required,
  onChange,
  value,
  toolbarHidden,
  description,
}) => {
  const { token } = useAuthorizedContext();
  const uploadImageApi = useCallback(
    async (file: File, fileName?: string | undefined) => {
      const idToken = await token();
      return uploadImageFromFile(idToken, file, fileName);
    },
    [token],
  );
  const oEmbedApi = useCallback(
    async (url: string) => {
      const idToken = await token();
      return getOEmbed(idToken, url);
    },
    [token],
  );
  return (
    <FormField required={required}>
      {label && <label htmlFor={id}>{label}</label>}
      <RichTextEditor
        initialValue={value}
        onChange={(val: any) => onChange(id, val)}
        toolbarHidden={toolbarHidden}
        plugins={["embed", "image", "emoji"]}
        uploadImageApi={uploadImageApi}
        oEmbedApi={oEmbedApi}
      />
      {children}
      {description && (
        <Typography type={definedTypographyTypes.smallCaption}>
          {description}
        </Typography>
      )}
    </FormField>
  );
};

export default memo(FormRichTextArea);

import React, { useState } from "react";
import { Icon, Loader } from "semantic-ui-react";
import { toast } from "react-toastify";
import useAuthorizedContext from "hooks/useAuthorizedContext";

import { IBaseDisplay } from "../../../api";
import { updateData } from "../../../api/DynamicApi";
import useTypeTableContext, {
  definedTableActions,
} from "../../../hooks/useTypeTableContext";
import DynamicModel from "../../../models/DynamicModel";

interface Props {
  cell: {
    value?: boolean | 1 | 0;
    column: { id: keyof IBaseDisplay };
    row: { original: IBaseDisplay };
  };
}

const BooleanCell: React.FC<Props> = ({ cell: { value, row, column } }) => {
  const [loading, setLoading] = useState(false);
  const context = useTypeTableContext();
  const { token } = useAuthorizedContext();
  const data = row.original;
  const accessor = column.id;
  const fastUpdate = async () => {
    try {
      setLoading(true);
      let newVal: string | boolean = false;
      const newItem: any = { ...data };
      const oldVal = newItem[accessor];
      if (oldVal === "1") newVal = "0";
      else if (oldVal === "0") newVal = "1";
      else if (typeof oldVal === "boolean") newVal = !oldVal;

      newItem[accessor] = newVal as any;
      const postForm = await DynamicModel(newItem);
      const idToken = await token();
      await updateData(idToken, postForm.type, postForm);
      if (context) {
        context.updateTableEntry(postForm, definedTableActions.update);
      }
    } catch (err) {
      toast.error("Kaut kas nogāja greizi.");
      console.error("BooleanCell failed", err);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <Loader active inline />;

  const cellVal = Boolean(Number(value));

  return cellVal ? (
    <Icon
      color="green"
      name="checkmark"
      size="large"
      style={{ cursor: "pointer" }}
      onClick={fastUpdate}
    />
  ) : (
    <Icon
      color="red"
      name="times"
      size="large"
      style={{ cursor: "pointer" }}
      onClick={fastUpdate}
    />
  );
};
// <FormRadioButton id="show" label="" value={cellVal} onChange={() => {}} />

export default BooleanCell;

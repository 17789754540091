import * as React from "react";
import { Cell } from "react-table";
import { useCallback } from "react";
import { Button } from "semantic-ui-react";
import { useImmer } from "use-immer";
import { listAllUsers } from "api/usersApi";
import Cognito from "api/cognito/Cognito";
import useAuthorizedContext from "hooks/useAuthorizedContext";
import CognitoUserAttrs from "api/cognito/CognitoUserAttrs";

import EditUserModal from "../Modal/EditUserModal";
import DeleteUserModal from "../Modal/DeleteUserModal";
import useAPI from "../../../hooks/useAPI";
import { definedAccessors } from "../../../helpers/tableHelpers";
import { definedTableActions } from "../../../hooks/useTypeTableContext";
import FetchMoreTable from "../../templates/Tables/FetchMoreTable";

interface Props {}

type IUser = ReturnType<InstanceType<typeof CognitoUserAttrs>["format"]>;

const columns = [
  definedAccessors.picture,
  definedAccessors.email,
  definedAccessors.displayName,
  definedAccessors.username,
  definedAccessors.userGroups,
  definedAccessors.status,
  definedAccessors.created,
  definedAccessors.role,
  definedAccessors.action,
];
const initialUsers = async () => {
  const token = Cognito.getIdTokenFromStorage();
  return listAllUsers(token, { limit: 41 });
};

const UserTable: React.FC<Props> = () => {
  const { token } = useAuthorizedContext();
  const { loading, response, setResponse } = useAPI(initialUsers, []);
  const [usedTokens, setUsedTokens] = useImmer<
    Map<string, "loading" | "completed">
  >(new Map());

  const updateUserObject = useCallback(
    (user: IUser, action: definedTableActions) => {
      setResponse((draft) => {
        if (draft && action === definedTableActions.update) {
          const index = draft.users.findIndex((item) => item.id === user.id);
          // eslint-disable-next-line no-param-reassign
          if (index > -1) draft.users[index] = user;

          return draft;
        }

        return draft;
      });
    },
    [setResponse],
  );

  const actionColumn = React.useMemo(
    () => ({
      Header: "Darbības",
      Cell: (cell: Cell<IUser>) => {
        const { original } = cell.row;
        return (
          <Button.Group>
            <EditUserModal data={original} updateFn={updateUserObject} />
            <DeleteUserModal data={original} updateFn={updateUserObject} />
          </Button.Group>
        );
      },
      disableSortBy: true,
    }),
    [updateUserObject],
  );

  const fetchMore = async (skipPageReset: () => void) => {
    if (!response) return;
    const nextToken = response.PaginationToken;
    if (!nextToken) return;
    if (usedTokens.has(nextToken)) return;
    setUsedTokens((prev) => prev.set(nextToken, "loading"));
    const idToken = await token();
    const { PaginationToken, users } = await listAllUsers(idToken, {
      paginationToken: nextToken,
      limit: 40,
    });
    skipPageReset();
    setResponse((prev) => ({
      PaginationToken,
      users: [...(prev?.users || []), ...users],
    }));
    setUsedTokens((prev) => prev.set(nextToken, "completed"));
  };

  const isLoading =
    Array.from(usedTokens.values()).includes("loading") || loading;

  return (
    <FetchMoreTable
      loading={isLoading}
      actionColumn={actionColumn}
      data={response?.users || []}
      columns={columns}
      canFetchMore={Boolean(response?.PaginationToken)}
      fetchMore={fetchMore}
    />
  );
};

export default UserTable;

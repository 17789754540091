import { EditorState, RichUtils } from "draft-js";
import { sanitizeUrl } from "@braintree/sanitize-url";

import { LINK_ENTITY_MUTABILITY, LINK_ENTITY_TYPE } from "../constants";

export default function addLink(
  editorState: EditorState,
  { url }: { url: string },
): EditorState {
  const sanitizedUrl = sanitizeUrl(url).trim();
  if (!url.length) {
    return EditorState.forceSelection(editorState, editorState.getSelection());
  }
  const contentState = editorState
    .getCurrentContent()
    .createEntity(LINK_ENTITY_TYPE, LINK_ENTITY_MUTABILITY, {
      href: sanitizedUrl,
      url: sanitizedUrl,
    });
  const entityKey = contentState.getLastCreatedEntityKey();
  let newEditorState = RichUtils.toggleLink(
    editorState,
    editorState.getSelection(),
    entityKey,
  );
  const newSelection = editorState.getSelection().merge({
    focusOffset: editorState.getSelection().getEndOffset(),
    anchorOffset: editorState.getSelection().getEndOffset(),
  });

  newEditorState = EditorState.forceSelection(newEditorState, newSelection);

  return newEditorState;
}

export enum COUNTRY {
  aw = "aw", // Aruba
  af = "af", // Afghanistan
  ao = "ao", // Angola
  ai = "ai", // Anguilla
  // aq = "aq", // Antarctica
  ax = "ax", // Åland Islands
  al = "al", // Albania
  ad = "ad", // Andorra
  ae = "ae", // United Arab Emirates
  ar = "ar", // Argentina
  am = "am", // Armenia
  as = "as", // American Samoa
  tf = "tf", // French Southern Territories
  ag = "ag", // Antigua and Barbuda
  au = "au", // Australia
  at = "at", // Austria
  az = "az", // Azerbaijan
  bi = "bi", // Burundi
  be = "be", // Belgium
  bj = "bj", // Benin
  // bq = "bq", // Bonaire, Sint Eustatius and Saba
  bf = "bf", // Burkina Faso
  bd = "bd", // Bangladesh
  bg = "bg", // Bulgaria
  bh = "bh", // Bahrain
  bs = "bs", // Bahamas
  ba = "ba", // Bosnia and Herzegovina
  // bl = "bl", // Saint Barthélemy
  by = "by", // Belarus
  bz = "bz", // Belize
  bm = "bm", // Bermuda
  bo = "bo", // Bolivia, Plurinational State of
  br = "br", // Brazil
  bb = "bb", // Barbados
  bn = "bn", // Brunei Darussalam
  bt = "bt", // Bhutan
  bv = "bv", // Bouvet Island
  bw = "bw", // Botswana
  cf = "cf", // Central African Republic
  ca = "ca", // Canada
  cc = "cc", // Cocos (Keeling) Islands
  ch = "ch", // Switzerland
  cl = "cl", // Chile
  cn = "cn", // China
  ci = "ci", // Côte d'Ivoire
  cm = "cm", // Cameroon
  cd = "cd", // Congo, the Democratic Republic of the
  cg = "cg", // Congo
  ck = "ck", // Cook Islands
  co = "co", // Colombia
  km = "km", // Comoros
  cv = "cv", // Cabo Verde
  cr = "cr", // Costa Rica
  cu = "cu", // Cuba
  // cw = "cw", // Curaçao
  cx = "cx", // Christmas Island
  ky = "ky", // Cayman Islands
  cy = "cy", // Cyprus
  cz = "cz", // Czechia
  de = "de", // Germany
  dj = "dj", // Djibouti
  dm = "dm", // Dominica
  dk = "dk", // Denmark
  do = "do", // Dominican Republic
  dz = "dz", // Algeria
  ec = "ec", // Ecuador
  eg = "eg", // Egypt
  er = "er", // Eritrea
  eh = "eh", // Western Sahara
  es = "es", // Spain
  ee = "ee", // Estonia
  et = "et", // Ethiopia
  fi = "fi", // Finland
  fj = "fj", // Fiji
  fk = "fk", // Falkland Islands (Malvinas)
  fr = "fr", // France
  fo = "fo", // Faroe Islands
  fm = "fm", // Micronesia, Federated States of
  ga = "ga", // Gabon
  gb = "gb", // United Kingdom
  ge = "ge", // Georgia
  // gg = "gg", // Guernsey
  gh = "gh", // Ghana
  gi = "gi", // Gibraltar
  gn = "gn", // Guinea
  gp = "gp", // Guadeloupe
  gm = "gm", // Gambia
  gw = "gw", // Guinea-Bissau
  gq = "gq", // Equatorial Guinea
  gr = "gr", // Greece
  gd = "gd", // Grenada
  gl = "gl", // Greenland
  gt = "gt", // Guatemala
  gf = "gf", // French Guiana
  gu = "gu", // Guam
  gy = "gy", // Guyana
  hk = "hk", // Hong Kong
  hm = "hm", // Heard Island and McDonald Islands
  hn = "hn", // Honduras
  hr = "hr", // Croatia
  ht = "ht", // Haiti
  hu = "hu", // Hungary
  id = "id", // Indonesia
  // im = "im", // Isle of Man
  in = "in", // India
  io = "io", // British Indian Ocean Territory
  ie = "ie", // Ireland
  ir = "ir", // Iran, Islamic Republic of
  iq = "iq", // Iraq
  is = "is", // Iceland
  il = "il", // Israel
  it = "it", // Italy
  jm = "jm", // Jamaica
  // je = "je", // Jersey
  jo = "jo", // Jordan
  jp = "jp", // Japan
  kz = "kz", // Kazakhstan
  ke = "ke", // Kenya
  kg = "kg", // Kyrgyzstan
  kh = "kh", // Cambodia
  ki = "ki", // Kiribati
  kn = "kn", // Saint Kitts and Nevis
  kr = "kr", // Korea, Republic of
  kw = "kw", // Kuwait
  la = "la", // Lao People's Democratic Republic
  lb = "lb", // Lebanon
  lr = "lr", // Liberia
  ly = "ly", // Libya
  lc = "lc", // Saint Lucia
  li = "li", // Liechtenstein
  lk = "lk", // Sri Lanka
  ls = "ls", // Lesotho
  lt = "lt", // Lithuania
  lu = "lu", // Luxembourg
  lv = "lv", // Latvia
  mo = "mo", // Macao
  // mf = "mf", // Saint Martin (French part)
  ma = "ma", // Morocco
  mc = "mc", // Monaco
  md = "md", // Moldova, Republic of
  mg = "mg", // Madagascar
  mv = "mv", // Maldives
  mx = "mx", // Mexico
  mh = "mh", // Marshall Islands
  mk = "mk", // Macedonia, the former Yugoslav Republic of
  ml = "ml", // Mali
  mt = "mt", // Malta
  mm = "mm", // Myanmar
  me = "me", // Montenegro
  mn = "mn", // Mongolia
  mp = "mp", // Northern Mariana Islands
  mz = "mz", // Mozambique
  mr = "mr", // Mauritania
  ms = "ms", // Montserrat
  mq = "mq", // Martinique
  mu = "mu", // Mauritius
  mw = "mw", // Malawi
  my = "my", // Malaysia
  yt = "yt", // Mayotte
  na = "na", // Namibia
  nc = "nc", // New Caledonia
  ne = "ne", // Niger
  nf = "nf", // Norfolk Island
  ng = "ng", // Nigeria
  ni = "ni", // Nicaragua
  nu = "nu", // Niue
  nl = "nl", // Netherlands
  no = "no", // Norway
  np = "np", // Nepal
  nr = "nr", // Nauru
  nz = "nz", // New Zealand
  om = "om", // Oman
  pk = "pk", // Pakistan
  pa = "pa", // Panama
  pn = "pn", // Pitcairn
  pe = "pe", // Peru
  ph = "ph", // Philippines
  pw = "pw", // Palau
  pg = "pg", // Papua New Guinea
  pl = "pl", // Poland
  pr = "pr", // Puerto Rico
  kp = "kp", // Korea, Democratic People's Republic of
  pt = "pt", // Portugal
  py = "py", // Paraguay
  ps = "ps", // Palestine, State of
  pf = "pf", // French Polynesia
  qa = "qa", // Qatar
  re = "re", // Réunion
  ro = "ro", // Romania
  ru = "ru", // Russian Federation
  rw = "rw", // Rwanda
  sa = "sa", // Saudi Arabia
  sd = "sd", // Sudan
  sn = "sn", // Senegal
  sg = "sg", // Singapore
  gs = "gs", // South Georgia and the South Sandwich Islands
  sh = "sh", // Saint Helena, Ascension and Tristan da Cunha
  sj = "sj", // Svalbard and Jan Mayen
  sb = "sb", // Solomon Islands
  sl = "sl", // Sierra Leone
  sv = "sv", // El Salvador
  sm = "sm", // San Marino
  so = "so", // Somalia
  pm = "pm", // Saint Pierre and Miquelon
  rs = "rs", // Serbia
  // ss = "ss", // South Sudan
  st = "st", // Sao Tome and Principe
  sr = "sr", // Suriname
  sk = "sk", // Slovakia
  si = "si", // Slovenia
  se = "se", // Sweden
  sz = "sz", // Swaziland
  // sx = "sx", // Sint Maarten (Dutch part)
  sc = "sc", // Seychelles
  sy = "sy", // Syrian Arab Republic
  tc = "tc", // Turks and Caicos Islands
  td = "td", // Chad
  tg = "tg", // Togo
  th = "th", // Thailand
  tj = "tj", // Tajikistan
  tk = "tk", // Tokelau
  tm = "tm", // Turkmenistan
  tl = "tl", // Timor-Leste
  to = "to", // Tonga
  tt = "tt", // Trinidad and Tobago
  tn = "tn", // Tunisia
  tr = "tr", // Turkey
  tv = "tv", // Tuvalu
  tw = "tw", // Taiwan
  tz = "tz", // Tanzania, United Republic of
  ug = "ug", // Uganda
  ua = "ua", // Ukraine
  um = "um", // United States Minor Outlying Islands
  uy = "uy", // Uruguay
  us = "us", // United States of America
  uz = "uz", // Uzbekistan
  va = "va", // Vatican
  vc = "vc", // Saint Vincent and the Grenadines
  ve = "ve", // Venezuela, Bolivarian Republic of
  vg = "vg", // Virgin Islands, British
  vi = "vi", // Virgin Islands, U.S.
  vn = "vn", // Viet Nam
  vu = "vu", // Vanuatu
  wf = "wf", // Wallis and Futuna
  ws = "ws", // Samoa
  ye = "ye", // Yemen
  za = "za", // South Africa
  zm = "zm", // Zambia
  zw = "zw", // Zimbabwe
}

// tslint:disable-next-line:no-unnecessary-type-annotation - To double-check it matches with enum.
export const COUNTRY_TITLE: { [key in COUNTRY]: string } = {
  aw: "Aruba",
  af: "Afghanistan",
  ao: "Angola",
  ai: "Anguilla",
  // aq: "Antarctica",
  ax: "Åland Islands",
  al: "Albania",
  ad: "Andorra",
  la: "Lao People's Democratic Republic",
  ae: "united arab emirates",
  ar: "argentina",
  am: "Armenia",
  as: "American Samoa",
  tf: "French Southern Territories",
  ag: "Antigua and Barbuda",
  au: "Australia",
  at: "Austria",
  az: "Azerbaijan",
  bi: "Burundi",
  be: "Belgium",
  bj: "Benin",
  // bq: "Bonaire, Sint Eustatius and Saba",
  bf: "Burkina Faso",
  bd: "Bangladesh",
  bg: "Bulgaria",
  bh: "Bahrain",
  bs: "Bahamas",
  ba: "Bosnia and Herzegovina",
  // bl: "Saint Barthélemy",
  by: "Belarus",
  bz: "Belize",
  bm: "Bermuda",
  bo: "Bolivia, Plurinational State of",
  br: "Brazil",
  bb: "Barbados",
  bn: "Brunei Darussalam",
  bt: "Bhutan",
  bv: "Bouvet Island",
  bw: "Botswana",
  cf: "Central African Republic",
  ca: "Canada",
  cc: "Cocos (Keeling) Islands",
  ch: "Switzerland",
  cl: "Chile",
  cn: "China",
  ci: "Côte d'Ivoire",
  cm: "Cameroon",
  cd: "Congo, the Democratic Republic of the",
  cg: "Congo",
  ck: "Cook Islands",
  co: "Colombia",
  km: "Comoros",
  cv: "Cabo Verde",
  cr: "Costa Rica",
  cu: "Cuba",
  // cw: "Curaçao",
  cx: "Christmas Island",
  ky: "Cayman Islands",
  cy: "Cyprus",
  cz: "Czechia",
  de: "Germany",
  dj: "Djibouti",
  dm: "Dominica",
  dk: "Denmark",
  do: "Dominican Republic",
  dz: "Algeria",
  ec: "Ecuador",
  eg: "Egypt",
  er: "Eritrea",
  eh: "Western Sahara",
  es: "Spain",
  ee: "Estonia",
  et: "Ethiopia",
  fi: "Finland",
  fj: "Fiji",
  fk: "Falkland Islands (Malvinas)",
  fr: "France",
  fo: "Faroe Islands",
  fm: "Micronesia, Federated States of",
  ga: "Gabon",
  gb: "United Kingdom",
  ge: "Georgia",
  // gg: "Guernsey",
  gh: "Ghana",
  gi: "Gibraltar",
  gn: "Guinea",
  gp: "Guadeloupe",
  gm: "Gambia",
  gw: "Guinea-Bissau",
  gq: "Equatorial Guinea",
  gr: "Greece",
  gd: "Grenada",
  gl: "Greenland",
  gt: "Guatemala",
  gf: "French Guiana",
  gu: "Guam",
  gy: "Guyana",
  hk: "Hong Kong",
  hm: "Heard Island and McDonald Islands",
  hn: "Honduras",
  hr: "Croatia",
  ht: "Haiti",
  hu: "Hungary",
  id: "Indonesia",
  // im: "Isle of Man",
  in: "India",
  io: "British Indian Ocean Territory",
  ie: "Ireland",
  ir: "Iran, Islamic Republic of",
  iq: "Iraq",
  is: "Iceland",
  il: "Israel",
  it: "Italy",
  jm: "Jamaica",
  // je: "Jersey",
  jo: "Jordan",
  jp: "Japan",
  kz: "Kazakhstan",
  ke: "Kenya",
  kg: "Kyrgyzstan",
  kh: "Cambodia",
  ki: "Kiribati",
  kn: "Saint Kitts and Nevis",
  kr: "Korea, Republic of",
  kw: "Kuwait",
  lb: "Lebanon",
  lr: "Liberia",
  ly: "Libya",
  lc: "Saint Lucia",
  li: "Liechtenstein",
  lk: "Sri Lanka",
  ls: "Lesotho",
  lt: "Lithuania",
  lu: "Luxembourg",
  lv: "Latvia",
  mo: "Macao",
  // mf: "Saint Martin (French part)",
  ma: "Morocco",
  mc: "Monaco",
  md: "Moldova, Republic of",
  mg: "Madagascar",
  mv: "Maldives",
  mx: "Mexico",
  mh: "Marshall Islands",
  mk: "Macedonia, the former Yugoslav Republic of",
  ml: "Mali",
  mt: "Malta",
  mm: "Myanmar",
  me: "Montenegro",
  mn: "Mongolia",
  mp: "Northern Mariana Islands",
  mz: "Mozambique",
  mr: "Mauritania",
  ms: "Montserrat",
  mq: "Martinique",
  mu: "Mauritius",
  mw: "Malawi",
  my: "Malaysia",
  yt: "Mayotte",
  na: "Namibia",
  nc: "New Caledonia",
  ne: "Niger",
  nf: "Norfolk Island",
  ng: "Nigeria",
  ni: "Nicaragua",
  nu: "Niue",
  nl: "Netherlands",
  no: "Norway",
  np: "Nepal",
  nr: "Nauru",
  nz: "New Zealand",
  om: "Oman",
  pk: "Pakistan",
  pa: "Panama",
  pn: "Pitcairn",
  pe: "Peru",
  ph: "Philippines",
  pw: "Palau",
  pg: "Papua New Guinea",
  pl: "Poland",
  pr: "Puerto Rico",
  pt: "Portugal",
  py: "Paraguay",
  ps: "Palestine, State of",
  pf: "French Polynesia",
  qa: "Qatar",
  re: "Réunion",
  kp: "Korea, Democratic People's Republic of",
  ro: "Romania",
  ru: "Russian Federation",
  rw: "Rwanda",
  sa: "Saudi Arabia",
  sd: "Sudan",
  sn: "Senegal",
  sg: "Singapore",
  gs: "South Georgia and the South Sandwich Islands",
  sh: "Saint Helena, Ascension and Tristan da Cunha",
  sj: "Svalbard and Jan Mayen",
  sb: "Solomon Islands",
  sl: "Sierra Leone",
  sv: "El Salvador",
  sm: "San Marino",
  so: "Somalia",
  pm: "Saint Pierre and Miquelon",
  rs: "Serbia",
  // ss: "South Sudan",
  st: "Sao Tome and Principe",
  sr: "Suriname",
  sk: "Slovakia",
  si: "Slovenia",
  se: "Sweden",
  sz: "Swaziland",
  // sx: "Sint Maarten (Dutch part)",
  sc: "Seychelles",
  sy: "Syrian Arab Republic",
  tc: "Turks and Caicos Islands",
  td: "Chad",
  tg: "Togo",
  th: "Thailand",
  tj: "Tajikistan",
  tk: "Tokelau",
  tm: "Turkmenistan",
  tl: "Timor-Leste",
  to: "Tonga",
  tt: "Trinidad and Tobago",
  tn: "Tunisia",
  tr: "Turkey",
  tv: "Tuvalu",
  tw: "Taiwan, Province of China",
  tz: "Tanzania, United Republic of",
  ug: "Uganda",
  ua: "Ukraine",
  um: "United States Minor Outlying Islands",
  uy: "Uruguay",
  us: "United States of America",
  uz: "Uzbekistan",
  va: "Vatican",
  vc: "Saint Vincent and the Grenadines",
  ve: "Venezuela, Bolivarian Republic of",
  vg: "Virgin Islands, British",
  vi: "Virgin Islands, U.S.",
  vn: "Viet Nam",
  vu: "Vanuatu",
  wf: "Wallis and Futuna",
  ws: "Samoa",
  ye: "Yemen",
  za: "South Africa",
  zm: "Zambia",
  zw: "Zimbabwe",
};

export const COUNTRY_TITLE_LV: { [key in COUNTRY]: string } = {
  aw: "Aruba",
  af: "Afganistāna",
  ao: "Angola",
  ai: "Angilja",
  ax: "Olande",
  al: "Albānija",
  ad: "Andora",
  la: "Laosa",
  ae: "Apvienotie Arābu Emirāti",
  ar: "Argentīna",
  am: "Armēnija",
  as: "Amerikāņu Samoa",
  tf: "Francijas Dienvidjūru un Antarktikas Zemes",
  ag: "Antigva un Barbuda",
  au: "Austrālija",
  at: "Austrija",
  az: "Azerbaidžāna",
  bi: "Burundi",
  be: "Beļģija",
  bj: "Benina",
  bf: "Burkinafaso",
  bd: "Bangladeša",
  bg: "Bulgārija",
  bh: "Bahreina",
  bs: "Bahamas",
  ba: "Bosnija un Hercegovina",
  by: "Baltkrievija",
  bz: "Beliza",
  bm: "Bermuda",
  bo: "Bolīvija",
  br: "Brazīlija",
  bb: "Barbadosa",
  bn: "Bruneja",
  bt: "Butāna",
  bv: "Buvē Sala",
  bw: "Botsvana",
  cf: "Centrālāfrikas Republika",
  ca: "Kanāda",
  cc: "Kokosu Salas",
  ch: "Šveice",
  cl: "Čīle",
  cn: "Ķīna",
  ci: "Kotdivuāra",
  cm: "Kamerūna",
  cd: "Kongo Demokrātiskā Republika",
  cg: "Kongo",
  ck: "Kuka Salas",
  co: "Kolumbija",
  km: "Komoras",
  cv: "Kaboverde",
  cr: "Kostarika",
  cu: "Kuba",
  cx: "Ziemsvētku Sala",
  ky: "Kaimanu Salas",
  cy: "Kipra",
  cz: "Čehija",
  de: "Vācija",
  dj: "Džibutija",
  dm: "Dominika",
  dk: "Dānija",
  do: "Dominikāna",
  dz: "Alžīrija",
  ec: "Ekvadora",
  eg: "Ēģipte",
  er: "Eritreja",
  eh: "Rietumsahāra",
  es: "Spānija",
  ee: "Igaunija",
  et: "Etiopija",
  fi: "Somija",
  fj: "Fidži",
  fk: "Folklenda Salas",
  fr: "Francija",
  fo: "Fēru Salas",
  fm: "Mikronēzija",
  ga: "Gabona",
  gb: "Lielbritānija",
  // uk: "Lielbritānija",
  ge: "Gruzija",
  gh: "Gana",
  gi: "Gibraltārs",
  gn: "Gvineja",
  gp: "Gvadelupa",
  gm: "Gambija",
  gw: "Gvineja-Bisava",
  gq: "Ekvatoriālā Gvineja",
  gr: "Grieķija",
  gd: "Grenāda",
  gl: "Grenlande",
  gt: "Gvatemala",
  gf: "Gviāna",
  gu: "Guama",
  gy: "Gajāna",
  hk: "Honkonga",
  hm: "Hērda Sala un Makdonalda Salas",
  hn: "Hondurasa",
  hr: "Horvātija",
  ht: "Haiti",
  hu: "Ungārija",
  id: "Indonēzija",
  in: "Indija",
  io: "Indijas Okeāna Britu Teritorija",
  ie: "Īrija",
  ir: "Irana",
  iq: "Irāka",
  is: "Islande",
  il: "Izraēla",
  it: "Itālija",
  jm: "Jamaika",
  jo: "Jordānija",
  jp: "Japāna",
  kz: "Kazakhstāna",
  ke: "Kenija",
  kg: "Kirgizstāna",
  kh: "Kambodža",
  ki: "Kiribati",
  kn: "Sentkitsa un Nevisa",
  kr: "Dienvidkoreja",
  kw: "Kuveita",
  lb: "Libāna",
  lr: "Libērija",
  ly: "Lībija",
  lc: "Sentlūsija",
  li: "Lihtenšteina",
  lk: "Šrilanka",
  ls: "Lesoto",
  lt: "Lietuva",
  lu: "Luksemburga",
  lv: "Latvija",
  mo: "Makao",
  ma: "Maroka",
  mc: "Monako",
  md: "Moldova",
  mg: "Madagaskara",
  mv: "Maldīvija",
  mx: "Meksika",
  mh: " Māršala Salas",
  mk: "Maķedonija",
  ml: "Mali",
  mt: "Malta",
  mm: "Mjanma",
  me: "Melnkalne",
  mn: "Mongolija",
  mp: "Ziemeļu Marianas Salas",
  mz: "Mozambika",
  mr: "Mauritānija",
  ms: "Montserrata",
  mq: "Martinika",
  mu: "Maurīcija",
  mw: "Malāvija",
  my: "Malaizija",
  yt: "Majota",
  na: "Namībija",
  nc: "Jaunkaledonija",
  ne: "Nigēra",
  nf: "Norfolkas Sala",
  ng: "Nigērija",
  ni: "Nikaragva",
  nu: "Niue",
  nl: "Nīderlande",
  no: "Norvēģija",
  np: "Nepāla",
  nr: "Nauru",
  nz: "Jaunzelande",
  om: "Omāna",
  pk: "Pakistāna",
  pa: "Panama",
  pn: "Pitkērna",
  pe: "Peru",
  ph: "Filipīnas",
  pw: "Palau",
  pg: "Papua-Jaungvineja",
  pl: "Polija",
  pr: "Puertoriko",
  pt: "Portugāle",
  py: "Paragvaja",
  ps: "Palestīna",
  pf: "Franču Polinēzija",
  qa: "Katara",
  re: "Reinjona",
  kp: "Ziemeļkoreja",
  ro: "Rumānija",
  ru: "Krievija",
  rw: "Ruanda",
  sa: "Saūda Arābija",
  sd: "Sudāna",
  sn: "Senegāla",
  sg: "Singapūra",
  gs: "Dienviddžordžija un Dienvidsendviču Salas",
  sh: "Svētās Helēnas Sala",
  sj: "Svalbāra, Jana Majena sala",
  sb: "Zālamana Salas",
  sl: "Sjerraleone",
  sv: "Salvadora",
  sm: "Sanmarīno",
  so: "Somālija",
  pm: "Senpjēra un Mikelona",
  rs: "Serbija",
  st: "Santome un Prinsipi",
  sr: "Surinama",
  sk: "Slovākija",
  si: "Slovēnija",
  se: "Zviedrija",
  sz: "Svazilenda",
  sc: "Seišelas",
  sy: "Sīrija",
  tc: "Tērksas un Kaikosas",
  td: "Čada",
  tg: "Togo",
  th: "Taizeme",
  tj: "Tadžikistāna",
  tk: "Tokelau",
  tm: "Turkmenistāna",
  tl: "Austrumtimora",
  to: "Tonga",
  tt: "Trinidāda un Tobāgo",
  tn: "Tunisija",
  tr: "Turcija",
  tv: "Tuvalu",
  tw: "Taivāna",
  tz: "Tanzanija",
  ug: "Uganda",
  ua: "Ukraina",
  um: "ASV Mazās Aizjūras Salas",
  uy: "Urugvaja",
  us: "ASV",
  uz: "Uzbekistāna",
  va: "Vatikāns",
  vc: "Sentvinsenta un Grenadīnas",
  ve: "Venecuēla",
  vg: "Britu Virdžīnas",
  vi: "ASV Virdžīnas",
  vn: "Vjetnama",
  vu: "Vanuatu",
  wf: "Volisa un Futuna",
  ws: "Samoa",
  ye: "Jemena",
  za: "Dienvidāfrika",
  zm: "Zambija",
  zw: "Zimbabve",
};

export const CountryOptions = (Object.keys(COUNTRY_TITLE) as COUNTRY[]).map(
  (value) => ({
    value,
    text: COUNTRY_TITLE_LV[value],
    flag: value,
  }),
);

import {
  AWSurl,
  handleApiGet,
  handleApiPost,
  handleApiDelete,
  handleApiPut,
} from "./api";
import { IBaseForm, EntityForm } from "./index.d";

const dynamic = (type: string) => `${AWSurl}/${type}`;

export function getSingleData(type: string, slug: string) {
  // for single data fetch in translation menu
  return handleApiGet(
    `https://9y5r4t1jo2.execute-api.eu-central-1.amazonaws.com/PROD_V1/api/${type}/single?slug=${slug}`,
  );
}

// TODO: Fix (body:any) @Dainis
export function postData(idToken: string, type: string, body: any) {
  return handleApiPost(dynamic(type), idToken, body);
}

export function deleteData(
  idToken: string,
  type: string,
  slug: string,
  imageUrl?: string,
) {
  return handleApiDelete(dynamic(type), idToken, slug, imageUrl);
}

export function updateData(
  idToken: string,
  type: string,
  body: Partial<IBaseForm | EntityForm>,
) {
  return handleApiPut(dynamic(type), idToken, body);
}

import { styledWithTheme } from "./theme";

// eslint-disable-next-line import/prefer-default-export
export const StyledDropzoneContainer = styledWithTheme.div(({ theme }) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  padding: "1.250em",
  borderWidth: "2px",
  borderRadius: "2px",
  borderColor: theme.palette.mono.lighter,
  borderStyle: "dashed",
  backgroundColor: theme.palette.mono.lightest,
  color: theme.palette.mono.light,
  outline: "none",
  transition: "border .24s ease-in-out",
}));

export const StyledDropzoneArea = styledWithTheme.div({
  width: "100%",
  height: "100%",
  alignItems: "center",
  textAlign: "center",
  minHeight: "210px",
});

import React from "react";
import { Grid } from "semantic-ui-react";

import {
  StyledFooter,
  StyledContainer,
  StyledImage,
  StyledAdjusted,
  StyledGrid,
  StyledButton,
} from "./MainFooterStyles";

import { LOGO_WHITE } from "../../../images/imageSources";
import useMobileDetector from "../../../hooks/useMobileDetector";
import { scrollToTop } from "../../../helpers/globalFunctions";

// {(window.location.hostname.split(".")[0] === "staging" ||
// process.env.NODE_ENV !== "production") && <BuildDate />}

// {(window.location.hostname.split(".")[0] === "staging" ||
// process.env.NODE_ENV !== "production") && <BuildDate />}

const MainFooter: React.FC = () => {
  const mobile = useMobileDetector(1024);

  return (
    <StyledContainer>
      <StyledAdjusted>
        <StyledButton
          circular
          icon="angle up"
          floated="right"
          onClick={scrollToTop}
        />
      </StyledAdjusted>
      <StyledFooter>
        <StyledGrid stackable columns={3}>
          <Grid.Column width="5" verticalAlign="middle">
            <StyledImage
              src={LOGO_WHITE}
              size={mobile ? "large" : "small"}
              {...(!mobile && { floated: "right" })}
            />{" "}
            <div style={{ float: "right", color: "white" }}>Admin</div>
          </Grid.Column>
          <Grid.Column width="9" verticalAlign="middle" />
          <Grid.Column width="2" textAlign="center" verticalAlign="middle">
            Build: {process.env.REACT_APP_BUILD_DATE || "**-**-****"}
          </Grid.Column>
        </StyledGrid>
      </StyledFooter>
    </StyledContainer>
  );
};

export default MainFooter;

import React from "react";
import { useTranslation } from "react-i18next";

import { StyledSelect } from "../../organisms/Menus/FilterMenu/FilterMenuStyles";
import { IColumnProps } from "../../../interfaces/tables";
import { EntityCategoryOpts } from "../../../constants/entityCategories";

interface Props {
  column: IColumnProps<{}>;
}

const EntityCategoryFilter: React.FC<Props> = ({ column }) => {
  const { t } = useTranslation();
  return (
    <StyledSelect
      placeholder={t("Filter.Category")}
      fluid
      search
      selection
      multiple
      options={EntityCategoryOpts}
      onChange={(e: any, data: any) => column.setFilter(data.value)}
    />
  );
};

export default EntityCategoryFilter;

import { useTranslation } from "react-i18next";
import React from "react";
import { Button, Image } from "semantic-ui-react";

interface DropzonePreviewProps {
  open?: () => void;
  imageSrc?: string;
}

const DropzonePreview: React.FC<DropzonePreviewProps> = ({
  open,
  imageSrc,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      {imageSrc ? (
        <Image src={imageSrc} />
      ) : (
        <p>{t("CommonComponents.Dropzone.DragImageHere")}</p>
      )}
      <Button onClick={open} size="mini">
        {t("CommonComponents.Dropzone.FindInFiles")}
      </Button>
    </div>
  );
};

export default DropzonePreview;

import React from "react";

import { IRow } from "../../../interfaces/tables";
import { IBaseForm } from "../../../api";
import { definedAuthorTypes } from "../../molecules/FormFields/FormAuthorFields";

interface Props {
  cell: { row: IRow<IBaseForm> };
}

const AuthorCell: React.FC<Props> = ({ cell: { row } }) => {
  const { author, authorType, creatorName, creator } = row.original;

  if (authorType === definedAuthorTypes.anonymous) return <>anonīms</>;

  return (
    // This is internal site
    // eslint-disable-next-line react/jsx-no-target-blank
    <>{creatorName || author || creator}</>
  );
};

export default AuthorCell;

import { useContext } from "react";
import ScriptContext, { IScriptContext } from "context/ScriptContext";

const useScriptContext = (): IScriptContext => {
  const context = useContext(ScriptContext);
  if (context === null) {
    throw new Error("useScriptContext cannot be used without Provider!");
  }

  return context;
};

export default useScriptContext;

import { SemanticButtonCP } from "../../../../helpers/ReactHelpers";
import { styledWithTheme } from "../../../../styles/theme";

export const BaseButton = styledWithTheme(SemanticButtonCP)<{
  initialPadding?: 0 | 1;
}>(({ theme, initialPadding }) => ({
  "&&&": {
    ...theme.typography.button1,
    padding: initialPadding ? undefined : ".62em 1.2em .62em",
    backgroundColor: theme.palette.mono.base,
    color: theme.palette.mono.black,
    "&:hover": {
      backgroundColor: theme.palette.mono.black,
      borderColor: theme.palette.mono.black,
      color: theme.palette.mono.white,
    },
  },
}));

export const MinimalActionButton = styledWithTheme(BaseButton)`
  &&& {
    margin: 0;
    background-color: ${({ theme }) => theme.palette.mono.white};
  }
`;

export const ActionButton = styledWithTheme(MinimalActionButton)`
  &&& {
    border: solid 1px ${({ theme }) => theme.palette.mono.black};
  }
`;

export const PrimaryButton = styledWithTheme(BaseButton)(({ theme }) => ({
  "&&&": {
    ...theme.typography.button2,
    backgroundColor: theme.palette.primary.main,
  },
}));

export const ConfirmButton = styledWithTheme(BaseButton)(({ theme }) => ({
  "&&&": {
    ...theme.typography.button2,
    backgroundColor: theme.palette.success.dark,
    margin: "0.2em",
  },
}));

export const CancelButton = styledWithTheme(BaseButton)(({ theme }) => ({
  "&&&": {
    ...theme.typography.button2,
    backgroundColor: theme.palette.error.dark,
    margin: "0.2em",
  },
}));

export const BasicButton = styledWithTheme(BaseButton)(({ theme }) => ({
  "&&&": {
    ...theme.typography.basicButton,
    backgroundColor: "inherit",
    padding: "0.2 em",
    margin: "0.2 em",
  },
}));

import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { withRouter, RouteComponentProps } from "react-router-dom";

import GlobalStateContext from "./GlobalContext";

import definedLocales from "../locales/definedLocales";

import "moment/locale/lv";

interface IGlobalContextState {
  locale: definedLocales;
  translationLocale: definedLocales;
}

class GlobalContextProvider extends Component<
  WithTranslation & RouteComponentProps,
  IGlobalContextState
> {
  state: IGlobalContextState = {
    locale: definedLocales.latvian,
    translationLocale: definedLocales.english,
  };

  handleSetLocale = async (language: definedLocales) => {
    // moment.locale(language);
    await this.props.i18n?.changeLanguage(language);
    this.setState({ locale: language });
  };

  handleSetTranslationLocale = async (language: definedLocales) => {
    this.setState({ translationLocale: language });
  };

  render() {
    const { locale, translationLocale } = this.state;
    const { children } = this.props;

    return (
      <GlobalStateContext.Provider
        value={{
          locale: {
            currentLocale: locale,
            setLocale: this.handleSetLocale,
            translationLocale,
            setTranslationLocale: this.handleSetTranslationLocale,
          },
        }}
      >
        {children}
      </GlobalStateContext.Provider>
    );
  }
}

export default withTranslation()(withRouter(GlobalContextProvider));

import { convertFromRaw } from "draft-js";
import CognitoUserAttrs from "api/cognito/CognitoUserAttrs";
import Cognito from "api/cognito/Cognito";

import {
  EntityForm,
  isCompany,
  isOrganization,
  isSelfEmployed,
} from "../api/index.d";
import { slugifyWord } from "../helpers/globalFunctions";
import { uploadImageFromFile } from "../api/ImageApi";

export default async function EntityPostModel<T extends EntityForm>(
  form: T,
  creator?: CognitoUserAttrs | null,
): Promise<T> {
  const token = Cognito.getIdTokenFromStorage();
  const { title, slug, shortSlug, description, imagePointer, imageUrl } = form;

  const parsed = { ...form };

  /* eslint-disable */
  delete parsed.imagePointer;
  parsed.imageUrl       = imagePointer ? await uploadImageFromFile(token, imagePointer) || imageUrl : imageUrl;
  parsed.slug           = slug || slugifyWord(title);
  parsed.shortSlug      = shortSlug ? slugifyWord(shortSlug) : parsed.slug;
  parsed.seoDescription = description ? convertFromRaw(description).getPlainText().slice(0, 320) : "";
  parsed.creator        = form.creator || creator?.email; // should be passed on create only but we are relying that lambda will do its thing
  parsed.creatorId      = form.creatorId || creator?.sub;// should be passed on create only but we are relying that lambda will do its thing

  /* eslint-enable */

  if (isOrganization(form) && isOrganization(parsed)) {
    parsed.organizationId = form.organizationId || slugifyWord(title);
  }

  if (isCompany(form)) {
    // no unique fields
  }

  /* eslint-disable */
  if (isSelfEmployed(form) && isSelfEmployed(parsed)) {
    const jointTitle = [form.name, form.surname].filter((data) => data).join(" ");

    parsed.title          = jointTitle;
    parsed.slug           = slug || slugifyWord(jointTitle);
    parsed.shortSlug      = parsed.slug;

    // !NB creator is the sole owner of this profile. Profiles created from admin currently have no owner
    parsed.creator        = form.creator || undefined;
    parsed.creatorId      = form.creatorId || undefined;
  
  }
  /* eslint-enable */

  // i am not sure what comment below means and what organizationId did ...
  // add organizationId unique and check when updating! organizationId || slugify(title)
  return parsed;
}

import * as React from "react";
import { Dropdown, FormField } from "semantic-ui-react";

import { IDropDownOption } from "../../../../interfaces";

export interface PictureArrayFieldProps {
  id: string;
  label: string;
  selectedValues: string[];
  options: IDropDownOption[];
  onChange: (id: string, value: string[]) => void;
  onAdd: (id: string, value: string) => void;
  search?: boolean;
  loading?: boolean;
  disabled?: boolean;
}

const PictureArrayField: React.FC<PictureArrayFieldProps> = ({
  id,
  label,
  selectedValues,
  options,
  onChange,
  onAdd,
  search,
  loading,
  disabled,
}) => {
  const searchMessage = search
    ? "Pievieno, lai atrastu!"
    : "Nav tādas vērtības. Vai vēlies pievienot?";
  return (
    <FormField
      label={label}
      control={Dropdown}
      multiple
      value={selectedValues}
      options={options}
      allowAdditions
      search
      selection
      additionLabel="Pievieno "
      noResultsMessage={searchMessage}
      onChange={(e: any, { value }: any) => onChange(id, value)}
      onAddItem={(e: any, { value }: any) => onAdd(id, value)}
      loading={loading}
      disabled={loading || disabled}
    />
  );
};

export default PictureArrayField;

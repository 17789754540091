/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from "react";
import { Form, Input, Loader, Grid } from "semantic-ui-react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import SimpleButton, {
  definedButtonTypes,
} from "../atoms/Buttons/SimpleButton/SimpleButton";
import Typography from "../atoms/Typography/Typography";
import { definedTypographyTypes } from "../atoms/Typography/TypographyStyles";
import { getSingleData } from "../../api/DynamicApi";
import { IndexSignature } from "../../interfaces";
import EventGridCard, {
  definedCardTypes,
  definedCardSizes,
} from "../molecules/Cards/EventGridCard";
import {
  UpdateTildeTranslation,
  GetTildeTranslation,
} from "../../api/tildeApi";
import { definedRouteTypes } from "../../api/api";
import { extractInfoFromLink } from "../../helpers/formatters";
import { IHomeDisplay } from "../../api";
import SimpleMessage from "../atoms/Text/SimpleMessage";
import useGlobalContext from "../../hooks/useGlobalContext";
import definedLocales from "../../locales/definedLocales";

const formFields = [
  {
    id: "url",
    label: "Iegūt no saites",
  },
  {
    id: "text",
    label: "Teksts ko tulkot",
  },
  {
    id: "translation",
    label: "Vēlamais tulkojums",
  },
];
const languageOptions = [
  { value: "EN", text: "ENG" },
  { value: "DE", text: "DE" },
];
const TranslationPage = () => {
  const [formState, setFormState] = useState<IndexSignature>({});
  const [loading, setLoading] = useState(false);
  const [cardPreview, setPreview] = useState<IHomeDisplay>();
  const {
    locale: { translationLocale, setTranslationLocale },
  } = useGlobalContext();
  const { t } = useTranslation();

  useEffect(() => {
    setTranslationLocale(languageOptions[0].value as definedLocales);
    setFormState((prevState) => ({
      ...prevState,
      language: languageOptions[0].value,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const setTranslation = async () => {
    if (formState.translation && formState.text) {
      setLoading(true);
      UpdateTildeTranslation(
        formState.text,
        formState.translation,
        formState.language,
      )
        .then((res) => {
          if (res.status !== "Error") {
            toast.success("Tulkojums pārveidots veiksmīgi!");
            setLoading(false);
          } else {
            toast.error(t("Toasts.GenericError"));
            setLoading(false);
          }
        })
        .catch((err) => {
          toast.error(t("Toasts.GenericError"));
          console.error("Translation update error", err);
        });
    }
  };

  const findByUrl = async (url: string) => {
    setLoading(true);
    const urlInfo = extractInfoFromLink(url);
    const { type: urlType, slug: urlSlug } = urlInfo;
    if (
      urlType &&
      Object.values(definedRouteTypes).includes(urlType as definedRouteTypes) &&
      urlSlug
    ) {
      const requestUrl = await getSingleData(urlType, urlSlug);
      const translation = await GetTildeTranslation(
        requestUrl.title,
        translationLocale,
      );
      setPreview(requestUrl);
      setFormState((prevState) => ({
        ...prevState,
        url,
        text: requestUrl.title,
        translation,
      }));
    } else if (url) {
      toast.error("Kaut kas nogāja greizi.. Šāds raksts nav atrasts!");
    }
    setLoading(false);
  };

  const setFormValues = (e: any, { id, value }: any) => {
    setFormState((prevState) => ({ ...prevState, [id]: value }));
  };
  return (
    <div>
      <Typography type={definedTypographyTypes.h2}>
        Rediģēt tulkojumu
      </Typography>
      <SimpleMessage
        title="Uzmanību! Jāievada pilna saite no Latviesi.com Portāla"
        listInfo={[
          "Piemēram: https://www.latviesi.com/jaunumi/rinkevics-arpus-es-izbraukusie-var-iesprust-uz-loti-ilgu-laiku ",
          "Tildes tulkojuma izmaiņa, var prasīt kādu laiku.",
        ]}
      />
      <Grid divided="vertically">
        <Grid.Row>
          <Grid.Column computer={10} mobile={16}>
            <Form>
              <Form.Select
                fluid
                id="language"
                label="Izvēlies valodu"
                options={languageOptions}
                value={formState.language || languageOptions[0].value}
                onChange={(e: any, { id, value }: any) => {
                  setFormValues(e, { id, value });
                  setTranslationLocale(value);
                }}
              />
              {formFields.map((field) => (
                <Form.Field
                  key={field.id}
                  id={field.id}
                  label={field.label}
                  control={Input}
                  value={formState[field.id] || ""}
                  onChange={setFormValues}
                  onBlur={
                    field.id === "url"
                      ? (e: any) => findByUrl(e.target.value)
                      : () => ({})
                  }
                />
              ))}
              <SimpleButton
                variant={definedButtonTypes.confirm}
                onClick={setTranslation}
                loading={loading}
              >
                Labot
              </SimpleButton>
            </Form>
          </Grid.Column>
          <Grid.Column computer={6} mobile={16}>
            <Typography type={definedTypographyTypes.h3}>
              Priekšskatījums:
            </Typography>
            {loading ? (
              <Loader active inline="centered" size="mini">
                Lūdzu uzgaidi! Lādējas...
              </Loader>
            ) : cardPreview ? (
              <EventGridCard
                route={cardPreview.type}
                event={cardPreview}
                type={definedCardTypes.post}
                size={definedCardSizes.large}
                translate
              />
            ) : (
              <></>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default TranslationPage;

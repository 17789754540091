import moment from "moment";
import { convertFromRaw } from "draft-js";
import Cognito from "api/cognito/Cognito";

import { IVideoNewsForm } from "../api/index.d";
import {
  slugifyWord,
  findBestThumbnailFromYoutubeLink,
} from "../helpers/globalFunctions";
import { CATEGORIES } from "../constants/categories";
import { ContentStatusTypes } from "../api/api";
import { postThumbnail, uploadImageFromFile } from "../api/ImageApi";
import { isYoutubeLink } from "../helpers/regexp";

export default async function VideoNewsModel(
  form: IVideoNewsForm,
): Promise<IVideoNewsForm> {
  const {
    id,
    title,
    description,
    category,
    subCategories,
    countries,
    address,
    authorType,
    author,
    creator,
    creatorName,
    organizationId,
    anotherAuthor,
    type,
    createdAt,
    toBePostedAt,
    highlighted,
    highlightedInCountry,
    show,
    imageAuthor,
    customLabel,
    imagePointer,
    exclusive,
    source,
    displayInHomePage,
    mediaLink,
    slug,
    online,
    organizationInfo,
    homepageRank,
    region,
  } = form;

  const realSlug = slug || slugifyWord(title);

  let newImageUrl;
  if (imagePointer) {
    const token = Cognito.getIdTokenFromStorage();
    newImageUrl = await uploadImageFromFile(token, imagePointer);
  } else if (isYoutubeLink(mediaLink)) {
    const token = Cognito.getIdTokenFromStorage();
    // We are reuploading youtube thumbnails just in case.
    const ytlink = await findBestThumbnailFromYoutubeLink(mediaLink);
    const upload = await postThumbnail(
      token,
      `youtube-thumbnail-${realSlug}`,
      ytlink,
    );
    newImageUrl = upload.Location;
  }

  const seoDescription = description
    ? convertFromRaw(description).getPlainText().slice(0, 320)
    : "";

  const newSubCategories = subCategories ? [...subCategories] : [];
  // Due to elastic search category must also be placed in subcategories.
  const allCategories = Object.values(CATEGORIES);
  // eslint-disable-next-line no-unused-expressions
  subCategories?.forEach((sub, idx) => {
    if (allCategories.find((cat) => sub === cat))
      newSubCategories.splice(idx, 1);
  });

  if (category) newSubCategories.push(category);

  const isItToBePosted = toBePostedAt ? moment().isBefore(toBePostedAt) : false;
  return {
    id,
    slug: realSlug,
    imageUrl: newImageUrl,
    imageAuthor,
    createdAt,
    toBePostedAt: toBePostedAt || moment(),
    title,
    countries,
    address,
    description,
    category,
    subCategories: newSubCategories,
    seoDescription,
    authorType,
    author,
    creator,
    creatorName,
    organizationId,
    anotherAuthor,
    customLabel,
    highlighted,
    highlightedInCountry,
    show,
    exclusive,
    type,
    source,
    displayInHomePage,
    mediaLink,
    online,
    status: isItToBePosted
      ? ContentStatusTypes.toBePosted
      : ContentStatusTypes.active,
    organizationInfo,
    homepageRank,
    region,
  };
}

import Rollbar from "rollbar";

export const RollbarErrorTracking = (() => {
  const RollbarObj = new Rollbar({
    accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,
  });
  const logErroInfo = (info: any) => {
    RollbarObj.info(info);
  };
  const logErrorInRollbar = (error: any) => {
    throw new Error(error);
  };
  return { logErroInfo, logErrorInRollbar };
})();
export default RollbarErrorTracking;

import React from "react";
import styled from "styled-components";
import { Segment } from "semantic-ui-react";

export interface FormSegmentProps {}

const StyledSegment = styled(Segment)({
  "&&&": { boxShadow: "none", margin: 0 },
});

const FormSegment: React.FC<FormSegmentProps> = ({ children }) => {
  return <StyledSegment>{children}</StyledSegment>;
};

export default FormSegment;

import * as React from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useAuthContext } from "hooks/useAuthorizedContext";

import { StyledWrapper, StyledForm } from "./LogInFormStyles";

import SimpleButton, {
  definedButtonTypes,
} from "../../../atoms/Buttons/SimpleButton/SimpleButton";
import FormTextInput from "../../../molecules/FormFields/FormTextInput";

interface Props { }

export interface LogInFormState {
  username: string;
  password: string;
}

const Form = "RegisterForm";
const Err = `${Form}.Errors`;

const LogInForm: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { login, loading } = useAuthContext();
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");

  const handleLogIn = async () => {
    try {
      await login(username, password);
    } catch (err) {
      console.error(err);
      toast.error(t(`${Err}.WrongUserNameOrPassword`));
      setPassword("");
    }
  };

  const handleSetValue = (id: string, value: string) => {
    if (id === "username") {
      setUsername(value);
    } else if (id === "password") {
      setPassword(value);
    }
  };

  return (
    <StyledForm disabled={loading} onSubmit={handleLogIn}>
      <FormTextInput
        id="username"
        label={t(`${Form}.Username`)}
        onChange={handleSetValue}
        value={username}
        inputType="email"
      />
      <FormTextInput
        id="password"
        label={t(`${Form}.Password`)}
        onChange={handleSetValue}
        inputType="password"
        value={password}
      />
      <StyledWrapper>
        <SimpleButton
          id="login-button"
          variant={definedButtonTypes.primary}
          type="submit"
          loading={loading}
        >
          {t(`${Form}.Login`)}
        </SimpleButton>
      </StyledWrapper>
    </StyledForm>
  );
};

export default LogInForm;

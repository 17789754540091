import styled from "styled-components";
import { Dropdown, Icon, Input } from "semantic-ui-react";

import { styledWithTheme } from "../../../../styles/theme";

// TODO: styles, and interfaces and groom Dropdown to use our from atoms
export const StyledContainer = styledWithTheme.div`
  &&& {
    padding: 30px 0 20px 0;
    border-bottom: solid 1px ${({ theme }) => theme.palette.mono.base};
  }
`;

export const StyledFilter = styled.div`
  &&& {
    display: grid;
    grid-template-columns: 20% 20% 40% 20%;
  }
`;

export const StyledText = styled.div`
  &&& {
    margin-right: 10px;
    line-height: 38px;
    text-align: right;
  }
`;

export const StyledSelect = styledWithTheme(Dropdown)`
  &&& {
    border: none;
    border-bottom: solid 1px ${({ theme }) => theme.palette.primary.main};
    border-radius: 0px;
  }
`;
export const StyledInput = styledWithTheme(Input)`
  &&& {
    border: none;
    border-bottom: solid 1px ${({ theme }) => theme.palette.primary.main};
    border-radius: 0px;
  }
`;

export const StyledIcon = styledWithTheme(Icon)`
  &&& {
    height: 40px;
    width: 35px;
    margin: 0;
    border-radius: 5px;
    margin-left: 10px;
    line-height: 38px;
    &:hover {
      color: ${({ theme }) => theme.palette.mono.black} !important;
    }
  }
`;

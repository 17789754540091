import React from "react";

import {
  StyleDefaultMenu,
  StyledMainMenu,
  StyledVerticalMenu,
  StyledCountryMenu,
  StyledSecondaryMenu,
} from "./SimpleMenuStyles";

export interface SimpleMenuProps {
  secondary?: boolean;
  type?: string;
}

export enum definedMenuTypes {
  main = "main",
  secondary = "secondary",
  small = "small",
  vertical = "vertical",
  country = "country",
  default = "default",
}

const SimpleMenu: React.FC<SimpleMenuProps> = ({ children, type }) => {
  let StyledMenu = StyleDefaultMenu;
  if (type === definedMenuTypes.main) StyledMenu = StyledMainMenu;
  else if (type === definedMenuTypes.vertical) StyledMenu = StyledVerticalMenu;
  else if (type === definedMenuTypes.country) StyledMenu = StyledCountryMenu;
  else if (type === definedMenuTypes.secondary) {
    StyledMenu = StyledSecondaryMenu;
  }

  return (
    <StyledMenu type={type} text vertical={type === definedMenuTypes.vertical}>
      {children}
    </StyledMenu>
  );
};

SimpleMenu.defaultProps = {
  type: definedMenuTypes.default,
};

export default SimpleMenu;

import { Select, Input } from "semantic-ui-react";

import { TypeOptions } from "../../../constants/types";
import { CountryOptions } from "../../../constants/country";
import { categoryOpts } from "../../../constants/categories";
import { SpecialAttributeOptions } from "../../../constants/specialAttributes";
import { LocaleOptions } from "../../../locales/definedLocales";

const iframeFields = [
  {
    id: "type",
    label: "Tips/i",
    options: TypeOptions,
    control: Select,
    clearable: true,
    multiple: true,
  },
  {
    id: "country",
    label: "Valsts/is",
    options: CountryOptions,
    control: Select,
    multiple: true,
    search: true,
  },
  {
    id: "category",
    label: "Kategorija/s",
    options: categoryOpts,
    control: Select,
    multiple: true,
    search: true,
  },
  {
    id: "special",
    label: "Īpašās atzīmes",
    options: SpecialAttributeOptions,
    control: Select,
    clearable: true,
  },
  {
    id: "language",
    label: "Valoda",
    options: LocaleOptions,
    control: Select,
    clearable: true,
  },
  // {
  //   id: "amount",
  //   label: "Ierakstu skaits",
  //   control: Input,
  //   type: "number",
  // },
  {
    id: "width",
    label: "Platums",
    control: Input,
  },
  {
    id: "height",
    label: "Augstums",
    control: Input,
  },
];

export default iframeFields;

import * as React from "react";
import { CSSObject } from "styled-components";

import {
  StyledBody1,
  StyledH1,
  StyledH2,
  StyledH3,
  StyledTitle1,
  StyledTitle2,
  StyledTitle3,
  StyledTitle4,
  StyledButton1Text,
  StyledButton2Text,
  StyledCaption,
  StyledSmallCaption,
  definedTypographyTypes,
} from "./TypographyStyles";

import { TComponentRef } from "../Primitives/DynamicRefComponent";

export const definedTypography = definedTypographyTypes; // FIXME: why dis?
interface TypographyProps {
  align?: CSSObject["textAlign"];
  color?: string;
  as?: TComponentRef;
  type?: definedTypographyTypes;
  inline?: boolean;
}

const defaultTypeMapping: Record<definedTypographyTypes, TComponentRef> = {
  h1: "h1",
  h2: "h2",
  h3: "h3",
  title1: "h4",
  title2: "h4",
  title3: "h4",
  title4: "h5",
  body1: "p",
  caption: "span",
  smallCaption: "span",
  button1: "span",
  button2: "span",
};

const Typography: React.FC<TypographyProps> = ({
  children,
  align,
  color,
  as: asComponent,
  type,
}) => {
  let Component = StyledBody1;
  if (type === definedTypography.button1) {
    Component = StyledButton1Text;
  } else if (type === definedTypography.button2) {
    Component = StyledButton2Text;
  } else if (type === definedTypography.caption) {
    Component = StyledCaption;
  } else if (type === definedTypography.smallCaption) {
    Component = StyledSmallCaption;
  } else if (type === definedTypography.h1) {
    Component = StyledH1;
  } else if (type === definedTypography.h2) {
    Component = StyledH2;
  } else if (type === definedTypography.h3) {
    Component = StyledH3;
  } else if (type === definedTypography.title1) {
    Component = StyledTitle1;
  } else if (type === definedTypography.title2) {
    Component = StyledTitle2;
  } else if (type === definedTypography.title3) {
    Component = StyledTitle3;
  } else if (type === definedTypography.title4) {
    Component = StyledTitle4;
  }

  const innerComponentRef =
    asComponent || defaultTypeMapping[type || definedTypography.body1];

  return (
    <Component align={align} color={color} componentRef={innerComponentRef}>
      {children}
    </Component>
  );
};

Typography.defaultProps = {
  align: "left",
  type: definedTypography.body1,
};

export default Typography;

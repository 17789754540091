import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Loader, Dimmer } from "semantic-ui-react";
import styled from "styled-components";
import useAuthorizedContext from "hooks/useAuthorizedContext";

import FormSelect from "./FormSelect";
import { mapEntityInfo } from "./FormOrganizationField";

import { IOrganizationInfo, EntityForm, isCompany } from "../../../api/index.d";

interface Props {
  onChange: (id: string, value?: IOrganizationInfo | string) => void;
  value?: string;
  required?: boolean;
}

const StyledLoader = styled.div({
  position: "relative",
  padding: "1em",
});
const mapAvailableCompanyOpts = (orgs?: EntityForm[]) => {
  if (orgs) {
    return orgs
      .filter((org) => isCompany(org))
      .map((o) => ({
        text: o.title,
        value: o.slug,
        key: o.slug,
      }))
      .sort((a, b) => a.text.localeCompare(b.text));
  }

  return [];
};

const FormCompanyField: React.FC<Props> = ({ onChange, value, required }) => {
  const { t } = useTranslation();
  const { orgData } = useAuthorizedContext();

  const OrgInfos = useMemo(() => {
    return mapEntityInfo(orgData);
  }, [orgData]);

  const OrgOptions = useMemo(() => {
    return mapAvailableCompanyOpts(orgData);
  }, [orgData]);

  const changeFn = (id: string, data: string) => {
    onChange("organizationInfo", OrgInfos.find((org) => org.id === data) || "");
    onChange(id, data);
  };

  if (OrgOptions.length === 0) {
    return (
      <StyledLoader>
        <Dimmer active inverted>
          <Loader active inline="centered" />
        </Dimmer>
      </StyledLoader>
    );
  }

  return (
    <FormSelect
      label={t("organization")}
      placeholder={t("Izvēlies organizāciju")}
      id="organizationId"
      value={value}
      required={required}
      options={OrgOptions}
      onChange={changeFn}
      search
    />
  );
};

export default FormCompanyField;

import { IFilters } from "api";
import { queryStringBuilder } from "helpers/globalFunctions";

import { AWSurl, handleApiGet } from "./api";
import { BackendUrl } from "./newBackendApi";

const url = `${AWSurl}`;

export default function getWideFilterQuery(query: string) {
  const filterUrl = `${url}/filter${query}`;
  return handleApiGet(filterUrl);
}

export function fullSearch(query: string) {
  const filterUrl = `${url}/search${query}`;
  return handleApiGet(filterUrl);
}

// new BE

export function getTypeData(token: string, opts: IFilters) {
  const query = queryStringBuilder(opts);
  const filterUrl = `${BackendUrl}/admin/filter/data${query}`;
  const headers: Record<string, string> = {};
  headers.Authorization = token;
  return handleApiGet(filterUrl, headers);
}
export function getTypeCount(token: string, opts?: IFilters) {
  const query = queryStringBuilder(opts);
  const headers: Record<string, string> = {};
  headers.Authorization = token;
  return handleApiGet(`${BackendUrl}/admin/filter/total${query}`, headers);
}

import { ComponentType } from "react";
import { EditorPlugin } from "@draft-js-plugins/editor";

import removeLink from "./modifiers/removeLink";
import addLink from "./modifiers/addLink";
import isLinkSelected from "./utils/isLinkSelected";
import linkStrategy from "./linkStrategy";
import * as types from "./constants";
import DefaultLink from "./components/DefaultLink";

interface LinkComponentProps {}

export interface LinkPluginConfig {
  linkComponent?: ComponentType<LinkComponentProps>;
  decorator?(
    component: ComponentType<LinkComponentProps>,
  ): ComponentType<LinkComponentProps>;
}

export interface LinkPlugin extends EditorPlugin {
  addLink: typeof addLink;
  removeLink: typeof removeLink;
  isLinkSelected: typeof isLinkSelected;
  types: typeof types;
}

export default function createLinkPlugin(
  config: LinkPluginConfig = {},
): LinkPlugin {
  let Link =
    config.linkComponent || (DefaultLink as ComponentType<LinkComponentProps>);
  if (config.decorator) {
    Link = config.decorator(Link);
  }
  return {
    decorators: [
      {
        strategy: linkStrategy,
        component: Link,
      },
    ],
    addLink,
    removeLink,
    isLinkSelected,
    types,
  };
}

import React, { useState } from "react";
import { Modal, Button } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import useAuthorizedContext from "hooks/useAuthorizedContext";

import SimpleButton, {
  definedButtonTypes,
} from "../../atoms/Buttons/SimpleButton/SimpleButton";
import { deleteData } from "../../../api/DynamicApi";
import { IBaseForm, IOrganizationForm } from "../../../api";
import useTypeTableContext, {
  definedTableActions,
} from "../../../hooks/useTypeTableContext";

interface DeleteModalProps {
  data: IBaseForm | IOrganizationForm;
}

const DeleteModal: React.FC<DeleteModalProps> = ({ data }) => {
  const { type, slug, imageUrl, title } = data;
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const typeTableContext = useTypeTableContext();
  const { token } = useAuthorizedContext();

  const deleteFn = async () => {
    try {
      const act = definedTableActions.remove;
      setLoading(true);
      const idToken = await token();
      await deleteData(idToken, type, slug, imageUrl);
      toast.success("Dzēsts veiksmīgi");
      if (typeTableContext) typeTableContext.updateTableEntry(data, act);
    } catch {
      toast.error("Dzēšot radās kļūda! Mēģini vēlreiz!");
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };

  return (
    <Modal
      size="mini"
      open={open}
      trigger={
        <Button
          size="mini"
          icon="close"
          onClick={() => setOpen(true)}
          id={`delete-${slug}`}
        />
      }
      closeOnEscape={false}
      closeOnDimmerClick={false}
    >
      <Modal.Header>Tiešām dzēst?</Modal.Header>
      <Modal.Content>
        <p>{`Vai tiešām vēlies dzēst "${title}"?`}</p>
        <p>Šī darbība ir neatgriezeniska.</p>
      </Modal.Content>
      <Modal.Actions>
        <SimpleButton
          variant={definedButtonTypes.secondary}
          onClick={() => setOpen(false)}
          disabled={loading}
        >
          {t("Forms.Modal.Cancel")}
        </SimpleButton>
        <SimpleButton
          variant={definedButtonTypes.primary}
          onClick={deleteFn}
          loading={loading}
        >
          {t("Forms.Modal.Delete")}
        </SimpleButton>
      </Modal.Actions>
    </Modal>
  );
};

export default DeleteModal;

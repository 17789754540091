import useAuthorizedContext from "hooks/useAuthorizedContext";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import useTypeTableContext, {
  definedTableActions,
} from "./useTypeTableContext";

import { IBaseForm } from "../api/index.d";
import DynamicModel from "../models/DynamicModel";
import { updateData, postData } from "../api/DynamicApi";
import { definedErrors, updateHomepageRank } from "../api/api";

const useSubmitRankedPosts = <T extends IBaseForm>(
  update?: boolean,
  skipRanking = false,
): ((form: T) => Promise<T>) => {
  const { token } = useAuthorizedContext();
  const typeTableContext = useTypeTableContext();
  const { t } = useTranslation();

  const act = update ? definedTableActions.update : definedTableActions.add;

  const submitFormQuery = async (form: T) => {
    let published;
    try {
      // 1. Create "postable" object
      const postForm = await DynamicModel(form);
      const imageUploadCount = postForm?.imageLinks?.length || 0;
      const { homepageRank } = postForm;

      const idToken = await token();
      // 2. Create or update it (except rank, lambda ignores it)
      if (act === definedTableActions.update) {
        published = await updateData(idToken, postForm.type, postForm);
      } else if (act === definedTableActions.add) {
        published = await postData(idToken, postForm.type, postForm);
      } else {
        throw new Error("wrong action type used for updateNews");
      }

      // 3. Capture any changes lambda did to object
      const updated = {
        ...postForm,
        slug: published.body.slug,
        createdAt: published.body.createdAt || postForm.createdAt,
        updatedAt: published.body.updatedAt,
        homepageRank: skipRanking ? homepageRank : undefined,
      };

      // 4. Update or create this object in client cache
      if (typeTableContext) {
        typeTableContext.updateTableEntry(updated, act);
      }
      if (skipRanking) {
        toast.success(
          t(
            update ? "Toasts.EditsSuccess" : `Toasts.Publish.${postForm.type}`,
            { count: imageUploadCount },
          ),
        );
        return updated;
      }

      // 5. Update ranking of the post in db and get all items that got reranked
      const withRank = { ...updated, homepageRank };
      const reRanked = await updateHomepageRank(idToken, withRank);

      // 6. Update ranking of posts in client side
      if (typeTableContext && reRanked.length) {
        reRanked.forEach((item) => {
          typeTableContext.updateTableEntry(item, definedTableActions.update);
        });
      } else if (typeTableContext) {
        // if no rank change happened during update, we have to "give cache back" the rank we took away in step 3
        typeTableContext.updateTableEntry(withRank, definedTableActions.update);
      }

      toast.success(
        t(update ? "Toasts.EditsSuccess" : `Toasts.Publish.${postForm.type}`, {
          count: imageUploadCount,
        }),
      );

      return updated;
    } catch (err) {
      if (err.message.includes(definedErrors.slugExists)) {
        toast.error(t("Toasts.SlugExists"));
      } else if (err.message.includes(definedErrors.noImagesInGallery)) {
        toast.error(t("Toasts.NoImagesInGallery"));
      } else if (published) {
        toast.error(t("Toasts.RankingFailed"));
      } else {
        toast.error(t("Toasts.GenericError"));
      }
      console.error("useSubmitRankedPosts failed", err);
      throw new Error("useSubmitRankedPosts failed");
    }
  };

  return submitFormQuery;
};

export default useSubmitRankedPosts;

/* eslint-disable no-param-reassign */
import React, { useCallback, FC } from "react";
import { useImmerReducer } from "use-immer";
import { FileRejection, useDropzone } from "react-dropzone";

import DropzoneContainer from "./Fragments/DropzoneContainer";
import DropzoneDropper from "./Fragments/DropzoneDropper";
import DropzoneLoader from "./Fragments/DropzoneLoader";
import DropzonePreview from "./Fragments/DropzonePreview";

export interface SingleImageDropzoneProps {
  // configuration
  maxSize?: number;
  accept?: string[];
  disabled?: boolean;
  initialImage?: string | File;

  // events
  onReject?: (file: FileRejection) => void;
  onSubmit?: (file: File) => Promise<void>;
  onSuccess?: () => void;
  onError?: (err: unknown) => void;

  // components
  previewComponent?: FC<{ open?: () => void; imageSrc?: string }>;
}

interface IDropzoneState {
  error: string;
  isLoading: boolean;
  imgUrl: string | undefined;
}

// TODO this bit calls for a refactor if different dropzones are needed - make this bit multipurpose.
enum DROPZONE_ACTION {
  SUBMIT = "submit",
  SUCCESS = "success",
  ERROR = "error",
  RESET = "reset",
}

function dropzoneReducer(
  draft: IDropzoneState,
  action: { type: DROPZONE_ACTION; imgUrl?: string },
) {
  switch (action.type) {
    case "reset": {
      draft.error = "";
      draft.isLoading = false;
      draft.imgUrl = undefined;
      return;
    }
    case "submit": {
      draft.error = "";
      draft.isLoading = true;
      return;
    }
    case "success": {
      draft.isLoading = false;
      draft.imgUrl = action.imgUrl;
      return;
    }
    case "error": {
      draft.error = "Image submission failed!!";
      draft.isLoading = false;
      return;
    }
    default: {
      draft.error = "Unrecognised action type used!!";
      draft.isLoading = false;
    }
  }
}

const SingleImageDropzone: React.FC<SingleImageDropzoneProps> = ({
  initialImage,
  onReject,
  onSubmit,
  onSuccess,
  onError,
  maxSize,
  accept = ["image/jpeg", "image/png"],
  disabled = false,
  previewComponent: PreviewComponent = DropzonePreview,
  children,
}) => {
  const [dropzoneState, dispatchDropzone] = useImmerReducer(dropzoneReducer, {
    error: "",
    isLoading: false,
    imgUrl:
      typeof initialImage === "object"
        ? URL.createObjectURL(initialImage)
        : initialImage,
  });

  const onDrop = useCallback(
    async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      try {
        // 1. Get the file
        const file = acceptedFiles[0];
        if (!file) {
          dispatchDropzone({ type: DROPZONE_ACTION.RESET });
          if (onReject) onReject(fileRejections[0]);
          return;
        }

        // 2. Update form value
        dispatchDropzone({ type: DROPZONE_ACTION.SUBMIT });
        if (onSubmit) await onSubmit(file);

        // 3. Generate preview
        dispatchDropzone({
          type: DROPZONE_ACTION.SUCCESS,
          imgUrl: URL.createObjectURL(file),
        });
        if (onSuccess) onSuccess();
      } catch (err) {
        dispatchDropzone({ type: DROPZONE_ACTION.ERROR });
        console.error("Failed to upload image!", err);
        if (onError) onError(err);
      }
    },
    [dispatchDropzone, onReject, onSubmit, onSuccess, onError],
  );

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    multiple: false,
    disabled,
    maxSize,
    accept,
  });

  const { isLoading, imgUrl } = dropzoneState;

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <DropzoneContainer>
        <PreviewComponent open={open} imageSrc={imgUrl} />
      </DropzoneContainer>
      <DropzoneDropper isDragActive={isDragActive} />
      <DropzoneLoader isLoading={isLoading} />
      {children}
    </div>
  );
};

export default SingleImageDropzone;

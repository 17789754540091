import createEmojiPlugin, {
  EmojiPlugin,
  EmojiPluginConfig,
} from "@draft-js-plugins/emoji";
import {
  ContentState,
  convertFromHTML,
  DefaultDraftBlockRenderMap,
} from "draft-js";

import VIEmojiSelectWrapper from "./components/VIEmojiSelectWrapper";
import { REGEX_EMOJI_TEST } from "./constants";
import attachImmutableEntitiesToEmojis from "./modifiers/attachImmutableEntitiesToEmojis";

import getSafeBodyFromHTML from "../../../utils/getSafeBodyFromHTML";
import mergeWithContentState from "../../../utils/mergeWithContentState";
import mergeWithText from "../../../utils/mergeWithText";

export type VIEmojiPluginConfig = EmojiPluginConfig;
export interface VIEmojiPlugin extends EmojiPlugin {
  VIEmojiSelectWrapper: React.FC;
}
export default function createVIEmojiPlugin(
  config: VIEmojiPluginConfig = {},
): VIEmojiPlugin {
  const emojiPlugin = createEmojiPlugin(config);
  return {
    ...emojiPlugin,
    VIEmojiSelectWrapper,
    // TODO rethink handlePastedText
    // plugins should be stackable
    // handlePastedText logic is not stackable!
    handlePastedText: (text, html, editorState, { setEditorState }) => {
      if (REGEX_EMOJI_TEST.test(text) && !html) {
        const newEditorState = mergeWithText(
          editorState,
          text,
          attachImmutableEntitiesToEmojis,
        );
        setEditorState(newEditorState);

        return "handled";
      }

      if (!html) return "not-handled";

      const safeBody = getSafeBodyFromHTML(html);

      if (!safeBody) return "not-handled";

      // !YOU CANT iterate through elements normally. Because it is not an array.
      // It kinda is though.
      // replaceChild function removes elements from array entirely. Thats why code is weird.
      const elements = safeBody.getElementsByTagName("img");
      const imageCount = elements.length;
      for (let i = imageCount - 1; i >= 0; i -= 1) {
        const alt = elements[i].alt.trim();
        if (REGEX_EMOJI_TEST.test(alt)) {
          const replacementNode = document.createTextNode(alt);
          // eslint-disable-next-line no-unused-expressions
          elements[i].parentNode?.replaceChild(replacementNode, elements[i]);
        }
      }

      const { contentBlocks, entityMap } = convertFromHTML(
        safeBody.innerHTML,
        undefined,
        DefaultDraftBlockRenderMap,
      );
      let additionalContent = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap,
      );
      additionalContent = attachImmutableEntitiesToEmojis(additionalContent);
      const newEditorState = mergeWithContentState(
        editorState,
        additionalContent,
      );

      setEditorState(newEditorState);

      return "handled";
    },
  };
}

import * as React from "react";
import { Grid, GridColumn } from "semantic-ui-react";
import styled from "styled-components";

import CardLoader from "./CardLoader";

import { renderGridElements } from "../../../helpers/ReactHelpers";

export interface GridLoaderProps {
  rows?: number;
  columns: 1 | 2 | 3 | 4;
  keyTag: string;
}

const GridCol = () => (
  <GridColumn>
    <CardLoader />
  </GridColumn>
);

const StyledGrid = styled(Grid)({
  width: "100%",
});
const GridLoader: React.FC<GridLoaderProps> = ({ rows, columns, keyTag }) => {
  return (
    <StyledGrid stackable columns={columns}>
      {renderGridElements(
        rows,
        <Grid.Row>
          {renderGridElements(columns, GridCol(), `row-${keyTag}`)}
        </Grid.Row>,
        `col-${keyTag}`,
      )}
    </StyledGrid>
  );
};

GridLoader.defaultProps = {
  rows: 3,
};

export default GridLoader;

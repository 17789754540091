import React, { memo, SyntheticEvent } from "react";
import {
  StrictDropdownItemProps,
  Dropdown,
  DropdownProps,
  FormField,
} from "semantic-ui-react";

import { IBaseFormFieldProps } from "../../../interfaces/forms";

// TODO: controlled @Agnese

export interface FormSelectFieldProps extends IBaseFormFieldProps<string> {
  options: StrictDropdownItemProps[];
  search?: boolean;
  defaultValue?: string;
  selectOnBlur?: boolean;
  selectOnNavigation?: boolean;
}

const FormSelect: React.FC<FormSelectFieldProps> = ({
  id,
  label,
  required,
  onChange,
  ...props
}) => {
  const changeFn = (event: SyntheticEvent, data: DropdownProps) => {
    if (onChange) onChange(id, data.value as string);
  };

  return (
    <FormField required={required}>
      {label && <label htmlFor={id}>{label}</label>}
      <Dropdown
        {...props}
        id={id}
        name={id}
        selection
        required={required}
        clearable={!required}
        onChange={changeFn}
      />
    </FormField>
  );
};

export default memo(FormSelect);

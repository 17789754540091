import { IndexSignature } from "../interfaces";

export enum SpecialAttributes {
  highlighted = "izcelts",
  highlughtedInHomePage = "izcelts-sakumlapa",
  displayInHomepage = "radit-sakumlapa",
  highlightedInCountry = "izcelts-valsti",
}

export const SPECIAL_ATTRIBUTES_LV = [
  "izcelts",
  "izcelts-sakumlapa",
  "radit-sakumlapa",
  "izcelts-valsti",
];

export const SPECIAL_ATTRIBUTES_LV_TEXT: IndexSignature = {
  izcelts: "Izcelts",
  "izcelts-sakumlapa": "Izcelts sākumlapā",
  "radit-sakumlapa": "Rādīt sākumlapā",
  "izcelts-valsti": "Izcelts valstī",
};

export const SpecialAttributeOptions = SPECIAL_ATTRIBUTES_LV.map((value) => ({
  value,
  key: value,
  text: SPECIAL_ATTRIBUTES_LV_TEXT[value],
}));

import React, { useRef, useEffect, useCallback, useState } from "react";
import { Menu, Image, Ref, Sticky, Dimmer, Icon } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useAuthContext } from "hooks/useAuthorizedContext";

import SimpleMenu, {
  definedMenuTypes,
} from "../../molecules/SimpleMenu/SimpleMenu";
import VerticalDrawer from "../../molecules/Sidebars/VerticalDrawer";
import { home, adminRoutes, contentRoutes } from "../../../routemap";
import LinkMenuItem from "../../atoms/MenuItems/LinkMenuItem";
import Divider, { definedDividerTypes } from "../../atoms/Divider/Divider";
import { Logo } from "../../../images/imageSources";
import OpenDrawerMenuItem from "../../atoms/MenuItems/OpenDrawerMenuItem";
import { styledWithTheme } from "../../../styles/theme";

export const StyledDimmer = styledWithTheme(Dimmer)({
  zIndex: "200!important" as any, // Because scrolled content dimmer works weird AF
});

const MainHeader: React.FC = ({ children }) => {
  const { t } = useTranslation();

  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const toggleSidebar = () => setSidebarOpen(!isSidebarOpen);
  const closeSidebar = useCallback(() => setSidebarOpen(false), []);
  const stickyContext = useRef(null);

  const { user, logout } = useAuthContext();

  // Close drawer whenever route changes
  const location = useLocation();
  useEffect(() => {
    closeSidebar();
  }, [closeSidebar, location]);

  return (
    <>
      <Sticky context={stickyContext}>
        <SimpleMenu type={definedMenuTypes.main}>
          {!user ? (
            // This element is important for flexbox!!!
            <div style={{ width: "46px", marginRight: "auto" }} />
          ) : (
            <Menu.Menu style={{ marginRight: "auto" }}>
              <OpenDrawerMenuItem
                open={isSidebarOpen}
                toggleDrawer={toggleSidebar}
              />
            </Menu.Menu>
          )}
          <LinkMenuItem route={home.path}>
            <Image src={Logo} size="small" />
          </LinkMenuItem>
          <div style={{ width: "46px", marginLeft: "auto" }} />
          <div />
        </SimpleMenu>
        <Divider type={definedDividerTypes.primary} fitted shrink />
        <VerticalDrawer open={isSidebarOpen} onHide={closeSidebar}>
          <Divider fitted type={definedDividerTypes.primary} />
          <SimpleMenu type={definedMenuTypes.vertical}>
            <Menu.Item id="heading">Saturs</Menu.Item>
            {contentRoutes.map((item) => (
              <LinkMenuItem
                key={item.path}
                route={item.path}
                id={`link-${item.path}`}
              >
                {t(item.name)}
              </LinkMenuItem>
            ))}
          </SimpleMenu>
          <Divider fitted type={definedDividerTypes.primary} />
          <SimpleMenu type={definedMenuTypes.vertical}>
            <Menu.Item id="heading">Administrēšana</Menu.Item>
            {adminRoutes.map((item) => (
              <LinkMenuItem
                key={item.path}
                route={item.path}
                id={`link-${item.path}`}
              >
                {t(item.name)}
              </LinkMenuItem>
            ))}
          </SimpleMenu>
          <Divider fitted type={definedDividerTypes.primary} />
          <SimpleMenu type={definedMenuTypes.vertical}>
            <Menu.Item onClick={() => logout()}>
              <Icon name="sign-out" />
              Izlogoties
            </Menu.Item>
          </SimpleMenu>
        </VerticalDrawer>
      </Sticky>
      <Ref innerRef={stickyContext}>
        <div>
          <Dimmer.Dimmable>
            <StyledDimmer active={isSidebarOpen} />
            {children}
          </Dimmer.Dimmable>
        </div>
      </Ref>
    </>
  );
};

export default MainHeader;

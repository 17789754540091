import React, { memo, SyntheticEvent, useMemo } from "react";
import {
  DropdownProps,
  Dropdown,
  FormField,
  DropdownItemProps,
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";

import { TFormOnChange } from "../../../interfaces/forms";
import {
  CATEGORIES,
  MatchSubCategory,
  CATEGORY_TITLE,
} from "../../../constants/categories";

export interface KeywordFormSelectProps {
  id: string;
  value: string[];
  required?: boolean;
  label?: string;
  onChange: TFormOnChange<string[]>;
  selectedCategory?: CATEGORIES;
}

const FormKeywordSelect: React.FC<KeywordFormSelectProps> = ({
  id,
  label,
  required,
  onChange,
  value,
  selectedCategory,
}) => {
  const { t } = useTranslation();
  const changeFn = (event: SyntheticEvent, data: DropdownProps) => {
    onChange(id, data.value instanceof Array ? (data.value as string[]) : []);
  };

  const options = useMemo(() => {
    const categories = Object.values(CATEGORIES);
    const index = selectedCategory ? categories.indexOf(selectedCategory) : -1;
    if (index > -1 && selectedCategory) {
      categories.splice(index, 1);
      categories.unshift(selectedCategory);
    }
    const opts: DropdownItemProps[] = [];
    categories.forEach((item) => {
      const specificOpts = MatchSubCategory[item].map((option, idx) => ({
        ...option,
        key: `${option.value}-${idx}`,
        text: t(`category:SubCategories.${option.value}`),
      }));
      opts.push({
        content: CATEGORY_TITLE[item],
        as: Dropdown.Header,
        disabled: true,
        key: CATEGORY_TITLE[item],
      });
      opts.push(...specificOpts);
    });
    return opts;
  }, [t, selectedCategory]);

  return (
    <FormField required={required}>
      {label && <label htmlFor={id}>{label}</label>}
      <Dropdown
        id={id}
        value={value}
        name={id}
        selection
        search
        options={options}
        required={required}
        onChange={changeFn}
        clearable
        multiple
      />
    </FormField>
  );
};

export default memo(FormKeywordSelect);

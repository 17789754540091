import React from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "semantic-ui-react";
import styled from "styled-components";

import GridLoader from "../../atoms/Loaders/GridLoader";
import MainGrid from "../../templates/MainGrid";
import SimpleHeader, {
  definedHeaderTypes,
} from "../../molecules/SimpleHeader/SimpleHeader";
import SimpleButton, {
  definedButtonTypes,
} from "../../atoms/Buttons/SimpleButton/SimpleButton";

// TODO: @Agnese memo Groom remove slice handle error, no events

interface DynamicCardGridProps {
  heading?: string;
  loading: boolean;
  rows: number;
  columns: 1 | 2 | 3 | 4;
  gridTag: string;
  gridClass?: string;
  showMore?: boolean;
  showAll?: boolean;
}
const FullWidth = styled.div({
  width: "100%",
});
const StyledDiv = styled.div({
  textAlign: "right",
});
const DynamicCardGrid: React.FC<DynamicCardGridProps> = ({
  loading,
  heading,
  rows,
  columns,
  gridTag,
  gridClass,
  showMore,
  showAll,
  children,
}) => {
  const { t } = useTranslation();

  return (
    <FullWidth>
      {heading && (
        <SimpleHeader type={definedHeaderTypes.emphasize}>
          {heading}
        </SimpleHeader>
      )}
      {loading ? (
        <GridLoader columns={columns} rows={rows} keyTag={gridTag} />
      ) : (
        <MainGrid columns={columns} classId={gridClass}>
          {children}
        </MainGrid>
      )}
      {showMore && (
        <StyledDiv>
          <SimpleButton variant={definedButtonTypes.action}>
            {t("Buttons.ShowMore")}
            <Icon name="angle right" />
          </SimpleButton>
        </StyledDiv>
      )}
      {showAll && (
        <StyledDiv>
          <SimpleButton variant={definedButtonTypes.action}>
            {t("Buttons.ShowAll")}
            <Icon name="angle right" />
          </SimpleButton>
        </StyledDiv>
      )}
    </FullWidth>
  );
};

DynamicCardGrid.defaultProps = {
  showMore: false,
};

export default DynamicCardGrid;

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from "react";
import { Dropdown, FormField, } from "semantic-ui-react";
const FormSelect = (_a) => {
    var { id, label, required, onChange } = _a, props = __rest(_a, ["id", "label", "required", "onChange"]);
    const changeFn = (event, data) => {
        if (onChange)
            onChange(id, data.value);
    };
    return (_jsxs(FormField, Object.assign({ required: required }, { children: [label && _jsx("label", Object.assign({ htmlFor: id }, { children: label })), _jsx(Dropdown, Object.assign({}, props, { id: id, name: id, selection: true, search: true, required: required, clearable: !required, onChange: changeFn }))] })));
};
export default memo(FormSelect);

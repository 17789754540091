import { useMemo } from "react";
import { ROUTE_TYPES } from "../types/utils/constants";
const groupToDropdown = (orgs = []) => {
    return orgs.map((o) => ({
        text: o.title,
        value: o.slug,
        key: o.slug,
    }));
};
const useUserGroupOpts = (userGroups) => {
    const CompanyOptions = useMemo(() => {
        const filterOnlyOrgs = userGroups === null || userGroups === void 0 ? void 0 : userGroups.filter((group) => group.type === ROUTE_TYPES.companies);
        const options = groupToDropdown(filterOnlyOrgs);
        return options || [];
    }, [userGroups]);
    const OrganizationOptions = useMemo(() => {
        const filterOnlyOrgs = userGroups === null || userGroups === void 0 ? void 0 : userGroups.filter((group) => group.type === ROUTE_TYPES.organizations);
        const options = groupToDropdown(filterOnlyOrgs);
        return options || [];
    }, [userGroups]);
    return { CompanyOptions, OrganizationOptions };
};
export default useUserGroupOpts;

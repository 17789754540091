import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Loader, Dimmer } from "semantic-ui-react";
import styled from "styled-components";
import useAuthorizedContext from "hooks/useAuthorizedContext";

import FormSelect from "./FormSelect";

import {
  IOrganizationInfo,
  EntityForm,
  isOrganization,
} from "../../../api/index.d";

interface FormOrganizationFieldProps {
  showOnlyDiaspore?: boolean;
  onChange: (id: string, value?: IOrganizationInfo | string) => void;
  value?: string;
  required?: boolean;
}

const StyledLoader = styled.div({
  position: "relative",
  padding: "1em",
});
const mapAvailableOrgOpts = (
  orgs?: EntityForm[],
  showOnlyDiaspore?: boolean,
) => {
  if (orgs) {
    return orgs
      .filter((org) => isOrganization(org))
      .filter((org) => (showOnlyDiaspore ? (org as any).diasporeMedia : true))
      .map((o) => ({
        text: o.title,
        value: o.slug,
        key: o.slug,
      }))
      .sort((a, b) => a.text.localeCompare(b.text));
  }

  return [];
};

export const mapEntityInfo = <T extends EntityForm[]>(
  organization: T,
): IOrganizationInfo[] => {
  return organization.map((org) => {
    const { slug, title, imageUrl, shortTitle } = org;
    return {
      id: slug || "",
      slug,
      imageUrl,
      title,
      shortTitle: shortTitle || "",
      diasporeMedia: (org as any).diasporeMedia as boolean,
    };
  });
};

const FormOrganizationField: React.FC<FormOrganizationFieldProps> = ({
  onChange,
  value,
  showOnlyDiaspore,
  required,
}) => {
  const { t } = useTranslation();
  const { orgData } = useAuthorizedContext();

  const OrgInfos = useMemo(() => {
    return mapEntityInfo(orgData);
  }, [orgData]);

  const OrgOptions = useMemo(() => {
    return mapAvailableOrgOpts(orgData, showOnlyDiaspore);
  }, [orgData, showOnlyDiaspore]);

  const changeFn = (id: string, data: string) => {
    onChange("organizationInfo", OrgInfos.find((org) => org.id === data) || "");
    onChange(id, data);
  };

  if (OrgOptions.length === 0) {
    return (
      <StyledLoader>
        <Dimmer active inverted>
          <Loader active inline="centered" />
        </Dimmer>
      </StyledLoader>
    );
  }

  return (
    <FormSelect
      label={t("organization")}
      placeholder={t("Izvēlies organizāciju")}
      id="organizationId"
      value={value}
      required={required}
      options={OrgOptions}
      onChange={changeFn}
      search
    />
  );
};

export default FormOrganizationField;

import React, { useState, useMemo } from "react";
import { useDebouncedCallback } from "use-debounce";

import { StyledInput } from "../../organisms/Menus/FilterMenu/FilterMenuStyles";
import { IColumnProps } from "../../../interfaces/tables";

interface Props {
  column: IColumnProps<{}>;
}

const InputFilter: React.FC<Props> = ({ column: { id, setFilter } }) => {
  const [value, setValue] = useState("");
  const memoizedState = useMemo(() => ({ value, setValue }), [value, setValue]);
  const [debouncedCallback] = useDebouncedCallback((val: string) => {
    setFilter(memoizedState.value);
  }, 500);

  return (
    <StyledInput
      id={id}
      value={memoizedState.value}
      onChange={(e: any) => {
        memoizedState.setValue(e.target.value);
        debouncedCallback(memoizedState.value);
      }}
      placeholder="Meklē ..."
    />
  );
};

export default InputFilter;

import { Menu } from "semantic-ui-react";
import styled from "styled-components";

import { styledWithTheme } from "../../../styles/theme";

export const StyleDefaultMenu = styledWithTheme(Menu)(({ theme }) => ({
  "&&&": {
    justifyContent: "center",
    minHeight: theme.space[5],
    width: "100%",
    background: theme.palette.mono.white,
    "&:after": {
      // removing pseudoelement from semantic
      content: "none",
    },
  },
}));
export const StyledMainMenu = styled(StyleDefaultMenu)(({ theme }) => ({
  "&&&": {
    position: "relative",
    justifyContent: "space-between",
    margin: 0,
    padding: "1em 20vw",
    zIndex: 200, // placing any main bars above sidebars
    "@media only screen and (max-width: 600px)": {
      padding: theme.space[2],
    },
  },
}));
export const StyledSecondaryMenu = styledWithTheme(StyleDefaultMenu)(
  ({ theme }) => ({
    "&&&": {
      ".item": theme.typography.h3,
      ".item:hover": {
        color: theme.palette.primary.main,
      },
      ">:not(:last-child)::after": {
        content: '"―"',
        color: theme.palette.primary.main,
        paddingLeft: theme.space[2],
      },
    },
  }),
);
export const StyledVerticalMenu = styled(StyleDefaultMenu)(({ theme }) => ({
  "&&&": {
    display: "flex",
    // !Vertical menus have weird margins
    margin: "0 !important",
    ...theme.typography.h3,
    ".item": {
      margin: ".2em 0 !important",
      textAlign: "center",
    },
    "#heading": {
      ...theme.typography.title1,
      color: theme.palette.primary.main,
    },
  },
}));

export const StyledCountryMenu = styledWithTheme(StyleDefaultMenu)(
  ({ theme }) => ({
    "&&&": {
      ".item": theme.typography.title1,
      ".active:nth-child(n)": {
        ...theme.typography.title1,
        color: theme.palette.primary.main,
      },
      ".item:hover": {
        color: theme.palette.primary.main,
      },
      ">:not(:last-child)::after": {
        content: '"-"',
        color: theme.palette.mono.black,
        paddingLeft: theme.space[2],
      },
    },
  }),
);

import { ContentState, EditorState, Modifier } from "draft-js";

const mergeWithContentState = (
  editorState: EditorState,
  contentStateToMerge: ContentState,
) => {
  let contentState = editorState.getCurrentContent();

  contentStateToMerge.getAllEntities().forEach((value, key) => {
    if (key && value) contentState = contentState.mergeEntityData(key, value);
  });

  contentState = Modifier.replaceWithFragment(
    contentState,
    editorState.getSelection(),
    contentStateToMerge.getBlockMap(),
  );

  return EditorState.push(editorState, contentState, "insert-characters");
};

export default mergeWithContentState;

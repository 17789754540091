import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";

import { IHomeDisplay } from "../../api";
import { ISearchQuery, ISearchQueryResult } from "../../interfaces";
import { ParseQueryValues, QueryBuilder } from "../../helpers/globalFunctions";
import { fullSearch } from "../../api/filterApi";
import { defaultLimit } from "../../constants/variables";
import QueryResult from "../organisms/QueryResult/QueryResult";
import { getSearchCount } from "../../api/CountApi";

export const EmptyQuery: ISearchQuery = {
  queryValue: "",
  limit: defaultLimit,
  offset: 0,
};

export interface SearchPageState {
  events: IHomeDisplay[];
  loading: boolean;
  query: ISearchQuery;
  count: number;
}

class SearchPage extends React.Component<WithTranslation, SearchPageState> {
  state = {
    events: [],
    loading: false,
    error: false,
    query: EmptyQuery,
    count: 0,
  };

  async componentDidMount() {
    const searchQuery = window.location.search.substr(
      1,
      window.location.search.length,
    );
    if (searchQuery) {
      const attributes = searchQuery.split("&");
      const query = ParseQueryValues(attributes);
      await this.handleFilter(query);
    }
  }

  handleFilter = async (query: ISearchQuery) => {
    const searchQuery = QueryBuilder(query);
    const abortController = new AbortController();
    const { signal } = abortController;
    this.setState((prevState) => ({
      ...prevState,
      loading: true,
    }));
    try {
      const data = await Promise.all([
        fullSearch(searchQuery),
        getSearchCount(searchQuery),
      ]);
      if (!signal.aborted) {
        this.setState((prevState) => ({
          ...prevState,
          query,
          events: data[0].items,
          count: data[1],
          loading: false,
        }));
      }
    } catch (e) {
      console.error(e);
    }
  };

  handleNextPage = (prev = false) => {
    const { query } = this.state;
    const { offset } = query;
    const newQuery = { ...query };

    let accLimit = defaultLimit;
    if (prev) {
      accLimit = -defaultLimit;
    }

    let newOffset = offset + accLimit;
    let newLimit = accLimit;
    if (newOffset < 0) newOffset = 0;
    if (newLimit < defaultLimit) newLimit = defaultLimit;

    newQuery.offset = newOffset;
    newQuery.limit = newLimit;
    this.handleFilter(newQuery);
  };

  render() {
    const { events, loading, count, query, error } = this.state;
    const { queryValue, offset } = query;
    const disablePrevPage = offset === 0;
    const disableNextPage = events.length !== defaultLimit;

    const queryResult: ISearchQueryResult = {
      filter: false,
      onQuery: this.handleFilter,
      handleNextPage: this.handleNextPage,
      handlePrevPage: this.handleNextPage,
      loading,
      error,
      events,
      count,
      query: queryValue,
      disableNextPage,
      disablePrevPage,
      showAllRecords: true,
    };

    return <QueryResult queryResult={queryResult} count />;
  }
}

export default withTranslation()(SearchPage);

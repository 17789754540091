import React from "react";
import { Form, Divider, FormField } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import useForm from "../../../hooks/useForm";
import FormGrid from "../../templates/FormGrid";
import Typography from "../../atoms/Typography/Typography";
import { EntityForm } from "../../../api/index.d";
import ITemplateSite, {
  definedTemplateGrids,
} from "../../../interfaces/templateSite/site";
import FormCheckbox from "../../molecules/FormFields/FormCheckbox";
import FormTemplateSelect from "../../molecules/FormFields/FormTemplateSelect";
import { definedTypographyTypes } from "../../atoms/Typography/TypographyStyles";
import SimpleButton, {
  definedButtonTypes,
} from "../../atoms/Buttons/SimpleButton/SimpleButton";

const CenterDiv = styled.div<{ top?: boolean }>(({ top }) => ({
  display: "flex",
  justifyContent: "center",
  margin: "2em",
  ...(top && {
    marginBottom: "2em",
  }),
}));

interface CreateHomepageProps {
  organization: EntityForm;
  onSubmit: (newData: ITemplateSite) => Promise<void>;
  onCancel: () => void;
}

const defaultInitValues: ITemplateSite = {
  pages: {},
  layout: definedTemplateGrids.default,
  newsPage: false,
  galleryPage: false,
  eventPage: false,
  videoPage: false,
};

const CreateHomepageForm: React.FC<CreateHomepageProps> = ({
  organization,
  onSubmit,
  onCancel,
}) => {
  const { t } = useTranslation();
  const isFormEdit = Boolean(organization.templateSite);
  const initialValues = organization.templateSite;

  const { values, submitForm, changeFormValue, isLoading } = useForm<
    ITemplateSite
  >(initialValues || defaultInitValues, onSubmit);
  const { newsPage, galleryPage, eventPage, videoPage, layout } = values;

  const formAction = isFormEdit
    ? t("Forms.Actions.Update")
    : t("Forms.Actions.Create");

  return (
    <Form name="editorialNewsForm" id="editorialNewsForm" loading={isLoading}>
      <Divider />
      <FormGrid label={t("Forms.Titles.Looks")}>
        <FormTemplateSelect
          id="layout"
          label={t("Forms.Fields.HomepageTemplate")}
          description={isFormEdit ? t("Forms.Fields.TempDesc") : undefined}
          value={layout}
          onChange={changeFormValue}
        />
      </FormGrid>
      <FormGrid label={t("Forms.Titles.Additional")}>
        <FormField id="checkboxes">
          <label htmlFor="checkboxes">{t("Forms.Fields.ChoosePages")}</label>
          <Typography type={definedTypographyTypes.smallCaption}>
            {t("Forms.Fields.ChoosePagesExplained")}
          </Typography>
          <FormCheckbox
            id="newsPage"
            value={newsPage}
            disabled={layout === definedTemplateGrids.default}
            checkboxLabel={t("Forms.Fields.NewsPage")}
            onChange={changeFormValue}
          />
          <FormCheckbox
            id="eventPage"
            value={eventPage}
            disabled={layout === definedTemplateGrids.default}
            checkboxLabel={t("Forms.Fields.EventPage")}
            onChange={changeFormValue}
          />
          <FormCheckbox
            id="galleryPage"
            value={galleryPage}
            disabled={layout === definedTemplateGrids.default}
            checkboxLabel={t("Forms.Fields.GalleryPage")}
            onChange={changeFormValue}
          />
          <FormCheckbox
            id="videoPage"
            disabled={layout === definedTemplateGrids.default}
            value={videoPage}
            checkboxLabel={t("Forms.Fields.VideoPage")}
            onChange={changeFormValue}
          />
        </FormField>
      </FormGrid>
      <CenterDiv>
        <SimpleButton
          id="cancel"
          variant={definedButtonTypes.action}
          onClick={onCancel}
        >
          {t("Forms.Actions.Cancel")}
        </SimpleButton>
        <SimpleButton
          id="modal-button"
          variant={definedButtonTypes.primary}
          onClick={(e) => submitForm(e)}
        >
          {formAction}
        </SimpleButton>
      </CenterDiv>
    </Form>
  );
};
export default CreateHomepageForm;

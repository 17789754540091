import IRoute from "./interfaces/route";
import LogInPage from "./components/pages/LogInPage";
import UserListPage from "./components/pages/UserListPage";
import StaticContentPage from "./components/pages/StaticContentPage";
import IframeMakerPage from "./components/pages/IframeMakerPage/IframeMakerPage";
import { definedRouteTypes } from "./api/api";
import TypeTablePage from "./components/pages/TypeTablePage";
import AdConfigurationPage from "./components/pages/AdConfigurationPage";
import KeywordPage from "./components/pages/KeywordPage";
import TranslationPage from "./components/pages/TranslationPage";
import NewsLetterMakerPage from "./components/pages/NewsletterMakerPage";
import NewsLetterHistoryPage from "./components/pages/NewsletterHistory";
import OrgSlugsPage from "./components/pages/OrgSlugsPage";
import HomepageTablePage from "./components/pages/HomepageTablePage";
import SearchPage from "./components/pages/SearchPage";
import PicturePage from "./components/pages/PicturePage";
import UnapprovedTablePage from "./components/pages/UnapprovedTablePage";

function routeMaker(name: string, path: string, component?: any) {
  const route: IRoute = {
    name,
    path,
    component,
  };
  return route;
}

export const home = routeMaker("HomePageData", "/", HomepageTablePage);

export const unapprovedPage = routeMaker(
  "UnapprovedPageData",
  `/unapproved`,
  UnapprovedTablePage,
);
export const logInPage = routeMaker("LoginPage", "/log-in", LogInPage);

export const homePageType = routeMaker(
  "HomepageList",
  `/homepage/:type`,
  HomepageTablePage,
);
export const unapprovedPageType = routeMaker(
  "UnapprovedContentList",
  `/unapproved/:type`,
  UnapprovedTablePage,
);

export const editorialNewsListPage = routeMaker(
  "EditorialNewsList",
  `/${definedRouteTypes.editorialNews}`,
  TypeTablePage,
);
export const organisationNewsListPage = routeMaker(
  "OrganisationNewsList",
  `/${definedRouteTypes.organizationNews}`,
  TypeTablePage,
);
export const offersListPage = routeMaker(
  "OffersList",
  `/${definedRouteTypes.offers}`,
  TypeTablePage,
);
export const diasporeNewsListPage = routeMaker(
  "DiasporeNewsList",
  `/${definedRouteTypes.diasporeNews}`,
  TypeTablePage,
);

export const galleryListPage = routeMaker(
  "GalleryList",
  `/${definedRouteTypes.imageGallery}`,
  TypeTablePage,
);
export const videoListPage = routeMaker(
  "VideoListPage",
  `/${definedRouteTypes.videoNews}`,
  TypeTablePage,
);
export const eventsListPage = routeMaker(
  "EventsList",
  `/${definedRouteTypes.events}`,
  TypeTablePage,
);
export const pastEventsListPage = routeMaker(
  "PastEventsList",
  `/${definedRouteTypes.pastEvents}`,
  TypeTablePage,
);

export const organizationListPage = routeMaker(
  "OrganizationListPage",
  `/${definedRouteTypes.organizations}`,
  TypeTablePage,
);
export const companyListPage = routeMaker(
  "CompanyListPage",
  `/${definedRouteTypes.companies}`,
  TypeTablePage,
);
export const selfEmployedListPage = routeMaker(
  "selfEmployedListPage",
  `/${definedRouteTypes.selfEmployed}`,
  TypeTablePage,
);
export const companyNewsListPage = routeMaker(
  "companyNewsListPage",
  `/${definedRouteTypes.companyNews}`,
  TypeTablePage,
);
export const linkMediaListPage = routeMaker(
  "linkMediaListPage",
  `/${definedRouteTypes.LINK_MEDIA}`,
  TypeTablePage,
);
export const sourceMediaListPage = routeMaker(
  "sourceMediaListPage",
  `/${definedRouteTypes.SOURCE_MEDIA}`,
  TypeTablePage,
);
export const orgShortSlugPage = routeMaker(
  "OrganizationSlugsPage",
  `/orgSlugs`,
  OrgSlugsPage,
);
export const userListPage = routeMaker("UserListPage", "/users", UserListPage);

export const footerContent = routeMaker(
  "Footer.Content",
  "/footer",
  StaticContentPage,
);
export const adConfigurations = routeMaker(
  "adConfigurations",
  "/ad",
  AdConfigurationPage,
);
export const translationPage = routeMaker(
  "translationPage",
  "/translations",
  TranslationPage,
);

export const iframeContent = routeMaker(
  "IframeMaker",
  "/iframe",
  IframeMakerPage,
);

export const keywordPage = routeMaker("keywordPage", "/keywords", KeywordPage);
export const newsLetterPage = routeMaker(
  "newsLetterPage",
  "/newsletter",
  NewsLetterMakerPage,
);

export const newsLetterHistoryRoute = routeMaker(
  "newsLetterHistory",
  "/newsletter/history",
  NewsLetterHistoryPage,
);

export const searchRoute = routeMaker("searchPage", "/search", SearchPage);
export const pictureRoute = routeMaker("pictures", "/pictures", PicturePage);

export const contentRoutes: IRoute[] = [
  home,
  unapprovedPage,
  editorialNewsListPage,
  organisationNewsListPage,
  offersListPage,
  diasporeNewsListPage,
  companyNewsListPage,
  galleryListPage,
  videoListPage,
  eventsListPage,
  searchRoute,
  pastEventsListPage,
  // TODO separate me
  linkMediaListPage,
  sourceMediaListPage,
];

export const adminRoutes: IRoute[] = [
  organizationListPage,
  companyListPage,
  selfEmployedListPage,
  orgShortSlugPage,
  userListPage,
  footerContent,
  keywordPage,
  adConfigurations,
  iframeContent,
  translationPage,
  newsLetterPage,
  newsLetterHistoryRoute,
  pictureRoute,
];

/*
  // @Agnese maybe kko sadu?
  const routes: IRoute[] = [
    ...contentRoutes,
    ...adminRoutes,
    specificRoute1,
    specificRout2,
  ];
*/

const routes: IRoute[] = [
  home,
  homePageType,
  unapprovedPage,
  unapprovedPageType,
  editorialNewsListPage,
  organisationNewsListPage,
  offersListPage,
  diasporeNewsListPage,
  galleryListPage,
  videoListPage,
  eventsListPage,
  pastEventsListPage,
  organizationListPage,
  companyNewsListPage,
  linkMediaListPage,
  sourceMediaListPage,
  selfEmployedListPage,
  companyListPage,
  orgShortSlugPage,
  userListPage,
  footerContent,
  keywordPage,
  adConfigurations,
  iframeContent,
  translationPage,
  newsLetterHistoryRoute,
  newsLetterPage,
  searchRoute,
  pastEventsListPage,
  pictureRoute,
];

export default routes;

/* eslint-disable react/static-property-placement */
/* eslint-disable @typescript-eslint/camelcase */
import {
  ADMIN_GROUP,
  EDITOR_GROUP,
  FACEBOOK_LOGIN_GROUP,
  GOOGLE_LOGIN_GROUP,
  SPECIAL_GROUPS,
  ROLE_GROUPS,
  USER_GROUP,
  CREATOR_GROUP,
} from "./constants";
import { BooleanFromAnything, parseStringOrObject } from "./helpers";
import { IdentityProvider, LatviesiUserType } from "./interfaces";

class CognitoUserAttrs {
  /**
   * claims object that is being returned by the authorizer lambda
   */
  private claims: Record<string, any>;

  /**
   * "cognito:groups" - all groups user is part of
   */
  private cognitoGroups: string[];

  /**
   * Some social logins do not have it.
   * Our primary means of telling people apart.
   */
  email: string;

  /**
   * sub is the globally unique identifier of the cognito user - its uuid
   */
  sub: string;

  /**
   * changeable id of the user. we do not use usernames within app (email only), so it has default cognito settings
   * Google logins - google_104683122508687579883
   * Facebook logins - facebook_100000619927050
   * Email logins - user's sub
   */
  username: string;

  constructor(claims: Record<string, any>) {
    if (!claims?.sub) {
      throw new Error("Cannot instantiate User without data");
    }

    this.claims = claims;
    this.sub = claims.sub;
    this.email = claims.email;
    this.username = claims["cognito:username"];
    this.cognitoGroups = [];
    const groups = claims["cognito:groups"] as unknown;
    if (groups && typeof groups === "string") {
      this.cognitoGroups = claims["cognito:groups"].split(",");
    } else if (groups instanceof Array) {
      this.cognitoGroups = groups;
    }
  }

  /**
   * organization entity cognito groups user is part of
   */
  organizations = (): string[] => {
    return this.cognitoGroups.filter(
      (group) => !SPECIAL_GROUPS.includes(group),
    );
  };

  /**
   * !Works as long as we have single login for single user.
   */
  identityProvider = (): IdentityProvider => {
    if (this.cognitoGroups.includes(FACEBOOK_LOGIN_GROUP)) return "Facebook";
    if (this.cognitoGroups.includes(GOOGLE_LOGIN_GROUP)) return "Google";
    return "Cognito";
  };

  admin = (): boolean => {
    return this.cognitoGroups.includes(ADMIN_GROUP);
  };

  creator = (): boolean => {
    return this.cognitoGroups.includes(CREATOR_GROUP);
  };

  editor = (): boolean => {
    return this.cognitoGroups.includes(EDITOR_GROUP);
  };

  role = (): typeof ROLE_GROUPS[number] => {
    if (this.admin()) return ADMIN_GROUP;
    if (this.editor()) return EDITOR_GROUP;
    if (this.creator()) return CREATOR_GROUP;
    return USER_GROUP;
  };

  emailVerified = (): boolean => {
    return BooleanFromAnything(this.claims.email_verified);
  };

  /**
   * first name + second name or email, if not available
   */
  displayName = (): string => {
    const { name, family_name, email } = this.claims;
    return (
      [name, family_name].filter((n) => n).join(" ") || email || "Anonymous"
    );
  };

  picture = (): string => {
    // Facebook is a little bitch, thats why.
    const { picture: pictureJSON } = this.claims;
    if (!pictureJSON) return "";

    const stringOrObject = parseStringOrObject(pictureJSON || "");

    if (typeof stringOrObject === "string") return stringOrObject;

    const maybeFBUrl = stringOrObject?.data?.url;
    if (typeof maybeFBUrl === "string") return maybeFBUrl;

    return "";
  };

  /**
   * Custom field
   */
  userType = (): Array<LatviesiUserType> => {
    const { "custom:userType": userTypeSerialized } = this.claims;

    let userType = [];
    try {
      const parsed = JSON.parse(userTypeSerialized || "[]");
      if (!(parsed instanceof Array)) throw Error("not array");
      // TODO test values?
      userType = parsed;
    } catch (err) {
      console.error("Bad field type in cognito db", err);
      // because I do not trust people to populate this correctly
    }

    return userType;
  };

  /**
   * Custom field
   */
  selfEmployed = (): string | undefined => {
    return this.claims["custom:selfEmployed"];
  };

  /**
   * Custom field
   * DEPRECIATED
   */
  approvedLinkAuthor = (): boolean => {
    return BooleanFromAnything(this.claims["custom:approvedLinkAuthor"]);
  };

  format = () => ({
    // ID
    id: this.sub,
    username: this.username,
    email: this.email,
    emailVerified: this.emailVerified(),
    provider: this.identityProvider(),

    // INFO
    name: this.claims.name,
    familyName: this.claims.family_name,
    displayName: this.displayName(),
    userType: this.userType(),
    selfEmployed: this.selfEmployed(),

    // PERMISSIONS
    userGroups: this.organizations(),
    role: this.role(),
    admin: this.admin(),
    editor: this.editor(),
    creator: this.creator(),
    picture: this.picture(),

    // ETC
    status: (this.claims as any).status || "UNKNOWN", // TODO can i get if from claims?
    created: (this.claims as any).created || undefined, // TODO can i get if from claims?
  });
}

export default CognitoUserAttrs;

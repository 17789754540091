import { EditorState } from "draft-js";
import React, { PureComponent } from "react";
import { Button, ButtonGroup } from "semantic-ui-react";

interface Props {
  entityType: string;
  getEditorState: () => EditorState;
  setEditorState: (state: EditorState) => void;
  addEmbedder: (state: EditorState) => EditorState;
}
class EmbedButton extends PureComponent<Props> {
  onClick = (event: any) => {
    event.preventDefault();
    const { setEditorState, addEmbedder, getEditorState } = this.props;

    setEditorState(addEmbedder(getEditorState()));
  };

  onMouseDown = (event: any) => {
    event.preventDefault();
  };

  render() {
    return (
      <ButtonGroup size="small" style={{ display: "flex" }}>
        <Button
          size="small"
          icon="video play"
          id="add-video-btn"
          onClick={this.onClick}
          onMouseDown={this.onMouseDown}
        />
        <Button
          size="small"
          icon="code"
          id="add-embed-btn"
          onClick={this.onClick}
          onMouseDown={this.onMouseDown}
        />
      </ButtonGroup>
    );
  }
}

export default EmbedButton;

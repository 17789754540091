import React from "react";

import { IRow } from "../../../interfaces/tables";
import { IBaseForm } from "../../../api";

interface Props {
  cell: { row: IRow<IBaseForm> };
}

const OrganizationCell: React.FC<Props> = ({ cell: { row } }) => {
  const { organizationId, organizationInfo } = row.original;

  if (!organizationId) return <>-</>;

  return (
    <>{organizationInfo?.title || organizationId}</>
  );
};

export default OrganizationCell;

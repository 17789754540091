import { EditorState } from "draft-js";

function getSelectedBlockType(editorState: EditorState) {
  const selection = editorState.getSelection();
  return editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();
}

export default getSelectedBlockType;

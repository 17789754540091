import SimpleButton from "components/atoms/Buttons/SimpleButton/SimpleButton";
import Typography from "components/atoms/Typography/Typography";
import { definedTypographyTypes } from "components/atoms/Typography/TypographyStyles";
import useAuthorizedContext from "hooks/useAuthorizedContext";
import React from "react";
import styled from "styled-components";

interface Props {}

const StyledDiv = styled.div({
  textAlign: "center",
  marginTop: "5em",
});

const Page401: React.FC<Props> = () => {
  const { logout } = useAuthorizedContext();
  return (
    <StyledDiv>
      <Typography type={definedTypographyTypes.title1} align="center">
        Tev nav pieeja šai lapai!
      </Typography>
      <SimpleButton onClick={logout}>Izlogoties</SimpleButton>
    </StyledDiv>
  );
};

export default Page401;

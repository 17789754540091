import React from "react";
import { Icon, IconProps } from "semantic-ui-react";

interface Props {
  desc?: boolean;
}

const SortIcon: React.FC<Props> = ({ desc }) => {
  let name: IconProps["name"] = "sort";
  if (desc === false) name = "sort up";
  if (desc === true) name = "sort down";

  return <Icon name={name} />;
};

export default SortIcon;

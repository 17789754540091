import { ContentBlock } from "draft-js";

function getEntityAtOffset(block: ContentBlock, offset: number) {
  const entityKey = block.getEntityAt(offset);
  if (entityKey == null) {
    return null;
  }
  let startOffset = offset;
  while (startOffset > 0 && block.getEntityAt(startOffset - 1) === entityKey) {
    startOffset -= 1;
  }
  let endOffset = startOffset;
  const blockLength = block.getLength();
  while (
    endOffset < blockLength &&
    block.getEntityAt(endOffset + 1) === entityKey
  ) {
    endOffset += 1;
  }
  return {
    entityKey,
    blockKey: block.getKey(),
    startOffset,
    endOffset: endOffset + 1,
  };
}

export default getEntityAtOffset;

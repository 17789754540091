import React, { MouseEvent, useState, useCallback } from "react";
import { Button, Feed, Form, Grid, Label, Segment } from "semantic-ui-react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { updateUserPermissions } from "api/usersApi";
import FormSelect from "components/molecules/FormFields/FormSelect";
import { PLACEHOLDER_SQUARE } from "images/imageSources";
import useAuthorizedContext from "hooks/useAuthorizedContext";
import CognitoUserAttrs from "api/cognito/CognitoUserAttrs";
import { ADMIN_GROUP, EDITOR_GROUP, ROLE_OPTIONS } from "api/cognito/constants";

import ImportantModal from "../../templates/Modals/ImportantModal";
import SimpleButton, {
  definedButtonTypes,
} from "../../atoms/Buttons/SimpleButton/SimpleButton";
import AdminMultiSelect from "../../atoms/AdminMultiSelect/AdminMultiSelect";
import Typography from "../../atoms/Typography/Typography";
import { definedTypographyTypes } from "../../atoms/Typography/TypographyStyles";
import { definedTableActions } from "../../../hooks/useTypeTableContext";

interface EditUserModalProps {
  data: ReturnType<InstanceType<typeof CognitoUserAttrs>["format"]>;
  updateFn?: any;
}

const EditUserModal: React.FC<EditUserModalProps> = ({ data, updateFn }) => {
  const {
    userGroups,
    picture,
    email,
    username,
    role,
    provider,
    userType,
    selfEmployed,
    status,
  } = data;
  const [open, setOpen] = useState(false);
  const [selectedGroups, setGroups] = useState(userGroups);
  const [selectedRole, setRole] = useState(role);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { token } = useAuthorizedContext();

  const onChange = useCallback((id: string, d: string[]) => {
    setGroups(d);
  }, []);

  const closeForm = (event: MouseEvent) => {
    event.preventDefault();
    setOpen(false);
  };

  const changePermissions = async (event: MouseEvent) => {
    try {
      setLoading(true);
      const idToken = await token();
      await updateUserPermissions(
        idToken,
        username,
        selectedGroups,
        selectedRole,
      );
      toast.success(t("Table.ChangeUserGroups"));
      updateFn(
        {
          ...data,
          userGroups: selectedGroups,
          role: selectedRole,
          admin: selectedRole === ADMIN_GROUP,
          editor: selectedRole === EDITOR_GROUP,
        },
        definedTableActions.update,
      );
      setLoading(false);
      closeForm(event);
    } catch (err) {
      setLoading(false);
      toast.error(t("Toasts.GenericError"));
      console.error("changePermissions failed", err);
    }
  };

  const isUserAdmin = selectedRole === ADMIN_GROUP;

  return (
    <ImportantModal
      open={open}
      trigger={<Button size="mini" icon="edit" onClick={() => setOpen(true)} />}
      actions={
        <>
          <SimpleButton
            variant={definedButtonTypes.action}
            onClick={closeForm}
            disabled={loading}
          >
            {t("Cancel")}
          </SimpleButton>
          <SimpleButton
            variant={definedButtonTypes.primary}
            onClick={changePermissions}
            loading={loading}
          >
            {t("Submit")}
          </SimpleButton>
        </>
      }
      header="Labot lietotāja piekļuvi"
    >
      <Grid columns={2}>
        <Grid.Column>
          <Feed>
            <Feed.Event>
              <Feed.Label image={picture || PLACEHOLDER_SQUARE} />
              <Feed.Content>
                <Feed.Summary>{email}</Feed.Summary>
                <Feed.Extra text>
                  Statuss: {status}
                  <br />
                  Autorizācija: {provider}
                  <br />
                  Tips: {userType.join(" ,") || "Nav izvēlēts"}
                  <br />
                  Pašnodarbinātais: {selfEmployed || "-"}
                  <br />
                  <br />
                </Feed.Extra>
              </Feed.Content>
            </Feed.Event>
          </Feed>
        </Grid.Column>
        <Grid.Column>
          <Form>
            <FormSelect
              label="Lietotāja Loma"
              id="role"
              required
              options={ROLE_OPTIONS}
              value={selectedRole}
              onChange={(_, val) => setRole(val as typeof role)}
            />
            <Label pointing>
              NB! Sistēmas superadmina loma dod piekļuvi VISAM portālam un redakcijas saitei.
              Satura partnera loma dod no latviesi.com publicēt ziņas bez redakcijas
              piekrišanas.
            </Label>
          </Form>
        </Grid.Column>
      </Grid>
      <Typography type={definedTypographyTypes.caption}>
        Izvēlies, kuras organizācijas vai uzņēmumus lietotājs var pārvaldīt
      </Typography>
      {isUserAdmin ? (
        <Segment secondary>
          Lietotājs ir Administrators un var pārvaldīt visas grupas.
        </Segment>
      ) : (
        <AdminMultiSelect
          id="userGroups"
          initialValues={data.userGroups}
          onChange={onChange}
        />
      )}
    </ImportantModal>
  );
};

export default EditUserModal;

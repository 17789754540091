import React from "react";
import { IconProps, Icon } from "semantic-ui-react";

import { IRow } from "../../../interfaces/tables";

interface Props {
  cell: { row: IRow };
}

const ExpanderCell: React.FC<Props> = ({ cell: { row } }) => {
  let name: IconProps["name"] = "plus circle";
  if (row.isExpanded) name = "minus circle";

  return (
    <span {...row.getToggleRowExpandedProps()} key="expandable">
      <Icon name={name} />
    </span>
  );
};

export default ExpanderCell;

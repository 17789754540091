import React, { memo, ChangeEvent } from "react";
import { FormField } from "semantic-ui-react";

import { IBaseFormFieldProps } from "../../../interfaces/forms";

// TODO: controlled @Agnese

type FormTimeInputProps = IBaseFormFieldProps<string>;

const FormTimeInput: React.FC<FormTimeInputProps> = ({
  id,
  label,
  required,
  onChange,
  ...props
}) => {
  const changeFn = (event: ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(id, event.target.value);
  };

  return (
    <FormField required={required}>
      {label && <label htmlFor={id}>{label}</label>}
      <input
        {...props}
        id={id}
        type="time"
        required={required}
        onChange={changeFn}
      />
    </FormField>
  );
};

export default memo(FormTimeInput);

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-array-index-key */
import React, {
  useState,
  useEffect,
  useRef,
  ReactElement,
  useCallback,
  SyntheticEvent,
} from "react";
import { ButtonGroup, Button, Popup } from "semantic-ui-react";

import { AlignmentPluginStore } from "./createVIAlignmentPlugin";

interface AlignmentToolProps {
  store: AlignmentPluginStore;
}

const AlignBlockLeftButton = () => (
  <svg
    height="16"
    viewBox="0 0 24 24"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M21,15 L15,15 L15,17 L21,17 L21,15 Z M21,7 L15,7 L15,9 L21,9 L21,7 Z M15,13 L21,13 L21,11 L15,11 L15,13 Z M3,21 L21,21 L21,19 L3,19 L3,21 Z M3,3 L3,5 L21,5 L21,3 L3,3 Z M3,7 L3,17 L13,17 L13,7 L3,7 Z" />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
);
const AlignBlockCenterButton = () => (
  <svg
    height="16"
    viewBox="0 0 24 24"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M3,21 L21,21 L21,19 L3,19 L3,21 Z M3,3 L3,5 L21,5 L21,3 L3,3 Z M5,7 L5,17 L19,17 L19,7 L5,7 Z" />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
);
const AlignBlockRightButton = () => (
  <svg
    height="16"
    viewBox="0 0 24 24"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9,15 L3,15 L3,17 L9,17 L9,15 Z M9,7 L3,7 L3,9 L9,9 L9,7 Z M3,13 L9,13 L9,11 L3,11 L3,13 Z M3,21 L21,21 L21,19 L3,19 L3,21 Z M3,3 L3,5 L21,5 L21,3 L3,3 Z M11,7 L11,17 L21,17 L21,7 L11,7 Z" />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
);

const defaultButtons = [
  { component: AlignBlockLeftButton, alignment: "left" },
  { component: AlignBlockCenterButton, alignment: "center" },
  { component: AlignBlockRightButton, alignment: "right" },
];

export default function AlignmentTool({
  store,
}: AlignmentToolProps): ReactElement {
  const [position, setPosition] = useState(false);
  const [alignment, setAlignment] = useState<string | null>(null);
  const ref = useRef<ReturnType<typeof setTimeout>>();

  const onVisibilityChanged = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (visibleBlock?: null | string): void => {
      const clear = setTimeout(() => {
        let newPosition;
        if (visibleBlock) {
          newPosition = true;
        } else {
          newPosition = false;
        }
        const newAlignment = store.getItem("alignment") || "default";
        setAlignment(newAlignment);
        setPosition(newPosition);
        ref.current = undefined;
      }, 0);
      ref.current = clear;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onAlignmentChange = useCallback((value: string | undefined): void => {
    if (value) {
      setAlignment(value);
    }
  }, []);

  useEffect(
    () => () => {
      // clear timeout on unmount
      if (ref.current) {
        clearTimeout(ref.current);
      }
    },
    [],
  );

  useEffect(() => {
    store.subscribeToItem("visibleBlock", onVisibilityChanged);
    store.subscribeToItem("alignment", onAlignmentChange);

    return () => {
      store.unsubscribeFromItem("visibleBlock", onVisibilityChanged);
      store.unsubscribeFromItem("alignment", onAlignmentChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onVisibilityChanged, onAlignmentChange]);

  return (
    <Popup
      open={position}
      position="top center"
      context={store.getItem("wrapperRef") as any}
      hoverable
    >
      <ButtonGroup>
        {defaultButtons.map(({ component: Icon, alignment: curr }, index) => (
          <Button
            /* the index can be used here as the buttons list won't change */
            key={index}
            active={alignment === curr}
            onMouseDown={(event: SyntheticEvent) => {
              event.preventDefault();
              event.stopPropagation();
              const lol = store.getItem("setAlignment");
              if (lol) {
                setAlignment(curr);
                lol({ alignment: curr });
              }
            }}
            icon={<Icon />}
          />
        ))}
      </ButtonGroup>
    </Popup>
  );
}

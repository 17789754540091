import React from "react";
import { Form } from "semantic-ui-react";

import { IAd } from "../../../../api/AdApi";
import FormImageZone from "../../../molecules/FormFields/FormImageZone";
import FormSourceField from "../../../molecules/FormFields/FormSourceField";

interface Props {
  onChange: (id: string, value: any) => void;
  content: Partial<IAd>;
}

const EditAdForm: React.FC<Props> = ({ content, onChange, children }) => {
  const { imageUrl, source } = content;
  return (
    <Form>
      <FormImageZone
        id="imageUrl"
        required
        initialImgUrl={imageUrl}
        label="Attēls"
        onChange={onChange}
      />
      <FormSourceField
        id="source"
        value={source}
        onChange={onChange}
        customNameLabel="Reklāmas nosaukums"
        customUrlLabel="Reklāmas saite"
      />
      {children}
    </Form>
  );
};

export default EditAdForm;

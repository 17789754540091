export function isValidURL(str: string) {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i",
  ); // fragment locator
  return !!pattern.test(str);
}

export const getQuotedText = (str: string) => {
  const pattern = new RegExp(`^src=".$"`);
  return str.search(pattern);
};

export const getSrcFromIframe = (str: string) => {
  const regex = /src="([^"]+)"/g;
  // const match = str.match(/(?<=src=["'])(.*?)(?=["'])/g);
  const match = str.match(regex);

  const extractedUrl = match ? match[0] : undefined;

  return extractedUrl?.split('"')[1];
};

export const isYoutubeLink = (str = "") => {
  if (str.includes("https://youtu.be") || str.includes("youtube.com")) {
    return true;
  }

  return false;
};

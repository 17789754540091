var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import { Image } from "semantic-ui-react";
import { PLACEHOLDER_16_TO_9 } from "../../../../constants/imageSources";
import { getLargeThumbnailLink, getThumbnailLink } from "../../../../helpers/thumbnails";
const PlaceHoldableImage = (_a) => {
    var { src, fallbackImage, thumbnail, alt, size } = _a, props = __rest(_a, ["src", "fallbackImage", "thumbnail", "alt", "size"]);
    const [img, setImg] = useState({
        src: undefined,
        thumbnailError: false,
        fallbackError: false,
    });
    useEffect(() => {
        if (src) {
            let source = src;
            if (thumbnail && size === "massive") {
                source = getLargeThumbnailLink(src) || src;
            }
            else if (thumbnail) {
                source = getThumbnailLink(src) || src;
            }
            setImg({
                src: source,
                thumbnailError: !thumbnail,
                fallbackError: false,
            });
        }
        else {
            setImg({
                src: fallbackImage || PLACEHOLDER_16_TO_9,
                thumbnailError: true,
                fallbackError: true,
            });
        }
    }, [src, fallbackImage, thumbnail, size]);
    const onError = () => {
        if (!src)
            return;
        const { thumbnailError, fallbackError } = img;
        if (!thumbnailError && !fallbackError) {
            setImg({
                src,
                thumbnailError: true,
                fallbackError: false,
            });
        }
        if (thumbnailError && !fallbackError) {
            setImg({
                src: fallbackImage || PLACEHOLDER_16_TO_9,
                thumbnailError: true,
                fallbackError: true,
            });
        }
        if (thumbnailError && fallbackError) {
            // Do nothing because all images have errored :(
        }
    };
    return (_jsx(Image, Object.assign({ alt: alt, size: size, src: img.src, onError: onError }, props)));
};
export default PlaceHoldableImage;

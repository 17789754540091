import { definedRouteTypes } from "api/api";

import { handleApiPut } from "./api";

// TODO: ADD TO ENVS & DEPLOY STAGING
export const BackendUrl =
  "https://clazi6m2wl.execute-api.eu-central-1.amazonaws.com/production";
// "http://localhost:3000/development";
// "https://0vslrcddzk.execute-api.eu-central-1.amazonaws.com/staging";
// "https://clazi6m2wl.execute-api.eu-central-1.amazonaws.com/production";

// Update Data
export function updateMediaLink(idToken: string, body: any) {
  return handleApiPut(
    `${BackendUrl}/admin/${definedRouteTypes.LINK_MEDIA}`,
    idToken,
    body,
  );
}
export function updateMediaSource(idToken: string, body: any) {
  return handleApiPut(
    `${BackendUrl}/admin/${definedRouteTypes.SOURCE_MEDIA}`,
    idToken,
    body,
  );
}

import React from "react";

import SimpleExpander from "./SimpleExpander";

import { IEditorialNewsDisplay } from "../../../api";
import { formatDate } from "../../../helpers/globalFunctions";

interface INewsExpanderProps {
  data: IEditorialNewsDisplay;
}

const NewsExpander: React.FC<INewsExpanderProps> = ({ data }) => {
  const {
    createdAt,
    subCategories,
    toBePostedAt,
    updatedAt,
    organizationInfo,
    author,
    creator,
  } = data;

  const column1 = {
    title: "Atslēgvardi:",
    contents: subCategories,
  };

  const column2: Array<[string, string]> = [
    ["Izveidots:", formatDate(createdAt)],
    ["Publicēts:", formatDate(toBePostedAt)],
    ["Atjaunots:", formatDate(updatedAt)],
  ];

  const column3: Array<[string, string]> = [
    ["Autors:", author],
    ["Organizācija:", organizationInfo?.title || "Nav"],
    ["Izveidoja:", creator],
  ];

  return (
    <SimpleExpander column1={column1} column2={column2} column3={column3} />
  );
};

export default NewsExpander;

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from "react";
import { Form, Input } from "semantic-ui-react";
import styled from "styled-components";
const StyledInput = styled(Form.Field)(({ description }) => ({
    "&&&": description
        ? {
            marginBottom: "0 !important",
        }
        : {},
}));
const FormTextInput = (_a) => {
    var { onChange, id, label, inputType, required, error, description } = _a, props = __rest(_a, ["onChange", "id", "label", "inputType", "required", "error", "description"]);
    const changeFn = (event) => {
        if (onChange)
            onChange(id, event.target.value);
    };
    return (_jsx(StyledInput, Object.assign({}, props, { control: Input, required: required, error: error, label: label, id: id, description: description, type: inputType, onChange: changeFn })));
};
export default memo(FormTextInput);

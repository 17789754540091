import * as React from "react";
import { List, Button } from "semantic-ui-react";

import { INewsLetter } from "../../../api/NewsLetterApi";
import Typography from "../../atoms/Typography/Typography";
import { definedTypographyTypes } from "../../atoms/Typography/TypographyStyles";
import ReadOnlyRSSList from "../Lists/ReadRSSList";

export interface NewsLetterPreviewPanelProps {
  newsletter: INewsLetter;
  open?: boolean;
  id: number | string;
  handleActivate: (id: string) => void;
  handleView: (id: string) => void;
  handleDelete: (id: string) => void;
  loading?: boolean;
}

const NewsLetterPreviewPanel: React.SFC<NewsLetterPreviewPanelProps> = ({
  newsletter,
  handleActivate,
  handleView,
  handleDelete,
  loading,
  open,
}) => {
  const { title, createdAt, slug, items } = newsletter;
  return (
    <List.Item>
      <List.Content floated="right">
        <Button
          icon="angle double down"
          onClick={() => handleView(slug)}
          disabled={loading}
        />
        <Button
          icon="trash alternate outline"
          onClick={() => handleDelete(slug)}
          disabled={loading}
          color="red"
        />
        <Button
          icon="rss"
          onClick={() => handleActivate(slug)}
          disabled={loading}
          color="yellow"
        />
      </List.Content>
      <List.Content>
        <List.Header>{title}</List.Header>
        <Typography type={definedTypographyTypes.smallCaption}>
          {createdAt}
          {open && <ReadOnlyRSSList selected={items} />}
        </Typography>
      </List.Content>
    </List.Item>
  );
};

export default NewsLetterPreviewPanel;

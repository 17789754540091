/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */
import React, { useState, useCallback, useMemo } from "react";
import { toast } from "react-toastify";
import { useImmer } from "use-immer";
import moment from "moment";
import { getTypeCount, getTypeData } from "api/filterApi";
import useAuthorizedContext from "hooks/useAuthorizedContext";

import TypeTableContext, { TypeTableData } from "./TypeTableContext";

import { ITableControlParams } from "../interfaces/tables";
import { tableToQueryFilters } from "../helpers/tableHelpers";
import {
  definedRouteTypes,
  definedHomePageRoutes,
  definedUnapprovedRoutes,
} from "../api/api";
import { IFilters } from "../api";
import { definedTableActions } from "../hooks/useTypeTableContext";

interface IProps {
  type: definedRouteTypes | definedHomePageRoutes | definedUnapprovedRoutes;
}

const TypeTableContextProvider: React.FC<IProps> = ({ children, type }) => {
  const { token } = useAuthorizedContext();
  const [loading, setLoading] = useState(true);
  const [pages, setPages] = useState<number>(0);
  const [count, setCount] = useState<number>(0);
  const [data, setData] = useImmer<TypeTableData[]>([]);

  const tableData = useMemo(
    () =>
      (data || []).map((item: any) => ({
        ...item,
        createdAt: item.createdAt || item.created,
        updatedAt: item.updatedAt || item.modified,
      })),
    [data],
  );

  const onFetchData = React.useCallback(
    async (params: ITableControlParams<TypeTableData>) => {
      const { pageSize } = params;
      setLoading(true);
      try {
        let opts: IFilters = {
          limit: pageSize,
          offset: 0,
        };
        opts = tableToQueryFilters(params);

        if (type === definedHomePageRoutes.news) {
          opts.homepageRank = ["1", "2", "3", "4"];
        } else if (type === definedHomePageRoutes.events) {
          opts.type = definedRouteTypes.events;
          opts.displayInHomePage = "true";
        } else if (type === definedHomePageRoutes.organizations) {
          opts.type = definedRouteTypes.organizations;
          opts.displayInHomePage = "true";
        } else if (type === definedRouteTypes.offers) {
          opts.keywords = "piedavajumi";
        } else if (type === definedRouteTypes.events) {
          opts.type = definedRouteTypes.events;
        } else if (type === definedRouteTypes.pastEvents) {
          // eslint-disable-next-line prefer-destructuring
          opts.toDate = moment().toISOString().split("T")[0];
          opts.fromDate = "1990-01-01";
          opts.type = definedRouteTypes.events;
        } else if (type === definedUnapprovedRoutes.organizations) {
          opts.type = [
            definedRouteTypes.organizations,
            definedRouteTypes.companies,
            definedRouteTypes.selfEmployed,
          ];
          opts.show = 0;
        } else if (type === definedUnapprovedRoutes.news) {
          opts.type = [
            definedRouteTypes.editorialNews,
            definedRouteTypes.imageGallery,
            definedRouteTypes.videoNews,
            definedRouteTypes.offers,
          ];
          opts.show = 0;
        } else if (type === definedUnapprovedRoutes.events) {
          opts.type = definedRouteTypes.events;
          opts.show = 0;
        } else if (type === definedUnapprovedRoutes.links) {
          opts.type = definedRouteTypes.LINK_MEDIA;
          opts.show = 0;
        } else {
          opts.type = type;
        }
        const idToken = await token();
        const [cnt, res] = await Promise.all([
          getTypeCount(idToken, opts),
          getTypeData(idToken, opts),
        ]);

        setCount(cnt);
        setPages(Math.ceil(cnt / pageSize));
        setData(() => res.items);
      } catch (err) {
        toast.error("Kaut kas nogāja greizi. Mēģini vēlāk vēlreiz.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    },
    [type, setData, token],
  );

  const updateTableEntry = useCallback(
    (newData: TypeTableData, action: definedTableActions) => {
      setData((draft) => {
        const index = draft.findIndex((item) => item.slug === newData.slug);

        if (action === definedTableActions.add && index === -1) {
          draft.unshift(newData);
        }
        if (action === definedTableActions.remove && index > -1) {
          draft.splice(index, 1);
        }
        if (action === definedTableActions.update && index > -1) {
          draft[index] = { ...draft[index], ...newData };
        }

        return draft;
      });
    },
    [setData],
  );

  return (
    <TypeTableContext.Provider
      value={{
        data: tableData,
        loading,
        count,
        pages,
        onFetchData,
        updateTableEntry,
      }}
    >
      {children}
    </TypeTableContext.Provider>
  );
};

export default TypeTableContextProvider;

import {
  CognitoUser,
  CognitoUserSession,
  AuthenticationDetails,
  CognitoRefreshToken,
} from "amazon-cognito-identity-js";

export const getSessionPromise = async (
  user: CognitoUser,
): Promise<CognitoUserSession | null> => {
  return new Promise((resolve, reject) => {
    if (!user) return reject(Error("No User Found"));
    user.getSession((err: null, session: CognitoUserSession | null) => {
      if (err) return reject(err);
      return resolve(session);
    });
  });
};

export const authenticateUserPromise = async (
  user: CognitoUser,
  authDetails: AuthenticationDetails,
): Promise<CognitoUserSession> => {
  return new Promise((resolve, reject) => {
    if (!user) return reject(Error("No User Found"));
    user.authenticateUser(authDetails, {
      async onSuccess(result: CognitoUserSession) {
        return resolve(result);
      },
      onFailure(error) {
        return reject(error);
      },
    });
  });
};

export const refreshSessionPromise = async (
  refreshToken: CognitoRefreshToken,
  user: CognitoUser,
): Promise<CognitoUserSession | null> => {
  return new Promise((resolve, reject) => {
    if (!user) return reject(Error("No User Found"));
    user.refreshSession(
      refreshToken,
      (err: null, session: CognitoUserSession | null) => {
        if (err) return reject(err);
        return resolve(session);
      },
    );
  });
};

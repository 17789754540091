import React, { useState, MouseEvent } from "react";

import ImportantModal from "../../templates/Modals/ImportantModal";
import SimpleButton, {
  definedButtonTypes,
} from "../../atoms/Buttons/SimpleButton/SimpleButton";
import { definedRouteTypes } from "../../../api/api";
import VideoNewsForm from "../Forms/VideoNewsForm";
import EventsForm from "../Forms/EventsForm";
import GalleryForm from "../Forms/GalleryForm";
import ContentForm from "../Forms/ContentForm";
import OrganizationsForm from "../Forms/OrganizationsForm";
import CompaniesForm from "../Forms/CompaniesForm";
import SelfEmployedForm from "../Forms/SelfEmployedForm";

interface AddModalProps {
  type: definedRouteTypes;
}

type Unionize<T> = T[keyof T];
const AddModal: React.FC<AddModalProps> = ({ type }) => {
  const [open, setOpen] = useState(false);
  const closeForm = (event?: MouseEvent) => {
    if (event) event.preventDefault();
    setOpen(false);
  };

  // TODO fix type issue
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let FormComponent: React.FC<any>;
  if (type === definedRouteTypes.organizations) {
    FormComponent = OrganizationsForm;
  } else if (type === definedRouteTypes.companies) {
    FormComponent = CompaniesForm;
  } else if (type === definedRouteTypes.events) {
    FormComponent = EventsForm;
  } else if (type === definedRouteTypes.imageGallery) {
    FormComponent = GalleryForm;
  } else if (type === definedRouteTypes.videoNews) {
    FormComponent = VideoNewsForm;
  } else if (type === definedRouteTypes.selfEmployed) {
    FormComponent = SelfEmployedForm;
  } else {
    FormComponent = ContentForm;
  }

  return (
    <ImportantModal
      open={open}
      trigger={
        <SimpleButton
          onClick={() => setOpen(true)}
          variant={definedButtonTypes.primary}
          id="Add-Content-Button"
        >
          Pievieno +
        </SimpleButton>
      }
    >
      <FormComponent type={type} onFormClose={closeForm} />
    </ImportantModal>
  );
};

export default AddModal;

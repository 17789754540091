import React, { useEffect } from "react";
import { Form, FormField, Icon } from "semantic-ui-react";
import { useImmer } from "use-immer";
import styled from "styled-components";

import FormSourceField from "../../../molecules/FormFields/FormSourceField";
import { ISource } from "../../../../interfaces";
import SimpleButton, {
  definedButtonTypes,
} from "../../../atoms/Buttons/SimpleButton/SimpleButton";
import { IAd } from "../../../../api/AdApi";

interface Props {
  onChange: (id: string, value: any) => void;
  content: Partial<IAd>;
}

const StyledDiv = styled.div({
  display: "grid",
  gridTemplateColumns: "1fr 4em",
  "#minusButton": {
    margin: "auto",
  },
});
const StyledButton = styled.div({ margin: "1.6em 0 0 1em" });

const EMPTY_SOURCE: ISource = { name: "", url: "" };

const EditAdStringForm: React.FC<Props> = ({ content, onChange, children }) => {
  const [arrValues, setValues] = useImmer<ISource[]>(content.links || []);

  const handleArrayFieldChange = (idx: number, data: ISource) => {
    setValues((prevState) => {
      const newValues = prevState;
      if (newValues) {
        newValues[idx] = data;
      }
      return newValues;
    });
  };

  useEffect(() => {
    onChange("links", arrValues);
  }, [onChange, arrValues]);

  return (
    <Form>
      <FormField>
        {arrValues?.map((val, idx) => (
          <StyledDiv key={`${idx}`}>
            <FormSourceField
              id="source"
              value={val}
              onChange={(_, data) => {
                handleArrayFieldChange(idx, data);
              }}
              customNameLabel="Reklāmas nosaukums"
              customUrlLabel="Reklāmas saite"
            />
            <StyledButton>
              <SimpleButton
                id="minusButton"
                initialPadding
                variant={definedButtonTypes.secondary}
                icon="minus square outline"
                onClick={(e: React.MouseEvent) => {
                  e.preventDefault();
                  setValues((currValues: any) => {
                    return currValues.filter((a: any, i: number) => i !== idx);
                  });
                }}
              />
            </StyledButton>
          </StyledDiv>
        ))}
        <SimpleButton
          id="addField"
          fluid
          variant={definedButtonTypes.secondary}
          onClick={(event: React.MouseEvent) => {
            event.preventDefault();
            setValues((draft) => {
              draft.push(EMPTY_SOURCE);
              return draft;
            });
          }}
        >
          <Icon name="plus square outline" />
          Pievieno vēl vienu reklāmas saiti
        </SimpleButton>
      </FormField>
      {children}
    </Form>
  );
};

export default EditAdStringForm;

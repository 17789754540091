import React, { useState, useEffect } from "react";

import SearchFields from "./SearchFields";

import { ISearchQuery } from "../../../../interfaces";
import { ParseQueryValues } from "../../../../helpers/globalFunctions";
import { defaultLimit } from "../../../../constants/variables";

export enum definedQueryTypes {
  search = "search",
}

export interface QueryMenuProps {
  onSearch(query: ISearchQuery): void;
  loading?: boolean;
}

const QueryMenu: React.FC<QueryMenuProps> = ({ onSearch, loading }) => {
  const [query, setQuery] = useState<ISearchQuery>({
    queryValue: "",
    limit: defaultLimit,
    offset: 0,
  });

  function setQueryValues(event: any, id: string) {
    setQuery({ ...query, limit: defaultLimit, offset: 0, [id]: event });
  }

  useEffect(() => {
    const searchQuery = window.location.search.substr(
      1,
      window.location.search.length,
    );
    if (searchQuery) {
      const attributes = searchQuery.split("&");
      const newQuery = ParseQueryValues(attributes);
      setQuery(newQuery);
    }
  }, []);

  return (
    <SearchFields
      query={query}
      setQuery={setQueryValues}
      onSearch={() => onSearch(query)}
      loading={loading}
    />
  );
};

export default QueryMenu;

import React, { useState, useCallback, SyntheticEvent } from "react";
import { Button, Divider, Form, FormGroup, List } from "semantic-ui-react";
import {
  IPictureEntityDisplay,
  IPictureEntityForm,
} from "api/types/PictureEntity";
import FormTextInput from "components/molecules/FormFields/FormTextInput";
import FormGeolocationInput from "components/molecules/FormFields/FormGeolocationInput";
import FormRadioButton from "components/molecules/FormFields/FormRadioButton";
import SinglePageImage from "@latviesi/common/lib/components/molecules/Images/SinglePageImage/SinglePageImage";
import FormAuthorFields from "@latviesi/common/lib/components/molecules/FormFields/FormAuthorFields";
import useAuthorizedContext from "hooks/useAuthorizedContext";
import FormMultiSelect from "components/molecules/FormFields/FormMultiSelect";
import useLocalizedCountryOpts from "hooks/useLocalizedCountryOpts";
import FormGrid from "components/templates/FormGrid";
import { AUTHOR_TYPE } from "@latviesi/common/lib/types/utils/constants";
import { toast } from "react-toastify";
import PicturePostModel from "models/PicturePostModel";

import PictureArrayField from "../../molecules/FormFields/PictureFields/PictureArrayField";
import { FormatDrpValToOption } from "../../../helpers/tableHelpers";
import { PrimaryButton } from "../../atoms/Buttons/SimpleButton/SimpleButtonStyles";

interface EditPictureProps {
  data: IPictureEntityDisplay;
  onSubmit: (data: IPictureEntityForm) => Promise<void>;
  onClose: (event?: SyntheticEvent) => void;
}

const EditPictureForm: React.FC<EditPictureProps> = ({
  data,
  onSubmit,
  onClose,
}) => {
  const { orgData } = useAuthorizedContext();
  const { url, content, entities, creatorName, creatorId } = data;
  const [loading, setLoading] = useState(false);

  const [form, setForm] = useState<IPictureEntityForm>(data);

  const onChange = useCallback((id: string, value: any) => {
    setForm((prev) => ({
      ...prev,
      [`${id}`]: value,
    }));
  }, []);

  // TODO FIx that damn hashtag field so useForm can be used...
  const addValue = (id: string, value: string) => {
    const newEntry: any = { ...form };
    if (!newEntry[id].includes(value)) {
      newEntry[id].push(value);
      setForm(() => newEntry);
    }
  };

  const CountryOptions = useLocalizedCountryOpts();

  const {
    key,
    show,
    persons,
    tags,
    imageText,
    countries,
    address,
    imageAuthor,
    authorType,
    anotherAuthor,
    organizationId,
  } = form;

  const convertedShow = Boolean(Number(show));
  const resources = [...content, ...entities];

  const submit = async (event: SyntheticEvent) => {
    event.preventDefault();
    try {
      setLoading(true);
      const model = await PicturePostModel(form);
      await onSubmit(model);
      toast.success("Attēla dati laboti veiksmīgi.");
    } catch (err) {
      console.error("could not submit picture entity", err);
      toast.success("Attelu neizdevās labot, lūdzu meginiet vēlāk velreiz.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form loading={loading}>
      <SinglePageImage src={url} imageAuthor={imageAuthor} />
      <Divider />
      <FormGrid label="Konfigurācija">
        <FormGroup widths="equal">
          <FormTextInput
            id="key"
            label="Faila nosaukums"
            value={key}
            readonly
          />
          <FormRadioButton
            id="show"
            label="Bilde ir Aktīva"
            value={convertedShow}
            onChange={onChange}
            style={{
              marginTop: "30px",
            }}
          />
        </FormGroup>
        <FormTextInput id="url" label="Faila saite" value={url} readonly />
        <FormTextInput
          id="imageAuthor"
          label="Bildes Avots (birkas teksts)"
          value={imageAuthor}
          onChange={onChange}
        />
        {resources.length > 0 && (
          <Form.Field id="resources">
            <label htmlFor="resources">
              Satura vienības, kas izmanto attēlu:
            </label>
            <List bulleted horizontal>
              {resources.map(({ type, slug: slugg }) => (
                <List.Item
                  target="_blank"
                  as="a"
                  href={`https://www.latviesi.com/${type}/${slugg}`}
                >
                  {slugg}
                </List.Item>
              ))}
            </List>
          </Form.Field>
        )}
      </FormGrid>
      <FormGrid label="Meklēšanas Atslēgvārdi">
        <PictureArrayField
          id="tags"
          label="Atslēgvārdi"
          selectedValues={tags}
          onChange={onChange}
          onAdd={addValue}
          options={FormatDrpValToOption(tags || [])}
        />
        <PictureArrayField
          id="persons"
          label="Personas"
          selectedValues={persons}
          onChange={onChange}
          onAdd={addValue}
          options={FormatDrpValToOption(persons || [])}
        />
        <FormTextInput
          id="imageText"
          label="Bildē atpazītais teksts"
          value={imageText}
          onChange={onChange}
        />
      </FormGrid>
      <FormGrid label="Autors">
        <FormAuthorFields
          organizationId={organizationId}
          anotherAuthor={anotherAuthor}
          authorType={
            (authorType as unknown as AUTHOR_TYPE) || AUTHOR_TYPE.none
          }
          changeFormValue={onChange}
          authorName={creatorName || creatorId || "N/A"}
          orgData={orgData}
        />
      </FormGrid>
      <FormGrid label="Vieta">
        <FormMultiSelect
          id="countries"
          label="Valstis"
          options={CountryOptions}
          value={countries}
          onChange={onChange}
          search
        />
        <FormGeolocationInput
          id="address"
          value={address}
          onChange={onChange}
        />
      </FormGrid>
      <Button onClick={onClose}> Aizvērt </Button>
      <PrimaryButton onClick={submit}>Saglabāt</PrimaryButton>
    </Form>
  );
};

export default EditPictureForm;

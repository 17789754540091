import React, { useState, useCallback, memo, useEffect } from "react";
import styled from "styled-components";

import FormSelect from "./FormSelect";
import FormRadioButton from "./FormRadioButton";

import { REGION, RegionOpts } from "../../../constants/regions";

interface Props {
  id: string;
  value?: REGION | null;
  onChange: (id: string, value: REGION | null) => void;
}

const StyledFormWrapper = styled.div<{ isShown: boolean }>(({ isShown }) => ({
  display: isShown ? "block" : "none",
}));

const FormRegionInput: React.FC<Props> = ({ id, value, onChange }) => {
  const [isOpen, setOpen] = useState(Boolean(value));
  const [location, setLocation] = useState(value || null);

  useEffect(() => {
    if (isOpen) {
      onChange(id, location);
    } else {
      onChange(id, null);
    }
  }, [id, onChange, location, isOpen]);

  const onCountryChange = useCallback(
    (fieldId: string, val: string) => {
      setLocation((val as REGION) || null);
    },
    [setLocation],
  );

  return (
    <div style={{ marginBottom: "14px" }}>
      <FormRadioButton
        id="regionu-poga"
        label="Reģionu ziņa"
        value={isOpen}
        onChange={() => setOpen((prevState) => !prevState)}
      />
      <StyledFormWrapper isShown={isOpen}>
        <FormSelect
          required
          id="region"
          label="Reģions"
          value={location as string}
          options={RegionOpts}
          onChange={onCountryChange}
        />
      </StyledFormWrapper>
    </div>
  );
};

export default memo(FormRegionInput);

import React, { useEffect } from "react";
import { Icon, FormField } from "semantic-ui-react";
import { useImmer } from "use-immer";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import FormTextInput from "./FormTextInput";

import SimpleButton, {
  definedButtonTypes,
} from "../../atoms/Buttons/SimpleButton/SimpleButton";
import { IBaseFormFieldProps } from "../../../interfaces/forms";

type FormTextArrayProps = IBaseFormFieldProps<string[]>;

const StyledDiv = styled.div({
  display: "grid",
  gridTemplateColumns: "1fr 4em",
  "#minusButton": {
    margin: "auto",
  },
});
const StyledButton = styled.div({ margin: "0 0 0 1em" });
const FormTextArray: React.FC<FormTextArrayProps> = ({
  value,
  onChange,
  id,
  label,
  required,
}) => {
  const [arrValues, setValues] = useImmer<string[]>(value || []);
  const { t } = useTranslation();

  const handleArrayFieldChange = (idx: number, data: string) => {
    setValues((prevState) => {
      const newValues = prevState;
      if (newValues) {
        newValues[idx] = data;
      }
      return newValues;
    });
  };

  useEffect(() => {
    if (onChange) onChange(id, arrValues);
  }, [id, onChange, arrValues]);

  return (
    <FormField required={required}>
      {label && <label htmlFor={id}>{label}</label>}
      {arrValues?.map((val, idx) => (
        <StyledDiv key={`${id}-${idx}`}>
          <FormTextInput
            id={id}
            value={val}
            onChange={(_, data) => {
              handleArrayFieldChange(idx, data);
            }}
          />
          <StyledButton>
            <SimpleButton
              id="minusButton"
              initialPadding
              variant={definedButtonTypes.secondary}
              icon="minus square outline"
              onClick={(e: React.MouseEvent) => {
                e.preventDefault();
                setValues((currValues: any) => {
                  return currValues.filter((a: any, i: number) => i !== idx);
                });
              }}
            />
          </StyledButton>
        </StyledDiv>
      ))}
      <SimpleButton
        id="addField"
        fluid
        variant={definedButtonTypes.secondary}
        onClick={(event: React.MouseEvent) => {
          event.preventDefault();
          setValues((currValues: any) => [...currValues, ""]);
        }}
      >
        <Icon name="plus square outline" />
        {t("Forms.Fields.AddMore")}
      </SimpleButton>
    </FormField>
  );
};

export default FormTextArray;

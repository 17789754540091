import { definedAuthorTypes } from "../components/molecules/FormFields/FormAuthorFields";
import { IPictureEntityForm } from "../api/types/PictureEntity";

export default async function PicturePostModel(
  form: IPictureEntityForm,
): Promise<any> {
  const {
    key,
    address,
    persons,
    tags,
    imageText,
    countries,
    imageAuthor,
    authorType,
    organizationId,
    anotherAuthor,
    show,
  } = form;

  return {
    key: key || "",

    address: address || null,
    countries: countries || [],
    authorType: authorType || definedAuthorTypes.none,

    anotherAuthor: anotherAuthor || "",
    imageAuthor: imageAuthor || "",
    organizationId: organizationId || null,
    show: show || false,

    persons: persons || [],
    tags: tags || [],
    imageText: imageText || "",
  };
}

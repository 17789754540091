import React from "react";

import SimpleExpander from "./SimpleExpander";

import { IEventsDisplay } from "../../../api";
import { formatDate } from "../../../helpers/globalFunctions";

interface IEventsExpanderProps {
  data: IEventsDisplay;
}

const EventsExpander: React.FC<IEventsExpanderProps> = ({ data }) => {
  const {
    createdAt,
    subCategories,
    toBePostedAt,
    organizationInfo,
    dateFrom,
    dateTo,
    updatedAt,
    creator,
    author,
  } = data;

  const column1 = {
    title: "Atslēgvardi:",
    contents: subCategories,
  };

  const column2: Array<[string, string]> = [
    ["Norises sākums:", formatDate(dateFrom)],
    ["Norises beigas:", formatDate(dateTo)],
    ["Izveidots:", formatDate(createdAt)],
    ["Publicēts:", formatDate(toBePostedAt)],
    ["Atjaunots:", formatDate(updatedAt)],
  ];

  const column3: Array<[string, string]> = [
    ["Autors:", author],
    ["Organizācija:", organizationInfo?.title || "Nav"],
    ["Izveidoja:", creator],
  ];

  return (
    <SimpleExpander column1={column1} column2={column2} column3={column3} />
  );
};

export default EventsExpander;

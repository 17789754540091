import React, { ChangeEvent, memo, FocusEvent } from "react";
import { FormField } from "semantic-ui-react";

import { IBaseFormFieldProps } from "../../../interfaces/forms";
import Typography from "../../atoms/Typography/Typography";
import { definedTypographyTypes } from "../../atoms/Typography/TypographyStyles";

// TODO: controlled @Agnese

export interface FormTextInputProps extends IBaseFormFieldProps<string> {
  inputType?: "email" | "number" | "text" | "password" | "tel" | "url";
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
}

const FormTextInput: React.FC<FormTextInputProps> = ({
  onChange,
  id,
  label,
  inputType,
  required,
  description,
  ...props
}) => {
  const changeFn = (event: ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(id, event.target.value);
  };

  return (
    <FormField required={required}>
      {label && <label htmlFor={id}>{label}</label>}
      <input
        {...props}
        id={id}
        type={inputType}
        required={required}
        onChange={changeFn}
      />
      {description && (
        <Typography type={definedTypographyTypes.smallCaption}>
          {description}
        </Typography>
      )}
    </FormField>
  );
};

export default memo(FormTextInput);

import React, { useMemo } from "react";
import { Cell } from "react-table";

import { IAdminKeyRow } from "../../pages/KeywordPage";
import UncontrolledTable from "../../templates/Tables/UncontrolledTable";
import BooleanButtonCell from "../../atoms/Cells/BooleanButtonCell";
import { definedAccessors } from "../../../helpers/tableHelpers";

interface Props {
  loading: boolean;
  selected: IAdminKeyRow[];
  all: IAdminKeyRow[];
  toggleSelected: (value: IAdminKeyRow, currentSelectedIndex: number) => void;
}

const columns = [
  definedAccessors.action,
  definedAccessors.localeKeyword,
  definedAccessors.localeCategory,
];

const KeywordTable: React.FC<Props> = ({
  loading,
  all,
  selected,
  toggleSelected,
}) => {
  const actionColumn = useMemo(() => {
    return {
      Header: "Izcelts",
      Cell: (cell: Cell<IAdminKeyRow>) => {
        const { original } = cell.row;
        const { keyword } = original;

        const selectedIndex = selected.findIndex(
          (item) => item.keyword === keyword,
        );
        const isSelected = selectedIndex !== -1;

        return (
          <BooleanButtonCell
            value={isSelected}
            onClick={() => toggleSelected(original, selectedIndex)}
          />
        );
      },
    };
  }, [selected, toggleSelected]);

  return (
    <UncontrolledTable
      loading={loading}
      actionColumn={actionColumn}
      data={all}
      columns={columns}
      initialPageSize={10}
      initialSortBy={[{ id: "localeCategory", desc: false }]}
    />
  );
};

export default KeywordTable;

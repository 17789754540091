import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { Menu } from "semantic-ui-react";

import { definedHomePageRoutes } from "../../api/api";
import TypeDataTable from "../organisms/Tables/TypeDataTable";
import TypeTableContextProvider from "../../context/TypeTableContextProvider";
import Typography from "../atoms/Typography/Typography";
import { definedTypographyTypes } from "../atoms/Typography/TypographyStyles";

interface Props {}

interface IParams {
  type: definedHomePageRoutes;
}

const HomepageTablePage: React.FC<Props> = () => {
  const params = useParams<IParams>();
  const history = useHistory();
  const type = params.type || definedHomePageRoutes.news;
  return (
    <>
      <Typography type={definedTypographyTypes.h1}>Sākumlapa</Typography>
      <Menu pointing secondary>
        <Menu.Item
          name="Jaunumi"
          active={type === definedHomePageRoutes.news}
          onClick={() =>
            history.push(`/homepage/${definedHomePageRoutes.news}`)
          }
        />
        <Menu.Item
          name="Notikumi"
          active={type === definedHomePageRoutes.events}
          onClick={() =>
            history.push(`/homepage/${definedHomePageRoutes.events}`)
          }
        />
        <Menu.Item
          name="Organizācijas"
          active={type === definedHomePageRoutes.organizations}
          onClick={() =>
            history.push(`/homepage/${definedHomePageRoutes.organizations}`)
          }
        />
      </Menu>
      <TypeTableContextProvider key={type} type={type}>
        <TypeDataTable type={type} />
      </TypeTableContextProvider>
    </>
  );
};

export default HomepageTablePage;

export enum ENTITY_CATEGORY {
  transportation = "auto-transports",
  finance = "finanses-apdrosinasana",
  building = "buvnieciba-majoklis",
  media = "mediji-reklama",
  services = "pakalpojumi",
  trade = "tirdznieciba",
  tourism = "turisms-edinasana",
  health = "veseliba-skaistums",
  education = "izglitiba",
  translations = "tulki",
  defense = "tieslietas-aizsardziba",
  government = "valsts-parvalde",
  // TO REMOVE?
  company = "uznemums",
}

export const ENTITY_CATEGORY_TITLE: { [key in ENTITY_CATEGORY]: string } = {
  "auto-transports": "Auto / Transports",
  // "transports-logistika": "Transports / Loģistika",
  "finanses-apdrosinasana": "Finanses / Apdrošināšana",
  // "finanses-ekonomika": "Finanses / Ekonomika",
  "buvnieciba-majoklis": "Būvniecība un mājoklis",
  "mediji-reklama": "Mediji / Mārketings",
  pakalpojumi: "Pakalpojumi",
  tirdznieciba: "Tirdzniecība",
  // "vide-turisms": "Vide / Tūrisms",
  "turisms-edinasana": "Tūrisms / Ēdināšana",
  // "labklajiba-veseliba": "Veselība / Medicīna",
  "veseliba-skaistums": "Veselība / Skaistums",
  izglitiba: "Izglītība / Zinātne",
  // "it-telekomunikacijas": "IT / Telekomunikācijas",
  tulki: "Tulki",
  "tieslietas-aizsardziba": "Tieslietas",
  "valsts-parvalde": "Valsts pārvalde",

  // TO REMOVE?
  uznemums: "Uzņēmums",
};

interface IEntityOpts {
  text: string;
  value: ENTITY_CATEGORY;
  key: string;
}
export const EntityCategoryOpts: Array<IEntityOpts> = Object.entries(
  ENTITY_CATEGORY,
).map(([, value]) => ({
  text: ENTITY_CATEGORY_TITLE[value],
  value,
  key: value,
}));

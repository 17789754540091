import FormMultiSelect from "components/molecules/FormFields/FormMultiSelect";
import FormRadioButton from "components/molecules/FormFields/FormRadioButton";
import FormTextInput from "components/molecules/FormFields/FormTextInput";
import useLocalizedCountryOpts from "hooks/useLocalizedCountryOpts";
import React, { useState } from "react";
import { Button, Form, Segment } from "semantic-ui-react";

interface Props {
  filter: (filters: Record<string, any>) => void;
}

const PictureDbFilters: React.FC<Props> = ({ filter }) => {
  const [filters, setFilters] = useState({
    query: "",
    author: "",
    businessEntity: "",
    countries: [],
    showAll: false,
  });
  const CountryOptions = useLocalizedCountryOpts();

  const onChange = (id: string, value: any) => {
    setFilters((prev) => ({ ...prev, [`${id}`]: value }));
  };

  const onSearch = () => {
    filter(filters);
  };

  return (
    <Segment.Group style={{ marginTop: "20px" }}>
      <Segment.Group horizontal>
        <Segment>
          <div
            style={{
              margin: "auto",
              position: "absolute",
              top: "50%",
              transform: "translateY(-50%)",
            }}
          >
            Meklēt pēc:
          </div>
        </Segment>
        <Segment>
          <Form>
            <Form.Group widths="equal">
              <FormTextInput
                id="query"
                label="Atslēgas vārda"
                value={filters.query}
                onChange={onChange}
              />
              <FormTextInput
                id="author"
                label="Autora"
                value={filters.author}
                onChange={onChange}
              />
              <FormTextInput
                id="businessEntity"
                label="Organizācijas"
                value={filters.businessEntity}
                onChange={onChange}
              />
              <FormMultiSelect
                id="countries"
                label="Valsts"
                options={CountryOptions}
                // value={countries}
                // onChange={changeFormValue}
                value={filters.countries}
                onChange={onChange}
                search
              />
              <FormRadioButton
                id="showAll"
                label="Meklēt tikai aktīvās bildes"
                value={!filters.showAll}
                onChange={(id, data) => onChange(id, !data)}
                style={{ marginTop: "30px" }}
              />
            </Form.Group>
          </Form>
        </Segment>

        <Segment>
          <Button
            style={{
              margin: "auto",
              position: "absolute",
              top: "50%",
              transform: "translateY(-50%)",
              width: "90%",
            }}
            onClick={onSearch}
          >
            Meklēt
          </Button>
        </Segment>
      </Segment.Group>
      <Segment>
        Meklēšanas funkcionalitāte (pagaidām) ir jūtīga pret mazajiem/lielajiem
        burtiem, garumzīmēm un mīkstinājuma zīmēm.
      </Segment>
    </Segment.Group>
  );
};

export default PictureDbFilters;

import React from "react";
import { Image, Icon } from "semantic-ui-react";

import LinkTo from "../../atoms/LinkTo/LinkTo";
import ContactDetailList from "../List/ContactDetailList";
import CardHeading from "../../atoms/CardDetails/CardHeading";
import { IOrganizationForm } from "../../../api";
import { styledWithTheme } from "../../../styles/theme";

interface Props {
  organization: IOrganizationForm;
}

const StyledCard = styledWithTheme.div(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "15em 1fr 2em",
  backgroundColor: "#f8f8f8",
  margin: "1.5em",
  minHeight: "13em",
  alignItems: "center",
  "@media only screen and (max-width: 600px)": {
    gridTemplateColumns: "6em 1fr 2em",
    margin: "0.5em",
  },
  ":hover": {
    transform: " translateY(-3px)",
    boxShadow: "1px 2px 5px 2px rgba(0,0,0,.2)",
  },
  "#rightLink": {
    height: "100%",
  },
  "#red": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    backgroundColor: `${theme.palette.primary.main}`,
    color: "white",
    i: {
      margin: 0,
    },
  },
  "#image": {
    padding: "2em",
    "@media only screen and (max-width: 600px)": {
      padding: "0.2em",
    },
  },
  "#content": {
    padding: "1em",
    "@media only screen and (max-width: 600px)": {
      fontSize: "1em",
    },
  },
}));

const OrganizationListCard: React.FC<Props> = ({ organization }) => {
  const {
    title,
    email,
    website,
    phone,
    imageUrl,
    slug,
    address,
  } = organization;
  // !TODO  fix or remove component
  const route = `${slug}`;

  return (
    <StyledCard>
      <div id="image">
        <LinkTo to={route}>
          <Image src={imageUrl} alt={title} />
        </LinkTo>
      </div>
      <div id="content">
        <CardHeading title={title} route={route} />
        <ContactDetailList
          website={website}
          phone={phone}
          email={email}
          address={address?.address}
        />
      </div>
      <div id="rightLink">
        <LinkTo to={route}>
          <div id="red">
            <Icon name="arrow right" />
          </div>
        </LinkTo>
      </div>
    </StyledCard>
  );
};

export default OrganizationListCard;

import React from "react";
import moment, { Moment } from "moment";

interface Props {
  cell: { value?: string | Date | Moment | null };
}

const DateCell: React.FC<Props> = ({ cell: { value } }) => {
  if (!value) return <>-</>;

  const date = moment(value);

  if (date.isValid())
    return <>{moment(value).format("Do MMM, YYYY (HH:mm)")}</>;

  return <>-</>;
};

export default DateCell;

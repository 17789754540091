import { CharacterMetadata, ContentBlock, ContentState } from "draft-js";

import { LINK_ENTITY_TYPE } from "./constants/index";

const linkStrategy = (
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState,
) => {
  contentBlock.findEntityRanges((character: CharacterMetadata) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === LINK_ENTITY_TYPE
    );
  }, callback);
};

export default linkStrategy;

import * as React from "react";
import { useTranslation } from "react-i18next";
import { Loader } from "semantic-ui-react";

import {
  StyledForm,
  StyledGrid,
  StyledColumn,
  StyledText,
  StyledInput,
  StyledIcon,
} from "./Styles";

import { ISearchQuery } from "../../../../interfaces";

export interface SearchFieldsProps {
  setQuery(value: string, id: string): void;
  query: ISearchQuery;
  onSearch: () => void;
  loading?: boolean;
}

const SearchFields: React.FC<SearchFieldsProps> = ({
  query,
  setQuery,
  onSearch,
  loading,
}) => {
  const { t } = useTranslation();
  return (
    <StyledForm onSubmit={onSearch} disabled={loading}>
      <StyledGrid>
        <StyledColumn computer={4} mobile={16}>
          <StyledText>{t("Filter.SearchPortal")}</StyledText>
        </StyledColumn>
        <StyledColumn computer={10} mobile={14}>
          <StyledInput
            id="source"
            fluid
            placeholder={t("Filter.EnterText")}
            value={query.queryValue}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setQuery(e.target.value, "queryValue")
            }
          />
        </StyledColumn>
        <StyledColumn computer={2} mobile={2}>
          {loading ? (
            <Loader active inline="centered" id="searchLoading" />
          ) : (
            <StyledIcon
              name="search"
              size="big"
              color="grey"
              onClick={onSearch}
              id="searchButton"
            />
          )}
        </StyledColumn>
      </StyledGrid>
    </StyledForm>
  );
};

export default SearchFields;

import React from "react";
import styled from "styled-components";

export interface DropzoneContainerProps {}

const StyledContainer = styled.div({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  padding: "1.250em",
  borderWidth: "2px",
  borderRadius: "2px",
  borderColor: "#E7E7E7",
  borderStyle: "dashed",
  backgroundColor: "#f8f8f8",
  color: "#BBBBBB",
  outline: "none",
  transition: "border .24s ease-in-out",
});

const StyledDropArea = styled.section({
  width: "100%",
  height: "100%",
  alignItems: "center",
  textAlign: "center",
  "* > img": {
    maxHeight: "300px!important",
  },
});

const DropzoneContainer: React.FC<DropzoneContainerProps> = ({ children }) => (
  <StyledContainer>
    <StyledDropArea>{children}</StyledDropArea>
  </StyledContainer>
);

export default DropzoneContainer;

import {
  AWSurl,
  handleApiGet,
  handleApiPost,
  handleApiPut,
  definedRouteTypes,
} from "./api";
import { getSingleData } from "./DynamicApi";

import { queryStringBuilder } from "../helpers/globalFunctions";

import { ICompanyForm, IFilters } from ".";

const companies = `${AWSurl}/${definedRouteTypes.companies}`;
const createCompanies = `${AWSurl}/${definedRouteTypes.companies}/create`;

export function getCompanies(opts?: IFilters) {
  const query = queryStringBuilder(opts);
  return handleApiGet(`${companies}${query}`);
}

export function getSingleCompany(slug: string) {
  return getSingleData(definedRouteTypes.companies, slug);
}

export function postCompanies(idToken: string, body: ICompanyForm) {
  return handleApiPost(createCompanies, idToken, body);
}

export function updateCompanies(idToken: string, body: Partial<ICompanyForm>) {
  return handleApiPut(companies, idToken, body);
}

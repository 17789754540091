import React, { MouseEvent } from "react";
import { Form, Divider } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import styled from "styled-components";
import { convertToRaw, ContentState } from "draft-js";
import useAuthorizedContext from "hooks/useAuthorizedContext";

import FormTextInput from "../../molecules/FormFields/FormTextInput";
import FormSelect from "../../molecules/FormFields/FormSelect";
import useForm from "../../../hooks/useForm";
import FormGrid from "../../templates/FormGrid";
import FormImageZone from "../../molecules/FormFields/FormImageZone";
import FormGeolocationFields from "../../molecules/FormFields/FormGeolocationInput";
import { definedRouteTypes, definedErrors } from "../../../api/api";
import FormTextArray from "../../molecules/FormFields/FormArrayField";
import Typography from "../../atoms/Typography/Typography";
import { definedTypographyTypes } from "../../atoms/Typography/TypographyStyles";
import SimpleButton, {
  definedButtonTypes,
} from "../../atoms/Buttons/SimpleButton/SimpleButton";
import { ICompanyForm } from "../../../api";
import FormTextArea from "../../molecules/FormFields/FormTextArea";
import FormRadioButton from "../../molecules/FormFields/FormRadioButton";
import FormSocialFields from "../../molecules/FormFields/FormSocialFields";
import FormMultiSelect from "../../molecules/FormFields/FormMultiSelect";
import { IBaseFormProps } from "../../../interfaces/forms";
import FormRichTextArea from "../../molecules/FormFields/FormRichTextArea";
import useLocalizedCountryOpts from "../../../hooks/useLocalizedCountryOpts";
import useTypeTableContext, {
  definedTableActions,
} from "../../../hooks/useTypeTableContext";
import EntityPostModel from "../../../models/EntityPostModel";
import {
  getSingleCompany,
  postCompanies,
  updateCompanies,
} from "../../../api/CompaniesApi";
import { slugifyWord } from "../../../helpers/globalFunctions";
import { EntityCategoryOpts } from "../../../constants/entityCategories";

interface Props extends IBaseFormProps {
  update?: boolean;
  initialValues?: ICompanyForm;
  onFormClose?: (event?: MouseEvent) => void;
  type?: definedRouteTypes.organizations;
}

const StyledDiv = styled.div({
  textAlign: "center",
});

const defaultInitValues: ICompanyForm = {
  title: "",
  description: convertToRaw(ContentState.createFromText("")),
  imagePointer: undefined,
  imageUrl: "",
  category: undefined,
  slug: "",
  shortSlug: "",
  countries: [],
  address: undefined,
  type: definedRouteTypes.companies,
  email: [],
  phone: [],
  media: {},
  show: false,
  highlighted: false,
  highlightedInCountry: false,
  displayInHomePage: false,
};

const CompaniesForm: React.FC<Props> = ({
  initialValues,
  update,
  onFormClose,
}) => {
  const { t } = useTranslation();
  const CountryOptions = useLocalizedCountryOpts();
  const typeTableContext = useTypeTableContext();
  const { user, token } = useAuthorizedContext();
  const submitFormQuery = async (form: ICompanyForm) => {
    try {
      const act = update ? definedTableActions.update : definedTableActions.add;
      const postForm = await EntityPostModel(form, user);

      const idToken = await token();
      if (update) {
        await updateCompanies(idToken, postForm);
      } else {
        const newOrg = await getSingleCompany(postForm.slug);
        if (newOrg?.slug) throw new Error(definedErrors.slugExists);
        await postCompanies(idToken, postForm);
      }

      toast.success(
        t(update ? "Toasts.EditsSuccess" : "Toasts.Publish.uznemumi"),
      );
      if (typeTableContext) typeTableContext.updateTableEntry(postForm, act);
      if (onFormClose) onFormClose();
    } catch (err) {
      if (err.message.includes(definedErrors.slugExists)) {
        toast.error(t("Toasts.CompanyExists"));
      } else {
        toast.error(t("Toasts.GenericError"));
      }
      console.error("postCompaniess failed", err);
    }
  };

  const {
    values,
    submitForm,
    changeFormValue,
    changeFormImageValue,
    isLoading,
  } = useForm(initialValues || defaultInitValues, submitFormQuery);
  const {
    title,
    description,
    phone,
    shortTitle,
    email,
    website,
    category,
    countries,
    address,
    highlighted,
    highlightedInCountry,
    show,
    bankCredentials,
    imageUrl,
    displayInHomePage,
    subCategories,
    contactPerson,
    comment,
  } = values;
  const media = values.media || {};

  const convertedShow = Boolean(Number(show));
  const convertedDisplay = Boolean(Number(displayInHomePage));

  const validateSlug = async () => {
    if (update) return;
    try {
      const newSlug = slugifyWord(title);
      const newOrg = await getSingleCompany(newSlug);
      if (newOrg?.slug) toast.error(t("Toasts.CompanyExists"));
    } catch (err) {
      console.error("something wrong with slug checking", err);
    }
  };

  return (
    <Form name="editorialNewsForm" loading={isLoading}>
      <Typography type={definedTypographyTypes.h1} as="span">
        {update ? t("Forms.Titles.EditCompany") : t("Forms.Titles.AddCompany")}
      </Typography>
      <Divider />
      <FormGrid label={t("Administrēšana")}>
        <FormRadioButton
          id="show"
          label="Redzams portālā"
          value={convertedShow}
          onChange={changeFormValue}
        />
        <FormRadioButton
          id="displayInHomePage"
          label="Rādīt Sākumlapā"
          value={convertedDisplay}
          onChange={changeFormValue}
        />
        <FormRadioButton
          id="highlighted"
          label="Izcelts"
          value={highlighted}
          onChange={changeFormValue}
        />
        <FormRadioButton
          id="highlightedInCountry"
          label="Izcelts valstī"
          value={highlightedInCountry}
          onChange={changeFormValue}
        />
      </FormGrid>
      <FormGrid label={t("Galvenie Dati")}>
        <FormTextInput
          id="title"
          required
          value={title}
          label={t("Uzņēmuma pilnais nosaukums")}
          onChange={changeFormValue}
          onBlur={validateSlug}
        />
        <FormTextInput
          id="shortTitle"
          value={shortTitle || ""}
          label={t("Uzņēmuma saīsinātais nosaukums / iniciāļi")}
          onChange={changeFormValue}
        />
        {/* {isFormEdit || (
          <FormTextInput
            id="shortSlug"
            value={shortSlug || ""}
            label={t("Forms.Titles.OrgShortSlug")}
            placeholder="mana-saite"
            onChange={changeFormValue}
            disabled={isFormEdit}
            required
          />
        )} */}
        <FormRichTextArea
          id="description"
          toolbarHidden
          required
          label={t("Piedāvājuma apraksts")}
          value={description}
          onChange={changeFormValue}
          description={t(
            "Pirmie 280 simboli tiks attēloti kā uzņēmuma apraksts portāla uzņēmumu sadaļā.",
          )}
        />
        <FormImageZone
          id="imageUrl"
          required
          initialImgUrl={imageUrl}
          label={t("Logo")}
          onImageChange={changeFormImageValue}
          onChange={changeFormValue}
        />
      </FormGrid>
      <FormGrid label={t("Kategorija")}>
        <FormSelect
          label={t("Kategorija")}
          id="category"
          required
          value={category}
          options={EntityCategoryOpts}
          onChange={changeFormValue}
          search
        />
        <FormMultiSelect
          id="countries"
          label={t("Valstis")}
          options={CountryOptions}
          value={countries}
          onChange={changeFormValue}
          search
        />
        {/* // subCategories HACK sorry :( */}
        <FormRadioButton
          id="subCategories"
          label="Latvijas pēdas pasaulē"
          value={(subCategories?.includes("latvijas-pedas-pasaule") || 0) > 0}
          onChange={(id, data) => {
            const cat = "latvijas-pedas-pasaule";
            const initial = (initialValues?.subCategories || []).filter(
              (item) => item !== cat,
            );
            changeFormValue(id, data ? [...initial, cat] : initial);
          }}
        />
      </FormGrid>
      <FormGrid label={t("Adrese")}>
        <FormGeolocationFields
          id="address"
          value={address}
          onChange={changeFormValue}
        />
      </FormGrid>
      <FormGrid label={t("Kontakti")}>
        <FormTextInput
          id="website"
          label={t("Mājaslapa")}
          value={website}
          onChange={changeFormValue}
        />
        <FormTextArray
          id="email"
          label={t("E-pasts")}
          value={email}
          onChange={changeFormValue}
        />
        <FormTextArray
          id="phone"
          label={t("Telefons")}
          value={phone}
          onChange={changeFormValue}
        />
        <FormSocialFields id="media" value={media} onChange={changeFormValue} />
        <FormTextArea
          id="bankCredentials"
          label={t("Bankas rekvizīti")}
          value={bankCredentials}
          onChange={changeFormValue}
        />
        <FormTextInput
          id="contactPerson"
          label="Kontaktpersona"
          value={contactPerson}
          onChange={changeFormValue}
        />
        <FormTextArea
          id="comment"
          label="Komentārs"
          value={comment}
          onChange={changeFormValue}
        />
      </FormGrid>
      <StyledDiv>
        {onFormClose && (
          <SimpleButton
            id="FormCancel"
            variant={definedButtonTypes.secondary}
            onClick={onFormClose}
          >
            {t("cancel")}
          </SimpleButton>
        )}
        <SimpleButton
          id="FormSubmit"
          onClick={(e) => submitForm(e)}
          variant={definedButtonTypes.primary}
        >
          {t("submit")}
        </SimpleButton>
      </StyledDiv>
    </Form>
  );
};
CompaniesForm.defaultProps = {
  type: definedRouteTypes.organizations,
};

export default CompaniesForm;

import React, { MouseEvent } from "react";
import { IconProps, Dropdown } from "semantic-ui-react";
import styled from "styled-components";

interface EditTemplateSectionButtonProps {
  remove: (event: MouseEvent) => void;
  title: (event: MouseEvent) => void;
  moveLeft: (event: MouseEvent) => void;
  moveRight: (event: MouseEvent) => void;
  icon: IconProps["name"];
}

const StyledContainer = styled.div({
  position: "relative",
});

const StyledEditButton = styled(Dropdown)({
  "&&&": {
    position: "absolute",
    top: 0,
    right: 0,
    zIndex: 1,
  },
});

const HoverButton: React.FC<EditTemplateSectionButtonProps> = ({
  remove,
  title,
  moveLeft,
  moveRight,
  icon,
  children,
}) => {
  return (
    <StyledContainer>
      <StyledEditButton
        icon={icon}
        button
        floating
        pointing="top left"
        className="icon"
      >
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={remove}
            icon="trash alternate outline"
            text="Dzēst attēlu"
          />
          <Dropdown.Item
            onClick={title}
            icon="images outline"
            text="Iestatīt kā titulbildi"
          />
          <Dropdown.Item
            onClick={moveRight}
            icon="angle right"
            text="Pārvietot pa labi"
          />
          <Dropdown.Item
            onClick={moveLeft}
            icon="angle left"
            text="Pārvietot pa kreisi"
          />
        </Dropdown.Menu>
      </StyledEditButton>
      {children}
    </StyledContainer>
  );
};

export default HoverButton;

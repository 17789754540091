import { EditorState, RichUtils } from "draft-js";
import React, { SyntheticEvent, useCallback } from "react";
import { Button, ButtonGroup } from "semantic-ui-react";

import getSelectedBlockType from "../utils/getSelectedBlockType";

const LIST_STYLE_BUTTONS = [
  { label: "UL", style: "unordered-list-item", icon: "list ul" },
  { label: "OL", style: "ordered-list-item", icon: "list ol" },
  { label: "Blockquote", style: "blockquote", icon: "quote left" },
  // { label: "Code Block", style: "code-block", icon: "code" },
];

interface Props {
  editorState: EditorState;
  onChange: (editorState: EditorState) => void;
}

const BlockStyleToolbar: React.FC<Props> = ({ onChange, editorState }) => {
  const onBlockStyleClick = useCallback(
    (style: string, state: EditorState) => (event: SyntheticEvent) => {
      event.preventDefault();
      onChange(RichUtils.toggleBlockType(state, style));
    },
    [onChange],
  );
  const blockType = getSelectedBlockType(editorState);
  return (
    <ButtonGroup>
      {LIST_STYLE_BUTTONS.map((btt) => (
        <Button
          active={btt.style === blockType}
          onMouseDown={onBlockStyleClick(btt.style, editorState)}
          icon={btt.icon}
          key={btt.icon}
        />
      ))}
    </ButtonGroup>
  );
};

export default BlockStyleToolbar;

import React, { useState } from "react";
import {
  Accordion,
  Button,
  FormGroup,
  Grid,
  Icon,
  Image,
  Segment,
} from "semantic-ui-react";
import copy from "copy-to-clipboard";
import { toast } from "react-toastify";
import chunk from "lodash.chunk";
import styled from "styled-components";
import useAuthorizedContext from "hooks/useAuthorizedContext";
import { FilterPictureDbApi } from "api/PictureApi";

import { IndexSignature } from "../../../../interfaces";
import Typography from "../../../atoms/Typography/Typography";
import { definedTypographyTypes } from "../../../atoms/Typography/TypographyStyles";
import FormTextInput from "../FormTextInput";

const StyledImage = styled(Image)`
  &&& {
    &:hover {
      transform: translateY(-3px);
      box-shadow: grey;
    }
  }
`;

export interface PictureSearchZoneProps {
  setImage: any;
  add?: boolean;
  disabled?: boolean;
}

export interface SearchImageZoneValues {
  imageAuthor: string;
  url: string;
}

const PictureSearchZone: React.FC<PictureSearchZoneProps> = ({
  setImage,
  add,
  disabled,
}) => {
  const [searchValues, setSearchValues] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [pages, setPages] = useState<IndexSignature[]>([]);
  const [activePage, setActivePage] = useState(0);
  const [show, setShow] = useState(0);
  const { token } = useAuthorizedContext();

  const setValue = async (id: string, value: string) => {
    setSearchValues(value);
  };

  const search = async () => {
    setLoading(true);
    try {
      const idToken = await token();
      const { Items } = await FilterPictureDbApi(
        idToken,
        { query: searchValues },
        40,
      );
      if (!Items.length) {
        toast.info("Pēc dotā atslēgas vārda neko neizdevās atrast!");
      }
      const chunked = chunk(Items, 2);
      const mapped = chunk(chunked, 10);
      setActivePage(0);
      setPages(mapped);
    } catch (err) {
      toast.error("Neizdevās iegūt bilžu datus");
    } finally {
      setLoading(false);
    }
  };

  // const traverseTable = (next: boolean) => {
  //   let currentPage = activePage;
  //   if (next) {
  //     currentPage += 1;
  //   } else {
  //     currentPage -= 1;
  //   }

  //   if (currentPage <= 0) currentPage = 0;

  //   setActivePage(currentPage);
  // };

  const copyToClipboard = (text: string) => {
    copy(text);
    toast.success("Veiksmīgi nokopēts");
  };
  return (
    <div style={{ marginBottom: "1em" }}>
      <FormGroup widths="equal">
        <FormTextInput
          id="query"
          label="Meklēt bildi datubāzē"
          value={searchValues}
          onChange={setValue}
          disabled={loading}
          // onChange={(id, val) => {
          //   setSearchValues(val);
          // }}
        />
        <Button
          onClick={search}
          loading={loading}
          disabled={loading}
          style={{
            marginTop: "21px",
          }}
        >
          Meklēt
        </Button>
      </FormGroup>
      {pages.length > 0 && (
        <Accordion styled fluid disabled={disabled}>
          <Accordion.Title
            active={show === 0}
            index={0}
            disabled={disabled}
            onClick={() => setShow(show === 0 ? 1 : 0)}
          >
            <Typography type={definedTypographyTypes.caption}>
              {show === 0 ? (
                <Icon name="angle double up" />
              ) : (
                <Icon name="angle double down" />
              )}{" "}
              Atrastās bildes
            </Typography>
          </Accordion.Title>
          <Accordion.Content active={show === 0}>
            <Segment basic loading={loading}>
              <Grid columns="2">
                {pages[activePage].map((pd: any) => (
                  <Grid.Row key={pd[0].SK}>
                    {pd.map((col: any) => (
                      <Grid.Column key={col.key}>
                        <StyledImage src={col.url} />
                        <p>{col.key} </p>
                        <p>
                          <b> Bildes Aut. :</b>
                          {col.imageAuthor}
                        </p>
                        <Button
                          onClick={() => {
                            setImage({
                              imageAuthor: col.imageAuthor || "",
                              url: col.url,
                            } as SearchImageZoneValues);
                            setShow(1);
                          }}
                        >
                          {add ? "Pievienot" : "Iestatīt kā titulbildi"}
                        </Button>
                        <Button onClick={() => copyToClipboard(col.url)}>
                          Kopēt linku
                        </Button>
                      </Grid.Column>
                    ))}
                  </Grid.Row>
                ))}
              </Grid>
              {/* <Segment>
                <Button
                  disabled={activePage === 0}
                  onClick={() => traverseTable(false)}
                >
                  &lt;
                </Button>
                <Button
                  disabled={
                    activePage + 1 >= pages.length || pages.length === 1
                  }
                  onClick={() => traverseTable(true)}
                >
                  &gt;
                </Button>
              </Segment> */}
            </Segment>
          </Accordion.Content>
        </Accordion>
      )}
    </div>
  );
};

export default PictureSearchZone;

import React, { useState, useCallback, memo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { FormField } from "semantic-ui-react";

import FormSelect from "./FormSelect";
import FormRadioButton from "./FormRadioButton";

import GoogleMap from "../Maps/GoogleMap";
import FormSegment from "../../templates/Wrappers/FormSegment";
import { ILocation } from "../../../interfaces";
import useLocalizedCountryOpts from "../../../hooks/useLocalizedCountryOpts";
import { COUNTRY } from "../../../constants/country";

interface Props {
  id: string;
  value?: ILocation | null;
  onChange: (id: string, value: ILocation | null) => void;
}

const StyledFormWrapper = styled.div<{ isShown: boolean }>(({ isShown }) => ({
  display: isShown ? "block" : "none",
}));

const FormRegionInput: React.FC<Props> = ({ id, value, onChange }) => {
  const { t } = useTranslation();
  const CountryOptions = useLocalizedCountryOpts();
  const [isOpen, setOpen] = useState(Boolean(value));
  const [location, setLocation] = useState(
    value || {
      // Riga is default location!
      country: COUNTRY.lv,
      address: "",
      geolocation: {
        lat: 56.9496487,
        lng: 24.1051865,
      },
    },
  );

  useEffect(() => {
    if (isOpen) {
      onChange(id, location);
    } else {
      onChange(id, null);
    }
  }, [id, onChange, location, isOpen]);

  const onCountryChange = useCallback(
    (fieldId: string, val: string) => {
      setLocation((prevLocation) => ({
        ...prevLocation,
        country: val as COUNTRY, // Because Semantic types kinda suck.
      }));
    },
    [setLocation],
  );

  const onAddressBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      setLocation((prevLocation) => ({
        ...prevLocation,
        address: event.currentTarget.value,
      }));
    },
    [setLocation],
  );

  const onGeolocationChange = useCallback(
    (val: ILocation["geolocation"]) => {
      setLocation((prevLocation) => ({
        ...prevLocation,
        geolocation: { ...val },
      }));
    },
    [setLocation],
  );

  const { country, address, geolocation } = location;

  return (
    <>
      <FormRadioButton
        id="vietas-piesaises-poga"
        label={t("Forms.Fields.hasLocationTrigger")}
        value={isOpen}
        onChange={() => setOpen((prevState) => !prevState)}
      />
      <StyledFormWrapper isShown={isOpen}>
        <FormSelect
          id="country"
          label={t("Forms.Fields.country")}
          options={CountryOptions}
          value={country}
          onChange={onCountryChange}
          search
        />
        <FormField>
          <label htmlFor={address}>{t("Forms.Fields.address")}</label>
          <input id="address" defaultValue={address} onBlur={onAddressBlur} />
        </FormField>
        <FormSegment>
          {isOpen && (
            <GoogleMap
              address={address}
              country={country}
              defaultGeolocation={geolocation}
              changeLocation={onGeolocationChange}
            />
          )}
        </FormSegment>
      </StyledFormWrapper>
    </>
  );
};

export default memo(FormRegionInput);

import React, { ReactNode } from "react";
import { Modal } from "semantic-ui-react";

import Typography from "../../atoms/Typography/Typography";
import { definedTypographyTypes } from "../../atoms/Typography/TypographyStyles";

interface ImportantModalModalProps {
  trigger: ReactNode;
  open?: boolean;
  actions?: ReactNode;
  header?: string;
}

const ImportantModal: React.FC<ImportantModalModalProps> = ({
  actions,
  header,
  open,
  trigger,
  children,
}) => {
  return (
    <Modal
      trigger={trigger}
      size="large"
      open={open}
      closeOnEscape={false}
      closeOnDimmerClick={false}
    >
      {header && (
        <Modal.Header>
          <Typography type={definedTypographyTypes.h1} as="span">
            {header}
          </Typography>
        </Modal.Header>
      )}
      <Modal.Content>
        <Modal.Description>{children}</Modal.Description>
      </Modal.Content>
      {actions && <Modal.Actions>{actions}</Modal.Actions>}
    </Modal>
  );
};

export default ImportantModal;

import { ContentBlock, EditorState } from "draft-js";

const ATOMIC = "atomic";

const isBlockWithEntityType = (
  editorState: EditorState,
  block: ContentBlock,
  entityType: string,
) => {
  if (block.getType() !== ATOMIC) {
    return false;
  }

  const contentState = editorState.getCurrentContent();
  const entityKey = block.getEntityAt(0);

  if (!entityKey) {
    return false;
  }

  const entity = contentState.getEntity(entityKey);

  return entity.getType() === entityType;
};

export default isBlockWithEntityType;

import React from "react";

import { IRow } from "../../../interfaces/tables";
import { IOrganizationDisplay } from "../../../api";
import { definedRouteTypes } from "../../../api/api";

interface Props {
  cell: { row: IRow<IOrganizationDisplay> };
}

const NewsTitleCell: React.FC<Props> = ({ cell: { row } }) => {
  const { slug, type, title, url } = row.original;

  if (
    (type as any) === definedRouteTypes.LINK_MEDIA ||
    (type as any) === definedRouteTypes.SOURCE_MEDIA
  ) {
    return (
      <a href={url} target="_blank" rel="noopener noreferrer">
        {title}
      </a>
    );
  }

  return (
    // This is internal site
    // eslint-disable-next-line react/jsx-no-target-blank
    <a href={`https://www.latviesi.com/${type}/${slug}`} target="_blank">
      {title}
    </a>
  );
};

export default NewsTitleCell;

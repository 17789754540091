import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-nested-ternary */
import { memo } from "react";
import { useTranslation } from "react-i18next";
import useUserGroupOpts from "../../../hooks/useUserGroupOpts";
import { AUTHOR_TYPE } from "../../../types/utils/constants";
import FormRadioGroup from "./FormRadioGroup";
import FormSelect from "./FormSelect";
import FormTextInput from "./FormTextInput";
const FormAuthorFields = ({ authorType, anotherAuthor, organizationId, changeFormValue, disabled, orgData, authorName, }) => {
    const { t } = useTranslation();
    const { CompanyOptions, OrganizationOptions } = useUserGroupOpts(orgData);
    return (_jsxs("div", { children: [_jsx(FormRadioGroup, { id: "authorType", options: [
                    {
                        name: AUTHOR_TYPE.organization,
                        value: AUTHOR_TYPE.organization,
                        label: t("Forms.AuthorTypes.organization"),
                        disabled,
                    },
                    {
                        name: AUTHOR_TYPE.company,
                        value: AUTHOR_TYPE.company,
                        label: t("Forms.AuthorTypes.company"),
                        disabled,
                    },
                    {
                        name: AUTHOR_TYPE.author,
                        value: AUTHOR_TYPE.author,
                        label: t("Forms.AuthorTypes.author"),
                        disabled,
                    },
                    {
                        name: AUTHOR_TYPE.anotherAuthor,
                        value: AUTHOR_TYPE.anotherAuthor,
                        label: t("Forms.AuthorTypes.anotherAuthor"),
                        disabled,
                    },
                    {
                        name: AUTHOR_TYPE.none,
                        value: AUTHOR_TYPE.none,
                        label: t("Forms.AuthorTypes.none"),
                        disabled,
                    },
                ], onChange: changeFormValue, value: authorType || AUTHOR_TYPE.author }), authorType === AUTHOR_TYPE.organization && (_jsx(FormSelect, { label: t("Forms.AuthorTypes.organization"), placeholder: t("Forms.SelectOrg"), id: "organizationId", value: organizationId, required: true, disabled: disabled, options: OrganizationOptions, onChange: changeFormValue, search: true })), authorType === AUTHOR_TYPE.company && (_jsx(FormSelect, { label: t("Forms.AuthorTypes.company"), placeholder: t("Forms.SelectCompany"), id: "organizationId", value: organizationId, required: true, disabled: disabled, options: CompanyOptions, onChange: changeFormValue, search: true })), authorType === AUTHOR_TYPE.author && (_jsx(FormTextInput, { id: "author", value: authorName, disabled: true })), authorType === AUTHOR_TYPE.anotherAuthor && (_jsx(FormTextInput, { id: "anotherAuthor", required: true, disabled: disabled, value: anotherAuthor || "", onChange: changeFormValue, placeholder: t("Forms.WriteAuthor") }))] }));
};
export default memo(FormAuthorFields);

import React from "react";
import { IDropDownOption } from "interfaces";

import { StyledSelect } from "../../organisms/Menus/FilterMenu/FilterMenuStyles";
import { IColumnProps } from "../../../interfaces/tables";

interface Props {
  column: IColumnProps<{}>;
}
const dropDownCategoryOpts: Array<IDropDownOption> = [
  {
    key: "all",
    text: "Visi",
    value: "all", // because falsy values acted weird
  },
  {
    key: "true",
    text: "Jā",
    value: "true",
  },
  {
    key: "false",
    text: "Nē",
    value: "false",
  },
];

const BooleanFilter: React.FC<Props> = ({ column: { id, setFilter } }) => {
  return (
    <StyledSelect
      placeholder="Partneria"
      fluid
      defaultValue="all"
      selection
      options={dropDownCategoryOpts}
      onChange={(e: any, data: any) => setFilter(data.value)}
    />
  );
};

export default BooleanFilter;

/* eslint-disable no-param-reassign */
import React, { useMemo, useCallback } from "react";
import { Cell } from "react-table";
import { Button } from "semantic-ui-react";
import { useImmer } from "use-immer";
import useAuthorizedContext from "hooks/useAuthorizedContext";
import { FilterPictureDbApi } from "api/PictureApi";
import { IPictureEntityForm } from "api/types/PictureEntity";
import copy from "copy-to-clipboard";
import { toast } from "react-toastify";

import useAPI from "../../../hooks/useAPI";
import { definedAccessors } from "../../../helpers/tableHelpers";
import { definedTableActions } from "../../../hooks/useTypeTableContext";
import FetchMoreTable from "../../templates/Tables/FetchMoreTable";
import EditPictureModal from "../Modal/EditPictureModal";
import DeletePictureModal from "../Modal/DeletePictureModal";

interface Props {
  filters: {
    query?: string;
    author?: string;
    businessEntity?: string;
    countries?: string[];
    showAll?: boolean;
  };
}

const columns = [
  definedAccessors.picture,
  definedAccessors.key,
  definedAccessors.countries,
  definedAccessors.organizationId,
  definedAccessors.createdAt,
  definedAccessors.creatorName,
  definedAccessors.imageAuthor,
  definedAccessors.resources,
  definedAccessors.active,
  definedAccessors.action,
];

const PictureTable: React.FC<Props> = ({ filters }) => {
  const { token } = useAuthorizedContext();
  const initialPics = useCallback(async () => {
    const idToken = await token();
    return FilterPictureDbApi(idToken, { ...filters, limit: 1000 });
  }, [filters, token]);

  const { loading, response, setResponse } = useAPI(initialPics, []);
  const [usedTokens, setUsedTokens] = useImmer<
    Map<string, "loading" | "completed">
  >(new Map());

  const updateTableEntry = useCallback(
    (newData: IPictureEntityForm, action: definedTableActions) => {
      setResponse((draft: any) => {
        const index = draft.Items.findIndex(
          (item: any) => item.key === newData.key,
        );

        if (draft && action === definedTableActions.add && index === -1) {
          draft.Items.unshift(newData);
        }
        if (draft && action === definedTableActions.remove && index > -1) {
          draft.Items.splice(index, 1);
        }
        if (draft && action === definedTableActions.update && index > -1) {
          draft.Items[index] = { ...draft.Items[index], ...newData };
        }

        return draft;
      });
    },
    [setResponse],
  );

  const actionColumn = React.useMemo(
    () => ({
      Header: "Darbības",
      Cell: (cell: Cell<any>) => {
        const { original } = cell.row;
        const deleteRow = async (data: IPictureEntityForm) => {
          updateTableEntry(data, definedTableActions.remove);
        };
        const editRow = async (data: IPictureEntityForm) => {
          updateTableEntry(data, definedTableActions.update);
        };
        return (
          <Button.Group key={original.key}>
            <Button
              icon="copy"
              onClick={() => {
                copy(original.url);
                toast.info("Attēla saite nokopēta.");
              }}
            />
            <EditPictureModal data={original} editRow={editRow} />
            <DeletePictureModal data={original} deleteRow={deleteRow} />
          </Button.Group>
        );
      },
      disableSortBy: true,
    }),
    [updateTableEntry],
  );

  const tableData = useMemo(
    () =>
      (response?.Items || []).map((item: any) => ({
        picture: item.url,
        createdAt: item.created,
        resources: [...item.content, ...item.entities],
        active: item.show,
        ...item,
      })),
    [response],
  );

  const fetchMore = async (skipPageReset: () => void) => {
    // TODO incorporate logic into FetchMore table!
    if (!response) return;
    const nextToken = response.LastEvaluatedKey.key;
    if (!nextToken) return;
    if (usedTokens.has(nextToken)) return;
    setUsedTokens((prev) => prev.set(nextToken, "loading"));
    const idToken = await token();
    const { LastEvaluatedKey, Items } = await FilterPictureDbApi(idToken, {
      ...filters,
      startKey: response.LastEvaluatedKey,
      limit: 1000,
    });
    skipPageReset();
    setResponse((prev) => ({
      LastEvaluatedKey,
      Items: [...(prev?.Items || []), ...Items],
    }));
    setUsedTokens((prev) => prev.set(nextToken, "completed"));
  };

  const isLoading =
    Array.from(usedTokens.values()).includes("loading") || loading;

  return (
    <FetchMoreTable
      loading={isLoading}
      actionColumn={actionColumn}
      data={tableData}
      columns={columns}
      canFetchMore={Boolean(response?.LastEvaluatedKey)}
      fetchMore={fetchMore}
      disableFilters
    />
  );
};

export default PictureTable;

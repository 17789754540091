import { ContentState, EditorState, Modifier } from "draft-js";

const mergeWithText = (
  editorState: EditorState,
  text: string,
  contentStateModifier: (state: ContentState) => ContentState,
) => {
  let newEditorState = editorState;
  let contentState = Modifier.replaceText(
    editorState.getCurrentContent(),
    editorState.getSelection(),
    text,
    editorState.getCurrentInlineStyle(),
  );
  contentState = contentStateModifier(contentState);
  newEditorState = EditorState.push(
    newEditorState,
    contentState,
    "insert-characters",
  );

  const nextOffSet = editorState.getSelection().getFocusOffset() + text.length;
  const newSelection = editorState.getSelection().merge({
    focusOffset: nextOffSet,
    anchorOffset: nextOffSet,
  });
  newEditorState = EditorState.acceptSelection(newEditorState, newSelection);

  return newEditorState;
};

export default mergeWithText;

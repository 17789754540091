import { findWithRegex } from "@draft-js-plugins/utils";
import { ContentState, Modifier, SelectionState } from "draft-js";

import {
  EMOJI_ENTITY_TYPE,
  EMOJI_ENTITY_MUTABILITY,
  REGEX_EMOJI_UNICODE,
} from "../constants";

function attachImmutableEntitiesToEmojis(contentState: ContentState) {
  const blocks = contentState.getBlockMap();
  let newContentState = contentState;
  blocks.forEach((block) => {
    if (block) {
      const plainText = block.getText();

      const addEntityToEmoji = function addEntityToEmoji(
        start: number,
        end: number,
      ) {
        const existingEntityKey = block.getEntityAt(start);

        if (existingEntityKey) {
          // avoid manipulation in case the emoji already has an entity
          const entity = newContentState.getEntity(existingEntityKey);

          if (entity && entity.getType() === EMOJI_ENTITY_TYPE) {
            return;
          }
        }

        const selection = SelectionState.createEmpty(block.getKey())
          .set("anchorOffset", start)
          .set("focusOffset", end);
        const emojiText = plainText.substring(start, end);
        const contentStateWithEntity = newContentState.createEntity(
          EMOJI_ENTITY_TYPE,
          EMOJI_ENTITY_MUTABILITY,
          {
            emojiUnicode: emojiText,
          },
        );
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        newContentState = Modifier.replaceText(
          newContentState,
          selection as any,
          emojiText,
          undefined,
          entityKey,
        );
      };
      findWithRegex(REGEX_EMOJI_UNICODE, block, addEntityToEmoji);
    }
  });

  return newContentState;
}

export default attachImmutableEntitiesToEmojis;

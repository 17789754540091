import React from "react";
import { ButtonProps } from "semantic-ui-react";

import {
  BaseButton,
  ActionButton,
  PrimaryButton,
  MinimalActionButton,
  BasicButton,
  ConfirmButton,
  CancelButton,
} from "./SimpleButtonStyles";

export enum definedButtonTypes {
  primary = "primary",
  submit = "submit",
  secondary = "secondary",
  action = "action",
  miniAction = "miniAction",
  basic = "basic",
  default = "default",
  confirm = "confirm",
  cancel = "cancel",
}

export interface SimpleButtonProps extends ButtonProps {
  id?: string;
  variant?: definedButtonTypes;
  animated?: boolean;
  disabled?: boolean;
  initialPadding?: boolean;
  icon?: ButtonProps["icon"];
  fluid?: ButtonProps["fluid"];
  loading?: boolean;
  href?: string;
  target?: string;
  size?: ButtonProps["size"];
}

const SimpleButton: React.FC<SimpleButtonProps> = ({
  children,
  variant,
  animated,
  initialPadding,
  ...props
}) => {
  let CurrentButton = BaseButton;
  if (variant === definedButtonTypes.miniAction) {
    CurrentButton = MinimalActionButton;
  } else if (variant === definedButtonTypes.action) {
    CurrentButton = ActionButton;
  } else if (variant === definedButtonTypes.primary) {
    CurrentButton = PrimaryButton;
  } else if (variant === definedButtonTypes.basic) {
    CurrentButton = BasicButton;
  } else if (variant === definedButtonTypes.confirm) {
    CurrentButton = ConfirmButton;
  } else if (variant === definedButtonTypes.cancel) {
    CurrentButton = CancelButton;
  }
  return (
    <CurrentButton
      {...props}
      animated={animated ? "vertical" : false}
      initialPadding={initialPadding ? 1 : 0}
    >
      {children}
    </CurrentButton>
  );
};
export default SimpleButton;

import React, { useState } from "react";
import { toast } from "react-toastify";
import { Modal, Button } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import useAuthorizedContext from "hooks/useAuthorizedContext";
import CognitoUserAttrs from "api/cognito/CognitoUserAttrs";

import { DeleteUser } from "../../../api/usersApi";
import SimpleButton, {
  definedButtonTypes,
} from "../../atoms/Buttons/SimpleButton/SimpleButton";
import { definedTableActions } from "../../../hooks/useTypeTableContext";

interface DeleteUserModalProps {
  data: ReturnType<InstanceType<typeof CognitoUserAttrs>["format"]>;
  updateFn: any;
}

const DeleteUserModal: React.FC<DeleteUserModalProps> = ({
  data,
  updateFn,
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { token } = useAuthorizedContext();

  const { email, username } = data;
  const deleteFn = async () => {
    try {
      setLoading(true);
      if (email) {
        const idToken = await token();
        await DeleteUser(idToken, username);
        toast.success("Dzēsts veiksmīgi");
        updateFn(data, definedTableActions.remove);
      }
    } catch {
      toast.error("Dzēšot radās kļūda! Mēģini vēlreiz!");
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };

  return (
    <Modal
      size="mini"
      open={open}
      trigger={
        <Button size="mini" icon="close" onClick={() => setOpen(true)} />
      }
      closeOnEscape={false}
      closeOnDimmerClick={false}
    >
      <Modal.Header>Tiešām dzēst?</Modal.Header>
      <Modal.Content>
        <p>Vai tiešām dzēst lietotāju {email}?</p>
      </Modal.Content>
      <Modal.Actions>
        <SimpleButton
          variant={definedButtonTypes.secondary}
          onClick={() => setOpen(false)}
          disabled={loading}
        >
          {t("Forms.Modal.Cancel")}
        </SimpleButton>
        <SimpleButton
          variant={definedButtonTypes.primary}
          onClick={deleteFn}
          loading={loading}
        >
          {t("Forms.Modal.Delete")}
        </SimpleButton>
      </Modal.Actions>
    </Modal>
  );
};

export default DeleteUserModal;

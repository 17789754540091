/* eslint-disable no-param-reassign */ // Because we are using immer
import React, { memo, useCallback, useEffect, MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import { useImmerReducer } from "use-immer";
import { toast } from "react-toastify";
import { FormField, Image, Grid, GridColumn, Divider } from "semantic-ui-react";

import PictureSearchZone from "./PictureFields/PictureSearchZone";

import { IBaseFormFieldProps } from "../../../interfaces/forms";
import { IGalleryImage } from "../../../api";
import MultiImageDropzone from "../../atoms/ImageDropzone/MultiImageDropzone";
import HoverButton from "../../atoms/Buttons/HoverButton";
import { styledWithTheme } from "../../../styles/theme";
import Typography from "../../atoms/Typography/Typography";
import { definedTypographyTypes } from "../../atoms/Typography/TypographyStyles";

export type FormMultiImageZoneProps = IBaseFormFieldProps<Array<IGalleryImage>>;

interface IDropzoneImage extends IGalleryImage {
  previewUrl?: string;
}

interface IDropzoneState {
  error: string;
  isLoading: boolean;
  images: Array<IDropzoneImage>;
}
enum actionTypes {
  submit = "submit",
  removeItem = "remove",
  successChange = "success change",
  makeTitleItem = "make title item",
  moveLeftItem = "move left item",
  moveRightItem = "move right item",
  error = "error",
  reset = "reset",
}

const StyledImage = styledWithTheme(Image)<{ index: number }>(
  ({ theme, index }) => ({
    "&&&": {
      border: index === 0 ? `5px solid ${theme.palette.primary.main}` : "none",
    },
  }),
);

// TODO probably this deserves a helper, revisit later
function dropzoneReducer(
  draft: IDropzoneState,
  action: {
    type: actionTypes;
    images?: Array<IDropzoneImage>;
    itemId?: number;
  },
) {
  switch (action.type) {
    case "reset": {
      draft.error = "";
      draft.isLoading = false;
      return;
    }
    case "submit": {
      draft.error = "";
      draft.isLoading = true;
      return;
    }
    case "success change": {
      draft.isLoading = false;
      draft.images = action.images || [];
      return;
    }
    case "remove": {
      if (typeof action.itemId === "number") {
        draft.images.splice(action.itemId, 1);
      }
      return;
    }
    case "make title item": {
      if (typeof action.itemId === "number") {
        const removed = draft.images.splice(action.itemId, 1);
        draft.images.unshift(removed[0]);
      }
      return;
    }
    case "move right item": {
      const { itemId } = action;
      if (typeof itemId === "number") {
        const newId = itemId + 1;
        if (draft.images.length <= newId) return;
        const removed = draft.images.splice(itemId, 1);
        draft.images.splice(newId, 0, removed[0]);
      }
      return;
    }
    case "move left item": {
      const { itemId } = action;
      if (typeof itemId === "number") {
        const newId = itemId - 1;
        if (newId < 0) return;
        const removed = draft.images.splice(itemId, 1);
        draft.images.splice(newId, 0, removed[0]);
      }
      return;
    }
    case "error": {
      draft.error = "Form submission failed!!";
      draft.isLoading = false;
      return;
    }
    default: {
      draft.error = "Unrecognised action type used!!";
      draft.isLoading = false;
    }
  }
}

const FormMultiImageZone: React.FC<FormMultiImageZoneProps> = ({
  id,
  label,
  required,
  value,
  description,
  onChange,
}) => {
  const { t } = useTranslation();

  const [dropzoneState, dispatchDropzone] = useImmerReducer<IDropzoneState>(
    dropzoneReducer,
    {
      error: "",
      isLoading: false,
      images: value || [],
    },
  );
  const { isLoading, images } = dropzoneState;

  const changeFn = useCallback(
    async (acceptedFiles: File[] | string) => {
      try {
        dispatchDropzone({ type: actionTypes.submit });
        let acceptedImages: IGalleryImage[] = [];
        const reCheckedImages: Array<IGalleryImage> = [];
        if (typeof acceptedFiles === "string") {
          acceptedImages.push({
            imagePointer: undefined,
            imageUrl: acceptedFiles,
          });
          acceptedImages.forEach((file) => {
            const isDuplicate = images.find(
              (image) => image.imageUrl === file.imagePointer?.name,
            );
            if (!isDuplicate) reCheckedImages.push(file);
          });
        } else {
          // 1. Map File to IGalleryImage
          acceptedImages = acceptedFiles.map((file: File) => ({
            previewUrl: URL.createObjectURL(file),
            imagePointer: file,
          }));
          // 2. Remove added duplicates
          // TODO redo into something more readable, im sorry ...
          acceptedImages.forEach((file) => {
            const isDuplicate = images.find(
              (image) => image.imagePointer?.name === file.imagePointer?.name,
            );
            if (!isDuplicate) reCheckedImages.push(file);
          });
        }

        // 3. Merge together previous and incoming images
        const allImages = [...images, ...reCheckedImages];

        // 4. save new image object in state
        dispatchDropzone({
          type: actionTypes.successChange,
          images: allImages,
        });
      } catch (err) {
        dispatchDropzone({ type: actionTypes.error });
        console.error("Failed to upload image!", err);
        toast.error(t("Toasts.GenericError"));
      }
    },
    [dispatchDropzone, t, images],
  );
  const removeFn = useCallback(
    (removeId: number) => (event: MouseEvent) => {
      event.preventDefault();
      dispatchDropzone({
        type: actionTypes.removeItem,
        itemId: removeId,
      });
    },
    [dispatchDropzone],
  );
  const titleFn = useCallback(
    (itemId: number) => (event: MouseEvent) => {
      event.preventDefault();
      dispatchDropzone({
        type: actionTypes.makeTitleItem,
        itemId,
      });
    },
    [dispatchDropzone],
  );
  const leftFn = useCallback(
    (itemId: number) => (event: MouseEvent) => {
      event.preventDefault();
      dispatchDropzone({
        type: actionTypes.moveLeftItem,
        itemId,
      });
    },
    [dispatchDropzone],
  );
  const rightFn = useCallback(
    (itemId: number) => (event: MouseEvent) => {
      event.preventDefault();
      dispatchDropzone({
        type: actionTypes.moveRightItem,
        itemId,
      });
    },
    [dispatchDropzone],
  );

  useEffect(() => {
    // This is uncontrolled component.
    // on inner state change we update outer onchange function
    if (onChange) onChange(id, images);
  }, [images, id, onChange]);

  return (
    <>
      <PictureSearchZone setImage={changeFn} add />
      <FormField required={required}>
        {label && <label htmlFor={id}>{label}</label>}
        <MultiImageDropzone isLoading={isLoading} onDrop={changeFn}>
          <Divider />
          <Grid columns={4}>
            {images.map((image, idx) => {
              const source = image.imageUrl || image.previewUrl;
              const key = image.imageUrl;
              return (
                <GridColumn key={key}>
                  <HoverButton
                    icon="edit"
                    remove={removeFn(idx)}
                    title={titleFn(idx)}
                    moveLeft={leftFn(idx)}
                    moveRight={rightFn(idx)}
                  >
                    <StyledImage src={source} size="small" index={idx} />
                  </HoverButton>
                </GridColumn>
              );
            })}
          </Grid>
        </MultiImageDropzone>
        {description && (
          <Typography type={definedTypographyTypes.smallCaption}>
            {description}
          </Typography>
        )}
      </FormField>
    </>
  );
};

export default memo(FormMultiImageZone);

import React, { memo, ChangeEvent } from "react";
import { FormField } from "semantic-ui-react";

import { IBaseFormFieldProps } from "../../../interfaces/forms";

// TODO: controlled @Agnese

type FormDateInputProps = IBaseFormFieldProps<string>;

const FormDateInput: React.FC<FormDateInputProps> = ({
  id,
  label,
  required,
  onChange,
  ...props
}) => {
  const changeFn = (event: ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(id, event.target.value);
  };

  return (
    <FormField required={required}>
      {label && <label htmlFor={id}>{label}</label>}
      <input
        {...props}
        id={id}
        required={required}
        type="date"
        onChange={changeFn}
      />
    </FormField>
  );
};

export default memo(FormDateInput);

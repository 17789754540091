import * as React from "react";
import { Card } from "semantic-ui-react";

import { ILinkCardProps } from "./LinkCard";
import LinkToArticleCard from "./LinkToArticleCard";

import EventImage, { IEventImageProps } from "../Images/EventImage";
import EventDetailList, {
  IEventDetailListProps,
} from "../List/EventDetailList";
import Typography, {
  definedTypography,
} from "../../atoms/Typography/Typography";
import { IHomeDisplay } from "../../../api";
import TildeTranslate from "../../atoms/Text/TildeTranslate";

export interface IEventGridProps {
  event: IHomeDisplay;
  type: definedCardTypes;
  size: definedCardSizes;
  route?: string;
  translate: boolean;
}

export enum definedCardSizes {
  large = "large",
  medium = "medium",
}

export enum definedCardTypes {
  gallery = "gallery",
  video = "video",
  calendar = "calendar",
  post = "post",
  highlighted = "highlighted",
  organization = "organization",
}

const EventGridCard: React.FC<IEventGridProps> = ({
  event,
  size,
  type,
  route,
  translate,
}) => {
  const cardProps: Partial<ILinkCardProps> = {};
  const imageProps: Partial<IEventImageProps> = {};
  const detailProps: Partial<IEventDetailListProps> = {};
  const {
    imageUrl,
    category,
    dateFrom,
    countries,
    address,
    organizationId,
    toBePostedAt,
    slug,
    title,
    type: eventType,
  } = event;

  // set default card -> Post, medium
  let headerType = definedTypography.h2;
  cardProps.minWidth = "100%";
  cardProps.width = "auto";
  cardProps.textAlign = "left";
  detailProps.date = toBePostedAt;
  detailProps.countries = countries;
  detailProps.address = address;

  if (size === definedCardSizes.large) {
    cardProps.maxWidth = "100%";
    cardProps.width = "auto";
    headerType = definedTypography.h1;
    imageProps.size = "massive";
  } else if (size === definedCardSizes.medium) {
    headerType = definedTypography.h3;
    detailProps.cardSize = size;
    imageProps.height = "9em";
    // imageProps.size = "large";
  }
  if (type === definedCardTypes.calendar) {
    imageProps.dateFrom = dateFrom;
    imageProps.centeredDate = true;
    detailProps.date = dateFrom;
    detailProps.countries = countries;
    detailProps.organizationId = organizationId;
  } else if (type === definedCardTypes.video) {
    imageProps.video = true;
    cardProps.textAlign = "center";
  } else if (type === definedCardTypes.gallery) {
    imageProps.hasHoverOverlay = true;
    imageProps.gallery = true;
    imageProps.size = "large";
    cardProps.textAlign = "center";
  } else if (type === definedCardTypes.highlighted) {
    cardProps.textAlign = "center";
  }

  return (
    <LinkToArticleCard
      route={`/${eventType}/${slug}`}
      hover
      newTab
      id={slug}
      title={title}
      {...cardProps}
    >
      <EventImage
        imageUrl={
          imageUrl ||
          "https://latviesi-post-images.s3.eu-central-1.amazonaws.com/L.svg"
        }
        category={category}
        translate={translate}
        {...imageProps}
      />
      <Card.Content>
        <Card.Header>
          <Typography align={cardProps.textAlign} type={headerType}>
            <TildeTranslate text={event.title} translate={translate} />
          </Typography>
        </Card.Header>
        <Card.Description>
          <EventDetailList {...detailProps} />
        </Card.Description>
      </Card.Content>
    </LinkToArticleCard>
  );
};
EventGridCard.defaultProps = {
  route: "default-route",
};

export default EventGridCard;

import React from "react";
import { Form, Message, Input } from "semantic-ui-react";
import copy from "copy-to-clipboard";
import { toast } from "react-toastify";

import iframeFields from "./IframeMakerFields";

import SimpleButton, {
  definedButtonTypes,
} from "../../atoms/Buttons/SimpleButton/SimpleButton";
import { IndexSignature } from "../../../interfaces";
import { MapIframeFieldsToFilter } from "../../../helpers/globalFunctions";
import { definedRouteTypes } from "../../../api/api";

export interface IframeMakerPageProps {}
const defaultIframeTitle = "Latviesi.com iframe";
const defaultWidth = "100%";
const defaultHeight = 600;
const defaultSrc = "https://www.latviesi.com/iframe";

const IframeMakerPage: React.SFC<IframeMakerPageProps> = () => {
  const [iframe, setIframe] = React.useState<IndexSignature>({
    width: "100%",
    showAllRecords: true,
  });
  const [iframeUrl, setIframeUrl] = React.useState(defaultSrc);
  const [iframeElement, setIframeElement] = React.useState(`
  <iframe 
    title="${defaultIframeTitle}"
    src="${defaultSrc}"
    height="${defaultHeight}"
    width="${defaultWidth}"/>
  </iframe>`);

  const setIframeValue = (e: any, { id, value }: any) => {
    setIframe((prevState) => ({ ...prevState, [id]: value }));
  };

  // const setAllRecords = () => {
  //   setIframe((prevState) => ({
  //     ...prevState,
  //     showAllRecords: !prevState.showAllRecords,
  //     amount: "",
  //   }));
  // };

  const copyToClipboard = (element: boolean) => {
    if (element) copy(iframeElement);
    else copy(iframeUrl);

    toast.success("Veiksmīgi nokopēts");
  };

  const handleIframeUrl = () => {
    const query = MapIframeFieldsToFilter(iframe);
    const url = `${defaultSrc}${query}`;

    const newIframeElement = `
      <iframe 
        title="${iframe.title || defaultIframeTitle}"
        src="${url || defaultSrc}"
        scrolling="no"
        height="${iframe.height || defaultHeight}"
        width="${iframe.width || defaultWidth}"/>
      </iframe>`;
    setIframeElement(newIframeElement);
    setIframeUrl(url);
  };

  return (
    <div>
      <h4>Izvēlies Iframe sastāvu</h4>
      <Form size="small" warning>
        <Form.Field
          id="title"
          label="Nosaukums"
          control={Input}
          value={iframe.title || ""}
          onChange={setIframeValue}
        />
        <Form.Group widths="equal">
          {iframeFields.map((field) => {
            let value = iframe[field.id] || "";
            if (field.multiple && !value) value = [];
            return (
              <Form.Field
                fluid
                key={field.id}
                {...field}
                value={value}
                onChange={setIframeValue}
                disabled={field.id === "amount" && iframe?.showAllRecords}
              />
            );
          })}
        </Form.Group>
        {iframe?.type?.includes(definedRouteTypes.editorialNews) && (
          <Message
            warning
            header="Uzmanību!"
            list={[
              `Izvēloties tipu "Jaunumi" tiks atlasīti visi jaunumi veidi, tajā skaitā, "Organizāciju jaunumi", "Diasporas mediju jaunumi", "Redakcijas jaunumi"`,
            ]}
          />
        )}
        <Form.Group>
          <SimpleButton
            variant={definedButtonTypes.submit}
            onClick={() => handleIframeUrl()}
          >
            Iegūt iframe saiti
          </SimpleButton>
          <SimpleButton
            variant={definedButtonTypes.action}
            onClick={() => window.open(iframeUrl, "_blank")}
          >
            Atvērt iframe
          </SimpleButton>
          {/* <StyledWrapper>
            <Radio
              label="Visi ieraksti"
              toggle
              checked={iframe?.showAllRecords}
              onChange={setAllRecords}
            >
              Visi ieraksti
            </Radio>
          </StyledWrapper> */}
        </Form.Group>
        {iframeUrl && (
          <Form.Group>
            <Form.Field>
              <h4> Iframe elements</h4>
              <h5>{iframeElement}</h5>
              <SimpleButton
                variant={definedButtonTypes.action}
                onClick={() => copyToClipboard(true)}
              >
                Kopēt
              </SimpleButton>
            </Form.Field>
            <Form.Field>
              <h4> Iframe saite </h4>
              <h5> {iframeUrl} </h5>
              <SimpleButton
                variant={definedButtonTypes.action}
                onClick={() => copyToClipboard(false)}
              >
                Kopēt
              </SimpleButton>
            </Form.Field>
          </Form.Group>
        )}
      </Form>
      <div>
        <iframe
          src={iframeUrl || "https://www.latviesi.com/iframe/notikumi"}
          title={iframe.title || "Latviesi.com iframe"}
          scrolling={iframe.height > 1249 ? "no" : "yes"}
          height={iframe.height || "600"}
          width={iframe.width || "100%"}
        />
      </div>
    </div>
  );
};

export default IframeMakerPage;

import React, { ComponentType, ReactElement, Ref, useState } from "react";
import { useTranslation } from "react-i18next";

import MultilineCaptionInput from "./components/MultilineCaptionInput";

interface DecoratorProps {}

interface ImageCaptionDecoratorProps {
  blockProps: {
    style: Record<string, string>;
    isFocused: boolean;
    setFocusToBlock: () => void;
    isCollapsedSelection: boolean;
    alignment: "default" | "left" | "center" | "right";
    captionData: { captions?: string };
    setCaptionData: (data: { captions: string }) => Promise<void>;
    setAlignment(val: { alignment: string }): void;
    setReadOnly: (state: boolean) => void;
  };
  className: string;
  // block: ContentBlock;
  // onClick(event: MouseEvent): void;
  ref: Ref<unknown>;
}

type WrappedComponentType = ComponentType<ImageCaptionDecoratorProps> & {
  WrappedComponent?: ComponentType<ImageCaptionDecoratorProps>;
};

// Get a component's display name
const getDisplayName = (WrappedComponent: WrappedComponentType): string => {
  const component = WrappedComponent.WrappedComponent || WrappedComponent;
  return component.displayName || component.name || "Component";
};

// eslint-disable-next-line no-empty-pattern
export default ({}: DecoratorProps) => (
  WrappedComponent: WrappedComponentType,
): ComponentType<ImageCaptionDecoratorProps> => {
  const ImageCaptionDecorator = React.forwardRef(
    ({ ...props }: ImageCaptionDecoratorProps, ref): ReactElement => {
      const [caption, setCaption] = useState(
        props.blockProps.captionData.captions || "",
      );
      const { t } = useTranslation();

      const onChange = (event: any) => {
        setCaption(String(event.target.value));
      };

      const onFocus = () => {
        props.blockProps.setReadOnly(true);
      };

      const onKeyDown = (event: any) => {
        // Exit on Escape, Del or Enter
        if (
          event.keyCode === 27 ||
          (event.keyCode === 46 && caption?.length === 0) ||
          event.key === "Enter"
        ) {
          event.preventDefault();
          event.stopPropagation();
          event.target.blur();
          props.blockProps.setReadOnly(false);
        }
      };

      const onBlur = async () => {
        await props.blockProps.setCaptionData({
          captions: caption?.trim() || "",
        });
        props.blockProps.setReadOnly(false);
      };

      return (
        <>
          <WrappedComponent {...props} ref={ref} />
          <MultilineCaptionInput
            onBlur={onBlur}
            onFocus={onFocus}
            onKeyDown={onKeyDown}
            value={caption} // innerHTML of the editable div
            onChange={onChange} // handle innerHTML change
            placeholder={t("CommonComponents.TypeCaptionPlaceholder")}
          />
        </>
      );
    },
  );

  ImageCaptionDecorator.displayName = `ImageCaption(${getDisplayName(
    WrappedComponent,
  )})`;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (ImageCaptionDecorator as any).WrappedComponent =
    WrappedComponent.WrappedComponent || WrappedComponent;

  return ImageCaptionDecorator;
};

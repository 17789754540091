import { IndexSignature } from "../interfaces/index.d";

enum definedLocales {
  latvian = "LV",
  english = "EN",
  german = "DE",
}

const LOCALES = ["lv", "en"];

const LOCALES_TEXT: IndexSignature = {
  lv: "Latviešu",
  en: "Angļu",
};

export const LocaleOptions = LOCALES.map((value) => ({
  value,
  key: value,
  text: LOCALES_TEXT[value],
}));

export const isDefinedLocale = (
  x: string | definedLocales,
): x is definedLocales => {
  return Object.values(definedLocales).some((value) => x === value);
};

export default definedLocales;

import React, { useState, useLayoutEffect } from "react";
import { Image } from "semantic-ui-react";

import { PLACEHOLDER_16_TO_9 } from "../../../images/imageSources";
import { getThumbnailLink } from "../../../helpers/globalFunctions";
import { s3Url } from "../../../api/ImageApi";

interface Props {
  cell: { value?: string };
}

enum imageState {
  thumbnail,
  full,
  placeholder,
}

const ImageCell: React.FC<Props> = ({ cell: { value } }) => {
  const [img, setImg] = useState({
    src: undefined as undefined | string,
    state: imageState.thumbnail,
  });

  useLayoutEffect(() => {
    if (value && value.includes(s3Url)) {
      setImg({
        src: getThumbnailLink(value),
        state: imageState.thumbnail,
      });
    } else if (value) {
      setImg({
        src: value,
        state: imageState.full,
      });
    } else {
      setImg({
        src: PLACEHOLDER_16_TO_9,
        state: imageState.placeholder,
      });
    }
  }, [value]);

  const onError = () => {
    if (img.state === imageState.thumbnail) {
      setImg({
        src: value,
        state: imageState.full,
      });
    }
    if (img.state === imageState.full) {
      setImg({
        src: PLACEHOLDER_16_TO_9,
        state: imageState.placeholder,
      });
    }
  };

  return <Image src={img.src} onError={onError} size="tiny" />;
};

export default ImageCell;

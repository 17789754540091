import { IndexSignature } from "../interfaces/index.d";

export enum Types {
  editorialNews = "jaunumi",
  diasporeNews = "mediju-jaunumi",
  organizationNews = "organizaciju-jaunumi",
  events = "notikumi",
  imageGallery = "attelu-galerijas",
  videoNews = "video-galerijas",
  offers = "piedavajumi",
  organizations = "organizacijas",
}

export const TYPES_LV = [
  "jaunumi",
  "mediju-jaunumi",
  "organizaciju-jaunumi",
  "notikumi",
  "attelu-galerijas",
  "video-galerijas",
  "piedavajumi",
  "organizacijas",
];

const TYPES_LV_TEXT: IndexSignature = {
  jaunumi: "Jaunumi",
  "mediju-jaunumi": "Mediju Jaunumi",
  "organizaciju-jaunumi": "Organizāciju jaunumi",
  notikumi: "Notikumi",
  "attelu-galerijas": "Attēlu Galerijas",
  "video-galerijas": "Video Galerijas",
  piedavajumi: "Piedāvājumi",
  organizacijas: "Organizācijas",
};

export const TypeOptions = TYPES_LV.map((value) => ({
  value,
  key: value,
  text: TYPES_LV_TEXT[value],
}));

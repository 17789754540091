export enum REGION {
  vidzeme = "vidzeme",
  kurzeme = "kurzeme",
  zemgale = "zemgale",
  latgale = "latgale",
  riga = "riga",
  eu = "eiropa",
  lv = "latvija",
}

export const REGION_TITLE: { [key in REGION]: string } = {
  vidzeme: "Vidzeme",
  kurzeme: "Kurzeme",
  zemgale: "Zemgale",
  latgale: "Latgale",
  riga: "Rīga",
  eiropa: "Eiropa",
  latvija: "Latvija",
};

interface IRegionOpts {
  text: string;
  value: REGION;
  key: string;
}
export const RegionOpts: Array<IRegionOpts> = Object.entries(REGION).map(
  ([, value]) => ({
    text: REGION_TITLE[value],
    value,
    key: value,
  }),
);

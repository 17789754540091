import React, { useState, useEffect } from "react";
import { Grid, List, Loader } from "semantic-ui-react";
import { toast } from "react-toastify";
import useAuthorizedContext from "hooks/useAuthorizedContext";

import TableTitleGrid from "../templates/TableTitleGrid";
import { definedTypographyTypes } from "../atoms/Typography/TypographyStyles";
import Typography from "../atoms/Typography/Typography";
import getWideFilterQuery from "../../api/filterApi";
import {
  INewsLetter,
  getActiveNewsletter,
  publishLatestNewsletter,
  deleteNewsletter,
} from "../../api/NewsLetterApi";
import NewsLetterPreviewPanel from "../organisms/Panels/NewsLetterPreviewPanel";
import ReadOnlyRSSList from "../organisms/Lists/ReadRSSList";

interface Props {}

export interface IKeyRow {
  type: string;
  slug: string;
  title: string;
  id: string;
  imageUrl: string;
}

const NewsLetterConfigurator: React.FC<Props> = () => {
  const [activeFeed, setActiveFeed] = useState<INewsLetter>();
  const [feeds, setAllFeeds] = useState<INewsLetter[]>([]);
  const [loading, setLoading] = useState(false);
  const { token } = useAuthorizedContext();

  const [active, setActive] = React.useState("");

  const setIndex = (id: string) => {
    const newIndex = id === active ? "" : id;
    setActive(newIndex);
  };

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;
    const doFetch = async () => {
      setLoading(true);
      try {
        const newsletterData = await Promise.all([
          getWideFilterQuery("?limit=10&offset=0&type=newsletter"),
          getActiveNewsletter(),
        ]);
        if (!signal.aborted) {
          const allFeeds = newsletterData[0].items;
          setActiveFeed(newsletterData[1]);
          setAllFeeds(allFeeds);
        }
      } catch (e) {
        if (!signal.aborted) {
          console.error(e);
          if (e === "SLUG exists") {
            toast.error("Vēstkopa ar šādu nosaukumu jau eksistē!");
          } else {
            toast.error("Kaut kas nogāja greizi.");
          }
        }
      } finally {
        if (!signal.aborted) setLoading(false);
      }
    };
    doFetch();
    return () => {
      abortController.abort();
    };
  }, [setActiveFeed]);

  const setRSSfeed = async (id: string) => {
    const abortController = new AbortController();
    const { signal } = abortController;
    const newFeed = feeds.find((f: INewsLetter) => f.slug === id);
    if (newFeed) {
      setLoading(true);
      try {
        const idToken = await token();
        const newsletterData = await Promise.all([
          publishLatestNewsletter(idToken, newFeed),
          getWideFilterQuery("?limit=10&offset=0&type=newsletter"),
          getActiveNewsletter(),
        ]);
        if (!signal.aborted) {
          const allFeeds = newsletterData[1].items;
          setAllFeeds(allFeeds);
          setActiveFeed(newFeed);
        }
      } catch (e) {
        if (!signal.aborted) {
          console.error(e);
          if (e === "SLUG exists") {
            toast.error("Vēstkopa ar šādu nosaukumu jau eksistē!");
          } else {
            toast.error("Kaut kas nogāja greizi.");
          }
        }
      } finally {
        if (!signal.aborted) setLoading(false);
      }
    }
  };

  const deleteRSSfeed = async (id: string) => {
    if (window.confirm("Vai tiešām vēlies dzēst?")) {
      const abortController = new AbortController();
      const { signal } = abortController;
      const newFeed = feeds.find((f: INewsLetter) => f.slug === id);
      if (newFeed) {
        setLoading(true);
        try {
          const idToken = await token();
          await deleteNewsletter(idToken, newFeed);
          if (!signal.aborted) {
            setAllFeeds(feeds.filter((f: INewsLetter) => f.slug !== id));
          }
        } catch (e) {
          if (!signal.aborted) {
            toast.error("Kaut kas nogāja greizi.");
          }
        } finally {
          if (!signal.aborted) setLoading(false);
        }
      }
    }
  };

  if (loading) return <Loader active />;

  return (
    <div>
      <TableTitleGrid title="Pārvaldīt vēstkopas" />
      <Grid columns={1} style={{ marginTop: "-2rem" }}>
        <Grid.Row>
          <Grid.Column>
            <Typography type={definedTypographyTypes.h3}>
              Šobrīd publicētā vēstkopa: <b>{activeFeed?.title}</b>
              <ReadOnlyRSSList selected={activeFeed?.items || []} />
            </Typography>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Typography type={definedTypographyTypes.h3}>
              Visas vēstkopas
            </Typography>
            <List divided verticalAlign="middle" ordered>
              {feeds.map((f) => (
                <NewsLetterPreviewPanel
                  id={f.slug}
                  newsletter={f}
                  key={f.title}
                  handleView={setIndex}
                  handleActivate={setRSSfeed}
                  handleDelete={deleteRSSfeed}
                  open={f.slug === active}
                />
              ))}
            </List>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default NewsLetterConfigurator;

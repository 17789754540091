import moment, { Moment } from "moment";

// eslint-disable-next-line import/prefer-default-export
export const formatDate = (date?: Moment | Date) => {
  return date ? moment(date).format("LLLL") : "-";
};
export const formatBoolean = (bool?: boolean) => {
  return bool ? "Jā" : "Nē";
};

export const extractInfoFromLink = (url: string) => {
  const split = url.split("/");
  const slug = split.pop();
  const type = split.pop();
  return {
    slug,
    type,
  };
}
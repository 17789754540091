import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";

import NewsExpander from "../../molecules/TableFragments/NewsExpander";
import {
  definedRouteTypes,
  definedHomePageRoutes,
  definedUnapprovedRoutes,
} from "../../../api/api";
import ControlledTable from "../../templates/Tables/ControlledTable";
import TableTitleGrid from "../../templates/TableTitleGrid";
import AddModal from "../Modal/AddModal";
import { definedAccessors } from "../../../helpers/tableHelpers";
import EventsExpander from "../../molecules/TableFragments/EventsExpander";
import OrganizationExpander from "../../molecules/TableFragments/OrganizationExpander";
import useTypeTableContext from "../../../hooks/useTypeTableContext";

interface Props {
  type: definedRouteTypes | definedHomePageRoutes | definedUnapprovedRoutes;
}
const baseColumns = [
  definedAccessors.imageUrl,
  definedAccessors.title,
  definedAccessors.countries,
  definedAccessors.category,
  definedAccessors.createdAt,
  definedAccessors.toBePostedAt,
  definedAccessors.updatedAt,
  definedAccessors.show,
];

const sourceMediaColumns = [
  definedAccessors.imageUrl,
  definedAccessors.title,
  definedAccessors.category,
  definedAccessors.countries,
  definedAccessors.blacklisted,
  definedAccessors.show,
  definedAccessors.automaticPosting,
  definedAccessors.createdAt,
  definedAccessors.updatedAt,
  definedAccessors.newsAction,
];
const linkMediaColumns = [
  definedAccessors.imageUrl,
  definedAccessors.title,
  definedAccessors.category,
  definedAccessors.countries,
  definedAccessors.show,
  definedAccessors.author,
  definedAccessors.createdAt,
  definedAccessors.updatedAt,
  definedAccessors.newsAction,
];

const newsColumns = [
  ...baseColumns,
  definedAccessors.highlighted,
  definedAccessors.rank,
  definedAccessors.newsAction,
  definedAccessors.expander,
];

const eventsColumns = [
  ...baseColumns,
  definedAccessors.dateFrom,
  definedAccessors.dateTo,
  definedAccessors.highlighted,
  definedAccessors.displayInHomePage,
  definedAccessors.newsAction,
  definedAccessors.expander,
];

const selfEmployedColumns = [
  definedAccessors.imageUrl,
  definedAccessors.title,
  definedAccessors.entityCategory,
  definedAccessors.countries,
  definedAccessors.createdAt,
  definedAccessors.updatedAt,
  definedAccessors.show,
  definedAccessors.editAction,
  definedAccessors.expander,
];

const companyColumns = [
  definedAccessors.imageUrl,
  definedAccessors.title,
  definedAccessors.entityCategory,
  definedAccessors.countries,
  definedAccessors.homepage,
  definedAccessors.createdAt,
  definedAccessors.updatedAt,
  definedAccessors.show,
  definedAccessors.newsAction,
  definedAccessors.expander,
];
const organizationColumns = [
  definedAccessors.imageUrl,
  definedAccessors.title,
  definedAccessors.countries,
  definedAccessors.orgCategory,
  definedAccessors.homepage,
  definedAccessors.createdAt,
  definedAccessors.updatedAt,
  definedAccessors.show,
  definedAccessors.partner,
  definedAccessors.displayInHomePage,
  definedAccessors.newsAction,
  definedAccessors.expander,
];

const homeColumns = [
  definedAccessors.imageUrl,
  definedAccessors.title,
  definedAccessors.countries,
  definedAccessors.category,
  definedAccessors.createdAt,
  definedAccessors.type,
  definedAccessors.show,
  definedAccessors.highlighted,
  definedAccessors.homepageRank,
  definedAccessors.newsAction,
];
const unapprovedNewsColumns = [
  ...baseColumns,
  definedAccessors.type,
  definedAccessors.creatorName,
  definedAccessors.highlighted,
  definedAccessors.rank,
  definedAccessors.newsAction,
  definedAccessors.expander,
];
const unapprovedEventsColumns = [
  ...baseColumns,
  definedAccessors.dateFrom,
  definedAccessors.dateTo,
  definedAccessors.creatorName,
  definedAccessors.highlighted,
  definedAccessors.newsAction,
  definedAccessors.expander,
];
const unapprovedOrganizationColumns = [
  definedAccessors.imageUrl,
  definedAccessors.title,
  definedAccessors.countries,
  definedAccessors.orgCategory,
  definedAccessors.createdAt,
  definedAccessors.updatedAt,
  definedAccessors.creator,
  definedAccessors.creatorName,
  definedAccessors.show,
  definedAccessors.displayInHomePage,
  definedAccessors.partner,
  definedAccessors.homepage,
  definedAccessors.newsAction,
  definedAccessors.expander,
];

const isCantCreate = (
  type: any,
): type is definedRouteTypes.LINK_MEDIA | definedRouteTypes.SOURCE_MEDIA => {
  return [
    definedRouteTypes.LINK_MEDIA,
    definedRouteTypes.SOURCE_MEDIA,
  ].includes(type);
};
const isHomePage = (type: any): type is definedHomePageRoutes => {
  return Object.values(definedHomePageRoutes).includes(
    type as definedHomePageRoutes,
  );
};
const isUnapprovedPage = (type: any): type is definedUnapprovedRoutes => {
  return Object.values(definedUnapprovedRoutes).includes(
    type as definedUnapprovedRoutes,
  );
};
const TypeDataTable: React.FC<Props> = ({ type }) => {
  const context = useTypeTableContext();
  const { t } = useTranslation();

  const columns = useMemo(() => {
    if (type === definedRouteTypes.LINK_MEDIA) return linkMediaColumns;
    if (type === definedRouteTypes.SOURCE_MEDIA) return sourceMediaColumns;
    if (type === definedRouteTypes.events) return eventsColumns;
    if (type === definedRouteTypes.pastEvents) return eventsColumns;
    if (type === definedRouteTypes.companies) return companyColumns;
    if (type === definedRouteTypes.organizations) return organizationColumns;
    if (type === definedRouteTypes.selfEmployed) return selfEmployedColumns;
    if (type === definedHomePageRoutes.news) return homeColumns;
    if (type === definedHomePageRoutes.events) return eventsColumns;
    if (type === definedHomePageRoutes.organizations)
      return organizationColumns;
    if (type === definedUnapprovedRoutes.news) return unapprovedNewsColumns;
    if (type === definedUnapprovedRoutes.events) return unapprovedEventsColumns;
    if (type === definedUnapprovedRoutes.organizations)
      return unapprovedOrganizationColumns;
    if (type === definedUnapprovedRoutes.links) return linkMediaColumns;

    return newsColumns;
  }, [type]);

  const Expander = useMemo(() => {
    if (type === definedRouteTypes.events) return EventsExpander;
    if (type === definedRouteTypes.pastEvents) return EventsExpander;
    if (type === definedRouteTypes.organizations) return OrganizationExpander;
    if (type === definedUnapprovedRoutes.events) return EventsExpander;
    if (type === definedUnapprovedRoutes.organizations)
      return OrganizationExpander;
    if (type === definedHomePageRoutes.events) return EventsExpander;
    if (type === definedHomePageRoutes.organizations)
      return OrganizationExpander;
    return NewsExpander;
  }, [type]);

  const initialSortBy = useMemo(() => {
    if (type === definedHomePageRoutes.news) {
      return [{ id: "homepageRank", desc: false }];
    }
    if (
      Object.values(definedUnapprovedRoutes).includes(
        type as definedUnapprovedRoutes,
      )
    ) {
      return [{ id: "createdAt", desc: true }];
    }
    if (type === definedRouteTypes.SOURCE_MEDIA) {
      return [{ id: "createdAt", desc: true }];
    }
    return undefined;
  }, [type]);

  if (context === null) return <Redirect to="/" />;

  const { loading, pages, count, data, onFetchData } = context;

  return (
    <>
      {!isHomePage(type) && !isUnapprovedPage(type) && (
        <TableTitleGrid title={t(`Table.${type}`)}>
          {!isCantCreate(type) && <AddModal type={type} />}
        </TableTitleGrid>
      )}
      <ControlledTable
        data={data}
        totalDataCount={count}
        totalPageCount={pages}
        columns={columns}
        loading={loading}
        expanderComponent={Expander}
        onFetchData={onFetchData}
        initialSortBy={initialSortBy}
      />
    </>
  );
};

export default TypeDataTable;

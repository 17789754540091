import { useEffect, useState } from "react";
/**
 * meant to optimize re-renderings that will arise from useWindowDimensions
 * @param mobileThreshold if window width is under this threshold, true is is returned
 * @param callback gets called whenever threshold triggers WRAP IT IN USECALLBACK
 */
const useMobileDetector = (mobileThreshold: number, callback?: () => void) => {
  const [isMobile, setIsMobile] = useState(
    () => window.innerWidth < mobileThreshold,
  );

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < mobileThreshold && !isMobile) {
        setIsMobile(true);
        if (callback) callback();
      } else if (window.innerWidth >= mobileThreshold && isMobile) {
        setIsMobile(false);
      }
      if (callback) callback();
    };
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [isMobile, mobileThreshold, callback]);

  return isMobile;
};

export default useMobileDetector;

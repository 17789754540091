import React from "react";
import { Sidebar } from "semantic-ui-react";

import { styledWithTheme } from "../../../styles/theme";

export interface TopDrawerProps {
  open: boolean;
  onHide: () => void;
}
const StyledDrawer = styledWithTheme(Sidebar)(({ theme }) => ({
  "&&&": {
    top: "63px!important",
    backgroundColor: theme.palette.mono.white,
    boxShadow: "none!important",
    zIndex: 1001, // To get it on top of Dimmer
    padding: "0 4em",
    overflowY: "scroll !important" as any,
    height: "calc(100vh - 63px) !important" as any,
  },
}));
const StyledDrawerContent = styledWithTheme.div({
  "&&&": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflowY: "auto",
    paddingBottom: "5em",
  },
});

const VerticalDrawer: React.FC<TopDrawerProps> = ({
  children,
  open,
  onHide,
}) => {
  return (
    <StyledDrawer
      animation="overlay"
      visible={open}
      onHide={onHide}
      width="wide"
    >
      <StyledDrawerContent>{children}</StyledDrawerContent>
    </StyledDrawer>
  );
};

export default VerticalDrawer;

/* eslint-disable react/no-array-index-key */
import * as React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { GridColumn, Divider } from "semantic-ui-react";

import EventGridCard, {
  definedCardTypes,
  definedCardSizes,
} from "../../molecules/Cards/EventGridCard";
import { ISearchQueryResult } from "../../../interfaces";
import { defaultLimit } from "../../../constants/variables";
import QueryMenu from "../Menus/QueryMenu/QueryMenu";
import ErrorBanner from "../../molecules/InfoBanners/ErrorBanner";
import {
  StyledResultText,
  StyledDivider,
  StyledPaginator,
} from "../Menus/QueryMenu/Styles";
import { definedRouteTypes } from "../../../api/api";
import { IOrganizationDisplay } from "../../../api";
import OrganizationListCard from "../../molecules/Cards/OrganizationListCard";
import Paginator from "../../molecules/TableFragments/Paginator";
import DynamicCardGrid from "../Grids/DynamicCardGrid";

export interface QueryResultProps {
  queryResult: ISearchQueryResult;
  count?: boolean;
  cardType?: definedCardTypes;
  disableMenu?: boolean;
  newTab?: boolean;
  columns?: 1 | 2 | 3 | 4;
}

const StyledAccents = styled.span`
  &&& {
    color: grey;
  }
`;

const QueryResult: React.SFC<QueryResultProps> = ({
  queryResult,
  count: showCount,
}) => {
  const {
    onQuery,
    handleNextPage,
    handlePrevPage,
    loading,
    error,
    events,
    count,
    disableNextPage,
    disablePrevPage,
    showAllRecords,
  } = queryResult;
  const { t } = useTranslation();
  return (
    <>
      <QueryMenu onSearch={onQuery} loading={loading} />
      {error && <ErrorBanner />}
      {showCount && (
        <>
          <StyledResultText>
            {t("Filter.Found")}
            <StyledAccents>{t("Filter.EventAmount", { count })}</StyledAccents>
          </StyledResultText>
          <StyledDivider>
            <Divider />
          </StyledDivider>
        </>
      )}
      {events.length > 0 && (
        <>
          <DynamicCardGrid
            loading={loading}
            columns={4}
            rows={4}
            gridTag="recentGrid"
          >
            {events.map((card, ind: number) => {
              const { slug, type: recordType } = card;
              if (recordType === definedRouteTypes.organizations) {
                const displayCard = (card as unknown) as IOrganizationDisplay;
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <React.Fragment key={`${card.slug}-${ind}`}>
                    <GridColumn>
                      <OrganizationListCard organization={displayCard} />
                    </GridColumn>
                    <Divider />
                  </React.Fragment>
                );
              }
              let newType = definedCardTypes.post;
              if (recordType.includes("notikumi")) {
                newType = definedCardTypes.calendar;
              }

              return (
                <GridColumn key={`${card.slug}-${ind}`} >
                  <EventGridCard
                    size={definedCardSizes.medium}
                    event={card}
                    type={newType || definedCardTypes.post}
                    key={slug}
                    translate={false}
                  />
                </GridColumn>
              );
            })}
          </DynamicCardGrid>
          {events.length > defaultLimit ||
            (showAllRecords && (
              <StyledPaginator>
                <Paginator
                  pageIndex={0}
                  goToPrevPage={handlePrevPage}
                  goToNextPage={handleNextPage}
                  isPrevAvailable={disablePrevPage}
                  isNextAvailable={disableNextPage}
                />
              </StyledPaginator>
            ))}
        </>
      )}
    </>
  );
};

export default QueryResult;

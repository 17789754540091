import React, { PureComponent } from "react";
import styled from "styled-components";

interface Props {
  block: any;
  blockProps: {
    placeholder: string;
    setReadOnly: (state: boolean) => void;
    onCancel: (block: any) => void;
    onRequest: (block: any, text: string) => void;
  };
}

interface State {
  text: string;
}
const StyledEmbedderContainer = styled.div`
  width: 100%;
  outline: none;
`;

const StyledEmbedderInput = styled.input({
  "&&&&": {
    width: "100%",
    outline: "none",
    fontSize: "inherit",
    fontFamily: "inherit",
    border: "none",
  },
});
class Embedder extends PureComponent<Props, State> {
  input: any;

  constructor(props: any) {
    super(props);
    // eslint-disable-next-line react/state-in-constructor
    this.state = { text: "" };
  }

  componentDidMount() {
    this.input.focus();
  }

  cancel = () => {
    const { blockProps, block } = this.props;
    blockProps.onCancel(block);
    blockProps.setReadOnly(false);
  };

  onChange = (event: any) => this.setState({ text: event.target.value });

  onFocus = () => {
    const { blockProps } = this.props;
    blockProps.setReadOnly(true);
  };

  onKeyDown = (event: any) => {
    // Cancel on Escape or Del.
    const { text } = this.state;
    if (event.keyCode === 27 || (event.keyCode === 46 && text.length === 0)) {
      this.cancel();
    }
  };

  onKeyPress = async (event: any) => {
    if (event.key === "Enter") {
      const { blockProps, block } = this.props;
      const { text } = this.state;
      await blockProps.onRequest(block, text);
      blockProps.setReadOnly(false);
    }
  };

  render() {
    const { blockProps } = this.props;
    const { text } = this.state;
    const { placeholder } = blockProps;

    return (
      <StyledEmbedderContainer onBlur={this.cancel} onFocus={this.onFocus}>
        <StyledEmbedderInput
          ref={(ref) => {
            this.input = ref;
          }}
          placeholder={placeholder}
          value={text}
          onChange={this.onChange}
          onKeyDown={this.onKeyDown}
          onKeyPress={this.onKeyPress}
        />
      </StyledEmbedderContainer>
    );
  }
}

export default Embedder;

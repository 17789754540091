import { ContentState, EditorState } from "draft-js";

import getCurrentBlock from "./getCurrentBlock";

const addBlock = (editorState: EditorState, entityType: string, data = {}) => {
  const selectionState = editorState.getSelection();

  if (!selectionState.isCollapsed()) {
    return editorState;
  }

  const contentState = editorState.getCurrentContent();
  const key = selectionState.getStartKey();
  const blockMap = contentState.getBlockMap();
  const currentBlock = getCurrentBlock(editorState);

  if (
    !currentBlock ||
    currentBlock.getLength() !== 0 ||
    currentBlock.getType() === entityType
  ) {
    return editorState;
  }

  const newBlock = currentBlock.merge({
    type: entityType,
    data,
  });

  const newContentState = contentState.merge({
    blockMap: blockMap.set(key, newBlock as any),
    selectionAfter: selectionState,
  }) as ContentState;

  return EditorState.push(editorState, newContentState, "change-block-type");
};

export default addBlock;

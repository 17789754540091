import React, { useState, useEffect, memo } from "react";
import { Form } from "semantic-ui-react";
import { Moment } from "moment";
import moment from "moment-timezone";

import FormDateInput from "./FormDateInput";
import FormTimeInput from "./FormTimeInput";

import { ConvertDateToUTC } from "../../../helpers/ReactHelpers";

export interface FormDateTimeInputProps {
  required?: boolean;
  id: string;
  onChange: (id: string, data: Moment | undefined) => void;
  value?: Moment;
  dateLabel?: string;
  timeLabel?: string;
  showUTC?: boolean;
}

// TODO: move to constants
const htmlDateFormat = "YYYY-MM-DD";
const htmlTimeFormat = "HH:mm";
const htmlDateTimeFormat = "YYYY-MM-DD HH:mm";

const FormDateTimeInput: React.FC<FormDateTimeInputProps> = ({
  id,
  dateLabel,
  timeLabel,
  required,
  value,
  onChange,
  showUTC,
}) => {
  // TODO rethink where should moment parsing happen (in model?)
  // This will be a bug if value string will not contain info about timezone!
  // Moment will assume local automatically. While backend holds ISO.
  const [date, setDate] = useState(() => {
    if (value) {
      if (showUTC) {
        return ConvertDateToUTC(value, htmlDateFormat);
      }
      return moment(value).format(htmlDateFormat);
    }
    return "";
  });
  const [time, setTime] = useState(() => {
    if (value) {
      if (showUTC) {
        return ConvertDateToUTC(value, htmlTimeFormat);
      }
      return moment(value).format(htmlTimeFormat);
    }
    return "";
  });

  useEffect(() => {
    // Date-time is considered empty if date field is not fully filled
    if (date === "") {
      onChange(id, undefined);
      return;
    }
    const newDate = showUTC
      ? moment.utc(`${date} ${time}`, htmlDateTimeFormat)
      : moment(`${date} ${time}`, htmlDateTimeFormat);

    if (!newDate.isValid()) {
      // This should be never happening
      console.error("FormDateTimeField received bad date format!", date, time);
      return;
    }
    onChange(id, newDate);
  }, [id, onChange, date, time, showUTC]);

  return (
    <Form.Group id={id} widths="equal">
      <FormDateInput
        id="date"
        label={dateLabel}
        required={required}
        value={date}
        onChange={(dateId, data) => setDate(data)}
      />
      <FormTimeInput
        label={timeLabel}
        id="time"
        required={required}
        value={time}
        onChange={(timeId, data) => setTime(data)}
      />
    </Form.Group>
  );
};

export default memo(FormDateTimeInput);

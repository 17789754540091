import React from "react";
import { Menu, MenuItemProps } from "semantic-ui-react";
import { NavLink } from "react-router-dom";

interface LinkMenuItemProps {
  route: string;
  position?: MenuItemProps["position"];
  id?: string;
}

const LinkMenuItem: React.FC<LinkMenuItemProps> = ({
  position,
  route,
  children,
  id,
}) => {
  return (
    <Menu.Item as={NavLink} to={route} position={position} id={id}>
      {children}
    </Menu.Item>
  );
};

export default LinkMenuItem;

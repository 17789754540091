import React from "react";

interface Props {
  cell: { value?: string[] };
}

const ArrayTextCell: React.FC<Props> = ({ cell: { value } }) => {
  if (!value || !value.length) return <>-</>;

  const firstValue = value[0];
  const entryCount = value.length;

  if (entryCount === 1) {
    return <>{firstValue}</>;
  }

  return <>{`${firstValue} (+${entryCount - 1} more)`}</>;
};

export default ArrayTextCell;

import React, { useState, useEffect } from "react";
import { Segment, Grid } from "semantic-ui-react";
import { toast } from "react-toastify";
import { Prompt } from "react-router-dom";
import { useImmer } from "use-immer";
import useAuthorizedContext from "hooks/useAuthorizedContext";

import { MatchSubCategory } from "../../constants/categories";
import TableTitleGrid from "../templates/TableTitleGrid";
import SimpleButton, {
  definedButtonTypes,
} from "../atoms/Buttons/SimpleButton/SimpleButton";
import { getKeywords, postKeywords } from "../../api/KeywordsApi";
import { definedTypographyTypes } from "../atoms/Typography/TypographyStyles";
import Typography from "../atoms/Typography/Typography";
import KeywordTable from "../organisms/Tables/KeywordTable";
import KeywordList from "../organisms/Lists/KeywordList";

interface Props {}

export interface IAdminKeyRow {
  localeCategory: string;
  keyword: string;
  category: string;
  localeKeyword: string;
}

const data: IAdminKeyRow[] = Object.entries(MatchSubCategory)
  .map(([category, keywords]) => {
    const all = keywords.map((keyword) => ({
      localeCategory: `category:SubCategories.${category}`,
      localeKeyword: `category:SubCategories.${keyword.value}`,
      keyword: keyword.value,
      category,
    }));

    all.unshift({
      localeCategory: `category:SubCategories.${category}`,
      localeKeyword: `category:SubCategories.${category}`,
      keyword: category,
      category,
    });

    return all;
  })
  .flat();

function fromStringToKeyRow(keyStrings: Array<string>): Array<IAdminKeyRow> {
  const adminKeyRow: Array<IAdminKeyRow> = [];
  keyStrings.forEach((keyString) => {
    const keyRow = data.find((item) => item.keyword === keyString);
    if (keyRow) adminKeyRow.push(keyRow);
  });
  return adminKeyRow;
}

const KeywordPage: React.FC<Props> = () => {
  const [selected, setSelected] = useImmer<Array<IAdminKeyRow>>([]);
  const [initial, setInitial] = useState<Array<IAdminKeyRow>>([]);
  const [loading, setLoading] = useState(false);
  const { token } = useAuthorizedContext();

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;
    const doFetch = async () => {
      setLoading(true);
      try {
        const res = await getKeywords();
        if (!signal.aborted) {
          const keyRow = fromStringToKeyRow(res?.subCategories);
          setSelected(() => keyRow);
          setInitial(keyRow);
        }
      } catch (e) {
        if (!signal.aborted) {
          console.error(e);
          toast.error("Kaut kas nogāja greizi.");
        }
      } finally {
        if (!signal.aborted) setLoading(false);
      }
    };
    doFetch();
    return () => {
      abortController.abort();
    };
  }, [setSelected]);

  const toggleSelected = (value: IAdminKeyRow, idx: number) => {
    setSelected((prev) => {
      const newSelection = [...prev];
      if (idx > -1) {
        newSelection.splice(idx, 1);
      } else {
        newSelection.push(value);
      }
      return newSelection;
    });
  };
  const removeSelected = (idx: number) => {
    setSelected((draft) => {
      draft.splice(idx, 1);
      return draft;
    });
  };
  const moveUpSelected = (idx: number) => {
    setSelected((draft) => {
      const newId = idx - 1;
      if (newId >= 0) {
        const removed = draft.splice(idx, 1);
        draft.splice(newId, 0, removed[0]);
      }
      return draft;
    });
  };
  const moveDownSelected = (idx: number) => {
    setSelected((draft) => {
      const newId = idx + 1;
      if (draft.length > newId) {
        const removed = draft.splice(idx, 1);
        draft.splice(newId, 0, removed[0]);
      }
      return draft;
    });
  };
  const submitSelection = async () => {
    setLoading(true);
    try {
      const toPublish = selected.map((item) => item.keyword) || [];
      const idToken = await token();
      await postKeywords(idToken, toPublish);
      setInitial(selected);
    } catch (e) {
      console.error(e);
      toast.error("Kaut kas nogāja greizi.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Prompt
        when={JSON.stringify(initial) !== JSON.stringify(selected)}
        message="Tev ir nesaglabātas izmaiņas, vai tiešām velies pamest šo lapu?"
      />
      <TableTitleGrid title="Izcelt Atslēgvārdus">
        <SimpleButton
          variant={definedButtonTypes.primary}
          loading={loading}
          onClick={submitSelection}
        >
          Saglabāt izmaiņas
        </SimpleButton>
      </TableTitleGrid>
      <Grid columns={2} style={{ marginTop: "-2rem" }}>
        <Grid.Column>
          <Typography type={definedTypographyTypes.h3}>
            Visi Atslēgvārdi
          </Typography>
          <KeywordTable
            selected={selected}
            toggleSelected={toggleSelected}
            loading={loading}
            all={data}
          />
        </Grid.Column>
        <Grid.Column>
          <Typography type={definedTypographyTypes.h3}>
            Izceltie Atslēgvārdi
          </Typography>
          <Segment>
            <KeywordList
              selected={selected}
              loading={loading}
              removeItem={removeSelected}
              moveUpItem={moveUpSelected}
              moveDownItem={moveDownSelected}
            />
          </Segment>
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default KeywordPage;

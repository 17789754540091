import styled, { CSSObject } from "styled-components";
import { Label } from "semantic-ui-react";
import { Link } from "react-router-dom";

import { styledWithTheme } from "./theme";

import { definedAttachedLabelTypes } from "../components/atoms/Labels/AttachedLabel";

/**
 * Styled Link Card (basically every card)
 */
export const StyledLinkCard = styled.div<{
  width?: string;
  maxwidth?: string;
  minwidth?: string;
  textAlign: CSSObject["textAlign"];
}>(({ width, maxwidth, minwidth, textAlign }) => ({
  "&&&": {
    display: "inline-block",
    width: `${width}`,
    maxWidth: `${maxwidth}`,
    minWidth: `${minwidth}`,
    textAlign,
  },
}));
export const StyledLinkCardLink = styled(Link)({
  "&&&": {
    position: "relative",
    zIndex: 1,
  },
});

export const StyledLinkToArticleCard = styledWithTheme.div<{
  hover?: boolean;
  width?: string;
  maxwidth?: string;
  minwidth?: string;
  textAlign: CSSObject["textAlign"];
}>(({ width, maxwidth, minwidth, textAlign, hover, theme }) => ({
  "&&&": {
    display: "inline-block",
    width: `${width}`,
    maxWidth: `${maxwidth}`,
    minWidth: `${minwidth}`,
    textAlign,
    ":hover": hover
      ? {
          transform: "translateY(-3px)",
          boxShadow: theme.palette.mono.light,
        }
      : {},
  },
}));

export const StyledLinkToArticleCardLink = styled.a({
  "&&&": {
    position: "relative",
    zIndex: 1,
  },
});

/**
 * Small Event Date rect for events cards
 */
export const StyledEventDateContainer = styledWithTheme.div<{
  absolute?: boolean;
}>(({ theme, absolute }) => ({
  padding: "0.4em 0em",
  background: theme.palette.mono.white,
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: "7px",
  ...(absolute
    ? {
        position: "absolute",
        top: "1em",
        left: "1em",
      }
    : null),
}));

export const StyledEventDateDay = styled.div({
  marginBottom: "0.3125em",
  fontSize: "1.7rem",
  alignSelf: "center",
  textAlign: "center",
});

export const StyledEventDateMonth = styledWithTheme.div(({ theme }) => ({
  margin: "0em 0.3125em",
  padding: "0 0.5em",
  width: "auto",
  color: theme.palette.mono.darker,
  fontSize: "1rem",
  alignSelf: "center",
  textAlign: "center",
  textTransform: "uppercase",
}));

/**
 * Attached Label on Event Image
 */
export const StyledAttachedLabel = styledWithTheme(Label)(
  ({ theme, type }) => ({
    "&&&": {
      marginBottom: "1em",
      marginLeft: "-0.5em",
      color: theme.palette.mono.white,
      backgroundColor: theme.palette.primary.main,
      borderRadius: "0",
      ...(type === definedAttachedLabelTypes.imageGallery && {
        marginLeft: "0",
        marginBottom: "0",
        marginTop: "1em",
        marginRight: "-0.5em",
        padding: "0.5em 1.5em",
        ".icon": {
          margin: "0",
        },
      }),
    },
  }),
);

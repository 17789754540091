import { EditorState } from "draft-js";
import React, { useCallback } from "react";
import { Button, ButtonGroup } from "semantic-ui-react";

interface Props {
  editorState: EditorState;
  onChange: (editorState: EditorState) => void;
}

const HistoryToolbar: React.FC<Props> = ({ onChange, editorState }) => {
  const canUndo = editorState.getUndoStack().size !== 0;
  const canRedo = editorState.getRedoStack().size !== 0;

  const undo = useCallback(
    (state: EditorState) => () => {
      onChange(EditorState.undo(state));
    },
    [onChange],
  );
  const redo = useCallback(
    (state: EditorState) => () => {
      onChange(EditorState.redo(state));
    },
    [onChange],
  );

  return (
    <ButtonGroup>
      <Button disabled={!canUndo} onMouseDown={undo(editorState)} icon="undo" />
      <Button disabled={!canRedo} onMouseDown={redo(editorState)} icon="redo" />
    </ButtonGroup>
  );
};

export default HistoryToolbar;

import { EditorState, RichUtils } from "draft-js";
import React, { useCallback } from "react";
import styled from "styled-components";
import { Button, ButtonGroup, Icon } from "semantic-ui-react";

import getSelectedBlockType from "../utils/getSelectedBlockType";

const StyledIcon = styled(Icon)({
  "&&&": {
    paddingTop: "2px",
  },
});

const BLOCK_STYLE_TEXT = [
  // { text: "H1", value: "header-one" },
  // { text: "H2", value: "header-two" },
  { text: "H3", value: "header-three", icon: "header" },
  {
    text: "H4",
    value: "header-four",
    icon: <StyledIcon name="header" size="small" />,
  },
  // { text: "H5", value: "header-five" },
  // { text: "H6", value: "header-six" },
];

interface Props {
  editorState: EditorState;
  onChange: (state: EditorState) => void;
}

const TextStyleToolbar: React.FC<Props> = ({ onChange, editorState }) => {
  const blockType = getSelectedBlockType(editorState);
  const onBlockStyleClick = useCallback(
    (style: string, state: EditorState) => (
      event: React.ChangeEvent<HTMLSelectElement>,
    ) => {
      event.preventDefault();
      const toggled = RichUtils.toggleBlockType(state, style);
      onChange(
        EditorState.forceSelection(
          toggled,
          toggled.getCurrentContent().getSelectionAfter(),
        ),
      );
    },
    [onChange],
  );

  return (
    <ButtonGroup>
      {BLOCK_STYLE_TEXT.map((btt) => (
        <Button
          active={btt.value === blockType}
          onMouseDown={onBlockStyleClick(btt.value, editorState)}
          icon={btt.icon}
          key={btt.value}
        />
      ))}
    </ButtonGroup>
  );
};

export default TextStyleToolbar;

import React from "react";

import { IBaseEntityForm, IBaseForm } from "../api";
import { ITableControlParams } from "../interfaces/tables";
import { definedTableActions } from "../hooks/useTypeTableContext";

export type TypeTableData = IBaseForm | IBaseEntityForm;

export interface ITypeTableContext {
  data: TypeTableData[];
  loading: boolean;
  count: number;
  pages: number;
  onFetchData: (params: ITableControlParams<TypeTableData>) => Promise<void>;
  updateTableEntry: (
    newData: TypeTableData,
    action: definedTableActions,
  ) => void;
}

const TypeTableContext = React.createContext<ITypeTableContext | null>(null);

export default TypeTableContext;

import React, { SyntheticEvent, useState } from "react";
import { Button } from "semantic-ui-react";
import {
  IPictureEntityDisplay,
  IPictureEntityForm,
} from "api/types/PictureEntity";
import useAuthorizedContext from "hooks/useAuthorizedContext";
import { UpdatePictureDbApi } from "api/PictureApi";

import EditPictureForm from "../Forms/EditPictureForm";
import ImportantModal from "../../templates/Modals/ImportantModal";

interface EditPictureProps {
  data: IPictureEntityDisplay;
  editRow: (data: IPictureEntityForm) => void;
}

const EditPictureModal: React.FC<EditPictureProps> = ({ data, editRow }) => {
  const { token } = useAuthorizedContext();
  const [open, setOpen] = useState(false);

  const submit = async (model: IPictureEntityForm) => {
    const idToken = await token();
    await UpdatePictureDbApi(idToken, model);
    editRow(model);
  };

  const closeForm = (event?: SyntheticEvent) => {
    if (event) event.preventDefault();
    setOpen(false);
  };

  return (
    <ImportantModal
      key={data.key}
      open={open}
      trigger={<Button size="mini" icon="edit" onClick={() => setOpen(true)} />}
    >
      <EditPictureForm data={data} onSubmit={submit} onClose={closeForm} />
    </ImportantModal>
  );
};

export default EditPictureModal;

export const Logo = "https://latviesi-static.s3.eu-central-1.amazonaws.com/LatviesiCom+logo.svg";
export const LOGO_WHITE = "https://latviesi-static.s3.eu-central-1.amazonaws.com/logoWhite.svg";
export const PLACEHOLDER_16_TO_9 = "https://latviesi-static.s3.eu-central-1.amazonaws.com/placeholder16to9.svg";
export const PLACEHOLDER_SQUARE = "https://latviesi-static.s3.eu-central-1.amazonaws.com/placeholder.svg";
export const TEMPLATE_MINIMAL = "https://latviesi-static.s3.eu-central-1.amazonaws.com/minimal-template.png";
export const TEMPLATE_ASSOCIATION = "https://latviesi-static.s3.eu-central-1.amazonaws.com/association-template.png";
export const TEMPLATE_ORGANIZATION = "https://latviesi-static.s3.eu-central-1.amazonaws.com/organisation-template.png";
export const TEMPLATE_MEDIA = "https://latviesi-static.s3.eu-central-1.amazonaws.com/media-template.png";
export const STORM_ICON = "https://latviesi-static.s3.eu-central-1.amazonaws.com/cloud-sun-rain-solid.svg";
export const UGLY_PLUS_BUTTON = "https://latviesi-static.s3.eu-central-1.amazonaws.com/plus-circle-solid.svg";
export const ANGLE_DOUBLE_DOWN = "https://latviesi-static.s3.eu-central-1.amazonaws.com/angle-double-down-solid.svg";
export const ADVERT_BANNER = "https://latviesi-static.s3.eu-central-1.amazonaws.com/Reklama+1.svg";
export const ADVERT_SQUARE = "https://latviesi-static.s3.eu-central-1.amazonaws.com/Reklama+2.svg";

export const BooleanFromAnything = (
  anything: string | number | boolean | null | undefined,
): boolean => {
  if (anything === "true") return true;
  if (anything === "1") return true;
  if (anything === 1) return true;
  if (anything === true) return true;

  return false;
};

export const parseStringOrObject = (
  maybeIamJson: string,
): string | Record<any, any> => {
  let orphan = maybeIamJson;
  try {
    orphan = JSON.parse(maybeIamJson);
  } catch (err) {
    // hes not j's son
  }
  return orphan;
};

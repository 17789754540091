import React from "react";
import { useDropzone } from "react-dropzone";
import { Dimmer, Icon, Button, Image } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

import DropzoneContainer from "./DropzoneContainer";

import Typography from "../Typography/Typography";

export interface ImageDropzoneProps {
  isLoading: boolean;
  onDrop: (acceptedFiles: File[]) => void;
  exampleImgUrl?: string;
  disabled?: boolean;
}

const ImageDropzone: React.FC<ImageDropzoneProps> = ({
  isLoading,
  onDrop,
  exampleImgUrl,
  disabled,
}) => {
  const { t } = useTranslation();
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    multiple: false,
    accept: "image/jpeg, image/png",
    disabled,
  });

  const btnText = exampleImgUrl
    ? t("Forms.ChangeImageAction")
    : t("Forms.BrowseFilesAction");

  return (
    <DropzoneContainer isLoading={isLoading}>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <Image src={exampleImgUrl} centered size="large" />
        {!exampleImgUrl && (
          <>
            <Icon name="cloud upload" size="massive" />
            <Typography as="p" align="center">
              {t("Forms.DropImageHere")}
            </Typography>
            <Typography as="p" align="center">
              {t("Forms.Or")}
            </Typography>
          </>
        )}
        <p />
        <Button type="button" onClick={open} disabled={disabled}>
          {btnText}
        </Button>
        <Dimmer active={isDragActive}>
          <Icon name="arrow down" size="huge" />
          <Typography>{t("Forms.OnImageDrop")}</Typography>
        </Dimmer>
      </div>
    </DropzoneContainer>
  );
};

export default ImageDropzone;

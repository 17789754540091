import React, { useState, MouseEvent } from "react";
import { Button } from "semantic-ui-react";

import ImportantModal from "../../templates/Modals/ImportantModal";
import { EntityForm, IBaseForm } from "../../../api";
import { definedRouteTypes } from "../../../api/api";
import OrganizationsForm from "../Forms/OrganizationsForm";
import EventsForm from "../Forms/EventsForm";
import GalleryForm from "../Forms/GalleryForm";
import VideoNewsForm from "../Forms/VideoNewsForm";
import ContentForm from "../Forms/ContentForm";
import CompaniesForm from "../Forms/CompaniesForm";
import SelfEmployedForm from "../Forms/SelfEmployedForm";
import MediaLinkForm from "../Forms/MediaLinkForm";
import MediaSourceForm from "../Forms/MediaSourceForm";

interface EditModalProps {
  data: IBaseForm | EntityForm;
}

const EditModal: React.FC<EditModalProps> = ({ children, data }) => {
  const { id, type, slug } = data;
  const [open, setOpen] = useState(false);
  const closeForm = (event?: MouseEvent) => {
    if (event) event.preventDefault();
    setOpen(false);
  };

  // TODO fix type issue
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let FormComponent: React.FC<any>;
  if (type === definedRouteTypes.organizations) {
    FormComponent = OrganizationsForm;
  } else if (type === definedRouteTypes.companies) {
    FormComponent = CompaniesForm;
  } else if (type === definedRouteTypes.selfEmployed) {
    FormComponent = SelfEmployedForm;
  } else if (type === definedRouteTypes.events) {
    FormComponent = EventsForm;
  } else if (type === definedRouteTypes.pastEvents) {
    FormComponent = EventsForm;
  } else if (type === definedRouteTypes.imageGallery) {
    FormComponent = GalleryForm;
  } else if (type === definedRouteTypes.videoNews) {
    FormComponent = VideoNewsForm;
  } else if (type === definedRouteTypes.LINK_MEDIA) {
    FormComponent = MediaLinkForm;
  } else if (type === definedRouteTypes.SOURCE_MEDIA) {
    FormComponent = MediaSourceForm;
  } else {
    FormComponent = ContentForm;
  }

  return (
    <ImportantModal
      key={id}
      open={open}
      trigger={
        <Button
          size="mini"
          icon="edit"
          onClick={() => setOpen(true)}
          id={`edit-${slug}`}
        />
      }
    >
      <FormComponent
        type={type}
        initialValues={data}
        update
        onFormClose={closeForm}
      />
      {children}
    </ImportantModal>
  );
};

export default EditModal;

import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown } from "semantic-ui-react";

import { CountryOptions } from "../constants/country";
import { IDropDownOption } from "../interfaces";

const popular = [
  "us",
  "lv",
  "gb",
  "es",
  "ca",
  "au",
  "de",
  "se",
  "ie",
  "fi",
  "be",
  "lu",
  "ee",
  "lt",
  "it",
];

const useLocalizedCountryOpts = (): IDropDownOption[] => {
  const { t } = useTranslation();
  return useMemo(() => {
    const all = CountryOptions.map((option) => ({
      ...option,
      key: option.value,
      text: t(`country:Countries.${option.value}`),
    })).sort((a, b) => a.text.localeCompare(b.text));

    const header = {
      content: t(`country:Countries.all`),
      text: t(`country:Countries.all`),
      as: Dropdown.Header,
      disabled: true,
      key: "header",
      value: "",
    };

    const popularOpts = popular
      .map((item) => ({
        flag: item,
        value: item,
        key: `${item}-popular`,
        text: t(`country:Countries.${item}`),
      }))
      .sort((a, b) => a.text.localeCompare(b.text));
    const remainingOpts = all.filter((el) => !popular.includes(el.key));
    return [...popularOpts, header, ...remainingOpts];
  }, [t]);
};

export default useLocalizedCountryOpts;

// TODO fix any
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, ReactNode } from "react";
import {
  useTable,
  usePagination,
  useExpanded,
  useFilters,
  useSortBy,
  SortingRule,
} from "react-table";
import { Table, Loader, Dimmer } from "semantic-ui-react";
import styled from "styled-components";

import Paginator from "../../molecules/TableFragments/Paginator";
import {
  ITable,
  IColumn,
  ITableControlParams,
} from "../../../interfaces/tables";
import { columnMaker, definedAccessors } from "../../../helpers/tableHelpers";
import SortIcon from "../../atoms/Icons/SortIcon";
import PageSizeSelect from "../../molecules/TableFragments/PageSizeSelect";

export interface TableProps<T extends object = {}> {
  data: T[];
  columns: definedAccessors[];
  totalPageCount: number;
  onFetchData: (opts: ITableControlParams<T>) => Promise<void>;
  totalDataCount?: number;
  initialSortBy?: Array<SortingRule<T>>;
  actionColumn?: Partial<IColumn<T>>;
  loading?: boolean;
  expanderComponent?: React.FC<any>;
  initialPageSize?: 10 | 20 | 30 | 50 | 100;
}

const StyleTableCell = styled(Table.Cell)`
  &&& {
    &&& {
      &:hover {
        background-color: #b1b6bd;
      }
    }
  }
`;

// Reason why React.FC type is not used is explained here
// https://stackoverflow.com/questions/53958028/how-to-use-generics-in-props-in-react-in-a-functional-component
const ControlledTable = <T extends object = {}>(
  props: TableProps<T> & { children?: ReactNode },
): ReactElement => {
  const {
    data,
    loading,
    expanderComponent: Expander,
    columns,
    totalDataCount,
    totalPageCount,
    actionColumn,
    onFetchData,
    initialSortBy,
    initialPageSize,
  } = props;

  // By react-table documentation, columns must be memoized!
  const cols = React.useMemo(() => columnMaker(columns, actionColumn), [
    columns,
    actionColumn,
  ]);
  const maxColspan = columns.length + 1;
  const isDataCountVisible = Boolean(totalDataCount);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageSize, pageIndex, sortBy, filters },
  } = useTable<any>(
    {
      columns: cols,
      data,
      initialState: {
        sortBy: initialSortBy || [{ id: "toBePostedAt", desc: true }],
        pageSize: initialPageSize || 20,
      },
      pageCount: totalPageCount,
      manualSortBy: true,
      manualFilters: true,
      manualPagination: true,
    } as any,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
  ) as ITable<T>;

  React.useEffect(() => {
    onFetchData({ sortBy, filters, pageIndex, pageSize });
  }, [onFetchData, pageSize, pageIndex, sortBy, filters]);

  return (
    <Dimmer.Dimmable dimmed={loading}>
      <Dimmer active={loading} inverted>
        <Loader size="massive">Ielādē saturu...</Loader>
      </Dimmer>
      {isDataCountVisible && <p>Atlasīto datu skaits: {totalDataCount}</p>}
      <Table size="small" compact="very" striped {...getTableProps()}>
        <Table.Header>
          {headerGroups.map((headerGroup) => (
            <Table.Row {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <Table.HeaderCell {...column.getHeaderProps()}>
                  <div {...column.getSortByToggleProps()}>
                    {column.render("Header")}
                    {column.canSort && <SortIcon desc={column.isSortedDesc} />}
                  </div>
                  <div>{column.canFilter ? column.render("Filter") : null}</div>
                </Table.HeaderCell>
              ))}
            </Table.Row>
          ))}
        </Table.Header>
        <Table.Body {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            const { key, ...rowProps } = row.getRowProps();

            return (
              <React.Fragment key={key}>
                <Table.Row {...rowProps}>
                  {row.cells.map((cell) => {
                    return (
                      <StyleTableCell {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </StyleTableCell>
                    );
                  })}
                </Table.Row>
                {(row as any).isExpanded && Expander && (
                  <Table.Row>
                    <Table.Cell colSpan={maxColspan}>
                      <Expander data={row.original} />
                    </Table.Cell>
                  </Table.Row>
                )}
              </React.Fragment>
            );
          })}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.Cell colSpan={maxColspan}>
              <Paginator
                disabled={loading}
                pageIndex={pageIndex}
                goToPrevPage={() => previousPage()}
                goToNextPage={() => nextPage()}
                isPrevAvailable={canPreviousPage}
                isNextAvailable={canNextPage}
              />
              <PageSizeSelect pageSize={pageSize} setPageSize={setPageSize} />
            </Table.Cell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </Dimmer.Dimmable>
  );
};

export default React.memo(ControlledTable);

import { EditorState } from "draft-js";

import getEntityAtCursor from "../../../../utils/getEntityAtCursor";
import { LINK_ENTITY_TYPE } from "../constants";

export default function isLinkSelected(editorState: EditorState): boolean {
  const contentState = editorState.getCurrentContent();
  const entity = getEntityAtCursor(editorState);
  if (!entity) return false;
  const type = contentState.getEntity(entity.entityKey).getType();
  return type === LINK_ENTITY_TYPE;
}

import React, { MouseEvent, useMemo, useCallback, useState } from "react";
import { Form, Divider } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { convertToRaw, ContentState } from "draft-js";
import styled from "styled-components";
import useAuthorizedContext from "hooks/useAuthorizedContext";

import FormTextInput from "../../molecules/FormFields/FormTextInput";
import FormSelect from "../../molecules/FormFields/FormSelect";
import FormDateTimeInput from "../../molecules/FormFields/FormDateTimeInput";
import useForm from "../../../hooks/useForm";
import FormMultiSelect from "../../molecules/FormFields/FormMultiSelect";
import FormGrid from "../../templates/FormGrid";
import FormRichTextArea from "../../molecules/FormFields/FormRichTextArea";
import FormImageZone from "../../molecules/FormFields/FormImageZone";
import { categoryOpts } from "../../../constants/categories";
import FormGeolocationFields from "../../molecules/FormFields/FormGeolocationInput";
import Typography, {
  definedTypography,
} from "../../atoms/Typography/Typography";
import {
  IEditorialNewsForm,
  IDiasporeNewsForm,
  IOrganizationNewsForm,
  IOffersForm,
  ICompanyNewsForm,
} from "../../../api";
import { definedRouteTypes } from "../../../api/api";
import SimpleButton, {
  definedButtonTypes,
} from "../../atoms/Buttons/SimpleButton/SimpleButton";
import FormRadioButton from "../../molecules/FormFields/FormRadioButton";
import FormSourceField from "../../molecules/FormFields/FormSourceField";
import FormAuthorFields, {
  definedAuthorTypes,
} from "../../molecules/FormFields/FormAuthorFields";
import { IBaseFormProps } from "../../../interfaces/forms";
import FormKeywordSelect from "../../molecules/FormFields/FormKeywordSelect";
import useLocalizedCountryOpts from "../../../hooks/useLocalizedCountryOpts";
import FormRankSelect from "../../molecules/FormFields/FormRankSelect";
import useSubmitRankedPosts from "../../../hooks/useSubmitRankedPosts";
import FormRegionInput from "../../molecules/FormFields/FormRegionInput";

export type availableContentForms =
  | IEditorialNewsForm
  | IDiasporeNewsForm
  | IOrganizationNewsForm
  | IOffersForm
  | ICompanyNewsForm;

export interface ContentFormProps extends IBaseFormProps {
  update?: boolean;
  type: availableContentForms["type"];
  initialValues?: availableContentForms;
  onFormClose?: (event?: MouseEvent) => void;
}

const StyledDiv = styled.div({
  textAlign: "center",
});

const ContentForm: React.FC<ContentFormProps> = ({
  initialValues,
  type,
  update,
  onFormClose,
}) => {
  const [isSubmitted, setSubmitted] = useState(update);
  const CountryOptions = useLocalizedCountryOpts();
  const { user } = useAuthorizedContext();
  const { t } = useTranslation();

  const defaultInitValues = useCallback(
    (initType: availableContentForms["type"]) => {
      let authorType = definedAuthorTypes.none;
      if (
        initType === definedRouteTypes.diasporeNews ||
        initType === definedRouteTypes.organizationNews
      ) {
        authorType = definedAuthorTypes.organization;
      } else if (initType === definedRouteTypes.companyNews) {
        authorType = definedAuthorTypes.company;
      }

      return {
        title: "",
        slug: "",
        description: convertToRaw(ContentState.createFromText("")),
        imageUrl: undefined,
        imagePointer: undefined,
        category: undefined,
        authorType,
        author: user.email,
        creator: user.username,
        creatorName: user.displayName(),
        organizationId: "",
        organizationInfo: undefined,
        anotherAuthor: "",
        subCategories: [],
        highlighted: false,
        highlightedInCountry: false,
        exclusive: false,
        show: true,
        countries: [],
        address: undefined,
        source: { name: "", url: "" },
        toBePostedAt: undefined,
        type: initType,
        displayInHomePage: false,
        online: false,
        region: undefined,
      };
    },
    [user],
  );

  const submitFormQuery = useSubmitRankedPosts(isSubmitted);

  const defaultInitialValues = useMemo(() => {
    return defaultInitValues(type);
  }, [type, defaultInitValues]);

  const {
    values,
    submitForm,
    changeFormValue,
    changeFormImageValue,
    isLoading,
  } = useForm(initialValues || defaultInitialValues, submitFormQuery);

  const {
    title,
    description,
    category,
    subCategories,
    countries,
    address,
    toBePostedAt,
    source,
    imageUrl,
    show,
    highlighted,
    highlightedInCountry,
    exclusive,
    customLabel,
    authorType,
    author,
    organizationId,
    anotherAuthor,
    imageAuthor,
    online,
    homepageRank,
    region,
  } = values;

  const formTitle = isSubmitted
    ? t("Forms.Titles.EditHeader")
    : t("Forms.Titles.AddHeader");
  const submit = isSubmitted
    ? t("Forms.Actions.Update")
    : t("Forms.Actions.Create");
  const submitAndClose = isSubmitted
    ? t("Forms.Actions.UpdateAndClose")
    : t("Forms.Actions.CreateAndClose");

  const convertedShow = Boolean(Number(show));

  return (
    <Form name="newsForm" loading={isLoading}>
      <Typography type={definedTypography.h1} as="span">
        {formTitle}
      </Typography>
      <Divider />
      <FormGrid label={t("Administrēšana")}>
        <FormRadioButton
          id="show"
          label="Redzams portālā"
          value={convertedShow}
          onChange={changeFormValue}
        />
        <FormRadioButton
          id="exclusive"
          label="Tikai Latvieši.com"
          value={exclusive}
          onChange={changeFormValue}
        />
        <FormRadioButton
          id="highlighted"
          label="Izcelts"
          value={highlighted}
          onChange={changeFormValue}
        />
        <FormRadioButton
          id="highlightedInCountry"
          label="Izcelts valstī"
          value={highlightedInCountry}
          onChange={changeFormValue}
        />
        <FormRankSelect
          label={t("Rādīt sākumlapā")}
          id="homepageRank"
          value={homepageRank}
          type={type}
          category={category}
          required
          onChange={changeFormValue}
        />
      </FormGrid>
      <FormGrid label={t("Forms.Titles.Content")}>
        <FormTextInput
          id="title"
          required
          value={title}
          label={t("Forms.Fields.title")}
          onChange={changeFormValue}
        />
        <FormImageZone
          id="imageUrl"
          required
          initialImgUrl={imageUrl}
          label={t("Forms.Fields.imagePointer")}
          onChange={changeFormValue}
          onImageChange={changeFormImageValue}
          description={t("Forms.Descriptions.newsImage")}
        />
        <FormRichTextArea
          id="description"
          required
          label={t("Forms.Fields.description")}
          value={description}
          onChange={changeFormValue}
        />
      </FormGrid>
      <FormGrid label={t("Forms.Titles.Author")}>
        {/* Author Select */}
        <FormAuthorFields
          type={type}
          authorType={authorType}
          author={author}
          organizationId={organizationId}
          anotherAuthor={anotherAuthor}
          changeFormValue={changeFormValue}
        />
      </FormGrid>
      <FormGrid label={t("Forms.Titles.Category")}>
        <FormSelect
          label={t("Forms.Fields.category")}
          id="category"
          value={category}
          options={categoryOpts}
          onChange={changeFormValue}
          search
        />
        <FormKeywordSelect
          label={t("Forms.Fields.subCategories")}
          id="subCategories"
          value={subCategories || []}
          onChange={changeFormValue}
          selectedCategory={category}
        />
        <FormMultiSelect
          id="countries"
          label={t("Forms.Fields.countries")}
          options={CountryOptions}
          value={countries}
          onChange={changeFormValue}
          search
        />
        <FormRegionInput
          id="region"
          value={region}
          onChange={changeFormValue}
        />
      </FormGrid>
      <FormGrid label={t("Forms.Titles.Place")}>
        <FormRadioButton
          id="online"
          label={t("Forms.Fields.Online")}
          value={online}
          onChange={changeFormValue}
        />
        <FormGeolocationFields
          id="address"
          value={address}
          onChange={changeFormValue}
        />
      </FormGrid>
      <FormGrid label={t("Forms.Titles.Settings")}>
        <FormDateTimeInput
          id="toBePostedAt"
          timeLabel={t("Forms.Fields.toBePostedAtTime")}
          dateLabel={t("Forms.Fields.toBePostedAtDate")}
          value={toBePostedAt}
          onChange={changeFormValue}
        />
        <FormSourceField
          id="source"
          value={source}
          onChange={changeFormValue}
        />
        <FormTextInput
          id="imageAuthor"
          label={t("Forms.Fields.imageAuthor")}
          value={imageAuthor}
          onChange={changeFormValue}
        />
        <FormTextInput
          id="customLabel"
          label={t("Forms.Fields.customLabel")}
          value={customLabel}
          description={
            type === definedRouteTypes.organizationNews ||
            type === definedRouteTypes.diasporeNews
              ? "Ja nenorāda birku, organizāciju ziņām automātiski tiek parādīts organizācijas nosaukums."
              : undefined
          }
          onChange={changeFormValue}
        />
      </FormGrid>
      <StyledDiv>
        {onFormClose && (
          <SimpleButton
            id="FormCancel"
            variant={definedButtonTypes.secondary}
            onClick={onFormClose}
          >
            {t("Forms.Actions.Cancel")}
          </SimpleButton>
        )}
        <SimpleButton
          id="FormSubmit"
          onClick={async (event) => {
            // TODO this is quickfix to enable updating...
            const response = await submitForm(event);
            setSubmitted(true);
            changeFormValue("slug", response ? response.slug : "");
          }}
          variant={definedButtonTypes.secondary}
        >
          {submit}
        </SimpleButton>
        <SimpleButton
          id="FormSubmitAndClose"
          onClick={async (event) => {
            await submitForm(event, () => {
              if (onFormClose) onFormClose();
            });
          }}
          variant={definedButtonTypes.primary}
        >
          {submitAndClose}
        </SimpleButton>
      </StyledDiv>
    </Form>
  );
};

export default ContentForm;

import styled from "styled-components";
import { Dropdown, Icon, Input, Grid, Form } from "semantic-ui-react";

import { styledWithTheme } from "../../../../styles/theme";

// TODO: styles, and interfaces and groom Dropdown to use our from atoms
export const StyledContainer = styledWithTheme.div`
  &&& {
    padding: 30px 0 20px 0;
  }
`;

export const StyledText = styledWithTheme.div(({ theme }) => ({
  ...theme.typography.title1,
  lineHeight: "38px",
  textAlign: "center",
  width: "100%",
}));
export const StyledWebText = styledWithTheme.div(({ theme }) => ({
  ...theme.typography.title1,
  lineHeight: "38px",
  textAlign: "center",
  width: "100%",
  "@media only screen and (max-width: 991px)": {
    display: "none",
  },
}));
export const StyledMobileText = styledWithTheme.div(({ theme }) => ({
  ...theme.typography.title1,
  lineHeight: "38px",
  textAlign: "center",
  "@media only screen and (min-width: 991.1px)": {
    display: "none",
  },
}));
export const StyledMobilButton = styledWithTheme.div(({ theme }) => ({
  ...theme.typography.title1,
  lineHeight: "38px",
  textAlign: "center",
  width: "100%",
  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "1em 0",
  "@media only screen and (min-width: 991.1px)": {
    display: "none",
  },
}));
export const StyledResultText = styledWithTheme.div(({ theme }) => ({
  ...theme.typography.h3,
  color: theme.palette.mono.black,
  marginRight: "10px",
  marginTop: "2em",
  textAlign: "left",
}));
export const StyledDivider = styledWithTheme.div({
  paddingBottom: "0.5em",
});

export const StyledSelect = styledWithTheme(Dropdown)(({ theme }) => ({
  "&&&": {
    border: "none",
    borderBottom: `solid 1px ${theme.palette.primary.main}`,
    borderRadius: "0px",
    marginBottom: "0.5em",
    ".default": {
      ...theme.typography.title1,
      textAlign: "center",
      width: "100%",
      color: `${theme.palette.primary.main}!important`,
    },
    ".dropdown.icon": {
      color: `${theme.palette.primary.main}!important`,
    },
  },
}));
export const StyledInput = styledWithTheme(Input)(({ theme }) => ({
  "&&&": {
    border: "none",
    borderBottom: `solid 1px ${theme.palette.primary.main}`,
    borderRadius: "0px",
    width: "100%",
    "> input": {
      border: "none",
      ...theme.typography.title1,
      textAlign: "center",
      width: "100%",
      color: `${theme.palette.primary.main}!important`,
    },
    "> h4": {
      margin: 0,
      alignSelf: "center",
      color: `${theme.palette.primary.main}`,
    },
    "> i": {
      color: `${theme.palette.primary.main}`,
    },
  },
}));
export const StyledSelectWrapper = styledWithTheme.div`
  &&& {
    margin: 0 0.5em;
  }
`;

export const StyledIcon = styledWithTheme(Icon)`
  &&& {
    height: 40px;
    width: 100%;
    margin: 0;
    border-radius: 5px;
    margin-left: auto;
    margin-right: auto;
    line-height: 38px;
    text-align: center;
    &:hover {
      color: ${({ theme }) => theme.palette.mono.black} !important;
      cursor: pointer;
    }
  }
`;

export const StyledGrid = styled(Grid)({
  "&&&": {
    // maxWidth: "60em",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "2em",
    marginBottom: "2em",
    "@media only screen and (max-width: 991px)": {
      marginTop: "0",
      marginBottom: "0",
    },
  },
});
export const StyledColumn = styled(Grid.Column)({
  "&&&": { paddingTop: "0!important", paddingBottom: "0!important" },
});
export const StyledForm = styled(Form)({
  "&&&": { paddingTop: "2em" },
});
export const StyledResponsiveForm = styled(Form)<{
  height: string;
  hide?: 1 | 0;
}>(({ height, hide }) => ({
  "&&&": {
    // paddingTop: "2em",
    height: "0",
    overflow: "hidden",
    transitionProperty: "height",
    transition: "all 0.5s ease-in-out",
    ...(hide ? {} : { height, overflow: "initial" }),
    "@media only screen and (min-width: 991.1px)": {
      height: "auto",
      overflow: "initial",
    },
  },
}));

export const StyledPaginator = styledWithTheme.div`
  &&& {
    margin-top: 2em;
    margin-bottom: 4em;
  }
`;

export const StyledExpandIcon = styled.img<{ hide?: 1 | 0 }>(({ hide }) => ({
  "&&&": {
    marginLeft: "0.5em",
    width: "0.7em",
    transform: "rotate(0deg)",
    transition: "transform 0.5s linear",
    ...(hide
      ? {}
      : {
          transform: "rotate(180deg)",
        }),
  },
}));

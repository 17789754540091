import React, { memo, useMemo } from "react";

import FormSelect, { FormSelectFieldProps } from "./FormSelect";
import { definedRouteTypes } from "../../../api/api";
import { CATEGORIES } from "../../../constants/categories";

// TODO: controlled @Agnese

export interface FormRankSelectProps
  extends Omit<FormSelectFieldProps, "options"> {
  value: any;
  type: definedRouteTypes;
  category?: CATEGORIES;
}

const options = (disableRank4: boolean, disableRanks1to3: boolean) => [
  {
    text: "Nerādīt sākumlapā",
    value: 5,
  },
  {
    text: "1 - Galvenā ziņa",
    description: "(1)",
    value: 1,
    disabled: disableRanks1to3,
  },
  {
    text: "2 - Izceltās ziņas",
    description: "(2)",
    value: 2,
    disabled: disableRanks1to3,
  },
  {
    text: "3 - Jaunākās ziņas",
    description: "(8)",
    value: 3,
    disabled: disableRanks1to3,
  },
  {
    text: "4 - Sadaļu ziņas",
    description: "(*)",
    disabled: disableRank4,
    value: 4,
  },
];

const FormRankSelect: React.FC<FormRankSelectProps> = ({
  value,
  type,
  category,
  ...props
}) => {
  const rank = Number(value) || (5 as any);

  const opts = useMemo(() => {
    let disableRanks1to3 = true;
    let disableRank4 = true;

    if (
      type === definedRouteTypes.diasporeNews ||
      type === definedRouteTypes.videoNews ||
      type === definedRouteTypes.offers ||
      type === definedRouteTypes.organizationNews
    ) {
      disableRanks1to3 = false;
      disableRank4 = false;
    } else if (
      type === definedRouteTypes.editorialNews &&
      (category === CATEGORIES.politics ||
        category === CATEGORIES.education ||
        category === CATEGORIES.culture)
    ) {
      disableRanks1to3 = false;
      disableRank4 = false;
    } else if (type === definedRouteTypes.editorialNews) {
      disableRanks1to3 = false;
    } else if (type === definedRouteTypes.imageGallery) {
      disableRank4 = false;
    }

    return options(disableRank4, disableRanks1to3);
  }, [type, category]);

  return (
    <FormSelect
      {...props}
      value={rank}
      selectOnBlur={false}
      selectOnNavigation={false}
      options={opts}
    />
  );
};

export default memo(FormRankSelect);

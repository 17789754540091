import React from "react";
import styled from "styled-components";
import { Grid } from "semantic-ui-react";

export interface MainGridProps {
  columns: number;
  classId?: string;
}

// / Only global style
const FlexGrid = styled(Grid)<{}>({
  "&&&": {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    margin: "0",
    marginBottom: "1em",
    width: "100%",
    ".column": {
      textAlign: "initial",
    },
  },
});

const MainGrid: React.FC<MainGridProps> = ({ classId, children, columns }) => (
  <FlexGrid columns={columns} stackable className={classId}>
    {children}
  </FlexGrid>
);

export default MainGrid;

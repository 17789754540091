import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { FormField } from "semantic-ui-react";

import FormTextInput from "./FormTextInput";
import FormImageZone from "./FormImageZone";

import { getSrcFromIframe } from "../../../helpers/regexp";
import {
  IBaseFormFieldProps,
  TFormOnImageChange,
} from "../../../interfaces/forms";

interface FormVideoLinkProps
  extends IBaseFormFieldProps<string | File | undefined> {
  id: string;
  value: string;
  label?: string;
  required?: boolean;
  imageUrl?: string;
  changeFormImageValue: TFormOnImageChange<any>;
}

const FormVideoLink: React.FC<FormVideoLinkProps> = ({
  id,
  value,
  onChange,
  label,
  required,
  imageUrl,
  changeFormImageValue,
}) => {
  const updateMediaLink = (link: string) => {
    const mediaLink = getSrcFromIframe(link);
    if (mediaLink && onChange) {
      onChange(id, mediaLink);
    }
  };

  const { t } = useTranslation();
  return (
    <FormField>
      <FormTextInput
        id={id}
        value={value}
        onChange={onChange}
        onBlur={() => {
          updateMediaLink(value);
        }}
        required={required}
        label={label}
      />
      <FormImageZone
        id="imageUrl"
        required
        initialImgUrl={imageUrl}
        label={t("Forms.Fields.imagePointer")}
        onImageChange={changeFormImageValue}
        onChange={onChange}
        description={t("Forms.Descriptions.videoImage")}
      />
    </FormField>
  );
};

export default memo(FormVideoLink);

import React from "react";
import { useTranslation } from "react-i18next";
import { Dimmer, Loader } from "semantic-ui-react";

export interface DropzoneContainerProps {
  isLoading: boolean;
}

const DropzoneLoader: React.FC<DropzoneContainerProps> = ({ isLoading }) => {
  const { t } = useTranslation();
  return (
    <Dimmer active={isLoading}>
      <Loader>{t("CommonComponents.Dropzone.UploadingImage")}</Loader>
    </Dimmer>
  );
};

export default DropzoneLoader;

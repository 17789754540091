import PictureTable from "components/organisms/Tables/PictureTable";
import React, { useState } from "react";

import PictureDbFilters from "../organisms/PictureDbFilters/PictureDbFilters";

const genRandom = () => Math.floor(Math.random() * 10);

const PicturePage: React.FC = () => {
  const [curr, setCurr] = useState({
    key: genRandom(),
    filters: {},
  });
  const search = (filters: Record<string, any>) => {
    setCurr({ key: genRandom(), filters });
  };

  return (
    <>
      <PictureDbFilters filter={search} />
      <PictureTable filters={curr.filters} key={curr.key} />
    </>
  );
};

export default PicturePage;

/* eslint-disable no-unused-expressions */
import React, { Component, createRef } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { withTranslation, WithTranslation } from "react-i18next";

import { COUNTRY, COUNTRY_TITLE } from "../../../constants/country";

interface GoogleMapProps {
  address: string;
  country: COUNTRY;
  defaultGeolocation: { lat: number; lng: number };
  changeLocation: (value: { lat: number; lng: number }) => void;
}

const StyledMapContainer = styled.div({
  width: "inherit",
  height: "12.5em",
});

/**
 * works like geolocation input.
 * Takes address and / or country
 * Displays said address and calls changeLocation.
 *
 */
class GoogleMap extends Component<GoogleMapProps & WithTranslation> {
  googleMapRef = createRef<HTMLDivElement>();

  googleMap: google.maps.Map<HTMLDivElement> | undefined;

  marker: google.maps.Marker | undefined;

  geocoder: google.maps.Geocoder | undefined;

  googleMapScript: HTMLScriptElement | undefined;

  componentDidMount() {
    this.googleMapScript = document.createElement("script");
    this.googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}&libraries=places`;
    window.document.body.appendChild(this.googleMapScript);

    this.googleMapScript.addEventListener("load", this.createGoogleMap);
  }

  componentDidUpdate(prevProps: GoogleMapProps) {
    const { address, country, changeLocation, t } = this.props;
    if (prevProps.address === address && prevProps.country === country) return;

    const geocodeString = `${COUNTRY_TITLE[country]} ${address}`;

    this.geocoder?.geocode({ address: geocodeString }, (results, status) => {
      if (status === "OK" && results[0]) {
        const result = results[0];
        const position = {
          lat: result.geometry.location.lat(),
          lng: result.geometry.location.lng(),
        };
        changeLocation(position);
        this.googleMap?.setCenter(position);
        this.marker?.setPosition(position);
      } else {
        const position = {
          lat: 0,
          lng: 0,
        };
        changeLocation(position);
        this.googleMap?.setCenter(position);
        this.marker?.setPosition(position);
        toast.error(t("Forms.MapError"));
      }
    });
  }

  componentWillUnmount() {
    this.googleMapScript?.removeEventListener("load", this.createGoogleMap);
  }

  createGoogleMap = () => {
    if (this.googleMapRef.current === null) return;

    const { defaultGeolocation: geolocation } = this.props;

    this.googleMap = new window.google.maps.Map(this.googleMapRef.current, {
      zoom: 16,
      center: geolocation,
      disableDefaultUI: true,
    });
    this.marker = new window.google.maps.Marker({
      position: geolocation,
      map: this.googleMap,
    });
    this.geocoder = new window.google.maps.Geocoder();
  };

  render() {
    return <StyledMapContainer id="google-map" ref={this.googleMapRef} />;
  }
}
export default withTranslation()(GoogleMap);

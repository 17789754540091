import * as React from "react";
import { CSSObject } from "styled-components";

import {
  StyledLinkToArticleCard,
  StyledLinkToArticleCardLink,
} from "../../../styles/CardStyles";

export interface ILinkToArticleCardProps {
  route?: string;
  width?: string;
  maxWidth?: string;
  minWidth?: string;
  textAlign?: CSSObject["textAlign"];
  newTab?: boolean;
  hover?: boolean;
  title: string;
  id: string;
}

const LinkToArticleCard: React.FC<ILinkToArticleCardProps> = ({
  route,
  width,
  maxWidth,
  minWidth,
  children,
  textAlign,
  hover,
  id,
  title,
}) => {
  if (route) {
    return (
      <StyledLinkToArticleCard
        width={width}
        maxwidth={maxWidth}
        minwidth={minWidth}
        textAlign={textAlign}
        hover={hover}
      >
        <StyledLinkToArticleCardLink
          href={`https://www.latviesi.com${route}`}
          target="_blank"
          id={id}
          title={title}
        >
          {children}
        </StyledLinkToArticleCardLink>
      </StyledLinkToArticleCard>
    );
  }

  return (
    <StyledLinkToArticleCard
      width={width}
      maxwidth={maxWidth}
      minwidth={minWidth}
      textAlign={textAlign}
      id={id}
      title={title}
    >
      {children}
    </StyledLinkToArticleCard>
  );
};

LinkToArticleCard.defaultProps = {
  maxWidth: "auto",
  width: "auto",
};

export default LinkToArticleCard;

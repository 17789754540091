import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Menu, Segment, Input, Icon, Divider } from "semantic-ui-react";
import styled from "styled-components";
import { toast } from "react-toastify";
import { FixedSizeList as List } from "react-window";
import useAuthorizedContext from "hooks/useAuthorizedContext";

import Typography from "../Typography/Typography";
import { definedTypographyTypes } from "../Typography/TypographyStyles";

interface AdminMultiSelectProps {
  id: string;
  initialValues?: string[];
  onChange: (id: string, data: string[]) => void;
}

const StyledSegment = styled(Segment)({
  "&&&": {
    width: "50%",
  },
});

const AdminMultiSelect: React.FC<AdminMultiSelectProps> = ({
  id,
  initialValues,
  onChange,
}) => {
  const { orgData } = useAuthorizedContext();
  const [available] = useState(orgData);

  const [selected, setSelected] = useState(initialValues || []);
  const [searchParam, setSearchParam] = useState("");

  useEffect(() => {
    onChange(id, selected);
  }, [selected, onChange, id]);

  const onSearchChange = useCallback((i, d) => setSearchParam(d.value), []);

  const addItem = useCallback(
    (item: string) => () => {
      if (selected.length < 100) {
        setSelected((prev) => [...prev, item]);
      } else {
        toast.error(
          "Viens lietotājs nevar administrēt vairāk par 100 individuālām organizācijām. Varbūt šim lietotājam ir nepieciešams administratora tiesības?",
        );
      }
    },
    [selected],
  );
  const removeItem = useCallback(
    (item: string) => () => {
      setSelected((prev) => {
        const idx = prev.indexOf(item);
        const newArr = [...prev];
        if (idx !== -1) newArr.splice(idx, 1);
        return newArr;
      });
    },
    [],
  );
  const unselected = useMemo(() => {
    if (available) {
      return available.filter((el) => !selected.includes(el.slug));
    }

    return [];
  }, [available, selected]);
  const unselectedFiltered = useMemo(() => {
    if (unselected) {
      return unselected
        .filter((element) =>
          element.title.toLowerCase().includes(searchParam.toLowerCase()),
        )
        .sort((a, b) => a.title.localeCompare(b.title));
    }

    return [];
  }, [unselected, searchParam]);

  const UnselectedRow = ({ index, key, style }: any) => (
    <div key={key} style={style}>
      <Menu.Item
        disabled={!unselectedFiltered[index]}
        onClick={addItem(unselectedFiltered[index]?.slug)}
      >
        <div
          style={{
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            paddingRight: "10px",
            display: "inline-block",
            overflow: "hidden",
            width: "90%",
          }}
        >
          {unselectedFiltered[index]?.title}
        </div>
        <Icon name="arrow right" />
      </Menu.Item>
    </div>
  );

  return (
    <Segment.Group>
      <Segment.Group horizontal>
        <StyledSegment>
          <Input
            fluid
            icon="search"
            placeholder="Meklēt organizāciju..."
            value={searchParam}
            onChange={onSearchChange}
          />
        </StyledSegment>
        <StyledSegment>
          <Typography type={definedTypographyTypes.h3}>
            Lietotāja pārvaldītās organizācijas un uzņēmumi:
          </Typography>
        </StyledSegment>
      </Segment.Group>
      <Segment.Group horizontal>
        <Menu vertical fluid as={Segment} style={{ height: 350, padding: 0 }}>
          <List
            height={350}
            itemCount={unselectedFiltered.length}
            itemSize={40}
            width="100%"
          >
            {UnselectedRow}
          </List>
          <Divider fitted />
        </Menu>
        <Menu
          vertical
          fluid
          as={Segment}
          style={{ overflow: "auto", height: 350, padding: 0 }}
        >
          {selected.map((item) => {
            const selectedItem = available?.find((av) => av.slug === item);
            const title = selectedItem?.title || selectedItem;

            return (
              <Menu.Item key={item} onClick={removeItem(item)}>
                <div style={{ paddingLeft: "20px" }}>
                  <Icon name="arrow left" /> {title || item}
                </div>
              </Menu.Item>
            );
          })}
          <Divider fitted />
        </Menu>
      </Segment.Group>
    </Segment.Group>
  );
};

export default AdminMultiSelect;

import React from "react";

import FormRadioButton from "../../molecules/FormFields/FormRadioButton";

interface Props {
  onClick: () => void;
  value?: boolean;
}

const BooleanButtonCell: React.FC<Props> = ({ value, onClick }) => {
  const cellVal = value || false;

  return (
    <FormRadioButton id="show" label="" value={cellVal} onChange={onClick} />
  );
};

export default BooleanButtonCell;

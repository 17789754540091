import MainFooter from "components/organisms/MainFooter/MainFooter";
import MainHeader from "components/organisms/MainHeader/MainHeader";
import React from "react";
import styled from "styled-components";

import LogInForm from "../organisms/Forms/LogInForm/LogInForm";

interface Props {}

const Container = styled.div({
  maxWidth: "400px",
  marginLeft: "auto",
  marginRight: "auto",
  marginTop: "50px",
});

export const StyledFrame = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 80vh;
`;

export const StyledFrame2 = styled.div`
  flex: 1 0 auto;
  margin: 0 2em;
`;

const LogInPage: React.FC<Props> = () => {
  return (
    <MainHeader>
      <StyledFrame>
        <StyledFrame2>
          <Container>
            <LogInForm />
          </Container>
        </StyledFrame2>
      </StyledFrame>
      <MainFooter />
    </MainHeader>
  );
};

export default LogInPage;

export var AUTHOR_TYPE;
(function (AUTHOR_TYPE) {
    AUTHOR_TYPE["organization"] = "organization";
    AUTHOR_TYPE["company"] = "company";
    AUTHOR_TYPE["author"] = "author";
    AUTHOR_TYPE["anotherAuthor"] = "anotherAuthor";
    AUTHOR_TYPE["none"] = "none";
})(AUTHOR_TYPE || (AUTHOR_TYPE = {}));
export var CONTENT_STATUS;
(function (CONTENT_STATUS) {
    CONTENT_STATUS["active"] = "active";
    CONTENT_STATUS["toBePosted"] = "toBePosted";
    CONTENT_STATUS["archived"] = "archived";
})(CONTENT_STATUS || (CONTENT_STATUS = {}));
export var ROUTE_TYPES;
(function (ROUTE_TYPES) {
    ROUTE_TYPES["upload"] = "upload";
    ROUTE_TYPES["editorialNews"] = "jaunumi";
    ROUTE_TYPES["diasporeNews"] = "mediju-jaunumi";
    ROUTE_TYPES["organizationNews"] = "organizaciju-jaunumi";
    ROUTE_TYPES["events"] = "notikumi";
    ROUTE_TYPES["pastEvents"] = "aizvaditie-notikumi";
    ROUTE_TYPES["imageGallery"] = "attelu-galerijas";
    ROUTE_TYPES["videoNews"] = "video-galerijas";
    ROUTE_TYPES["offers"] = "piedavajumi";
    ROUTE_TYPES["organizations"] = "organizacijas";
    ROUTE_TYPES["search"] = "search";
    ROUTE_TYPES["dynamic"] = "dynamic";
    ROUTE_TYPES["footer"] = "static";
    ROUTE_TYPES["newsletter"] = "newsletter";
    ROUTE_TYPES["orgSlugs"] = "orgSlugs";
    ROUTE_TYPES["pictures"] = "pictures";
    ROUTE_TYPES["companies"] = "uznemumi";
    ROUTE_TYPES["selfEmployed"] = "profesionali";
    ROUTE_TYPES["companyNews"] = "uznemumu-jaunumi";
    ROUTE_TYPES["SOURCE_MEDIA"] = "mediju-avoti";
    ROUTE_TYPES["LINK_MEDIA"] = "mediju-saites";
})(ROUTE_TYPES || (ROUTE_TYPES = {}));
export var MEDIA;
(function (MEDIA) {
    MEDIA["facebook"] = "facebook";
    MEDIA["twitter"] = "twitter";
    MEDIA["google"] = "google";
    MEDIA["instagram"] = "instagram";
    MEDIA["youtube"] = "youtube";
    MEDIA["linkedin"] = "linkedin";
    MEDIA["website"] = "website";
})(MEDIA || (MEDIA = {}));

import styled from "styled-components";
import { List, Image, Grid, Button } from "semantic-ui-react";

import theme, { styledWithTheme } from "../../../styles/theme";

export const StyledImage = styled(Image)`
  &&& {
    margin: auto;
  }
`;

export const StyledFooter = styled.div`
   {
    padding: 2em;
    flex-shrink: 0;
    background-color: ${theme.palette.mono.darker};
  }
`;

export const StyledList = styled(List)`
  &&& {
    margin: auto;
  }
`;

export const StyledListMobile = styled(List)`
  &&& {
    margin: auto;
    text-align: center;
  }
`;

export const StyledContainer = styled.div`
  &&& {
    margin-top: 30px;
  }
`;

export const StyledAdjusted = styled.div`
  &&& {
    position: relative;
    top: -20px;
    margin-right: 10%;
  }
`;
export const StyledGrid = styled(Grid)`
  &&& {
    width: 100%;
  }
`;

export const StyledButton = styledWithTheme(Button)`
  &&& {
    background: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.mono.white};
  }
`;

import React, { FC, MouseEventHandler } from "react";

export interface SideToolbarButtonProps {
  className?: string;
}

// TODO move me to a separate plugin
const SideToolbarButton: FC<SideToolbarButtonProps> = ({ className }) => {
  const preventPropagate: MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div className={className} onMouseDown={preventPropagate} id="CATCHME">
      <svg
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
      </svg>
    </div>
  );
};

export default SideToolbarButton;

import {
  AWSurl,
  handleApiGet,
  handleApiPost,
  handleApiPut,
  definedRouteTypes,
} from "./api";
import { getSingleData } from "./DynamicApi";

import { queryStringBuilder } from "../helpers/globalFunctions";

import { IOrganizationForm, IFilters } from ".";

const ORGANIZATIONS_URL = `${AWSurl}/${definedRouteTypes.organizations}`;

export function getOrganizations(opts?: IFilters) {
  const query = queryStringBuilder(opts);
  return handleApiGet(`${ORGANIZATIONS_URL}${query}`);
}

export function getSingleOrganization(slug: string) {
  return getSingleData(definedRouteTypes.organizations, slug);
}

export function postOrganizations(idToken: string, body: IOrganizationForm) {
  return handleApiPost(`${ORGANIZATIONS_URL}/create`, idToken, body);
}

export function updateOrganizations(
  idToken: string,
  body: Partial<IOrganizationForm>,
) {
  return handleApiPut(ORGANIZATIONS_URL, idToken, body);
}

export function getOrganizationUrls() {
  return handleApiGet(`${AWSurl}/orgslugs`);
}

export function postOrganizationUrls(
  idToken: string,
  items: { name: string; pseudonym: string }[],
) {
  const body = {
    items,
  };
  return handleApiPost(`${AWSurl}/orgslugs`, idToken, body);
}

export function updateOrganizationSlugs(
  idToken: string,
  items: { shortSlug: string; slug: string }[],
) {
  const body = {
    items,
  };
  return handleApiPut(`${AWSurl}/orgslugs`, idToken, body);
}

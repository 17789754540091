import React, { ChangeEvent, memo } from "react";
import { FormField } from "semantic-ui-react";

import { IBaseFormFieldProps } from "../../../interfaces/forms";

export type FormTextAreaProps = IBaseFormFieldProps<string>;

// TODO: controlled @Agnese

const FormTextArea: React.FC<FormTextAreaProps> = ({
  onChange,
  id,
  label,
  required,
  ...props
}) => {
  const changeFn = (event: ChangeEvent<HTMLTextAreaElement>) => {
    if (onChange) onChange(id, event.target.value);
  };

  return (
    <FormField required={required}>
      {label && <label htmlFor={id}>{label}</label>}
      <textarea {...props} id={id} required={required} onChange={changeFn} />
    </FormField>
  );
};

export default memo(FormTextArea);

import React from "react";
import { useRouteMatch, Redirect } from "react-router-dom";

import { definedRouteTypes } from "../../api/api";
import TypeDataTable from "../organisms/Tables/TypeDataTable";
import TypeTableContextProvider from "../../context/TypeTableContextProvider";

interface Props {}

interface IParams {
  type: definedRouteTypes;
}

const TypeTablePage: React.FC<Props> = () => {
  const match = useRouteMatch<IParams>("/:type");

  if (match === null) return <Redirect to="/" />;

  const { type } = match.params;

  return (
    <TypeTableContextProvider type={type}>
      <TypeDataTable type={type} />
    </TypeTableContextProvider>
  );
};

export default TypeTablePage;

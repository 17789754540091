import React, { useState } from "react";
import { Form, Divider } from "semantic-ui-react";

import Typography, {
  definedTypography,
} from "../../atoms/Typography/Typography";
import FormGrid from "../../templates/FormGrid";
import FormTextInput from "../../molecules/FormFields/FormTextInput";
import SimpleButton from "../../atoms/Buttons/SimpleButton/SimpleButton";
import { hasWhiteSpace, slugifyWord } from "../../../helpers/globalFunctions";

interface Props {
  onClose: any;
  onSubmit: any;
}

const OrgSlugsForm: React.FC<Props> = ({ onClose, onSubmit }) => {
  const [values, setValues] = useState({ name: "", pseudonym: "" });
  const { name, pseudonym } = values;

  const changeFormValue = (id: any, data: any) => {
    let setData = data;
    if (id === "name") {
      // check for whitespaces
      setData = hasWhiteSpace(data) ? slugifyWord(data) : data;
    }
    setValues((prevState) => ({ ...prevState, [id]: setData }));
  };

  return (
    <Form name="editorialNewsForm">
      <Typography type={definedTypography.h1} as="span">
        Pievienot jaunu organizācijas īso saiti
      </Typography>
      <Divider />
      <FormGrid label="Info">
        <FormTextInput
          id="name"
          required
          value={name}
          label="īsā saite"
          placeholder="isa-saite"
          onChange={changeFormValue}
        />
        <FormTextInput
          id="pseudonym"
          required
          value={pseudonym}
          placeholder="pilna-organizacijas-saite"
          label="pilnā saite"
          onChange={changeFormValue}
        />
      </FormGrid>
      <SimpleButton onClick={() => onClose(false)}>Atcelt</SimpleButton>
      <SimpleButton onClick={() => onSubmit(values)}>Saglabāt</SimpleButton>
    </Form>
  );
};
export default OrgSlugsForm;

import {
  AWSurl,
  handleApiPost,
  definedRouteTypes,
  ContentStatusTypes,
  handleApiPut,
  handleApiGet,
  handleApiDelete,
} from "./api";

import { IKeyRow } from "../components/pages/NewsletterMakerPage";

const newsLetterUrl = `${AWSurl}/${definedRouteTypes.newsletter}`;

export interface INewsLetter {
  title: string;
  slug: string;
  items: IKeyRow[];
  status: ContentStatusTypes;
  show: number;
  createdAt?: string;
}
export default function postNewsletter(idToken: string, body: INewsLetter) {
  return handleApiPost(newsLetterUrl, idToken, body);
}

export function getSingleNewsletter(slug: string) {
  return handleApiGet(
    `${newsLetterUrl}?type=${definedRouteTypes.newsletter}&slug=${slug}`,
  );
}

export function getActiveNewsletter() {
  return handleApiGet(`${newsLetterUrl}`);
}

export function publishLatestNewsletter(idToken: string, body: INewsLetter) {
  return handleApiPut(newsLetterUrl, idToken, { slug: body.slug });
}

export function deleteNewsletter(idToken: string, body: INewsLetter) {
  return handleApiDelete(newsLetterUrl, idToken, body.slug);
}

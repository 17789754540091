import React, { useMemo } from "react";
import { Cell } from "react-table";

import { IKeyRow } from "../../pages/NewsletterMakerPage";
import UncontrolledTable from "../../templates/Tables/UncontrolledTable";
import BooleanButtonCell from "../../atoms/Cells/BooleanButtonCell";
import { definedAccessors } from "../../../helpers/tableHelpers";

interface Props {
  loading: boolean;
  selected: IKeyRow[];
  all: IKeyRow[];
  toggleSelected: (value: IKeyRow, currentSelectedIndex: number) => void;
}

const columns = [
  definedAccessors.action,
  definedAccessors.title,
  definedAccessors.type,
  definedAccessors.imageUrl,
];

const LimitTable: React.FC<Props> = ({
  loading,
  all,
  selected,
  toggleSelected,
}) => {
  const actionColumn = useMemo(() => {
    return {
      Header: "Izcelts",
      Cell: (cell: Cell<IKeyRow>) => {
        const { original } = cell.row;
        const { id } = original;

        const selectedIndex = selected.findIndex((item) => item.id === id);
        const isSelected = selectedIndex !== -1;

        return (
          <BooleanButtonCell
            value={isSelected}
            onClick={() => toggleSelected(original, selectedIndex)}
          />
        );
      },
    };
  }, [selected, toggleSelected]);

  return (
    <UncontrolledTable
      loading={loading}
      actionColumn={actionColumn}
      data={all}
      columns={columns}
      initialPageSize={10}
      initialSortBy={[{ id: "localeCategory", desc: false }]}
    />
  );
};

export default LimitTable;

import { RawDraftContentState } from "draft-js";

import { IStaticInfo } from "../../api/index.d";

export enum definedTemplateSections {
  newsShowcase = "newsShowcase",
  eventsShowcase = "eventsShowcase",
  contactCard = "contactCard",
  text = "text",
  media = "media",
  contactList = "contactList",
  richText = "richText",
  associationTitle = "associationTitle",
  mediaTitle = "mediaTitle",
  organizationTitle = "organizationTitle",
  defaultTitle = "defaultTitle",
}

interface ITemplateSection {
  type: definedTemplateSections;
  showSection: boolean;
  title?: string;
  description?: string;
  body?: RawDraftContentState;
  imageUrl?: string;
  contacts?: IStaticInfo[];
  mediaUrl?: string;
  customLinkTitle?: string;
  customLinkUrl?: string;
}

export default ITemplateSection;

import { useContext } from "react";

import GlobalStateContext, {
  IGlobalStateContext,
} from "../context/GlobalContext";

const useGlobalContext = (): IGlobalStateContext => {
  const context = useContext(GlobalStateContext);
  if (context === null) {
    throw new Error("useGlobalContext cannot be used without Provider!");
  }

  return context;
};

export default useGlobalContext;

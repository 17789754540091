import React, { MouseEvent, useCallback, useMemo, useState } from "react";
import { Form, Divider } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { convertToRaw, ContentState } from "draft-js";
import styled from "styled-components";
import useAuthorizedContext from "hooks/useAuthorizedContext";

import FormTextInput from "../../molecules/FormFields/FormTextInput";
import FormSelect from "../../molecules/FormFields/FormSelect";
import FormDateTimeInput from "../../molecules/FormFields/FormDateTimeInput";
import useForm from "../../../hooks/useForm";
import FormMultiSelect from "../../molecules/FormFields/FormMultiSelect";
import FormGrid from "../../templates/FormGrid";
import FormRichTextArea from "../../molecules/FormFields/FormRichTextArea";
import FormImageZone from "../../molecules/FormFields/FormImageZone";
import { categoryOpts } from "../../../constants/categories";
import FormGeolocationFields from "../../molecules/FormFields/FormGeolocationInput";
import Typography, {
  definedTypography,
} from "../../atoms/Typography/Typography";
import { IEventsForm } from "../../../api";
import { definedRouteTypes } from "../../../api/api";
import FormRadioButton from "../../molecules/FormFields/FormRadioButton";
import SimpleButton, {
  definedButtonTypes,
} from "../../atoms/Buttons/SimpleButton/SimpleButton";
import FormSourceField from "../../molecules/FormFields/FormSourceField";
import FormAuthorFields, {
  definedAuthorTypes,
} from "../../molecules/FormFields/FormAuthorFields";
import { IBaseFormProps } from "../../../interfaces/forms";
import FormKeywordSelect from "../../molecules/FormFields/FormKeywordSelect";
import useLocalizedCountryOpts from "../../../hooks/useLocalizedCountryOpts";
import useSubmitRankedPosts from "../../../hooks/useSubmitRankedPosts";

interface EventsFormProps extends IBaseFormProps {
  update?: boolean;
  initialValues?: IEventsForm;
  onFormClose?: (event?: MouseEvent) => void;
  type?: definedRouteTypes.events;
}

const StyledDiv = styled.div({
  textAlign: "center",
});

const EventsForm: React.FC<EventsFormProps> = ({
  initialValues,
  onFormClose,
  update,
}) => {
  const { t } = useTranslation();
  const { user } = useAuthorizedContext();
  const [isSubmitted, setSubmitted] = useState(update);

  const CountryOptions = useLocalizedCountryOpts();
  const defaultInitValues = useCallback((): IEventsForm => {
    return {
      title: "",
      slug: "",
      description: convertToRaw(ContentState.createFromText("")),
      imagePointer: undefined,
      category: undefined,
      authorType: definedAuthorTypes.none,
      author: user.email,
      creator: user.username,
      creatorName: user.displayName(),
      organizationId: "",
      organizationInfo: undefined,
      subCategories: [],
      highlighted: false,
      highlightedInCountry: false,
      exclusive: false,
      show: true,
      countries: [],
      address: undefined,
      source: { name: "", url: "" },
      toBePostedAt: undefined,
      dateFrom: undefined,
      dateTo: undefined,
      type: definedRouteTypes.events || definedRouteTypes.pastEvents,
      displayInHomePage: false,
      online: false,
    };
  }, [user]);

  const submitFormQuery = useSubmitRankedPosts<IEventsForm>(isSubmitted, true);

  const defaultInitialValues = useMemo(
    () => defaultInitValues(),
    [defaultInitValues],
  );

  const {
    values,
    submitForm,
    changeFormValue,
    changeFormImageValue,
    isLoading,
  } = useForm(initialValues || defaultInitialValues, submitFormQuery);
  const {
    title,
    description,
    category,
    subCategories,
    address,
    dateFrom,
    dateTo,
    toBePostedAt,
    source,
    show,
    exclusive,
    highlighted,
    displayInHomePage,
    countries,
    customLabel,
    imageUrl,
    authorType,
    author,
    organizationId,
    anotherAuthor,
    imageAuthor,
    online,
  } = values;

  const formTitle = isSubmitted
    ? t("Forms.Titles.EditHeader")
    : t("Forms.Titles.AddHeader");
  const submit = isSubmitted
    ? t("Forms.Actions.Update")
    : t("Forms.Actions.Create");
  const submitAndClose = isSubmitted
    ? t("Forms.Actions.UpdateAndClose")
    : t("Forms.Actions.CreateAndClose");

  const convertedShow = Boolean(Number(show));
  const convertedDisplay = Boolean(Number(displayInHomePage));

  return (
    <Form name="editorialNewsForm" loading={isLoading}>
      <Typography type={definedTypography.h1} as="span">
        {formTitle}
      </Typography>
      <Divider />
      <FormGrid label={t("Administrēšana")}>
        <FormRadioButton
          id="show"
          label="Redzams portālā"
          value={convertedShow}
          onChange={changeFormValue}
        />
        <FormRadioButton
          id="displayInHomePage"
          label="Rādīt sākumlapā"
          value={convertedDisplay}
          onChange={changeFormValue}
        />
        <FormRadioButton
          id="exclusive"
          label="Tikai Latvieši.com"
          value={exclusive}
          onChange={changeFormValue}
        />
        <FormRadioButton
          id="highlighted"
          label="Izcelts"
          value={highlighted}
          onChange={changeFormValue}
        />
      </FormGrid>
      <FormGrid label={t("Forms.Titles.Content")}>
        <FormTextInput
          id="title"
          required
          value={title}
          label={t("Forms.Fields.title")}
          onChange={changeFormValue}
        />
        <FormDateTimeInput
          id="dateFrom"
          required
          timeLabel={t("Forms.Fields.dateFromTime")}
          dateLabel={t("Forms.Fields.dateFromDate")}
          value={dateFrom}
          onChange={changeFormValue}
          showUTC
        />
        <FormDateTimeInput
          id="dateTo"
          required
          timeLabel={t("Forms.Fields.dateToTime")}
          dateLabel={t("Forms.Fields.dateToDate")}
          value={dateTo}
          onChange={changeFormValue}
          showUTC
        />
        <FormImageZone
          id="imageUrl"
          required
          initialImgUrl={imageUrl}
          label={t("Forms.Fields.imagePointer")}
          onChange={changeFormValue}
          onImageChange={changeFormImageValue}
          description={t("Forms.Descriptions.eventsImage")}
        />
        <FormRichTextArea
          id="description"
          required
          label={t("Forms.Fields.description")}
          value={description}
          onChange={changeFormValue}
        />
      </FormGrid>
      <FormGrid label={t("Forms.Titles.Author")}>
        <FormAuthorFields
          authorType={authorType}
          author={author}
          organizationId={organizationId}
          anotherAuthor={anotherAuthor}
          changeFormValue={changeFormValue}
        />
      </FormGrid>
      <FormGrid label={t("Forms.Titles.Category")}>
        <FormSelect
          label={t("Forms.Fields.category")}
          id="category"
          value={category}
          options={categoryOpts}
          onChange={changeFormValue}
          search
        />
        <FormKeywordSelect
          label={t("Forms.Fields.subCategories")}
          id="subCategories"
          value={subCategories || []}
          onChange={changeFormValue}
          selectedCategory={category}
        />
        <FormMultiSelect
          id="countries"
          label={t("Forms.Fields.countries")}
          options={CountryOptions}
          value={countries}
          onChange={changeFormValue}
          search
        />
      </FormGrid>
      <FormGrid label={t("Forms.Titles.Place")}>
        <FormRadioButton
          id="online"
          label={t("Forms.Fields.Online")}
          value={online}
          onChange={changeFormValue}
        />
        <FormGeolocationFields
          id="address"
          value={address}
          onChange={changeFormValue}
        />
      </FormGrid>
      <FormGrid label={t("Forms.Titles.Settings")}>
        <FormDateTimeInput
          id="toBePostedAt"
          timeLabel={t("Forms.Fields.toBePostedAtTime")}
          dateLabel={t("Forms.Fields.toBePostedAtDate")}
          value={toBePostedAt}
          onChange={changeFormValue}
        />
        <FormSourceField
          id="source"
          value={source}
          onChange={changeFormValue}
        />
        <FormTextInput
          id="imageAuthor"
          label={t("Forms.Fields.imageAuthor")}
          value={imageAuthor}
          onChange={changeFormValue}
        />
        <FormTextInput
          id="customLabel"
          label={t("Forms.Fields.customLabel")}
          value={customLabel}
          onChange={changeFormValue}
          description="Ja nenorāda birku, notikumiem automātiski tiek parādīta tā kategorija."
        />
      </FormGrid>
      <StyledDiv>
        {onFormClose && (
          <SimpleButton
            id="FormCancel"
            variant={definedButtonTypes.secondary}
            onClick={onFormClose}
          >
            {t("Forms.Actions.Cancel")}
          </SimpleButton>
        )}
        <SimpleButton
          id="FormSubmit"
          onClick={async (event) => {
            // TODO this is quickfix to enable updating...
            await submitForm(event, (res) => {
              setSubmitted(true);
              changeFormValue("slug", res ? res.slug : "");
            });
          }}
          variant={definedButtonTypes.secondary}
        >
          {submit}
        </SimpleButton>
        <SimpleButton
          id="FormSubmitAndClose"
          onClick={async (event) => {
            await submitForm(event, () => {
              if (onFormClose) onFormClose();
            });
          }}
          variant={definedButtonTypes.primary}
        >
          {submitAndClose}
        </SimpleButton>
      </StyledDiv>
    </Form>
  );
};

EventsForm.defaultProps = {
  type: definedRouteTypes.events,
};

export default EventsForm;

import React from "react";

interface Props {
  cell: { value?: string };
}

const TextCell: React.FC<Props> = ({ cell: { value } }) => {
  return <>{value || "-"}</>;
};

export default TextCell;

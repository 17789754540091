import {
  AWSRootUrl,
  DeleteSettings,
  handleApi,
  handleApiGet,
  handleApiPut,
} from "./api";
import CognitoUserAttrs from "./cognito/CognitoUserAttrs";
import { BackendUrl } from "./newBackendApi";

const URL_PERMISSIONS = `${BackendUrl}/admin/permissions`;
const URL_LIST_USERS = `${BackendUrl}/admin/listUsers`;
const URL_DELETE_USER = `${AWSRootUrl}/userorgs`;

type IUser = ReturnType<InstanceType<typeof CognitoUserAttrs>["format"]>;

interface IUserList {
  users: IUser[];
  PaginationToken: string;
}

export async function listAllUsers(
  idToken: string,
  query: {
    paginationToken?: string;
    limit?: number;
  } = {},
): Promise<IUserList> {
  const params = Object.keys(query).map(
    (key) => `${key}=${encodeURIComponent((query as any)[key])}`,
  );
  return handleApiGet(`${URL_LIST_USERS}?${params.join("&")}`, {
    Authorization: idToken,
  });
}

// TODO preferably returns a new user object
export async function updateUserPermissions(
  idToken: string,
  username: string,
  groups: string[],
  role: string,
) {
  return handleApiPut(URL_PERMISSIONS, idToken, { username, groups, role });
}

// TODO move to new BE
export async function DeleteUser(idToken: string, username: string) {
  const settings = DeleteSettings({ username }, idToken);
  return handleApi(URL_DELETE_USER, settings);
}

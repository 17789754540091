import * as React from "react";
import { StrictLabelProps } from "semantic-ui-react";

import { StyledAttachedLabel } from "../../../styles/CardStyles";

interface AttachedLabelProps {
  attached?: StrictLabelProps["attached"];
  type?: definedAttachedLabelTypes;
}

export enum definedAttachedLabelTypes {
  category = "category",
  imageGallery = "imageGallery",
}

const AttachedLabel: React.FC<AttachedLabelProps> = ({
  attached,
  children,
  type,
}) => {
  return (
    <StyledAttachedLabel type={type} attached={attached}>
      {children}
    </StyledAttachedLabel>
  );
};

AttachedLabel.defaultProps = {
  attached: "bottom left",
  type: definedAttachedLabelTypes.category,
};

export default AttachedLabel;

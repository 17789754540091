import { KeyboardEvent } from "react";
import { EditorPlugin } from "@draft-js-plugins/editor";
import {
  DefaultDraftBlockRenderMap,
  EditorState,
  getDefaultKeyBinding,
  Modifier,
  RichUtils,
} from "draft-js";

import * as types from "./constants";

const { CHANGETYPE_COMMAND_NEW_LINE, CHANGETYPE_COMMAND_NEW_PARAGRAPH } = types;

export interface ParagraphPluginConfig {}

export interface ParagraphPlugin extends EditorPlugin {
  types: typeof types;
}

const blockRenderMap = DefaultDraftBlockRenderMap.set("unstyled", {
  element: "p",
});

const handleKeyCommand: EditorPlugin["handleKeyCommand"] = (
  command,
  editorState,
  eventTimeStamp,
  { setEditorState },
) => {
  let newEditorState: EditorState | null = null;

  if (command === CHANGETYPE_COMMAND_NEW_PARAGRAPH) {
    const currentContent = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    const textWithEntity = Modifier.splitBlock(currentContent, selection);

    newEditorState = EditorState.push(
      editorState,
      textWithEntity,
      CHANGETYPE_COMMAND_NEW_LINE,
    );
  } else if (command === CHANGETYPE_COMMAND_NEW_LINE) {
    newEditorState = RichUtils.insertSoftNewline(editorState);
  }

  if (newEditorState) {
    setEditorState(newEditorState);
    return "handled";
  }
  return "not-handled";
};

const keyBindingFn: EditorPlugin["keyBindingFn"] = (
  e: KeyboardEvent,
): string | null | undefined => {
  const defaultCommand = getDefaultKeyBinding(e);
  if (defaultCommand === CHANGETYPE_COMMAND_NEW_LINE && !e.shiftKey) {
    return CHANGETYPE_COMMAND_NEW_PARAGRAPH;
  }
  return defaultCommand;
};

export default function createParagraphPlugin(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  config: ParagraphPluginConfig = {},
): ParagraphPlugin {
  return {
    blockRenderMap,
    keyBindingFn,
    handleKeyCommand,
    types,
  };
}

import React, { FormEvent, memo } from "react";
import { Checkbox, CheckboxProps, FormField } from "semantic-ui-react";

import { IBaseFormFieldProps } from "../../../interfaces/forms";

// TODO: controlled @Agnese

export interface FormCheckboxProps extends IBaseFormFieldProps<boolean> {
  checkboxLabel: string; // TODO something more elegant than this
  toggle?: boolean;
}

const FormCheckbox: React.SFC<FormCheckboxProps> = ({
  id,
  label,
  checkboxLabel,
  onChange,
  value,
  required,
  ...props
}) => {
  const changeFn = (event: FormEvent, data: CheckboxProps) => {
    if (onChange) onChange(id, data.checked || false);
  };

  return (
    <FormField required={required}>
      {label && <label htmlFor={id}>{label}</label>}
      <Checkbox
        {...props}
        checked={value}
        required={required}
        id={id}
        onChange={changeFn}
        label={checkboxLabel}
      />
    </FormField>
  );
};

export default memo(FormCheckbox);

import { IStaticPageData } from "./index.d";
import {
  AWSurl,
  handleApiGet,
  handleApiPost,
  definedFooterRoutes,
} from "./api";

const staticPageGet = (type: string) => `${AWSurl}/${type}/static`;
const staticPage = (type: string) => `${AWSurl}/${type}/static`;

export function getStaticData(type: string) {
  return handleApiGet(staticPageGet(type));
}

export function updateStaticData(
  idToken: string,
  type: definedFooterRoutes,
  body: IStaticPageData,
) {
  return handleApiPost(staticPage(type), idToken, body);
}

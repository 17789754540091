import React, { useState, useEffect } from "react";
import { RawDraftContentState, ContentState, convertToRaw } from "draft-js";
import { useTranslation } from "react-i18next";
import { Form, Loader } from "semantic-ui-react";
import { toast } from "react-toastify";
import FormRichTextArea from "components/molecules/FormFields/FormRichTextArea";
import useAuthorizedContext from "hooks/useAuthorizedContext";

import { IStaticInfo } from "../../../api";
import SimpleButton, {
  definedButtonTypes,
} from "../../atoms/Buttons/SimpleButton/SimpleButton";
import { definedFooterRoutes } from "../../../api/api";
import { getStaticData, updateStaticData } from "../../../api/staticPageApi";

interface Props {
  type: definedFooterRoutes;
}

const BasicFooterPanel: React.FC<Props> = ({ type }) => {
  const [loading, setLoading] = useState(true);
  const { token } = useAuthorizedContext();
  const [editorState, setEditorState] = useState<RawDraftContentState>(
    convertToRaw(ContentState.createFromText("")),
  );

  const { t } = useTranslation();

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;
    getStaticData(type)
      .then((data) => {
        if (signal.aborted) return;
        const { description } = data[0];
        setEditorState(description);
        setLoading(false);
      })
      .catch((error: Error) => {
        console.error("error: ", error);
      });
    return () => {
      abortController.abort();
    };
  }, [type]);

  async function handleSubmit() {
    let title = "title";
    if (type === definedFooterRoutes.about) {
      title = "Par Latviesi.com";
    }
    const info: IStaticInfo = {
      type,
      address: undefined,
      highlighted: false,
      show: true,
      displayInHomePage: false,
      slug: type,
      title,
      email: [],
      phone: [],
      description: editorState,
      imageUrl: "",
    };
    const idToken = await token();
    updateStaticData(idToken, type, info)
      .then(() => {
        toast.success(t("Footer.Success.Update"));
      })
      .catch(() => {
        toast.error(t("Footer.Error.Update"));
      });
  }

  if (loading) return <Loader active inline="centered" />;
  return (
    <Form>
      <FormRichTextArea
        id={`${type}-editor`}
        value={editorState}
        onChange={(id, e) => setEditorState(e)}
      />
      <SimpleButton
        variant={definedButtonTypes.action}
        onClick={() => handleSubmit()}
      >
        {t("Footer.SaveChanges")}
      </SimpleButton>
    </Form>
  );
};

export default BasicFooterPanel;

import * as React from "react";
import { Image, StrictImageProps, Icon } from "semantic-ui-react";
import moment, { Moment } from "moment";
import styled from "styled-components";

import MagnifyImageIcon from "../../atoms/Icons/MagnifyImageIcon";
import { CATEGORIES } from "../../../constants/categories";
import AttachedLabel, {
  definedAttachedLabelTypes,
} from "../../atoms/Labels/AttachedLabel";
import { styledWithTheme } from "../../../styles/theme";
import EventDate from "../Cards/EventDate";
import { PLACEHOLDER_16_TO_9 } from "../../../images/imageSources";
import TildeTranslate from "../../atoms/Text/TildeTranslate";

export interface IEventImageProps {
  imageUrl?: string;
  size?: StrictImageProps["size"];
  category?: CATEGORIES;
  dateFrom?: Moment;
  height?: string;
  hasHoverOverlay?: boolean;
  gallery?: boolean;
  video?: boolean;
  centeredDate?: boolean;
  translate?: boolean;
}

// TODO: move to stlyes @Elvijs

const StyledImageContainer = styled.div`
   {
    position: relative;
    min-width: 1.25em;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover .hoverUnderlay {
      opacity: 0.3;
    }
    &:hover .hoverOverlay {
      opacity: 1;
    }
  }
`;

const StyledImage = styled(Image)<{ height: string }>(({ height }) => ({
  "&&&": {
    height,
    objectFit: "cover",
    opacity: "1",
    transition: "0.5s ease",
    backfaceVisibility: "hidden",
    display: "flex",
    justifyContent: "left",
    alignItems: "flex-start",
  },
}));

const StyledOverlay = styled.div`
   {
    transition: 0.5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }
`;
const StyledPlayButton = styledWithTheme.div(({ theme }) => ({
  position: "absolute",
  height: "4em",
  width: "4em",
  background: theme.palette.primary.main,
  opacity: "0.8",
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  ".icon": {
    color: theme.palette.mono.white,
    fontSize: "1.5rem",
    margin: "0",
  },
}));
const EventImage: React.FC<IEventImageProps> = ({
  imageUrl,
  size,
  category,
  dateFrom,
  hasHoverOverlay,
  height,
  gallery,
  video,
  centeredDate,
  translate,
}) => {
  return (
    <StyledImageContainer>
      <StyledImage
        className={hasHoverOverlay ? "hoverUnderlay" : undefined}
        src={imageUrl || PLACEHOLDER_16_TO_9}
        size={size}
        height={height}
        fluid
      />

      {dateFrom && (
        <EventDate
          day={moment(dateFrom).format("DD")}
          month={moment(dateFrom).format("MMM")}
          absolutePosition={centeredDate}
        />
      )}

      {gallery && (
        <AttachedLabel
          type={definedAttachedLabelTypes.imageGallery}
          attached="top right"
        >
          <Icon name="images outline" />
        </AttachedLabel>
      )}
      {video && (
        <StyledPlayButton>
          <Icon name="play" />
        </StyledPlayButton>
      )}
      {category && (
        <AttachedLabel>
          <TildeTranslate text={category} translate={translate} />
        </AttachedLabel>
      )}
      {hasHoverOverlay && (
        <StyledOverlay className="hoverOverlay">
          <MagnifyImageIcon />
        </StyledOverlay>
      )}
    </StyledImageContainer>
  );
};

export default EventImage;

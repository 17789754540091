import React, { ReactElement, MutableRefObject, ComponentType } from "react";
import { PluginFunctions, EditorPlugin } from "@draft-js-plugins/editor";
import { createStore, Store } from "@draft-js-plugins/utils";
import { ContentBlock, EditorState } from "draft-js";

import AlignmentTool from "./AlignmentTool";
import createDecorator from "./createDecorator";

export interface VIAlignmentPluginConfig {}
export interface VIAlignmentPlugin extends EditorPlugin {
  decorator: ReturnType<typeof createDecorator>;
  AlignmentTool: ComponentType;
}

const createSetAlignment = (
  contentBlock: ContentBlock,
  {
    getEditorState,
    setEditorState,
  }: {
    setEditorState(editorState: EditorState): void; // a function to update the EditorState
    getEditorState(): EditorState; // a function to get the current EditorState
  },
) => (data: Record<string, unknown>) => {
  const entityKey = contentBlock.getEntityAt(0);
  if (entityKey) {
    const editorState = getEditorState();
    const contentState = editorState.getCurrentContent();
    contentState.mergeEntityData(entityKey, { ...data });
    setEditorState(
      EditorState.forceSelection(editorState, editorState.getSelection()),
    );
  }
};

interface StoreItemMap {
  isVisible?: boolean;
  getReadOnly?(): boolean;
  getEditorState?(): EditorState;
  setEditorState?(editorState: EditorState): void;
  visibleBlock?: null | string;
  setAlignment?(val: { alignment: string }): void;
  alignment?: string;
  boundingRect?: DOMRect;
  wrapperRef?: MutableRefObject<HTMLElement | null | undefined>;
}

export type AlignmentPluginStore = Store<StoreItemMap>;

export default function createVIAlignmentPlugin(): VIAlignmentPlugin {
  const store = createStore<StoreItemMap>({
    isVisible: false,
  });

  const DecoratedAlignmentTool = (): ReactElement => (
    <AlignmentTool store={store} />
  );

  return {
    initialize: ({ getReadOnly, getEditorState, setEditorState }) => {
      store.updateItem("getReadOnly", getReadOnly);
      store.updateItem("getEditorState", getEditorState);
      store.updateItem("setEditorState", setEditorState);
    },
    decorator: createDecorator({ store }),
    blockRendererFn: (contentBlock, { getEditorState, setEditorState }) => {
      const entityKey = contentBlock.getEntityAt(0);
      const contentState = getEditorState().getCurrentContent();
      const alignmentData = entityKey
        ? contentState.getEntity(entityKey).getData()
        : {};
      return {
        props: {
          alignment: alignmentData.alignment || "default",
          setAlignment: createSetAlignment(contentBlock, {
            getEditorState,
            setEditorState,
          }),
        },
      };
    },
    // ! this plugin kinda assumes that all atomic blocks are images :(
    // class resides in general stylesheet, that is also not good :(
    blockStyleFn: (
      contentBlock: ContentBlock,
      { getEditorState }: PluginFunctions,
    ) => {
      if (contentBlock.getType() === "atomic") {
        const entityKey = contentBlock.getEntityAt(0);
        const contentState = getEditorState().getCurrentContent();
        const entity = entityKey
          ? contentState.getEntity(entityKey)
          : undefined;
        const alignment = entity?.getData()?.alignment;
        // const type = entity?.getType();
        // if(type === "image")

        if (alignment) return `figure-${alignment}`;
      }
    },
    AlignmentTool: DecoratedAlignmentTool,
  };
}

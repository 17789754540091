import * as React from "react";
import { Accordion, Icon } from "semantic-ui-react";

export interface AccordionItemProps {
  idx: number;
  active: boolean;
  setIndex: (index: number) => void;
  title: string;
}

const AccordionItem: React.SFC<AccordionItemProps> = ({
  active,
  setIndex,
  idx,
  title,
  children,
}) => {
  return (
    <>
      <Accordion.Title
        active={active}
        index={idx}
        onClick={(e: any, { index }: any) => setIndex(index)}
      >
        <Icon name="dropdown" />
        {title}
      </Accordion.Title>
      <Accordion.Content active={active}>{children}</Accordion.Content>
    </>
  );
};

export default AccordionItem;

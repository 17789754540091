import React from "react";

import SimpleExpander from "./SimpleExpander";

import { IOrganizationDisplay } from "../../../api";
import { formatDate, formatBoolean } from "../../../helpers/formatters";

interface IOrganizationExpanderProps {
  data: IOrganizationDisplay;
}

const OrganizationExpander: React.FC<IOrganizationExpanderProps> = ({
  data,
}) => {
  const {
    admins,
    createdAt,
    category,
    diasporeMedia,
    highlighted,
    highlightedInCountry,
    partner,
    creator,
  } = data;

  const column1 = {
    title: "Administratori:",
    contents: admins instanceof Array ? admins : [],
  };

  const column2: Array<[string, string]> = [
    ["Izveidots:", formatDate(createdAt)],
    ["Izveidoja:", creator],
    ["Kategorija:", category],
  ];

  const column3: Array<[string, string]> = [
    ["Partneris:", formatBoolean(partner)],
    ["Disaporas medijs:", formatBoolean(diasporeMedia)],
    ["Izcelts:", formatBoolean(highlighted)],
    ["Izcelts valstī:", formatBoolean(highlightedInCountry)],
  ];

  return (
    <SimpleExpander column1={column1} column2={column2} column3={column3} />
  );
};

export default OrganizationExpander;

import React, { memo, FormEvent } from "react";
import { FormField, Radio, CheckboxProps, FormGroup } from "semantic-ui-react";

import { IBaseFormFieldProps, TFormOnChange } from "../../../interfaces/forms";

export interface IRadioProps {
  name: string;
  value: string;
  label: string;
  disabled?: boolean;
}
export interface FormRadioGroupProps extends IBaseFormFieldProps<string> {
  options: IRadioProps[];
  onChange: TFormOnChange<string>;
  value: string;
}

const FormRadioGroup: React.FC<FormRadioGroupProps> = ({
  id,
  label,
  options,
  value,
  onChange,
  required,
}) => {
  const changeFn = (_: FormEvent, data: CheckboxProps) => {
    onChange(id, data.value as string);
  };
  return (
    <FormGroup id={id} required={required} grouped>
      {label && <label htmlFor={id}>{label}</label>}
      {options.map((optionProps) => (
        <FormField key={optionProps.value}>
          <Radio
            label={optionProps.label}
            name={optionProps.name}
            value={optionProps.value}
            disabled={optionProps.disabled}
            checked={optionProps.value === value}
            onChange={changeFn}
          />
        </FormField>
      ))}
    </FormGroup>
  );
};

export default memo(FormRadioGroup);

import React from "react";

import definedLocales from "../locales/definedLocales";

function placeHolder() {}

export type TLocale = {
  currentLocale: definedLocales;
  setLocale: (locale: definedLocales) => void;
  translationLocale: definedLocales;
  setTranslationLocale: (locale: definedLocales) => void;
};

export interface IGlobalStateContext {
  locale: TLocale;
}

const GlobalStateContext = React.createContext<IGlobalStateContext>({
  locale: {
    currentLocale: definedLocales.latvian,
    setLocale: placeHolder,
    translationLocale: definedLocales.english,
    setTranslationLocale: placeHolder,
  },
});

export default GlobalStateContext;

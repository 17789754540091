const SPACE = " ";
const REGEX_NBSP = new RegExp("&nbsp;", "g");

const getSafeBodyFromHTML = (html: string): Element | undefined => {
  let doc;
  let root;
  if (
    typeof window !== undefined &&
    document.implementation &&
    document.implementation.createHTMLDocument
  ) {
    const sanitizedHtml = html.trim().replace(REGEX_NBSP, SPACE);

    doc = document.implementation.createHTMLDocument("foo");
    doc.documentElement.innerHTML = sanitizedHtml;
    // eslint-disable-next-line prefer-destructuring
    root = doc.getElementsByTagName("body")[0];
  }
  return root;
};

export default getSafeBodyFromHTML;

import { IDropDownOption } from "../interfaces/index";
import { CheckUniqueKeyWord } from "../helpers/globalFunctions";

// value from locales key
export enum CATEGORIES {
  education = "izglitiba",
  work = "darbs",
  daily = "sadzive",
  business = "bizness",
  politics = "politika-sabiedribas-lidzdaliba",
  religion = "religija",
  culture = "kultura-izklaide",
  tradition = "svetki-tradicijas",
  sports = "sports",
  travel = "celosana",
  latviaInWorld = "par-latviju-pasaule",
  government = "valsts-parvalde",
  other = "cits",
}

export const CATEGORY_TITLE: { [key in CATEGORIES]: string } = {
  izglitiba: "Izglītība",
  darbs: "Darbs",
  sadzive: "Sadzīve",
  bizness: "Bizness",
  "politika-sabiedribas-lidzdaliba": "Politika / Sabiedrības līdzdalība",
  religija: "Reliģija",
  "kultura-izklaide": "Kultūra / Izklaide",
  "svetki-tradicijas": "Svētki / Tradīcijas",
  sports: "Sports",
  celosana: "Ceļošana",
  "par-latviju-pasaule": "Par Latviju pasaulē",
  "valsts-parvalde": "Valsts pārvalde",
  cits: "Cits",
};

interface ICategoryOpts {
  text: string;
  value: CATEGORIES;
}

export const categoryOpts: Array<ICategoryOpts> = Object.entries(
  CATEGORIES,
).map(([, value]) => ({
  text: CATEGORY_TITLE[value],
  value,
}));

export const dropDownCategoryOpts: Array<IDropDownOption> = Object.entries(
  CATEGORIES,
).map(([key, value]) => ({
  key,
  text: CATEGORY_TITLE[value],
  value,
}));

const EDUCATION_SUBCATEGORIES = [
  "atverto-durvju-dienas",
  "augstskola-studijas",
  "inovacijas-un-sasniegumi",
  "latviska-izglitiba",
  "lekcijas",
  "muzizglitiba",
  "nodarbibas",
  "pamata-un-videja-izglitiba",
  "nometnes",
  "pirmsskolas-izglitiba",
  "profesionalie-kursi",
  "pulcini",
  "salidojumi-izlaidumi",
  "seminari",
  "stipendijas",
  "svesvalodas-apgusana",
  "zinatne",
  "latviesu-valoda",
  "e-apmacibas",
  "augstaka-izglitiba",
  "latviesu-skolinas",
];

const WORK_SUBCATEGORIES = [
  "arzemes-gutie-ienakumi",
  "atlaujas",
  "brivpratigais-darbs",
  "darba-meklesana-uzsaksana",
  "darba-nodokli",
  "darba-partrauksana",
  "nodoklu-atgriesana",
  "prakse",
  "problemsituacijas-ar-darba-deveju",
  "vakances",
];
const DAILY_SUBCATEGORIES = [
  "apdrosinasana",
  "auto",
  "berna-piedzimsana",
  "ciltskoks",
  "daba-darzs",
  "dokumenti",
  "drosiba",
  "dzivoklis-maja",
  "edinasana",
  "gimene",
  "iepirksanas",
  "informacija-mediji",
  "internets",
  "labdariba",
  "laulibas",
  "majdzivnieki",
  "pabalsti",
  "parcelsanas",
  "pensija",
  "pilsoniba",
  "repatriacija-atgriesanas-latvija",
  "seru-vestis",
  "skaistumkopsana",
  "telefona-sakari",
  "transports-satiksme",
  "uzturesanas",
  "veselibas-aprupe",
];
const BUSINESS_SUBCATEGORIES = [
  "biznesa-uzsaksana",
  "ekonomika",
  "eksports-no-latvijas",
  "gramatvediba",
  "imports-uz-latviju",
  "licences",
  "maksatnespeja-likvidacija",
  "pasnodarbinata-statuss",
  "uznemejdarbibas-likumdosana",
  "uznemejdarbibas-nodokli",
];

const POLITICS_SUBCATEGORIES = [
  "atklasana",
  "dibinasana",
  "diplomatiskas-vizites",
  "diskusijas",
  "konferences",
  "likumdosana-tiesibu-akti",
  "organizaciju-darbiba",
  "politika",
  "sabiedribas-lidzdaliba",
  "sapulces",
  "starpvalstu-attiecibas",
  "velesanas-referendumi",
];
const RELIGION_SUBCATEGORIES = [
  "aizlugumi",
  "dievgalds",
  "dievkalpojumi",
  "svetdienas-skola",
];
const CULTURE_SUBCATEGORIES = [
  "balles-deju-vakari",
  "deja",
  "dziedasana",
  "festivali",
  "filmu-vakari",
  "folklora",
  "vietas",
  "gatavosana",
  "humors",
  "izklaide-atputa",
  "izstades-ekspozicijas",
  "karaoke",
  "kino",
  "klasiska-muzika",
  "kolekcionesana",
  "koncerti",
  "kopa-sanaksanas",
  "literatura-dzeja",
  "maksla",
  "mode",
  "muzika",
  "opera",
  "pirts",
  "rokdarbi",
  "senosana",
  "talkas",
  "teatris",
  "tirdzini",
];
const TRADITION_SUBCATEGORIES = [
  "atceres-un-pieminas-dienas",
  "dziesmu-svetki",
  "jaunais-gads",
  "jani",
  "lacplesa-diena",
  "lieldienas",
  "neatkaribas-atjaunosanas-diena-4maijs",
  "proklamesanas-diena",
  "valentina-diena",
  "vesture",
  "visu-sveto-nakts-halloween",
  "ziemassvetki",
  "zinibu-diena",
  "latvijas-karoga-cels",
  "lv100",
];

const TRAVEL_SUBCATEGORIES = [
  "apskates-objekti",
  "auto-noma",
  "celojumi",
  "celojumu-apraksti",
  "viesnicas-naktsmajas",
  "vizas",
  "vide-turisms",
];

const LATVIANS_SUBCATEGORIES = [
  "par-latviesiem-pasaule",
  "mediji-par-latviju",
  "dzivesstasti",
  "latvijas-pedas-pasaule",
  "latviesu-viedokli-medijos",
  "latvijaRadits", // to filter out specifically
];

const GOVERNMENT_SUBCATEGORIES = [
  "pasvaldiba",
  "vestnieciba",
  "parstavnieciba",
  "goda-konsuls",
  "tielietas-aizsardziba",
  "labklajiba-veseliba",
  "ekonomika-finanses",
  "satiksme-vide",
  "valsts-parvalde",
];

const SPORT_SUBCATEGORIES = [
  "aerobika",
  "autosports",
  "badmintons",
  "basketbols",
  "biljards",
  "bmx",
  "body-building",
  "bokss",
  "boulings",
  "cinas-sports",
  "dambrete",
  "ekstremais-sports",
  "fitness",
  "florbols",
  "frisbijs",
  "futbols",
  "galda-speles",
  "golfs",
  "hokejs",
  "jasanas-sports",
  "joga",
  "karate",
  "karsu-speles",
  "makskeresana",
  "medibas",
  "motosports",
  "novuss",
  "nujosana",
  "orientesanas",
  "peintbols",
  "peldesana",
  "ritenbrauksana",
  "skriesana",
  "skritulosana",
  "slepnosana-geocatching",
  "sleposana",
  "slidosana",
  "sporta-skatisanas-fanosana",
  "stritbols",
  "svarcelsana",
  "sahs",
  "sausana",
  "sautrinmesana",
  "teniss",
  "vieglatletika",
  "vingrosana",
  "volejbols",
  "zemledus-makskeresana",
  "zolite-pokers",
];
const OTHER_SUBCATEGORIES = [
  "foto",
  "cits-cits",
  "mediji-reklama",
  "viedoklis",
  "marketings-reklama",
  "piedavajumi",
];

function createSubCategoryOpts(subcategoryArray: Array<string>) {
  return subcategoryArray.map((value) => ({ text: value, value, key: value }));
}

export interface ISubCategoryOpts {
  text: string;
  value: string;
  key: string;
}

export const AllKeyWords = () => {
  const UnqiueKeyWords: string[] = [];
  CheckUniqueKeyWord(UnqiueKeyWords, EDUCATION_SUBCATEGORIES);
  CheckUniqueKeyWord(UnqiueKeyWords, WORK_SUBCATEGORIES);
  CheckUniqueKeyWord(UnqiueKeyWords, DAILY_SUBCATEGORIES);
  CheckUniqueKeyWord(UnqiueKeyWords, BUSINESS_SUBCATEGORIES);
  CheckUniqueKeyWord(UnqiueKeyWords, POLITICS_SUBCATEGORIES);
  CheckUniqueKeyWord(UnqiueKeyWords, RELIGION_SUBCATEGORIES);
  CheckUniqueKeyWord(UnqiueKeyWords, CULTURE_SUBCATEGORIES);
  CheckUniqueKeyWord(UnqiueKeyWords, TRADITION_SUBCATEGORIES);
  CheckUniqueKeyWord(UnqiueKeyWords, SPORT_SUBCATEGORIES);
  CheckUniqueKeyWord(UnqiueKeyWords, TRAVEL_SUBCATEGORIES);
  CheckUniqueKeyWord(UnqiueKeyWords, LATVIANS_SUBCATEGORIES);
  CheckUniqueKeyWord(UnqiueKeyWords, OTHER_SUBCATEGORIES);
  CheckUniqueKeyWord(UnqiueKeyWords, GOVERNMENT_SUBCATEGORIES);

  return createSubCategoryOpts(UnqiueKeyWords);
};

export const MatchSubCategory: Record<
  CATEGORIES,
  Array<ISubCategoryOpts | IDropDownOption>
> = {
  [CATEGORIES.business]: createSubCategoryOpts(BUSINESS_SUBCATEGORIES),
  [CATEGORIES.travel]: createSubCategoryOpts(TRAVEL_SUBCATEGORIES),
  [CATEGORIES.work]: createSubCategoryOpts(WORK_SUBCATEGORIES),
  [CATEGORIES.education]: createSubCategoryOpts(EDUCATION_SUBCATEGORIES),
  [CATEGORIES.culture]: createSubCategoryOpts(CULTURE_SUBCATEGORIES),
  [CATEGORIES.daily]: createSubCategoryOpts(DAILY_SUBCATEGORIES),
  [CATEGORIES.latviaInWorld]: createSubCategoryOpts(LATVIANS_SUBCATEGORIES),
  [CATEGORIES.politics]: createSubCategoryOpts(POLITICS_SUBCATEGORIES),
  [CATEGORIES.religion]: createSubCategoryOpts(RELIGION_SUBCATEGORIES),
  [CATEGORIES.sports]: createSubCategoryOpts(SPORT_SUBCATEGORIES),
  [CATEGORIES.tradition]: createSubCategoryOpts(TRADITION_SUBCATEGORIES),
  [CATEGORIES.government]: createSubCategoryOpts(GOVERNMENT_SUBCATEGORIES),
  [CATEGORIES.other]: createSubCategoryOpts(OTHER_SUBCATEGORIES),
};

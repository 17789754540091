import { convertToRaw, ContentState } from "draft-js";

import EntityPostModel from "./EntityPostModel";

import ITemplateSite, {
  definedTemplateGrids,
} from "../interfaces/templateSite/site";
import { EntityForm } from "../api";
import { definedTemplateSections } from "../interfaces/templateSite/section";

const placeholderTitle = "Virsraksts";
const placeholderDescription =
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.";
const placeholderBody = convertToRaw(
  ContentState.createFromText(placeholderDescription),
);
const initialMinimalisticPages: ITemplateSite["pages"] = {
  home: {
    route: "home",
    routeTitle: "Sākums",
    sections: [
      {
        type: definedTemplateSections.defaultTitle,
        showSection: true,
        title: "",
      },
      {
        type: definedTemplateSections.richText,
        showSection: true,
        title: placeholderTitle,
        body: placeholderBody,
      },
      {
        type: definedTemplateSections.contactCard,
        showSection: true,
      },
    ],
  },
};

const initialAssociationPages: ITemplateSite["pages"] = {
  home: {
    route: "home",
    routeTitle: "Sākums",
    sections: [
      {
        type: definedTemplateSections.associationTitle,
        showSection: true,
        title: placeholderTitle,
        description: placeholderDescription,
        imageUrl: "",
        customLinkTitle: "",
        customLinkUrl: "",
      },
      {
        type: definedTemplateSections.text,
        showSection: true,
        title: placeholderTitle,
        description: placeholderDescription,
        customLinkTitle: "",
        customLinkUrl: "",
      },
      {
        type: definedTemplateSections.text,
        showSection: true,
        title: placeholderTitle,
        description: placeholderDescription,
        customLinkTitle: "",
        customLinkUrl: "",
      },
      {
        type: definedTemplateSections.text,
        showSection: true,
        title: placeholderTitle,
        description: placeholderDescription,
        customLinkTitle: "",
        customLinkUrl: "",
      },
      {
        type: definedTemplateSections.newsShowcase,
        showSection: true,
      },
      {
        type: definedTemplateSections.eventsShowcase,
        showSection: true,
      },
      {
        type: definedTemplateSections.contactList,
        showSection: true,
        title: placeholderTitle,
        description: placeholderDescription,
        contacts: [],
      },
      {
        type: definedTemplateSections.contactCard,
        showSection: true,
      },
    ],
  },
};

const initialMediaPages: ITemplateSite["pages"] = {
  home: {
    route: "home",
    routeTitle: "Sākums",
    sections: [
      {
        type: definedTemplateSections.mediaTitle,
        showSection: true,
        imageUrl: "",
        title: placeholderTitle,
        description: placeholderDescription,
        customLinkTitle: "",
        customLinkUrl: "",
      },
      {
        type: definedTemplateSections.text,
        showSection: true,
        title: placeholderTitle,
        description: placeholderDescription,
        customLinkTitle: "",
        customLinkUrl: "",
      },
      {
        type: definedTemplateSections.text,
        showSection: true,
        title: placeholderTitle,
        description: placeholderDescription,
        customLinkTitle: "",
        customLinkUrl: "",
      },
      {
        type: definedTemplateSections.text,
        showSection: true,
        title: placeholderTitle,
        description: placeholderDescription,
        customLinkTitle: "",
        customLinkUrl: "",
      },
      {
        type: definedTemplateSections.newsShowcase,
        showSection: true,
      },
      {
        type: definedTemplateSections.contactCard,
        showSection: true,
      },
    ],
  },
};

const initialOrganisationPages: ITemplateSite["pages"] = {
  home: {
    route: "home",
    routeTitle: "Sākums",
    sections: [
      {
        type: definedTemplateSections.organizationTitle,
        showSection: true,
        title: placeholderTitle,
        description: placeholderDescription,
        imageUrl: "",
        customLinkTitle: "",
        customLinkUrl: "",
      },
      {
        type: definedTemplateSections.text,
        showSection: true,
        title: placeholderTitle,
        description: placeholderDescription,
        customLinkTitle: "",
        customLinkUrl: "",
      },
      {
        type: definedTemplateSections.text,
        showSection: true,
        title: placeholderTitle,
        description: placeholderDescription,
        customLinkTitle: "",
        customLinkUrl: "",
      },
      {
        type: definedTemplateSections.text,
        showSection: true,
        title: placeholderTitle,
        description: placeholderDescription,
        customLinkTitle: "",
        customLinkUrl: "",
      },
      {
        type: definedTemplateSections.media,
        showSection: true,
        title: placeholderTitle,
        description: placeholderDescription,
        mediaUrl: "",
        customLinkTitle: "",
        customLinkUrl: "",
      },
      {
        type: definedTemplateSections.eventsShowcase,
        showSection: true,
      },
      {
        type: definedTemplateSections.contactCard,
        showSection: true,
      },
    ],
  },
};

// TODO write something that does not completely delete data upon template change.
export default async function HomepagePostModel(
  initialData: EntityForm,
  formData: ITemplateSite,
): Promise<EntityForm> {
  const orgData = await EntityPostModel(initialData);

  if (
    initialData.templateSite &&
    formData.layout === initialData.templateSite?.layout
  ) {
    return {
      ...orgData,
      templateSite: {
        ...initialData.templateSite,
        newsPage: formData.newsPage,
        galleryPage: formData.galleryPage,
        eventPage: formData.eventPage,
        videoPage: formData.videoPage,
      },
    };
  }
  let pages = initialMinimalisticPages;
  if (formData.layout === definedTemplateGrids.association) {
    pages = initialAssociationPages;
  }
  if (formData.layout === definedTemplateGrids.newsPaper) {
    pages = initialMediaPages;
  }
  if (formData.layout === definedTemplateGrids.events) {
    pages = initialOrganisationPages;
  }

  return {
    ...orgData,
    templateSite: {
      ...formData,
      pages,
    },
  };
}

import React from "react";
import { Button } from "semantic-ui-react";
import styled from "styled-components";

const StyledSemanticButtonReplica = styled(Button)({
  "&&&": {
    padding: "0",
    "& div > div > div > div > div > section": {
      cursor: "auto",
    },
    "& div > div > div > div > div > section > ul > li > button": {
      fontSize: "1em",
      cursor: "pointer",
    },
    "& div > div > ul > li > button": {
      cursor: "pointer",
    },
    "& div > button": {
      padding: ".78571429em .78571429em .78571429em",
      fontSize: "inherit",
      cursor: "pointer",
      display: "inline-block",
      minHeight: "1em",
      outline: 0,
      border: "none",
      width: "38.52px",
      height: "36px",
      verticalAlign: "baseline",
      background: "#e0e1e2 none",
      color: "rgba(0,0,0,.6)",
      fontFamily: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
      margin: 0,
      textTransform: "none",
      textShadow: "none",
      fontWeight: 700,
      lineHeight: "1em",
      fontStyle: "normal",
      textAlign: "center",
      textDecoration: "none",
      borderRadius: ".28571429rem",
      boxShadow:
        "0 0 0 1px transparent inset, 0 0 0 0 rgba(34,36,38,.15) inset",
      userSelect: "none",
      transition:
        "opacity .1s ease,background-color .1s ease,color .1s ease,box-shadow .1s ease,background .1s ease",
      willChange: "",
      webkitTapHighlightColor: "transparent",
    },
    "& button:hover": {
      backgroundColor: "#cacbcd",
      backgroundImage: "none",
      boxShadow:
        "0 0 0 1px transparent inset, 0 0 0 0 rgba(34,36,38,.15) inset",
      color: "rgba(0,0,0,.8)",
    },
    "& button:focus": {
      backgroundColor: "#cacbcd",
      color: "rgba(0,0,0,.8)",
      backgroundImage: "''!important",
      boxShadow: "''!important",
    },
    "& button:active": {
      backgroundColor: "#babbbc",
      backgroundImage: "",
      color: "rgba(0,0,0,.9)",
      boxShadow: "0 0 0 1px transparent inset,none",
    },
  },
});

const VIEmojiSelectWrapper: React.FC = ({ children }) => (
  <StyledSemanticButtonReplica>{children}</StyledSemanticButtonReplica>
);

export default VIEmojiSelectWrapper;

import * as React from "react";
import { NavLink } from "react-router-dom";

export interface LinkToProps {
  to: string;
}

const LinkTo: React.FC<LinkToProps> = ({ to, children }) => (
  <NavLink to={to}>{children}</NavLink>
);

export default LinkTo;

import React from "react";
import { ContentState } from "draft-js";
import { Popup } from "semantic-ui-react";
import styled from "styled-components";

interface DefaultLinkProps {
  entityKey: string;
  contentState: ContentState;
}
const StyledPopup = styled(Popup)({
  "&&&": {},
});

const StyledPopupContent = styled(Popup.Content)({
  "&&&": {
    maxWidth: "300px",
    textAlign: "center",
  },
});

const StyledPopupTrigger = styled.a({
  "&&&": {
    cursor: "text!important",
  },
});

const StyledPopupLink = styled.a({
  "&&&": {
    wordBreak: "break-all",
  },
});

const DefaultLink: React.FC<DefaultLinkProps> = (props) => {
  const { contentState, entityKey, children } = props;
  const { url } = contentState.getEntity(entityKey).getData();

  return (
    <StyledPopup
      position="bottom center"
      hoverable
      size="tiny"
      trigger={
        <StyledPopupTrigger className="link" aria-label={url}>
          {children}
        </StyledPopupTrigger>
      }
    >
      <StyledPopupContent>
        <StyledPopupLink
          className="link"
          href={url}
          rel="noopener noreferrer"
          target="_blank"
          aria-label={url}
        >
          {url}
        </StyledPopupLink>
      </StyledPopupContent>
    </StyledPopup>
  );
};

export default DefaultLink;

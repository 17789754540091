import * as React from "react";

import {
  StyledEventDateContainer,
  StyledEventDateDay,
  StyledEventDateMonth,
} from "../../../styles/CardStyles";

interface EventDateProps {
  month: string;
  day: string;
  absolutePosition?: boolean;
}
const EventDate: React.FC<EventDateProps> = ({
  month,
  day,
  absolutePosition,
}) => {
  return (
    <StyledEventDateContainer absolute={absolutePosition}>
      <StyledEventDateDay>{day}</StyledEventDateDay>
      <StyledEventDateMonth>{month}</StyledEventDateMonth>
    </StyledEventDateContainer>
  );
};

export default EventDate;

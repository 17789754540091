import { ITemplatePage } from "./page";

export enum definedTemplateGrids {
  default = "default",
  association = "association",
  newsPaper = "newsPaper",
  events = "events",
}

interface ITemplateSite {
  pages?: Record<string, ITemplatePage>;

  layout?: definedTemplateGrids;
  newsPage?: boolean;
  galleryPage?: boolean;
  eventPage?: boolean;
  videoPage?: boolean;
}

export default ITemplateSite;

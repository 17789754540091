import * as React from "react";
import { Dimmer, Loader } from "semantic-ui-react";

interface Props {
  loading?: boolean;
}

const DimmerLoader: React.FC<Props> = ({ children, loading }) => (
  <Dimmer.Dimmable dimmed={loading}>
    <Dimmer active={loading} inverted>
      <Loader size="massive">Ielādē saturu...</Loader>
    </Dimmer>
    {children}
  </Dimmer.Dimmable>
);

export default DimmerLoader;

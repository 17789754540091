import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
  cell: { value?: string };
}

const LocaleTextCell: React.FC<Props> = ({ cell: { value } }) => {
  const { t } = useTranslation();

  const displayValue = value ? t(value) : "-";

  return <>{displayValue}</>;
};

export default LocaleTextCell;

import { AWSurl, handleApiGet, handleApiPost } from "./api";

const keywordsType = "keywords";

export async function getKeywords() {
  return handleApiGet(`${AWSurl}/${keywordsType}/${keywordsType}`);
}

export function postKeywords(idToken: string, newKeywords: string[]) {
  const body = {
    slug: keywordsType,
    type: keywordsType,
    subCategories: newKeywords,
  };
  return handleApiPost(
    `${AWSurl}/${keywordsType}/${keywordsType}`,
    idToken,
    body,
  );
}

import React from "react";
import { Divider as SemanticDivider } from "semantic-ui-react";

import { styledWithTheme } from "../../../styles/theme";

export enum definedDividerTypes {
  primary = "primary",
  default = "default",
}

export interface DividerProps {
  type?: definedDividerTypes;
  fitted?: boolean;
  vertical?: boolean;
  shrink?: boolean;
  section?: boolean;
  className?: string;
}

const StyledDivider = styledWithTheme.div<{
  type?: definedDividerTypes;
  margin: string | undefined;
}>(({ type, margin, theme }) => ({
  width: margin ? "50%" : "100%",
  ...(margin ? { margin } : {}),
  "@media only screen and (max-width: 700px)": {
    ...(margin ? { margin: "0 1em", width: "-webkit-fill-available" } : {}),
  },
  ".divider": {
    background:
      type === definedDividerTypes.primary
        ? theme.palette.primary.main
        : "inherit",
  },
}));

const Divider: React.FC<DividerProps> = ({
  fitted,
  vertical,
  shrink,
  type,
  section,
  children,
  className,
}) => (
  <StyledDivider type={type} margin={shrink ? "0 25vw" : undefined}>
    <SemanticDivider
      className={className}
      fitted={fitted}
      vertical={vertical}
      section={section}
    >
      {children}
    </SemanticDivider>
  </StyledDivider>
);

Divider.defaultProps = {
  type: definedDividerTypes.default,
};

export default Divider;

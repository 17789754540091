import React, { Suspense } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { Loader } from "semantic-ui-react";
import { ToastContainer } from "react-toastify";
import styled, { ThemeProvider } from "styled-components";
import AuthContextProvider from "context/AuthContextProvider";

import GlobalContextProvider from "./context/GlobalContextProvider";
import "react-toastify/dist/ReactToastify.css";
import MainHeader from "./components/organisms/MainHeader/MainHeader";
import MainFooter from "./components/organisms/MainFooter/MainFooter";
import theme from "./styles/theme";
import ErrorBoundary from "./components/organisms/ErrorBoundary/ErrorBoundary";
import routes from "./routemap";
import IRoute from "./interfaces/route";

const StyledContainer = styled.div`
  flex: 1 0 auto;
  margin: 0 2em;
`;

const AppDiv = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 80vh;
`;

const App: React.FC = () => {
  return (
    <Suspense fallback={<Loader />}>
      <ThemeProvider theme={theme}>
        <ToastContainer />
        <ErrorBoundary>
          <Router>
            <GlobalContextProvider>
              <AuthContextProvider>
                <MainHeader>
                  <AppDiv>
                    <StyledContainer>
                      <Switch>
                        {routes.map((route: IRoute) => (
                          <Route exact {...route} key={route.name} />
                        ))}
                      </Switch>
                    </StyledContainer>
                  </AppDiv>
                  <MainFooter />
                </MainHeader>
              </AuthContextProvider>
            </GlobalContextProvider>
          </Router>
        </ErrorBoundary>
      </ThemeProvider>
    </Suspense>
  );
};

export default App;

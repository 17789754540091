import React, { MouseEvent, useCallback, useMemo, useState } from "react";
import { Form, Divider } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { toast } from "react-toastify";
import FormKeywordSelect from "components/molecules/FormFields/FormKeywordSelect";
import useAuthorizedContext from "hooks/useAuthorizedContext";

import FormTextInput from "../../molecules/FormFields/FormTextInput";
import FormSelect from "../../molecules/FormFields/FormSelect";
import useForm from "../../../hooks/useForm";
import FormMultiSelect from "../../molecules/FormFields/FormMultiSelect";
import FormGrid from "../../templates/FormGrid";
import { categoryOpts } from "../../../constants/categories";
import Typography, {
  definedTypography,
} from "../../atoms/Typography/Typography";
import { definedRouteTypes } from "../../../api/api";
import FormRadioButton from "../../molecules/FormFields/FormRadioButton";
import SimpleButton, {
  definedButtonTypes,
} from "../../atoms/Buttons/SimpleButton/SimpleButton";
import { IMediaLinkForm } from "../../../api";
import useLocalizedCountryOpts from "../../../hooks/useLocalizedCountryOpts";
import FormTextArea from "../../molecules/FormFields/FormTextArea";
import FormImageZone from "../../molecules/FormFields/FormImageZone";
import useTypeTableContext, {
  definedTableActions,
} from "../../../hooks/useTypeTableContext";
import NewBackendModel from "../../../models/NewBackendModel";
import { updateMediaLink } from "../../../api/newBackendApi";
import FormRegionInput from "../../molecules/FormFields/FormRegionInput";

interface MediaLinkFormProps {
  update?: boolean;
  initialValues?: Partial<IMediaLinkForm>;
  onFormClose?: (event?: MouseEvent) => void;
  type?: definedRouteTypes.videoNews;
}

const StyledDiv = styled.div({
  textAlign: "center",
});

const MediaLinkForm: React.FC<MediaLinkFormProps> = ({
  initialValues,
  onFormClose,
  update,
}) => {
  const CountryOptions = useLocalizedCountryOpts();
  const [isSubmitted, setSubmitted] = useState(update);
  const typeTableContext = useTypeTableContext();
  const { token } = useAuthorizedContext();

  const defaultInitValues = useCallback((): Partial<IMediaLinkForm> => {
    return {
      category: undefined,
      countries: [],
      imageUrl: "",
      seoDescription: "",
      show: true,
      title: "",
      region: undefined,

      type: definedRouteTypes.LINK_MEDIA,
      url: "",

      // authorType: definedAuthorTypes.anonymous,
      // creator: "2571222d-45a0-4a3d-8cbc-5cd2b8fde23f",
      // creatorId: "2571222d-45a0-4a3d-8cbc-5cd2b8fde23f",
      // creatorName: "Elvijs Vilkelss",

      // createdAt: new Date(),
      // id: "33eb658c-1778-489d-ab5f-f5268edfd504",
      // mediaSource: "https://forbesbaltics.com",
      // mediaSourceId: "https-forbesbaltics-com",
      // slug:
      //   "https-forbesbaltics-com-lv-zinas-raksts-1-min-klinika-jurmalas-soseja",
      // updatedAt: new Date(),
      // highlighted: false,
    };
  }, []);
  const { t } = useTranslation();

  const submitFormQuery = async (form: Partial<IMediaLinkForm>) => {
    try {
      const act = update ? definedTableActions.update : definedTableActions.add;
      const postForm = await NewBackendModel(form);

      if (update) {
        const idToken = await token();
        await updateMediaLink(idToken, postForm);
      } else {
        throw new Error("create not supported yet, sowwy");
      }

      toast.success(
        t(update ? "Toasts.EditsSuccess" : "Toasts.Publish.mediju-saites"),
      );
      if (typeTableContext) {
        typeTableContext.updateTableEntry(postForm as any, act);
      }
      if (onFormClose) onFormClose();
    } catch (err) {
      toast.error(t("Toasts.GenericError"));
      console.error("MediaLinkForm failed", err);
    }
  };

  const defaultInitialValues = useMemo(
    () => defaultInitValues(),
    [defaultInitValues],
  );

  const {
    values,
    submitForm,
    changeFormValue,
    isLoading,
    changeFormImageValue,
  } = useForm<Partial<IMediaLinkForm>>(
    initialValues || defaultInitialValues,
    submitFormQuery,
  );

  const {
    title,
    imageUrl,
    category,
    countries,
    subCategories,
    seoDescription,
    url,
    show,
    creator,
    creatorName,
    author,
    region,
  } = values;

  const formTitle = isSubmitted
    ? t("Forms.Titles.EditHeader")
    : t("Forms.Titles.AddHeader");
  const submit = isSubmitted
    ? t("Forms.Actions.Update")
    : t("Forms.Actions.Create");
  const submitAndClose = isSubmitted
    ? t("Forms.Actions.UpdateAndClose")
    : t("Forms.Actions.CreateAndClose");

  const convertedShow = Boolean(Number(show));

  return (
    <Form name="editorialNewsForm" loading={isLoading}>
      <Typography type={definedTypography.h1} as="span">
        {formTitle}
      </Typography>
      <Divider />
      <FormGrid label={t("Administrēšana")}>
        <FormRadioButton
          id="show"
          label="Redzams portālā"
          value={convertedShow}
          onChange={changeFormValue}
        />
        <FormTextInput
          id="author"
          value={creatorName || author || creator || "Anonīms"}
          label={t("Forms.Fields.author")}
          disabled
        />
      </FormGrid>
      <FormGrid label={t("Forms.Titles.Content")}>
        <FormTextInput
          id="title"
          required
          value={title}
          label={t("Forms.Fields.title")}
          onChange={changeFormValue}
        />
        <FormTextInput
          id="url"
          value={url}
          label={t("Forms.Fields.url")}
          disabled
        />
        <FormImageZone
          id="imageUrl"
          required
          initialImgUrl={imageUrl}
          label={t("Forms.Fields.imagePointer")}
          onChange={changeFormValue}
          onImageChange={changeFormImageValue}
          description={t("Forms.Descriptions.eventsImage")}
        />
        <FormTextArea
          id="seoDescription"
          required
          label={t("Forms.Fields.seoDescription")}
          value={seoDescription}
          onChange={changeFormValue}
        />
      </FormGrid>
      <FormGrid label={t("Forms.Titles.Category")}>
        <FormSelect
          label={t("Forms.Fields.category")}
          id="category"
          value={category}
          options={categoryOpts}
          onChange={changeFormValue}
          search
        />
        <FormKeywordSelect
          label={t("Forms.Fields.subCategories")}
          id="subCategories"
          value={subCategories || []}
          onChange={changeFormValue}
          selectedCategory={category}
        />
        <FormMultiSelect
          id="countries"
          label={t("Forms.Fields.countries")}
          options={CountryOptions}
          value={countries || []}
          onChange={changeFormValue}
          search
        />
        <FormRegionInput
          id="region"
          value={region}
          onChange={changeFormValue}
        />
      </FormGrid>
      <StyledDiv>
        {onFormClose && (
          <SimpleButton
            id="FormCancel"
            variant={definedButtonTypes.secondary}
            onClick={onFormClose}
          >
            {t("Forms.Actions.Cancel")}
          </SimpleButton>
        )}
        <SimpleButton
          id="FormSubmit"
          onClick={async (event) => {
            // TODO this is quickfix to enable updating...
            const response = await submitForm(event);
            setSubmitted(true);
            changeFormValue("slug", response ? response.slug : "");
          }}
          variant={definedButtonTypes.secondary}
        >
          {submit}
        </SimpleButton>
        <SimpleButton
          id="FormSubmitAndClose"
          onClick={async (event) => {
            await submitForm(event);
            if (onFormClose) onFormClose();
          }}
          variant={definedButtonTypes.primary}
        >
          {submitAndClose}
        </SimpleButton>
      </StyledDiv>
    </Form>
  );
};

MediaLinkForm.defaultProps = {
  type: definedRouteTypes.videoNews,
};

export default MediaLinkForm;

import { AWSurl, handleApiGet, handleApiPost } from "./api";

import { ISource } from "../interfaces";

const adType = "ad-banner";
export enum definedAdTypes {
  big = "ad-big",
  small = "ad-small",
  text = "ad-text",
}

export interface IAd {
  slug: definedAdTypes;
  type: typeof adType;
  source?: ISource;
  imageUrl?: string;
  links: Array<ISource>;
  imagePointer?: File;
}

export async function getAd(slug: definedAdTypes) {
  return handleApiGet(`${AWSurl}/${adType}/${adType}?slug=${slug}`);
}

export function postAd(
  idToken: string,
  slug: definedAdTypes,
  body: Partial<IAd>,
) {
  const postableBody = {
    ...body,
    type: adType,
    slug,
  };
  return handleApiPost(`${AWSurl}/${adType}/${adType}`, idToken, postableBody);
}

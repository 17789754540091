import React from "react";

import { IRow } from "../../../interfaces/tables";
import { IHomeDisplay } from "../../../api";
import { definedRouteTypes } from "../../../api/api";
import { CATEGORIES } from "../../../constants/categories";

interface Props {
  cell: { row: IRow<IHomeDisplay> };
}

const RankCell: React.FC<Props> = ({ cell: { row } }) => {
  const { category, type, homepageRank, subCategories, region } = row.original;
  const rank = Number(homepageRank) || undefined;

  let text = "-";

  if (rank === 1) text = "1 - Galvenā ziņa";
  if (rank === 2) text = "2 - Izceltā ziņas";
  if (rank === 3) text = "3 - Jaunākā ziņas";
  if (rank === 4) {
    if (region !== undefined) {
      text = "4 - Reģionu ziņa";
    }
    if (
      type === definedRouteTypes.editorialNews &&
      category === CATEGORIES.politics
    ) {
      text = "4 - Politika";
    }
    if (
      type === definedRouteTypes.editorialNews &&
      category === CATEGORIES.education
    ) {
      text = "4 - Izglītība";
    }
    if (
      type === definedRouteTypes.editorialNews &&
      category === CATEGORIES.culture
    ) {
      text = "4 - Kultūra";
    }
    if (type === definedRouteTypes.diasporeNews) {
      text = "4 - Mediju jaunumi";
    }
    if (type === definedRouteTypes.organizationNews) {
      text = "4 - Organizāciju jaunumi";
    }
    if (type === definedRouteTypes.imageGallery) {
      text = "4 - Galerijas";
    }
    if (type === definedRouteTypes.videoNews) {
      text = "4 - Video";
    }
    if (subCategories?.includes("piedavajumi")) {
      text = "4 - Piedāvājumi";
    }
  }
  return <>{text}</>;
};

export default RankCell;

import React from "react";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import { enableMapSet } from "immer";
import { ScriptContextProvider } from "context/ScriptContext";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import i18n from "./locales/i18n";

import "semantic-ui-css/semantic.min.css";
import "./index.css";

enableMapSet();

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <ScriptContextProvider>
      <App />
    </ScriptContextProvider>
  </I18nextProvider>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React from "react";
import { Icon } from "semantic-ui-react";

interface MagnifyImageIconProps {}

const MagnifyImageIcon: React.FC<MagnifyImageIconProps> = () => {
  return <Icon id="magnify" name="magnify" size="huge" />;
};

export default MagnifyImageIcon;

import { BackendUrl } from "./newBackendApi";
import { AWSurl, handleApi, handleApiGet, handleApiPost } from "./api";

export const s3Url = "https://latviesi-media.s3.eu-central-1.amazonaws.com";

export const isFile = (x: any): x is File =>
  (x as File)?.name !== undefined && (x as File)?.type !== undefined;

const IMAGE_LAMBDA = `${BackendUrl}/presignImage`;

interface Is3Upload {
  Bucket: string;
  ETag: string;
  Key: string;
  Location: string;
  key: string;
}
// !TODO NEW BACKEND
export function postThumbnail(
  idToken: string,
  fileName: string,
  imgUrl: string,
): Promise<Is3Upload> {
  return handleApiPost(`${AWSurl}/upload/youtubethumbnail`, idToken, {
    fileName,
    url: imgUrl,
  });
}

// eslint-disable-next-line import/prefer-default-export
export async function uploadImageFromFile(
  idToken: string,
  file: File,
  fileName?: string,
): Promise<string> {
  const newFile = file;
  const filename = fileName || encodeURI(file.name);
  const filetype = encodeURI(file.type);

  if (!idToken) throw new Error("Cannot upload image without token.");

  const headers: Record<string, string> = {};
  headers.Authorization = `Bearer ${idToken}`;

  const response = await handleApiGet(
    `${IMAGE_LAMBDA}?filename=${filename}&filetype=${filetype}`,
    headers,
  );

  // existing helpers ruin necessary logic
  await handleApi(
    `${response.presigned}`,
    {
      method: "PUT",
      body: newFile,
    },
    true,
  );

  return response.presigned?.split("?")[0];
}

export default uploadImageFromFile;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { PLACEHOLDER_16_TO_9 } from "../../../../constants/imageSources";
import AttachedLabel from "../../../atoms/Labels/AttachedLabel/AttachedLabel";
import ImageAuthor from "../ImageAuthor/ImageAuthor";
import PlaceHoldableImage from "../PlaceholdableImage/PlaceholdableImage";
const StyledWrapper = styled.div({
    position: "relative",
});
const SinglePageImage = ({ src, imageAuthor, alt, label, }) => {
    return (_jsxs(StyledWrapper, { children: [_jsx(PlaceHoldableImage, { src: src, fallbackImage: PLACEHOLDER_16_TO_9, centered: true, alt: alt }), imageAuthor && _jsx(ImageAuthor, { imageAuthor: imageAuthor }), label && _jsx(AttachedLabel, { children: label })] }));
};
export default SinglePageImage;

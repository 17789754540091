import React from "react";
import { Button } from "semantic-ui-react";

import { IRow } from "../../../interfaces/tables";
import { IOrganizationDisplay } from "../../../api";
import DeleteHomepageModal from "../../organisms/Modal/DeleteHomepageModal";
import AddHomepageModal from "../../organisms/Modal/AddHomepageModal";

interface Props {
  cell: { row: IRow<IOrganizationDisplay> };
}

const HomepageCell: React.FC<Props> = ({ cell: { row } }) => {
  const { slug, templateSite, type } = row.original;

  if (!slug) return <>-</>;

  if (templateSite) {
    return (
      <Button.Group>
        <Button
          size="mini"
          icon="home"
          href={`https://www.latviesi.com/${type}/${slug}`}
          target="_blank"
        />
        <DeleteHomepageModal data={row.original} />
      </Button.Group>
    );
  }

  return (
    <>
      <AddHomepageModal data={row.original} />
    </>
  );
};

export default HomepageCell;

import { AtomicBlockUtils, EditorState } from "draft-js";

const IMMUTABLE = "IMMUTABLE";
const EMPTY_TEXT = " ";

const addAtomicBlock = (
  editorState: EditorState,
  entityType: string,
  data = {},
) => {
  const contentState = editorState.getCurrentContent();
  const contentStateWithEntity = contentState.createEntity(
    entityType,
    IMMUTABLE,
    data,
  );
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

  const newEditorState = AtomicBlockUtils.insertAtomicBlock(
    editorState,
    entityKey,
    EMPTY_TEXT,
  );

  return EditorState.forceSelection(
    newEditorState,
    newEditorState.getCurrentContent().getSelectionAfter(),
  );
};

export default addAtomicBlock;

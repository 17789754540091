import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Loader } from "semantic-ui-react";
import { toast } from "react-toastify";
import useAuthorizedContext from "hooks/useAuthorizedContext";

import EditAdForm from "../Forms/AdForm/EditAdForm";
import EditSringAdForm from "../Forms/AdForm/EditSringAdForm";
import { definedAdTypes, getAd, postAd, IAd } from "../../../api/AdApi";
import uploadImageFromFile from "../../../api/ImageApi";
import SimpleButton, {
  definedButtonTypes,
} from "../../atoms/Buttons/SimpleButton/SimpleButton";

interface Props {
  type: definedAdTypes;
}

const EMPTY_AD = {
  source: { name: "", url: "" },
  links: [],
};

const EditAdPanel: React.FC<Props> = ({ type }) => {
  const [loading, setLoading] = useState(true);
  const [content, setContent] = useState<Partial<IAd>>({});
  const { token } = useAuthorizedContext();

  const { t } = useTranslation();

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;
    getAd(type)
      .then((data) => {
        if (signal.aborted) return;
        setContent(data);
        setLoading(false);
      })
      .catch((error: Error) => {
        console.error("error: ", error);
      });
    return () => {
      abortController.abort();
    };
  }, [type]);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const body = { ...content, imagePointer: undefined };
      const idToken = await token();
      if (content.imagePointer) {
        const imageUrl = await uploadImageFromFile(
          idToken,
          content.imagePointer,
        );
        body.imageUrl = imageUrl;
      }
      await postAd(idToken, type, body);
      setContent(body);
      toast.success(t("Footer.Success.Update"));
    } catch (err) {
      toast.error(t("Footer.Error.Update"));
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      const idToken = await token();
      await postAd(idToken, type, EMPTY_AD);
      setContent(EMPTY_AD);
      toast.success(t("Footer.Success.Update"));
    } catch (err) {
      toast.error(t("Footer.Error.Update"));
    } finally {
      setLoading(false);
    }
  };

  const onChange = useCallback(
    (id: string, value: any) =>
      setContent((prev) => ({
        ...prev,
        [id]: value,
      })),
    [],
  );

  if (loading) return <Loader active inline="centered" />;

  let AdForm = EditAdForm;
  if (type === definedAdTypes.text) AdForm = EditSringAdForm;

  return (
    <>
      {type === definedAdTypes.big && "Ieteicamais izmērs: 1650px x 210px"}
      {type === definedAdTypes.small && "Ieteicamais izmērs: 325px x  210px"}
      <p />
      <AdForm onChange={onChange} content={content}>
        <SimpleButton
          variant={definedButtonTypes.action}
          onClick={handleDelete}
        >
          Dzēst
        </SimpleButton>
        <SimpleButton
          variant={definedButtonTypes.primary}
          onClick={handleSubmit}
        >
          Atjaunot
        </SimpleButton>
      </AdForm>
    </>
  );
};

export default React.memo(EditAdPanel);

import React, { useState, useEffect } from "react";
import { Segment, Grid, Accordion, Input } from "semantic-ui-react";
import { toast } from "react-toastify";
import { useImmer } from "use-immer";
import moment from "moment";
import useAuthorizedContext from "hooks/useAuthorizedContext";

import TableTitleGrid from "../templates/TableTitleGrid";
import SimpleButton, {
  definedButtonTypes,
} from "../atoms/Buttons/SimpleButton/SimpleButton";
import { definedTypographyTypes } from "../atoms/Typography/TypographyStyles";
import Typography from "../atoms/Typography/Typography";
import LimitTable from "../organisms/Tables/LimitTable";
import getWideFilterQuery from "../../api/filterApi";
import RSSList from "../organisms/Lists/RSSList";
import { IHomeDisplay } from "../../api";
import { getSingleData } from "../../api/DynamicApi";
import AccordionItem from "../molecules/AccordionItem/AccordionItem";
import { extractInfoFromLink } from "../../helpers/formatters";
import { definedRouteTypes, ContentStatusTypes } from "../../api/api";
import postNewsletter, {
  INewsLetter,
  getSingleNewsletter,
} from "../../api/NewsLetterApi";
import { slugifyWord } from "../../helpers/globalFunctions";
import SimpleMessage from "../atoms/Text/SimpleMessage";

interface Props {}

export interface IKeyRow {
  type: string;
  slug: string;
  title: string;
  id: string;
  imageUrl: string;
}

function fromStringToKeyRow(keyStrings: IHomeDisplay[]): Array<IKeyRow> {
  const adminKeyRow: Array<IKeyRow> = [];
  keyStrings.forEach((record) => {
    const { type, slug, title, imageUrl } = record;
    const keyRow: IKeyRow = {
      type,
      slug,
      title,
      imageUrl: imageUrl || "",
      id: `${type}-${slug}`,
    };
    adminKeyRow.push(keyRow);
  });
  return adminKeyRow;
}

const NewsLetterPage: React.FC<Props> = () => {
  const [selectedFeed, setSelectedFeed] = useImmer<Array<IKeyRow>>([]);
  const [events, setEvents] = useState<Array<IKeyRow>>([]);
  const [news, setNews] = useState<Array<IKeyRow>>([]);
  const [loading, setLoading] = useState(false);
  const [finding, setFinding] = useState(false);
  const [url, setUrl] = useState("");
  const [name, setName] = useState(moment().format("DD. MMM. YYYY HH:mm"));
  const { token } = useAuthorizedContext();

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;
    const doFetch = async () => {
      setLoading(true);
      try {
        const newsletterData = await Promise.all([
          getWideFilterQuery("?limit=50&offset=0&type=notikumi"),
          getWideFilterQuery("?limit=100&offset=0&type=jaunumi"),
        ]);
        if (!signal.aborted) {
          const latestEvents = newsletterData[0].items;
          const latestNews = newsletterData[1].items;
          const eventKeyRow = fromStringToKeyRow(latestEvents || []);
          const newsKeyRows = fromStringToKeyRow(latestNews || []);
          setEvents(eventKeyRow);
          setNews(newsKeyRows);
        }
      } catch (e) {
        if (!signal.aborted) {
          console.error(e);
          if (e === "SLUG exists") {
            toast.error("Vēstkopa ar šādu nosaukumu jau eksistē!");
          } else {
            toast.error("Kaut kas nogāja greizi.");
          }
        }
      } finally {
        if (!signal.aborted) setLoading(false);
      }
    };
    doFetch();
    return () => {
      abortController.abort();
    };
  }, [setSelectedFeed]);

  const [active, setActive] = React.useState(-1);

  const setIndex = (index: string | number | undefined) => {
    const newIndex = index === active ? -1 : index;
    setActive(newIndex as number);
  };

  const findByUrl = async () => {
    setFinding(true);
    const urlInfo = extractInfoFromLink(url);
    const { type: urlType, slug: urlSlug } = urlInfo;
    if (
      urlType &&
      Object.values(definedRouteTypes).includes(urlType as definedRouteTypes) &&
      urlSlug
    ) {
      const requestUrl = await getSingleData(urlType, urlSlug);
      const { type, slug, title, imageUrl } = requestUrl;
      const keyRow: IKeyRow = {
        type,
        slug,
        title,
        imageUrl: imageUrl || "",
        id: `${type}-${slug}`,
      };
      const newSelectedFeeds = [...selectedFeed];
      newSelectedFeeds.push(keyRow);
      setSelectedFeed((prev) => [...prev, keyRow]);
    } else {
      toast.error("Kaut kas nogāja greizi.. Šāds raksts nav atrasts!");
    }
    setFinding(false);
  };

  const toggleSelected = (value: IKeyRow, idx: number) => {
    setSelectedFeed((prev) => {
      const newSelection = [...prev];
      if (idx > -1) {
        newSelection.splice(idx, 1);
      } else {
        newSelection.push(value);
      }
      return newSelection;
    });
  };
  const removeSelected = (idx: number) => {
    setSelectedFeed((draft) => {
      draft.splice(idx, 1);
      return draft;
    });
  };
  const moveSelected = (idx: number, up?: boolean) => {
    setSelectedFeed((draft) => {
      const newId = up ? idx - 1 : idx + 1;
      if (newId >= 0) {
        const removed = draft.splice(idx, 1);
        draft.splice(newId, 0, removed[0]);
      }
      return draft;
    });
  };

  const submitSelection = async () => {
    if (selectedFeed.length > 0) {
      setLoading(true);
      try {
        // const toPublish = selected.map((item) => item.keyword) || [];
        const newFeed: INewsLetter = {
          title: name,
          slug: slugifyWord(name),
          items: selectedFeed,
          status: ContentStatusTypes.toBePosted,
          show: 1,
        };
        const idToken = await token();
        await postNewsletter(idToken, newFeed);
        setEvents(selectedFeed);
      } catch (e) {
        console.error(e);
        toast.error("Kaut kas nogāja greizi.");
      } finally {
        setLoading(false);
        toast.success("Vēstkopa veiksmīgi saglabāta!");
      }
    } else {
      toast.info("Vēstkopā ir jābūt vismaz vienam ierakstam!");
    }
  };

  const createNewsletter = async () => {
    if (selectedFeed.length > 0) {
      submitSelection();
      setName(moment().format("DD. MMM. YYYY HH:mm"));
      setSelectedFeed(() => []);
    }
  };

  async function findNewsletter() {
    setLoading(true);
    const resp = await getSingleNewsletter(slugifyWord(name));
    if (resp) {
      const setItems = resp.items || [];
      setSelectedFeed(() => [...setItems]);
    }
    setLoading(false);
  }

  function generateNewsletter() {
    if (selectedFeed.length === 0) {
      const newSelectedEvents = events.slice(0, 2);
      const newSelectedRecords = news.slice(0, 8);
      setSelectedFeed((prev) => [
        ...prev,
        ...newSelectedEvents,
        ...newSelectedRecords,
      ]);
    } else {
      toast.info(
        "Lai ģenerētu vēstkopu, tai jābūt tukšai. Lūdzu noņem visus vēstkopas ierakstus",
      );
    }
  }

  return (
    <div>
      <TableTitleGrid title="Veidot Vēstkopu">
        <SimpleButton
          variant={definedButtonTypes.secondary}
          loading={loading}
          onClick={createNewsletter}
        >
          Saglabāt un veidot jaunu
        </SimpleButton>
        <SimpleButton
          variant={definedButtonTypes.action}
          loading={loading}
          onClick={generateNewsletter}
        >
          Ģenerēt vēstkopu
        </SimpleButton>
        <SimpleButton
          variant={definedButtonTypes.primary}
          loading={loading}
          onClick={submitSelection}
        >
          Saglabāt
        </SimpleButton>
      </TableTitleGrid>
      <Grid columns={2} style={{ marginTop: "-2rem" }}>
        <Grid.Row>
          <Grid.Column>
            <SimpleMessage
              title="Uzmanību! Jāievada pilna saite no Latviesi.com Portāla"
              listInfo={[
                "Piemēram: https://www.latviesi.com/jaunumi/rinkevics-arpus-es-izbraukusie-var-iesprust-uz-loti-ilgu-laiku ",
              ]}
            />
            <Input
              label="Ievadīt ierakstu no saites"
              placeholder="Meklēt..."
              value={url}
              onBlur={() => findByUrl()}
              onChange={(e: any) => setUrl(e.target.value)}
              loading={finding}
              fluid
            />
          </Grid.Column>
          <Grid.Column>
            <SimpleMessage
              title="Svarīgi! Šis nosaukums, ir portāla meklētaja nolūkos"
              listInfo={[
                "Vēstkopas nosaukums, var nesakirst ar 'Mailigen' e-pasta nosaukumu",
              ]}
            />
            <Input
              label="Vēstkopas nosaukums"
              value={name}
              onChange={(e) => setName(e.target.value)}
              icon={{
                name: "search",
                circular: true,
                link: true,
                onClick: findNewsletter,
              }}
              fluid
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Typography type={definedTypographyTypes.h3}>
              Izvēlies ierakstu no saraksta
            </Typography>
            <Accordion fluid styled>
              <AccordionItem
                idx={0}
                active={active === 0}
                title="Notikumi"
                setIndex={setIndex}
              >
                <LimitTable
                  selected={selectedFeed}
                  toggleSelected={toggleSelected}
                  loading={loading}
                  all={events}
                />
              </AccordionItem>
              <AccordionItem
                idx={1}
                active={active === 1}
                title="Jaunumi"
                setIndex={setIndex}
              >
                <LimitTable
                  selected={selectedFeed}
                  toggleSelected={toggleSelected}
                  loading={loading}
                  all={news}
                />
              </AccordionItem>
            </Accordion>
          </Grid.Column>
          <Grid.Column>
            <Typography type={definedTypographyTypes.h3}>
              Ieraksti Vēstokopā
            </Typography>
            <Segment>
              <RSSList
                selected={selectedFeed}
                loading={loading}
                removeItem={removeSelected}
                moveItem={moveSelected}
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default NewsLetterPage;

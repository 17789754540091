import { EditorState } from "draft-js";

import getSelectedBlock from "./getSelectedBlock";

function getEntityRange(editorState: EditorState, entityKey: string) {
  const block = getSelectedBlock(editorState);
  let entityRange: {
    start: number;
    end: number;
    text: string;
  };
  // eslint-disable-next-line no-unused-expressions
  block?.findEntityRanges(
    (value) => (value as any).get("entity") === entityKey,
    (start, end) => {
      entityRange = {
        start,
        end,
        text: block.get("text").slice(start, end),
      };
    },
  );
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  return entityRange;
}

export default getEntityRange;

import React from "react";
import { Grid, GridColumn, List } from "semantic-ui-react";

import Typography from "../../atoms/Typography/Typography";
import { definedTypographyTypes } from "../../atoms/Typography/TypographyStyles";
import { styledWithTheme } from "../../../styles/theme";

interface ISimpleExpanderProps {
  column1: { title: string; contents: string[] };
  column2: Array<[string, string]>;
  column3: Array<[string, string]>;
}

const StyledGrid = styledWithTheme(Grid)(({ theme }) => ({
  padding: theme.space[2],
}));

const SimpleExpander: React.FC<ISimpleExpanderProps> = ({
  column1,
  column2,
  column3,
}) => {
  return (
    <StyledGrid columns={3}>
      <GridColumn>
        <Typography type={definedTypographyTypes.title1}>
          {column1.title}
        </Typography>
        <List bulleted>
          {column1.contents?.map((subCategory) => (
            <List.Item key={subCategory}>{subCategory}</List.Item>
          ))}
        </List>
      </GridColumn>
      <GridColumn>
        <List>
          {column2.map(([title, contents]) => (
            <List.Item key={title}>
              <Typography type={definedTypographyTypes.h3} as="span">
                {title}
              </Typography>
              {contents}
            </List.Item>
          ))}
        </List>
      </GridColumn>
      <GridColumn>
        <List>
          {column3.map(([title, contents]) => (
            <List.Item key={title}>
              <Typography type={definedTypographyTypes.h3} as="span">
                {title}
              </Typography>
              {contents}
            </List.Item>
          ))}
        </List>
      </GridColumn>
    </StyledGrid>
  );
};

export default SimpleExpander;

import { handleApi } from "./api";
import { BackendUrl } from "./newBackendApi";
import Cognito from "./cognito/Cognito";

interface ITildeParams {
  token?: string;
  authId?: boolean;
}
const PostTildeSettings = (
  body: any,
  { token, authId }: ITildeParams = {},
) => ({
  method: "POST",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...(token && {
      Authorization: token,
    }),
    ...(authId && {
      "client-id": process.env.REACT_APP_TILDE_API_KEY,
    }),
  },
  body: JSON.stringify(body),
});

async function handleTildeApiPost(
  url: string,
  body: any,
  params: ITildeParams,
) {
  return handleApi(url, PostTildeSettings(body, params));
}

export async function GetTildeTranslation(text: string, language: string) {
  const url = `${BackendUrl}/admin/getTildeTranslation`;
  const body = {
    language,
    text,
  };

  const res = await fetch(url, {
    method: "post",
    headers: { Accept: "application/json", "Content-Type": "application/json" },
    body: JSON.stringify(body),
  })
    .then((r) => r.json())
    .catch((e) => {
      console.error(e);
    });
  return res.translation;
}

export async function UpdateTildeTranslation(
  text: string,
  translation: string,
  language: string,
) {
  const url = `${BackendUrl}/admin/updateTilde`;
  const token = Cognito.getIdTokenFromStorage();
  const body = {
    text,
    translation,
    language,
  };
  return handleTildeApiPost(url, body, { token });
}

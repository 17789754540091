import moment from "moment";
import { convertFromRaw } from "draft-js";
import Cognito from "api/cognito/Cognito";

import { IGalleryNewsForm } from "../api/index.d";
import { slugifyWord } from "../helpers/globalFunctions";
import { CATEGORIES } from "../constants/categories";
import { ContentStatusTypes } from "../api/api";
import { uploadImageFromFile } from "../api/ImageApi";

export default async function GalleryFormModel(
  form: IGalleryNewsForm,
): Promise<IGalleryNewsForm> {
  const {
    id,
    title,
    description,
    category,
    subCategories,
    countries,
    address,
    imageLinks,
    authorType,
    author,
    creator,
    creatorName,
    organizationId,
    anotherAuthor,
    type,
    createdAt,
    toBePostedAt,
    highlighted,
    highlightedInCountry,
    show,
    imageAuthor,
    customLabel,
    exclusive,
    source,
    slug,
    displayInHomePage,
    online,
    organizationInfo,
    homepageRank,
  } = form;

  if (!imageLinks || imageLinks.length === 0)
    throw new Error("Gallery should contain at least one image.");

  const imageLinksArr = await Promise.all(
    imageLinks.map(async (galleryImage) => {
      const { imagePointer } = galleryImage;
      if (imagePointer) {
        const token = Cognito.getIdTokenFromStorage();
        const imageUrl = await uploadImageFromFile(token, imagePointer);
        return { imageUrl };
      }
      return galleryImage;
    }),
  );
  const newImageUrl = imageLinksArr[0].imageUrl;

  const realSlug = slug !== "" ? slug : slugifyWord(title);
  const seoDescription = description
    ? convertFromRaw(description).getPlainText().slice(0, 320)
    : "";

  const newSubCategories = subCategories ? [...subCategories] : [];

  // Due to elastic search category must also be placed in subcategories.
  const allCategories = Object.values(CATEGORIES);
  // eslint-disable-next-line no-unused-expressions
  subCategories?.forEach((sub, idx) => {
    if (allCategories.find((cat) => sub === cat))
      newSubCategories.splice(idx, 1);
  });

  if (category) newSubCategories.push(category);

  const isItToBePosted = toBePostedAt ? moment().isBefore(toBePostedAt) : false;
  return {
    id,
    slug: realSlug,
    imageUrl: newImageUrl,
    imageAuthor,
    imageLinks: imageLinksArr,
    createdAt,
    toBePostedAt: toBePostedAt || moment(),
    title,
    countries,
    address,
    description,
    category,
    subCategories: newSubCategories,
    seoDescription,
    authorType,
    author,
    creator,
    creatorName,
    organizationId,
    anotherAuthor,
    customLabel,
    highlighted,
    highlightedInCountry,
    show,
    exclusive,
    type,
    source,
    displayInHomePage,
    online,
    status: isItToBePosted
      ? ContentStatusTypes.toBePosted
      : ContentStatusTypes.active,
    organizationInfo,
    homepageRank,
  };
}

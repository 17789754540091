import React, { memo, SyntheticEvent } from "react";
import {
  StrictDropdownItemProps,
  DropdownProps,
  Dropdown,
  FormField,
} from "semantic-ui-react";

import { IBaseFormFieldProps } from "../../../interfaces/forms";

// TODO: controlled @Agnese

export interface MultiFormSelectProps extends IBaseFormFieldProps<string[]> {
  options: StrictDropdownItemProps[];
  search?: boolean;
}

const FormMultiSelect: React.FC<MultiFormSelectProps> = ({
  id,
  label,
  required,
  onChange,
  ...props
}) => {
  const changeFn = (event: SyntheticEvent, data: DropdownProps) => {
    if (onChange)
      onChange(id, data.value instanceof Array ? (data.value as string[]) : []);
  };

  return (
    <FormField required={required}>
      {label && <label htmlFor={id}>{label}</label>}
      <Dropdown
        {...props}
        id={id}
        name={id}
        selection
        required={required}
        onChange={changeFn}
        clearable
        multiple
      />
    </FormField>
  );
};

export default memo(FormMultiSelect);

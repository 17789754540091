import React from "react";
import { Menu, Icon, IconProps } from "semantic-ui-react";

interface OpenDrawerMenuItemProps {
  open: boolean;
  toggleDrawer: () => void;
}

const OpenDrawerMenuItem: React.FC<OpenDrawerMenuItemProps> = ({
  toggleDrawer,
  open,
}) => {
  let iconId = "open";
  let iconName: IconProps["name"] = "bars";

  if (open) {
    iconId = "close";
    iconName = "close";
  }
  return (
    <Menu.Item onClick={toggleDrawer}>
      <Icon id={iconId} name={iconName} size="large" />
    </Menu.Item>
  );
};

export default OpenDrawerMenuItem;

/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */

import { useEffect, useCallback, SyntheticEvent, MouseEvent } from "react";
import { useImmerReducer } from "use-immer";

import { IndexSignature } from "../interfaces";

interface IForm<T> {
  error: string;
  isLoading: boolean;
  isSubmitted: boolean;
  values: T;
}

type TAction<T> =
  | { type: "submit" | "success" | "error" }
  | { type: "field"; payload: T[keyof T]; fieldName: keyof T }
  | { type: "reset"; payload: T };

function textSectionReducer<T>(draft: IForm<T>, action: TAction<T>) {
  switch (action.type) {
    case "reset": {
      draft.values = action.payload;
      return;
    }
    case "field": {
      draft.values[action.fieldName] = action.payload;
      return;
    }
    case "submit": {
      draft.error = "";
      draft.isLoading = true;
      return;
    }
    case "success": {
      draft.isSubmitted = true;
      draft.isLoading = false;
      return;
    }
    case "error": {
      draft.error = "Form submission failed!!";
      draft.isLoading = false;
      return;
    }
    default: {
      draft.error = "Unrecognised action type used!!";
      draft.isLoading = false;
    }
  }
}

const useForm = <T>(
  initialData: T,
  submitFormQuery: (form: T) => Promise<T | void>,
) => {
  const [formState, dispatchForm] = useImmerReducer<IForm<T>, TAction<T>>(
    textSectionReducer as any, // TODO fix type. It should be correct though ...
    {
      error: "",
      isLoading: false,
      isSubmitted: false,
      values: initialData,
    },
  );
  const { values, isLoading, isSubmitted, error } = formState;

  const resetForm = useCallback(
    (event?: MouseEvent<HTMLButtonElement>) => {
      if (event) event.preventDefault();
      dispatchForm({
        type: "reset",
        payload: initialData,
      });
    },
    [initialData, dispatchForm],
  );

  const changeFormValue = useCallback(
    (id: string, data: T[keyof T]) => {
      // TODO guard against nonexisting ids!
      dispatchForm({
        type: "field",
        fieldName: id as keyof T,
        payload: data,
      });
    },
    [dispatchForm],
  );

  const submitForm = async (
    event: SyntheticEvent,
    successCalback?: (res?: T | void) => void,
  ) => {
    event.preventDefault();
    dispatchForm({ type: "submit" });
    try {
      const postForm = await submitFormQuery(values);
      dispatchForm({ type: "success" });
      if (successCalback) successCalback(postForm);
      return postForm;
    } catch (err) {
      dispatchForm({ type: "error" });
    }
  };

  // TODO UGLY YUCK
  const changeFormImageValue = useCallback(
    (imageValue: IndexSignature) => {
      // TODO guard against nonexisting ids!
      console.log("SETTINF", imageValue);

      dispatchForm({
        type: "field",
        fieldName: "imageUrl" as keyof T,
        payload: imageValue.url as T[keyof T],
      });
      dispatchForm({
        type: "field",
        fieldName: "imagePointer" as keyof T,
        payload: undefined as unknown as T[keyof T],
      });

      dispatchForm({
        type: "field",
        fieldName: "imageAuthor" as keyof T,
        payload: imageValue.imageAuthor as T[keyof T],
      });
    },
    [dispatchForm],
  );

  useEffect(() => {
    resetForm();
  }, [resetForm, initialData]);

  return {
    values,
    isLoading,
    isSubmitted,
    error,
    submitForm,
    changeFormValue,
    changeFormImageValue,
    resetForm,
  };
};

export default useForm;

import * as React from "react";
import { Radio, FormField } from "semantic-ui-react";

interface FormRadioButtonProps {
  id: string;
  label: string;
  onChange: (id: string, data: boolean) => void;
  value: boolean | undefined;
  style?: any;
}

const FormRadioButton: React.FC<FormRadioButtonProps> = ({
  onChange,
  id,
  value,
  label,
  style,
}) => {
  return (
    <FormField style={style}>
      <Radio
        toggle
        label={label}
        checked={value}
        onClick={(event, data) => onChange(id, data.checked || false)}
      />
    </FormField>
  );
};

export default FormRadioButton;

import React, { useCallback, memo } from "react";
import { useTranslation } from "react-i18next";
import { FormGroup } from "semantic-ui-react";

import FormTextInput from "./FormTextInput";

import { ISource } from "../../../interfaces";

interface FormSourceFieldProps {
  id: string;
  value?: ISource;
  onChange: (id: string, value: ISource) => void;
  customNameLabel?: string;
  customUrlLabel?: string;
}
const FormSourceField: React.FC<FormSourceFieldProps> = ({
  id,
  value,
  onChange,
  customNameLabel,
  customUrlLabel,
}) => {
  const { t } = useTranslation();

  const onSourceChange = useCallback(
    (fieldId: string, val: string) => {
      if (value) {
        const newVals = { ...value };
        newVals[fieldId as "url" | "name"] = val;
        onChange(id, newVals);
      }
    },
    [onChange, value, id],
  );
  if (!value) return null;

  const { name, url } = value;

  return (
    <FormGroup widths="equal">
      <FormTextInput
        id="name"
        value={name}
        label={customNameLabel || t("Forms.Fields.SourceName")}
        onChange={onSourceChange}
      />
      <FormTextInput
        id="url"
        value={url}
        label={customUrlLabel || t("Forms.Fields.SourceUrl")}
        onChange={onSourceChange}
      />
    </FormGroup>
  );
};

export default memo(FormSourceField);

import React, { memo } from "react";
import { FormField, Tab, Image, Menu } from "semantic-ui-react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { IBaseFormFieldProps } from "../../../interfaces/forms";
import { definedTemplateGrids } from "../../../interfaces/templateSite/site";
import SimpleButton, {
  definedButtonTypes,
} from "../../atoms/Buttons/SimpleButton/SimpleButton";
import {
  TEMPLATE_MINIMAL,
  TEMPLATE_ORGANIZATION,
  TEMPLATE_ASSOCIATION,
  TEMPLATE_MEDIA,
} from "../../../images/imageSources";
import Typography from "../../atoms/Typography/Typography";
import { definedTypographyTypes } from "../../atoms/Typography/TypographyStyles";

const StyledPane = styled(Tab.Pane)({
  "&&&": {
    textAlign: "center",
    "> a": {
      marginTop: "1em",
    },
  },
});
export interface FormTemplateSelectProps
  extends IBaseFormFieldProps<definedTemplateGrids> {
  onChange: (id: string, value: definedTemplateGrids) => void;
  value?: definedTemplateGrids;
  description?: string;
}

const FormTemplateSelect: React.FC<FormTemplateSelectProps> = ({
  onChange,
  id,
  label,
  required,
  value,
  description,
}) => {
  const { t } = useTranslation();
  let defaultActiveIndex = 0;
  if (value === definedTemplateGrids.events) defaultActiveIndex = 1;
  if (value === definedTemplateGrids.association) defaultActiveIndex = 2;
  if (value === definedTemplateGrids.newsPaper) defaultActiveIndex = 3;
  const panes = [
    {
      menuItem: (
        <Menu.Item id={definedTemplateGrids.default}>
          {t("Forms.Fields.MinimalisticTemplate")}
        </Menu.Item>
      ),
      render: () => (
        <StyledPane attached={false}>
          <Image
            src={TEMPLATE_MINIMAL}
            size="medium"
            centered
            alt="Minimālas mājaslapas veidnes piemērs"
          />
          <SimpleButton
            id={`seePreviewButton-${definedTemplateGrids.default}-${id}`}
            href={TEMPLATE_MINIMAL}
            target="_blank"
            variant={definedButtonTypes.action}
          >
            {t("Forms.Actions.SeePreview")}
          </SimpleButton>
        </StyledPane>
      ),
    },
    {
      menuItem: (
        <Menu.Item id={definedTemplateGrids.events}>
          {t("Forms.Fields.OrganisationTemplate")}
        </Menu.Item>
      ),
      render: () => (
        <StyledPane attached={false}>
          <Image
            src={TEMPLATE_ORGANIZATION}
            size="medium"
            centered
            alt="Organizatora mājaslapas veidnes piemērs"
          />
          <SimpleButton
            id={`seePreviewButton-preview-button-${definedTemplateGrids.events}-${id}`}
            href={TEMPLATE_ORGANIZATION}
            target="_blank"
            variant={definedButtonTypes.action}
          >
            {t("Forms.Actions.SeePreview")}
          </SimpleButton>
        </StyledPane>
      ),
    },
    {
      menuItem: (
        <Menu.Item id={definedTemplateGrids.association}>
          {t("Forms.Fields.AssociationTemplate")}
        </Menu.Item>
      ),
      render: () => (
        <StyledPane attached={false}>
          <Image
            src={TEMPLATE_ASSOCIATION}
            size="medium"
            centered
            alt="Biedrības mājaslapas veidnes piemērs"
          />
          <SimpleButton
            id={`seePreviewButton-${definedTemplateGrids.association}-${id}`}
            href={TEMPLATE_ASSOCIATION}
            target="_blank"
            variant={definedButtonTypes.action}
          >
            {t("Forms.Actions.SeePreview")}
          </SimpleButton>
        </StyledPane>
      ),
    },
    {
      menuItem: (
        <Menu.Item id={definedTemplateGrids.newsPaper}>
          {t("Forms.Fields.MediaTemplate")}
        </Menu.Item>
      ),
      render: () => (
        <StyledPane attached={false}>
          <Image
            src={TEMPLATE_MEDIA}
            size="medium"
            centered
            alt="Medija mājaslapas veidnes piemērs"
          />
          <SimpleButton
            id={`seePreviewButton-${definedTemplateGrids.newsPaper}-${id}`}
            href={TEMPLATE_MEDIA}
            target="_blank"
            variant={definedButtonTypes.action}
          >
            {t("Forms.Actions.SeePreview")}
          </SimpleButton>
        </StyledPane>
      ),
    },
    {
      menuItem: (
        <Menu.Item disabled>{t("Forms.Fields.SpecialTemplate")}</Menu.Item>
      ),
    },
  ];
  return (
    <FormField required={required}>
      {label && <label htmlFor={id}>{label}</label>}
      {description && (
        <Typography type={definedTypographyTypes.smallCaption}>
          {description}
        </Typography>
      )}
      <Tab
        defaultActiveIndex={defaultActiveIndex}
        menu={{ attached: false, widths: 5 }}
        panes={panes}
        onTabChange={(event, data) => {
          onChange(id, event.currentTarget.id as definedTemplateGrids);
        }}
      />
    </FormField>
  );
};

export default memo(FormTemplateSelect);

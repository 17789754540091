/* eslint-disable no-nested-ternary */
import React, { memo, useEffect } from "react";

import FormRadioGroup from "./FormRadioGroup";
import FormTextInput from "./FormTextInput";
import FormOrganizationField from "./FormOrganizationField";
import FormCompanyField from "./FormCompanyField";

import { IOrganizationInfo } from "../../../api";
import { definedRouteTypes } from "../../../api/api";

interface FormAuthorFieldsProps {
  type?: definedRouteTypes;
  author?: string;
  organizationId?: string;
  anotherAuthor?: string;
  authorType?: definedAuthorTypes;
  changeFormValue: (id: string, value?: IOrganizationInfo | string) => void;
}
export enum definedAuthorTypes {
  organization = "organization",
  author = "author",
  anotherAuthor = "anotherAuthor",
  none = "none",
  company = "company",
  anonymous = "anonymous",
}

const FormAuthorFields: React.FC<FormAuthorFieldsProps> = ({
  authorType,
  author,
  anotherAuthor,
  organizationId,
  changeFormValue,
  type,
}) => {
  useEffect(() => {
    if (
      authorType !== definedAuthorTypes.organization &&
      authorType !== definedAuthorTypes.company
    ) {
      changeFormValue("organizationId", undefined);
      changeFormValue("organizationInfo", undefined);
    }
    if (authorType !== definedAuthorTypes.anotherAuthor) {
      changeFormValue("anotherAuthor", undefined);
    }
  }, [authorType, changeFormValue]);

  return (
    <div>
      <FormRadioGroup
        id="authorType"
        options={[
          {
            name: definedAuthorTypes.organization,
            value: definedAuthorTypes.organization,
            label: "Organizācija",
            disabled:
              type === definedRouteTypes.editorialNews ||
              type === definedRouteTypes.companyNews,
          },
          {
            name: definedAuthorTypes.author,
            value: definedAuthorTypes.author,
            label: "Autors",
            disabled:
              type === definedRouteTypes.organizationNews ||
              type === definedRouteTypes.companyNews,
          },
          {
            name: definedAuthorTypes.company,
            value: definedAuthorTypes.company,
            label: "Uzņēmums",
            disabled:
              type === definedRouteTypes.editorialNews ||
              type === definedRouteTypes.organizationNews,
          },
          {
            name: definedAuthorTypes.anotherAuthor,
            value: definedAuthorTypes.anotherAuthor,
            label: "Cits autors",
            disabled:
              type === definedRouteTypes.organizationNews ||
              type === definedRouteTypes.companyNews,
          },
          {
            name: definedAuthorTypes.none,
            value: definedAuthorTypes.none,
            label: "Autoru nenorādīt",
            disabled:
              type === definedRouteTypes.organizationNews ||
              type === definedRouteTypes.companyNews,
          },
        ]}
        onChange={changeFormValue}
        value={authorType || definedAuthorTypes.author}
      />
      {authorType === definedAuthorTypes.organization && (
        <FormOrganizationField
          required
          onChange={changeFormValue}
          value={organizationId}
          showOnlyDiaspore={type === definedRouteTypes.diasporeNews}
        />
      )}
      {authorType === definedAuthorTypes.company && (
        <FormCompanyField
          required
          onChange={changeFormValue}
          value={organizationId}
        />
      )}
      {authorType === definedAuthorTypes.author && (
        <FormTextInput id="author" value={author} disabled />
      )}
      {authorType === definedAuthorTypes.anotherAuthor && (
        <FormTextInput
          id="anotherAuthor"
          value={anotherAuthor}
          onChange={changeFormValue}
          placeholder="Ievadi autora vārdu"
          required
        />
      )}
    </div>
  );
};

export default memo(FormAuthorFields);

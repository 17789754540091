import React, { useState } from "react";
import { Accordion, Icon } from "semantic-ui-react";
import styled from "styled-components";

import ContactsPanel from "../organisms/Panels/ContactsPanel";
import BasicFooterPanel from "../organisms/Panels/BasicFooterPanel";
import { definedFooterRoutes } from "../../api/api";

export interface StaticContentPageProps {}
export interface IAccordion {
  index: number;
  title: string;
  content: any;
  type: definedFooterRoutes;
}
const StyledPage = styled.div`
  &&& {
    margin-top: 2em;
    width: 100%;
  }
`;

const StaticContentPage: React.FC<StaticContentPageProps> = () => {
  const [active, setActive] = useState<number>(0);
  function setIndex(index: number) {
    let newIndex = index;
    if (index === active) newIndex = 0;
    setActive(newIndex);
  }

  const AccordionItems: IAccordion[] = [
    {
      index: 1,
      title: "Par Latviesi.com",
      content: BasicFooterPanel,
      type: definedFooterRoutes.about,
    },
    {
      index: 2,
      title: "Kontakti",
      content: ContactsPanel,
      type: definedFooterRoutes.contacts,
    },
    {
      index: 3,
      title: "Lietošanas noteikumi",
      content: BasicFooterPanel,
      type: definedFooterRoutes.tos,
    },
    {
      index: 4,
      title: "Organizācijām",
      content: BasicFooterPanel,
      type: definedFooterRoutes.forOrganizations,
    },
    {
      index: 5,
      title: "Atbalstītāji",
      content: BasicFooterPanel,
      type: definedFooterRoutes.partners,
    },
    {
      index: 6,
      title: "Lakstīgala",
      content: BasicFooterPanel,
      type: definedFooterRoutes.nightingale,
    },
    {
      index: 7,
      title: "Lietošanas pamācība",
      content: BasicFooterPanel,
      type: definedFooterRoutes.instructions,
    },
  ];

  return (
    <StyledPage>
      <Accordion styled fluid>
        {AccordionItems.map((item) => {
          const { index, title, content, type } = item;
          const CurrentMenu = content;
          return (
            <div key={item.title}>
              <Accordion.Title
                active={active === index}
                index={index}
                onClick={() => setIndex(index)}
              >
                <Icon name="dropdown" />
                {title}
              </Accordion.Title>
              <Accordion.Content active={active === index}>
                {active === index && <CurrentMenu type={type} />}
              </Accordion.Content>
            </div>
          );
        })}
      </Accordion>
    </StyledPage>
  );
};

export default StaticContentPage;

import * as React from "react";
import { Message } from "semantic-ui-react";

import Typography from "../Typography/Typography";
import { definedTypographyTypes } from "../Typography/TypographyStyles";

export interface SimpleMessageProps {
  title: string;
  listInfo?: string[];
}

const SimpleMessage: React.SFC<SimpleMessageProps> = ({ title, listInfo }) => (
  <Message
    info
    header={
      <Typography type={definedTypographyTypes.smallCaption}>
        {title}
      </Typography>
    }
    list={listInfo}
  />
);

export default SimpleMessage;

import React from "react";
import { Icon, Button } from "semantic-ui-react";

import Typography from "../../atoms/Typography/Typography";
import { definedTypographyTypes } from "../../atoms/Typography/TypographyStyles";

export interface IPaginatorProps {
  disabled?: boolean;
  pageIndex: number;
  goToPrevPage: () => void;
  goToNextPage: () => void;
  isPrevAvailable: boolean;
  isNextAvailable: boolean;
}

const Paginator: React.FC<IPaginatorProps> = ({
  disabled,
  pageIndex,
  goToPrevPage,
  goToNextPage,
  isPrevAvailable,
  isNextAvailable,
}) => {
  const isPrevDisabled = disabled || !isPrevAvailable;
  const isNextDisabled = disabled || !isNextAvailable;
  return (
    <>
      <Typography type={definedTypographyTypes.title4}>
        Lapa : {pageIndex + 1}
      </Typography>
      <Button onClick={goToPrevPage} disabled={isPrevDisabled}>
        <Icon name="angle left" />
      </Button>
      <Button onClick={goToNextPage} disabled={isNextDisabled}>
        <Icon name="angle right" />
      </Button>
    </>
  );
};

export default Paginator;

import styled from "styled-components";

export const BaseEditor = styled.div``;

export const StyledEditor = styled.div<{
  toolbarHidden?: boolean;
  large?: boolean;
}>(({ theme, large, toolbarHidden }) => ({
  "&&&": {
    background: theme?.palette?.mono?.white || "#fff",
    border: "1px solid rgba(34,36,38,.15)",
    margin: "0.5rem 0",
    padding: "0.67499em 1em",
    minHeight: "300px",
    boxShadow: "none",
    float: "left",
    width: "100%",

    // eslint-disable-next-line no-nested-ternary
    height: large ? "700px" : toolbarHidden ? "100%" : "400px",
    color: "black",
    overflowY: toolbarHidden ? "hidden" : "auto",
    ".DraftEditor-root": {
      height: "auto",
    },
    ":hover": {
      cursor: "text",
    },
    "& * h1, h2, h3, h4, h5, h6, p, li": {
      fontFamily: `${theme?.typography?.fontFamily || "Ubuntu"}`,
    },
    "& h4, p, li": {
      fontSize: `16px`,
    },
    "& * .figure-center": {
      textAlign: "center",
      margin: "0 0 1em",
      ">*": {
        display: "block",
        maxWidth: "100%",
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    "& * .figure-left": {
      textAlign: "center",
      float: "none",
      margin: "0 0 1em",
      ">*": {
        float: "none!important" as "none",
        maxWidth: "100%",
      },
    },
    "& * .figure-right": {
      textAlign: "center",
      float: "none",
      margin: "0 0 1em",
      ">*": {
        float: "none!important" as "none",
        maxWidth: "100%",
      },
    },
    [`@media only screen and (min-width: 768px)`]: {
      "& * .figure-left": {
        width: "45%",
        float: "left",
        margin: "0.5em 1.5em 0.5em 0",
      },
      "& * .figure-right": {
        width: "45%",
        float: "right",
        margin: "0.5em 0 0.5em 1.5em",
      },
    },
    "& * figure": {
      zIndex: 1, // why?
      position: "relative", // why?
      ">*": {
        maxWidth: "100%", // because bigger images go out of frame

        "> :first-child": {
          flex: 1,
        },
      },
    },
    "& * .figcaption-text": {
      fontFamily: '"Ubuntu", sans-serif', // Roboto
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "13px",
      lineHeight: "20px",
      color: "#757575",
      ...(theme?.typography?.captions || {}), // Overwriting with theme, if available.
      marginTop: "0.5em",
    },
    "& * a": {
      color: theme?.palette?.link?.default || "#5e93c5",
      textDecoration: "none",
    },
    "& * a:visited": {},
    "& * a:hover": {
      color: theme?.palette?.link?.hover || "#7eadda",
      outline: 0 /* reset for :focus */,
      cursor: "pointer",
    },
    "& * a:focus": {
      color: theme?.palette?.link?.focus || "#7eadda",
      outline: 0 /* reset for :focus */,
      cursor: "pointer",
    },
    "& * a:active": {
      color: theme?.palette?.link?.active || "#4a7bab",
    },

    // BLOCKQUOTES
    "& blockquote": {
      borderLeft: `3px solid ${theme.palette.primary.main}`,
      color: "#1a1a1a",
      fontFamily: "Raleway",
      fontSize: "16px",
      fontStyle: "italic",
      lineHeight: "1.8em",
      margin: "1.1em 1em",
      padding: "1em 2em",
      position: "relative",
      transition: ".2s border ease-in-out",
      [`@media only screen and (max-width: 768px)`]: {
        paddingLeft: "1em",
        paddingRight: 0,
        marginLeft: 0,
        marginRight: 0,
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    "& blockquote:before": {
      content: "' '",
      position: "absolute",
      backgroundColor: "white",
      top: "50%",
      left: "-4px",
      height: "3em",
      width: "5px",
      marginTop: "-1.5em",
    },
    "& blockquote:after": {
      content: "'\\201C'",
      position: "absolute",
      top: "50%",
      left: "-0.5em",
      color: theme.palette.primary.main,
      fontFamily: "Raleway",
      fontStyle: "normal",
      lineHeight: "1.5em",
      fontSize: "4em",
      textAlign: "center",
      textIndent: "-2px",
      width: "1em",
      marginTop: "-0.5em",
      transition: ".2s all ease-in-out, .4s transform ease-in-out",
    },
  },
}));

export const StyledToolbar = styled.div({
  "&&&": {
    "& > div": {
      marginRight: "5px !important",
    },
  },
});

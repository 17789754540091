import React, { useState } from "react";
import { Modal, Button } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  IPictureEntityDisplay,
  IPictureEntityForm,
} from "api/types/PictureEntity";
import { DeletePictureDbApi } from "api/PictureApi";
import useAuthorizedContext from "hooks/useAuthorizedContext";

import SimpleButton, {
  definedButtonTypes,
} from "../../atoms/Buttons/SimpleButton/SimpleButton";

interface DeletePictureProps {
  data: IPictureEntityDisplay;
  deleteRow: (data: IPictureEntityForm) => void;
}

const DeletePictureModal: React.FC<DeletePictureProps> = ({
  data,
  deleteRow,
}) => {
  const { t } = useTranslation();
  const { token } = useAuthorizedContext();
  const { key } = data;
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const deleteFn = async () => {
    try {
      setLoading(true);
      const idToken = await token();
      await DeletePictureDbApi(idToken, key);
      deleteRow(data);
      toast.success("Dzēsts veiksmīgi");
    } catch {
      toast.error("Dzēšot radās kļūda! Mēģini vēlreiz!");
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };

  return (
    <Modal
      size="mini"
      open={open}
      trigger={
        <Button size="mini" icon="close" onClick={() => setOpen(true)} />
      }
      closeOnEscape={false}
      closeOnDimmerClick={false}
    >
      <Modal.Header>Tiešām dzēst?</Modal.Header>
      <Modal.Content>
        <p>
          Vai tiešām vēlies dzēst `<b>{key}</b>?
        </p>
        <p>
          Pirms šīs darbības pārbaudi visas satura vienības, kas ir izmantojušas
          šo attēlu!
        </p>
        <p>Šī darbība ir neatgriezeniska.</p>
      </Modal.Content>
      <Modal.Actions>
        <SimpleButton
          variant={definedButtonTypes.secondary}
          onClick={() => setOpen(false)}
          disabled={loading}
        >
          {t("Forms.Modal.Cancel")}
        </SimpleButton>
        <SimpleButton
          variant={definedButtonTypes.primary}
          onClick={deleteFn}
          loading={loading}
        >
          {t("Forms.Modal.Delete")}
        </SimpleButton>
      </Modal.Actions>
    </Modal>
  );
};

export default DeletePictureModal;

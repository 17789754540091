import React from "react";
import { Grid, GridColumn } from "semantic-ui-react";

import Typography from "../atoms/Typography/Typography";
import { definedTypographyTypes } from "../atoms/Typography/TypographyStyles";

interface TableTitleGridProps {
  title: string;
}

const TableTitleGrid: React.FC<TableTitleGridProps> = ({ children, title }) => {
  return (
    <Grid columns={2} verticalAlign="middle">
      <GridColumn textAlign="left">
        <Typography type={definedTypographyTypes.h1}>{title}</Typography>
      </GridColumn>
      <GridColumn textAlign="right">{children}</GridColumn>
    </Grid>
  );
};
export default TableTitleGrid;

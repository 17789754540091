import React, { SyntheticEvent, Component } from "react";
import Editor, {
  composeDecorators,
  EditorPlugin,
} from "@draft-js-plugins/editor";
import createImagePlugin from "@draft-js-plugins/image";
import createFocusPlugin from "@draft-js-plugins/focus";
import createSideToolbarPlugin from "@draft-js-plugins/side-toolbar";
import {
  EditorState,
  RawDraftContentState,
  convertToRaw,
  convertFromRaw,
} from "draft-js";
import styled from "styled-components";
import { Icon } from "semantic-ui-react";

import {
  StyledEditor,
  StyledToolbar,
  BaseEditor,
} from "./RichTextEditor.styles";
import createEmbedPlugin from "./plugins/embed/createEmbedPlugin";
import createLinkPlugin from "./plugins/link/createLinkPlugin";
import createParagraphPlugin from "./plugins/paragraph";
import { OEMBED_ADD_FORM_TYPE } from "./plugins/embed/constants";
import createVIEmojiPlugin from "./plugins/VIEmoji";
import createVIAlignmentPlugin from "./plugins/VIAlignment";
import createCaptionsPlugin from "./plugins/captions";

import ImageToolbar from "../Toolbars/ImageToolbar";
import LinkToolbar from "../Toolbars/LinkToolbar";
import BlockStyleToolbar from "../Toolbars/BlockStyleToolbar";
import InlineStyleToolbar from "../Toolbars/InlineStyleToolbar";
import TextStyleToolbar from "../Toolbars/TextStyleToolbar";
import HistoryToolbar from "../Toolbars/HistoryToolbar";
import SideToolbarButton from "../utils/SideToolbarButton";
import { EMPTY_CONTENT_STATE } from "../utils/constants";
import canIAddBlock from "../utils/canIAddBlock";

import "draft-js/dist/Draft.css";
import "@draft-js-plugins/image/lib/plugin.css";
import "@draft-js-plugins/focus/lib/plugin.css";
import "@draft-js-plugins/side-toolbar/lib/plugin.css";
import "@draft-js-plugins/emoji/lib/plugin.css";

interface Props {
  initialValue?: RawDraftContentState;
  placeholder?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  toolbarHidden?: boolean;
  onChange: (val: RawDraftContentState) => void;
  onBlur?: (e?: SyntheticEvent<any>) => void;

  // plugins
  plugins: Array<"embed" | "image" | "emoji">;
  oEmbedApi?: (url: string) => Promise<any>;
  uploadImageApi?: (file: File, fileName: string) => Promise<string>;
  large?: boolean;
}
interface State {
  editorState: EditorState;
}

const emojiPlugin = createVIEmojiPlugin({
  useNativeArt: true,
  selectButtonContent: <Icon name="smile outline" />,
});
const emptyEmbedPlugin = createEmbedPlugin(); // workaround because api does not come from commons :(
const sideToolbarPlugin = createSideToolbarPlugin({
  sideToolbarButtonComponent: SideToolbarButton,
});
const paragraphPlugin = createParagraphPlugin();
const linkPlugin = createLinkPlugin();
const focusPlugin = createFocusPlugin();
const alignmentPlugin = createVIAlignmentPlugin();
const captionsPlugin = createCaptionsPlugin();
const imagePlugin = createImagePlugin({
  decorator: composeDecorators(
    alignmentPlugin.decorator,
    focusPlugin.decorator,
    captionsPlugin.decorator,
  ),
});

const { EmojiSuggestions, EmojiSelect, VIEmojiSelectWrapper } = emojiPlugin;
const { SideToolbar } = sideToolbarPlugin;
const { AlignmentTool } = alignmentPlugin;
const { EmbedButton } = emptyEmbedPlugin;

const StyledDiv = styled.div<{ show: boolean }>(({ show }) => ({
  "* #CATCHME": {
    // sad workaround for sidetoolbar to work
    display: show ? "block" : "none!important",
  },
}));
class RichTextEditor extends Component<Props, State> {
  editor: HTMLDivElement | undefined;

  plugins: EditorPlugin[] | undefined;

  constructor(props: Props) {
    super(props);
    const { initialValue, oEmbedApi } = props;

    // SET PLUGINS
    this.plugins = [
      linkPlugin,
      paragraphPlugin,
      // image functionality
      focusPlugin,
      alignmentPlugin,
      imagePlugin,
      captionsPlugin,
      // embed functionality
      sideToolbarPlugin,
      oEmbedApi
        ? createEmbedPlugin({ options: { getOEmbedApi: oEmbedApi } })
        : emptyEmbedPlugin,
      // emoji functionality
      emojiPlugin,
    ];

    // SET INITAL VALUE
    let editorState = EditorState.createWithContent(EMPTY_CONTENT_STATE);

    if (initialValue) {
      try {
        const content = EditorState.createWithContent(
          convertFromRaw(initialValue),
        );
        editorState = content;
      } catch (err) {
        console.error(
          "Invalid editor state supplied, falling back to empty content",
        );
      }
    }

    // remove this lint rule
    // eslint-disable-next-line react/state-in-constructor
    this.state = { editorState };
  }

  onChange = (editorState: EditorState) => {
    const { onChange } = this.props;
    this.setState({
      editorState,
    });
    const content = editorState.getCurrentContent();
    const rawContent = convertToRaw(content);
    onChange(rawContent);
  };

  // redundant if no custom command handling outside plugins
  // handleKeyCommand = (command: DraftEditorCommand) => {
  //   const { editorState } = this.state;
  //   const newState = RichUtils.handleKeyCommand(editorState, command);
  //   if (newState) {
  //     this.onChange(newState);
  //     return "handled";
  //   }
  //   return "not-handled";
  // };

  focusEditor = () => {
    // eslint-disable-next-line no-unused-expressions
    this.editor?.focus();
  };

  render() {
    const { editorState } = this.state;
    const {
      placeholder,
      disabled,
      onBlur,
      uploadImageApi,
      plugins,
      toolbarHidden,
      large,
    } = this.props;

    const showSideToolbar =
      canIAddBlock(editorState, OEMBED_ADD_FORM_TYPE) && !toolbarHidden;

    return (
      <BaseEditor>
        <StyledDiv show={showSideToolbar}>
          {!toolbarHidden && (
            <StyledToolbar>
              <InlineStyleToolbar
                editorState={editorState}
                onChange={this.onChange}
              />
              <BlockStyleToolbar
                editorState={editorState}
                onChange={this.onChange}
              />
              <LinkToolbar
                editorState={editorState}
                onChange={this.onChange}
                addLink={linkPlugin.addLink}
                removeLink={linkPlugin.removeLink}
                isLinkSelected={linkPlugin.isLinkSelected}
              />
              {plugins?.includes("image") && (
                <ImageToolbar
                  editorState={editorState}
                  onChange={this.onChange}
                  modifier={imagePlugin.addImage}
                  uploadImageApi={uploadImageApi}
                />
              )}
              <TextStyleToolbar
                editorState={editorState}
                onChange={this.onChange}
              />
              <HistoryToolbar
                editorState={editorState}
                onChange={this.onChange}
              />
              {plugins?.includes("emoji") && (
                <VIEmojiSelectWrapper>
                  <EmojiSelect />
                </VIEmojiSelectWrapper>
              )}
            </StyledToolbar>
          )}
          <StyledEditor
            onClick={this.focusEditor}
            className="ui segment"
            toolbarHidden={toolbarHidden}
            large={large}
          >
            <Editor
              placeholder={placeholder}
              editorState={editorState}
              readOnly={disabled}
              // handleKeyCommand={this.handleKeyCommand}
              onChange={this.onChange}
              onBlur={onBlur}
              ref={(el: any) => {
                this.editor = el;
              }}
              plugins={this.plugins}
              spellCheck
            />
            {plugins?.includes("image") && <AlignmentTool />}
            {plugins?.includes("emoji") && <EmojiSuggestions />}
          </StyledEditor>
          {plugins?.includes("embed") && (
            <SideToolbar>
              {(externalProps) => (
                <>
                  <EmbedButton {...externalProps} />
                </>
              )}
            </SideToolbar>
          )}
        </StyledDiv>
      </BaseEditor>
    );
  }
}

export default RichTextEditor;

import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Prompt } from "react-router-dom";
import { useImmer } from "use-immer";
import { List, Button, Input, Loader } from "semantic-ui-react";
import styled from "styled-components";
import useAuthorizedContext from "hooks/useAuthorizedContext";

import {
  getOrganizationUrls,
  postOrganizationUrls,
  updateOrganizationSlugs,
} from "../../api/OrganizationsApi";
import TableTitleGrid from "../templates/TableTitleGrid";
import SimpleButton, {
  definedButtonTypes,
} from "../atoms/Buttons/SimpleButton/SimpleButton";
import Typography from "../atoms/Typography/Typography";
import { definedTypographyTypes } from "../atoms/Typography/TypographyStyles";
import { hasWhiteSpace, slugifyWord } from "../../helpers/globalFunctions";
import ImportantModal from "../templates/Modals/ImportantModal";
import OrgSlugsForm from "../organisms/Forms/OrgSlugsForm";

export interface ISlugRow {
  name: string;
  pseudonym: string;
  edit?: boolean;
}
const StyledListItem = styled(List.Item)<{ edited: boolean }>(({ edited }) => ({
  backgroundColor: edited ? "beige" : "initial",
}));
const OrgSlugsPage: React.FC = () => {
  const { token } = useAuthorizedContext();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [initial, setInitial] = useImmer<Array<ISlugRow>>([]);
  const [updated, setUpdated] = useImmer<Array<ISlugRow>>([]);
  const [orgSlugs, setOrgSlugs] = useImmer<
    Array<{ shortSlug: string; slug: string }>
  >([]);
  const [tempVal, setTempVal] = useState("");
  const [updatedIds, setUpdatedIds] = useState<Array<string>>([]);
  const [refetch, setRefetch] = useState(false);

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;
    const doFetch = async () => {
      setLoading(true);
      try {
        const res = await getOrganizationUrls();
        if (!signal.aborted) {
          const { items } = res;
          const sortedItems = items.sort((a: any, b: any) => {
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1;
            }
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          setInitial(() => [...sortedItems]);
          setUpdated(() => [...sortedItems]);
        }
      } catch (e) {
        if (!signal.aborted) {
          console.error(e);
          toast.error("Kaut kas nogāja greizi.");
        }
      } finally {
        if (!signal.aborted) setLoading(false);
      }
    };
    doFetch();
    return () => {
      abortController.abort();
    };
  }, [refetch, setInitial, setUpdated]);

  const submitSelection = async () => {
    setLoading(true);
    try {
      const toPublish = updated.map((item) => ({
        name: item.name,
        pseudonym: item.pseudonym,
      }));
      const idToken = await token();
      // Update orgSlugs for admin and individual org attribute
      await postOrganizationUrls(idToken, toPublish);
      await updateOrganizationSlugs(idToken, orgSlugs);
    } catch (err) {
      console.error(err);
      toast.error("Kaut kas nogāja greizi.");
    } finally {
      setOrgSlugs((draft) => []);
      toast.success("Organizāciju saites atjaunotas!");
      setLoading(false);
      setUpdatedIds([]);
      setRefetch(true);
    }
  };
  const revertItems = () => {
    setUpdatedIds([]);
    setUpdated((draft) => {
      return initial;
    });
    setOrgSlugs((draft) => {
      return [];
    });
  };

  // List Item fn:
  const createItem = (obj: { name: string; pseudonym: string }) => {
    setUpdatedIds((prevState) => {
      return [...prevState, `${updated.length}`];
    });
    const { name, pseudonym } = obj;
    setOrgSlugs((draft) => {
      return [...draft, { slug: pseudonym, shortSlug: name }];
    });
    setUpdated((draft) => {
      return [
        ...draft,
        { name: hasWhiteSpace(name) ? slugifyWord(name) : name, pseudonym },
      ];
    });
    setOpen(false);
  };

  const removeItem = (idx: number) => {
    // update orgSlugs array
    setOrgSlugs((draft) => {
      const removed = {
        slug: updated[idx].pseudonym,
        shortSlug: updated[idx].pseudonym,
      };
      // check if already exists
      const index = draft.findIndex((el) => el.slug === updated[idx].pseudonym);
      if (index > -1) {
        draft[index] = removed;
        return draft;
      }
      return [...draft, removed];
    });
    // magic to see updated Ids
    setUpdatedIds((prev) => {
      const newIds: string[] = [];
      prev.forEach((el: string) => {
        const num = Number(el);
        if (num > 0) {
          if (num !== idx) {
            newIds.push(el);
          }
          if (idx < num) {
            const arrIdx = newIds.indexOf(el);
            newIds.splice(arrIdx, 1);
            newIds.push(`${num - 1}`);
          }
        } else if (num === 0) {
          newIds.push("0");
        }
      });
      return [...newIds, `-${idx}`];
    });
    setUpdated((draft) => {
      draft.splice(idx, 1);
      return draft;
    });
  };
  const selectEditable = (idx: number) => {
    setUpdated((draft) => {
      draft.map((item, rowIdx) => {
        if (item.edit && idx !== rowIdx) {
          item.edit = false;
        }
        return item;
      });

      setTempVal(draft[idx].name);
      if (draft[idx].edit) {
        draft[idx].edit = false;
      } else {
        draft[idx].edit = true;
      }
      return draft;
    });
  };
  const editItem = (idx: number) => {
    if (updated[idx].name !== tempVal) {
      setUpdatedIds((prevState) => {
        return [...prevState, `${idx}`];
      });
    }
    // add To orgSlugArr for Org data update
    setOrgSlugs((draft) => {
      return [...draft, { slug: updated[idx].pseudonym, shortSlug: tempVal }];
    });

    setUpdated((draft) => {
      draft[idx].name = hasWhiteSpace(tempVal) ? slugifyWord(tempVal) : tempVal;
      draft[idx].edit = false;
      return draft;
    });
  };

  if (loading) {
    return <Loader active={loading} inline="centered" />;
  }

  return (
    <div>
      <Prompt
        when={
          JSON.stringify(initial) !== JSON.stringify(updated) &&
          updatedIds.length > 0
        }
        message="Tev ir nesaglabātas izmaiņas, vai tiešām velies pamest šo lapu?"
      />
      <TableTitleGrid title="Izveidot Organizācijas saiti">
        {JSON.stringify(initial) !== JSON.stringify(updated) &&
          updatedIds.length > 0 && (
            <SimpleButton
              icon="redo alternate"
              variant={definedButtonTypes.default}
              loading={loading}
              onClick={revertItems}
            />
          )}
        <ImportantModal
          key="add-item"
          open={open}
          trigger={
            <Button size="small" icon="plus" onClick={() => setOpen(true)} />
          }
        >
          <OrgSlugsForm onClose={setOpen} onSubmit={createItem} />
        </ImportantModal>
        <SimpleButton
          disabled={
            JSON.stringify(initial) === JSON.stringify(updated) ||
            updatedIds.length === 0
          }
          variant={definedButtonTypes.primary}
          loading={loading}
          onClick={submitSelection}
        >
          Saglabāt izmaiņas
        </SimpleButton>
      </TableTitleGrid>
      <List divided verticalAlign="middle" ordered>
        {updated.map((row, idx) => {
          const highlighted = updatedIds.includes(String(idx));
          return (
            <StyledListItem key={row.name} edited={highlighted ? 1 : 0}>
              {row?.edit ? (
                <>
                  <List.Content floated="right">
                    <Button
                      icon="check circle outline"
                      onClick={() => editItem(idx)}
                      disabled={loading}
                      color="green"
                    />
                    <Button
                      color="red"
                      icon="times circle outline"
                      onClick={() => selectEditable(idx)}
                      disabled={loading}
                    />
                  </List.Content>
                  <List.Content>
                    <Input
                      focus
                      fluid
                      value={tempVal}
                      onChange={(e, data) => setTempVal(data.value)}
                    />
                    <Typography type={definedTypographyTypes.caption}>
                      Īstā saite:
                    </Typography>
                    <Typography
                      type={definedTypographyTypes.caption}
                      color="red"
                    >
                      {" "}
                      {row.pseudonym}
                    </Typography>
                  </List.Content>
                </>
              ) : (
                <>
                  <List.Content floated="right">
                    <Button
                      icon="pencil alternate"
                      onClick={() => selectEditable(idx)}
                      disabled={loading}
                    />
                    <Button
                      icon="trash alternate outline"
                      onClick={() => removeItem(idx)}
                      disabled={loading}
                    />
                  </List.Content>
                  <List.Content>
                    <List.Header>{row.name}</List.Header>
                    <Typography type={definedTypographyTypes.caption}>
                      Īstā saite:
                    </Typography>
                    <Typography
                      type={definedTypographyTypes.caption}
                      color="red"
                    >
                      {" "}
                      {row.pseudonym}
                    </Typography>
                  </List.Content>
                </>
              )}
            </StyledListItem>
          );
        })}
      </List>
    </div>
  );
};

export default OrgSlugsPage;

import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
} from "amazon-cognito-identity-js";

import CognitoUserAttrs from "./CognitoUserAttrs";
import {
  authenticateUserPromise,
  getSessionPromise,
  refreshSessionPromise,
} from "./promiseWrappers";

const USER_POOL_OPTS = {
  UserPoolId: "eu-central-1_FY9dnBI1u", // TODO ENV
  ClientId: process.env.REACT_APP_AWS_CLIENT_ID || "",
  // storage: Storage,
};

class Cognito {
  private Pool: CognitoUserPool;

  private constructor(Pool: CognitoUserPool) {
    this.Pool = Pool;
  }

  /*
   * To initialize user pool with user you need to call getSession
   * This deals with session refresh and cache clearance.
   * Should never fail.
   */
  public static async init() {
    const pool = new CognitoUserPool(USER_POOL_OPTS);
    const user = pool.getCurrentUser();
    if (user) {
      await getSessionPromise(user).catch((err) =>
        console.error("Failed to initialize user", err),
      );
    }

    return new Cognito(pool);
  }

  private getUser(): CognitoUser | null {
    return this.Pool.getCurrentUser();
  }

  /*
   * You dont need to call isValid after this. It should return a valid, refreshed session.
   */
  private async getSession(): Promise<CognitoUserSession | null> {
    const user = this.getUser();
    if (!user) return null;
    return getSessionPromise(user);
  }

  /*
   * Should never fail.
   */
  public async getCurrUserAttrs(): Promise<CognitoUserAttrs | null> {
    const session = await this.getSession().catch((err) =>
      console.error("Failed to getCurrUserAttrs", err),
    );
    if (!session) return null;

    const userToken = session.getIdToken().payload;

    return new CognitoUserAttrs(userToken);
  }

  /*
   * Should never fail.
   */
  public getCurrentIdJwt = async (): Promise<string | undefined> => {
    const session = await this.getSession()
      .catch((err) => console.error("Failed to getCurrentIdJwt", err))
      .then((res) => res || null);
    return session?.getIdToken().getJwtToken();
  };

  /*
   * Will fail on err.
   */
  public refreshSession = async () => {
    const user = this.getUser();
    if (!user) throw new Error("No user to refresh.");
    const session = await this.getSession();
    if (!session?.isValid()) throw new Error("Failed to get new session");

    const token = session.getRefreshToken();
    return refreshSessionPromise(token, user);
  };

  /*
   * Will fail on err.
   */
  public signIn = async (Username: string, Password: string) => {
    const user = this.getUser();
    if (user) throw new Error("User already present...");
    const authDetails = new AuthenticationDetails({
      Username,
      Password,
    });
    const newUser = new CognitoUser({
      Username,
      Pool: this.Pool,
      // Storage,
    });
    await authenticateUserPromise(newUser, authDetails);
  };

  /*
   * Will fail on err.
   */
  public signOut = () => {
    const user = this.getUser();
    user?.signOut();
  };

  /**
   * AVOID! Does not refresh session!
   */
  public static getIdTokenFromStorage(): string {
    const keyPrefix = `CognitoIdentityServiceProvider.${USER_POOL_OPTS.ClientId}`;
    const userNameKey = `${keyPrefix}.LastAuthUser`;
    const username = localStorage.getItem(userNameKey);
    if (!username) return "";
    const idTokenKey = `${keyPrefix}.${username}.idToken`;
    const idToken = localStorage.getItem(idTokenKey);
    return idToken || "";
  }
}

export default Cognito;

import * as React from "react";
import { List } from "semantic-ui-react";

import Typography, {
  definedTypography,
} from "../../atoms/Typography/Typography";

export interface ContactDetailListProps {
  phone?: string[];
  email?: string[];
  website?: string;
  address?: string;
}

const ContactDetailList: React.FC<ContactDetailListProps> = ({
  address,
  phone,
  email,
  website,
}) => {
  return (
    <List>
      {website && (
        <List.Item key={website}>
          <List.Icon name="world" id="world" />
          <List.Content>
            <Typography type={definedTypography.caption}>
              <a href={website}>{website}</a>
            </Typography>
          </List.Content>
        </List.Item>
      )}
      {email?.map((item) => (
        <List.Item key={item}>
          <List.Icon name="mail" id="mail" />
          <List.Content>
            <Typography type={definedTypography.caption}>{item}</Typography>
          </List.Content>
        </List.Item>
      ))}
      {phone?.map((item) => (
        <List.Item key={item}>
          <List.Icon name="phone" id="phone" />
          <List.Content>
            <Typography type={definedTypography.caption}>{item}</Typography>
          </List.Content>
        </List.Item>
      ))}
      {address && (
        <List.Item key={address}>
          <List.Icon name="map marker alternate" id="address" />
          <List.Content>
            <Typography type={definedTypography.caption}>{address}</Typography>
          </List.Content>
        </List.Item>
      )}
    </List>
  );
};

export default ContactDetailList;

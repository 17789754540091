import React, { memo, useState, useLayoutEffect } from "react";
import { Image } from "semantic-ui-react";

import FormTextInput from "./FormTextInput";

import FormSegment from "../../templates/Wrappers/FormSegment";
import { StyledDropzoneContainer } from "../../../styles/DropzoneStyles";
import {
  YouTubeParser,
  imageDimensions,
} from "../../../helpers/globalFunctions";

interface FormYoutubeLinkProps {
  id: string;
  value: string;
  label?: string;
  onChange: (id: string, value: string) => void;
  required?: boolean;
}

const DEFAULT_THUMBNAIL = "https://img.youtube.com/vi/default.jpg";

const FormYoutubeLink: React.FC<FormYoutubeLinkProps> = ({
  id,
  value,
  onChange,
  label,
  required,
}) => {
  const [imageLink, setImageLink] = useState("");

  useLayoutEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;

    let youtubeValue = "";
    try {
      youtubeValue = YouTubeParser(value);
    } catch (err) {
      youtubeValue = "";
    }

    if (youtubeValue === "") {
      setImageLink(DEFAULT_THUMBNAIL);
      return;
    }

    // we need to check validity of image
    // not every video has maxresdefault thumbnail
    // if its missing, youtube returns default image with 120px width
    // thus we default to hqdefault, which always exist
    const asyncFn = async () => {
      try {
        let image = `https://img.youtube.com/vi/${youtubeValue}/maxresdefault.jpg`;
        const dimensions = await imageDimensions(image);
        if (dimensions.width === 120) {
          image = `https://img.youtube.com/vi/${youtubeValue}/hqdefault.jpg`;
        }
        if (!signal.aborted) setImageLink(image);
      } catch (e) {
        console.error(e);
      }
    };

    asyncFn();

    return () => {
      abortController.abort();
    };
  }, [value]);

  return (
    <>
      <FormTextInput
        id={id}
        value={value}
        onChange={onChange}
        required={required}
        label={label}
      />
      <FormSegment>
        <StyledDropzoneContainer>
          <Image src={imageLink} />
        </StyledDropzoneContainer>
      </FormSegment>
    </>
  );
};

export default memo(FormYoutubeLink);

import React from "react";
import { useTranslation } from "react-i18next";
import { Dimmer, Icon } from "semantic-ui-react";

export interface DropzoneDropperProps {
  isDragActive: boolean;
}

const DropzoneDropper: React.FC<DropzoneDropperProps> = ({ isDragActive }) => {
  const { t } = useTranslation();
  return (
    <Dimmer active={isDragActive}>
      <Icon name="arrow down" size="huge" />
      {t("CommonComponents.Dropzone.DropFileHere")}
    </Dimmer>
  );
};

export default DropzoneDropper;

import { EditorState } from "draft-js";

import getSelectedBlocksMap from "./getSelectedBlocksMap";

function getSelectedBlock(editorState: EditorState) {
  if (editorState) {
    return getSelectedBlocksMap(editorState).toList().get(0);
  }
  return undefined;
}

export default getSelectedBlock;

/* eslint-disable no-unused-expressions */
import React, { Component } from "react";
import Cognito from "api/cognito/Cognito";
import { EntityForm } from "api";
import CognitoUserAttrs from "api/cognito/CognitoUserAttrs";
import Page401 from "components/pages/Page401";
import LogInPage from "components/pages/LogInPage";

import { getOrganizations } from "../api/OrganizationsApi";
import { getCompanies } from "../api/CompaniesApi";

interface IAuthContextState {
  user: CognitoUserAttrs | null;
  orgData: EntityForm[];
  loading: boolean;
}

export interface IAuthContext extends IAuthContextState {
  login: (username: string, password: string) => Promise<void>;
  logout: () => void;
  refresh: () => Promise<void>;
  token: () => Promise<string>;
}
export const AuthContext = React.createContext<IAuthContext | null>(null);

class AuthContextProvider extends Component<{}, IAuthContextState> {
  state: IAuthContextState = {
    user: null,
    orgData: [], // this is not sensitive, no care is needed upon login
    loading: true,
  };

  cognito: Cognito | undefined;

  async componentDidMount() {
    try {
      this.cognito = await Cognito.init();
      const user = await this.cognito.getCurrUserAttrs();
      this.setState({ user, loading: false });
    } catch (err) {
      console.error("Failed to init cognito", err);
      this.setState({ user: null, loading: false });
    }
  }

  async componentDidUpdate() {
    const { user, orgData } = this.state;
    if (user?.admin && !orgData.length) {
      try {
        const [orgs, companies] = await Promise.all([
          getOrganizations({
            limit: 1500,
            offset: 0,
            lite: true,
          }),
          getCompanies({
            limit: 100,
            offset: 0,
            lite: true,
          }),
        ]);
        const userGroups = [...orgs.items, ...companies.items];
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ orgData: userGroups });
      } catch (err) {
        console.error("failed to get organizations", err);
      }
    }
  }

  /*
   * WILL THROW
   */
  handleLogIn = async (username: string, password: string) => {
    // TODO will throw
    try {
      this.setState({ loading: true });
      await this.cognito?.signOut();
      await this.cognito?.signIn(username, password);
      const attrs = await this.cognito?.getCurrUserAttrs();
      if (!attrs) {
        this.cognito?.signOut();
        throw new Error("Failed to get User Attributes");
      }
      this.setState({ user: attrs, loading: false });
    } catch (err) {
      this.setState({ user: null, loading: false });
      throw err;
    }
  };

  /*
   * WILL THROW
   */
  handleLogout = () => {
    this.cognito?.signOut();
    this.setState({ user: null });
  };

  /*
   * WILL THROW
   */
  handleRefresh = async () => {
    await this.cognito?.refreshSession();
  };

  handleGetToken = async () => {
    const token = await this.cognito?.getCurrentIdJwt();
    return token || "";
  };

  render() {
    const { user, orgData, loading } = this.state;
    const { children } = this.props;

    return (
      <AuthContext.Provider
        value={{
          loading,
          user,
          orgData,
          login: this.handleLogIn,
          logout: this.handleLogout,
          refresh: this.handleRefresh,
          token: this.handleGetToken,
        }}
      >
        {!user && <LogInPage />}
        {user && !user.admin() && <Page401 />}
        {user && user.admin() && children}
      </AuthContext.Provider>
    );
  }
}

export default AuthContextProvider;

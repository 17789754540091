import React from "react";
import { Flag, FlagNameValues } from "semantic-ui-react";

interface Props {
  flag: FlagNameValues;
}

const FlagLabel: React.FC<Props> = ({ flag, children }) => {
  return (
    <>
      <Flag name={flag} />
      {children}
    </>
  );
};

export default FlagLabel;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { Icon } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
const AuthorContainer = styled.div({
    position: "relative",
    zIndex: 2,
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "-2em",
});
const AuthorContent = styled.div(({ theme }) => {
    var _a, _b, _c, _d;
    return ({
        backgroundColor: (_b = (_a = theme === null || theme === void 0 ? void 0 : theme.palette) === null || _a === void 0 ? void 0 : _a.mono) === null || _b === void 0 ? void 0 : _b.darker,
        color: (_d = (_c = theme === null || theme === void 0 ? void 0 : theme.palette) === null || _c === void 0 ? void 0 : _c.mono) === null || _d === void 0 ? void 0 : _d.white,
        padding: "0.3em 0.7em",
    });
});
const ImageAuthor = ({ imageAuthor }) => {
    const { t } = useTranslation();
    return (_jsx(AuthorContainer, { children: _jsxs(AuthorContent, { children: [_jsx(Icon, { name: "photo", id: "photo" }), t("PhotoAuthor"), imageAuthor] }) }));
};
export default ImageAuthor;

import { jsx as _jsx } from "react/jsx-runtime";
import { Label } from "semantic-ui-react";
import styled from "styled-components";
import { ATTACHED_LABEL_TYPES } from "./constants";
const StyledAttachedLabel = styled(Label)(({ theme, type }) => ({
    "&&&": Object.assign({ marginBottom: "1em", marginLeft: "-0.5em", color: theme.palette.mono.white, backgroundColor: theme.palette.primary.main, borderRadius: "0" }, (type === ATTACHED_LABEL_TYPES.imageGallery && {
        marginLeft: "0",
        marginBottom: "0",
        marginTop: "1em",
        marginRight: "-0.5em",
        padding: "0.5em 1.5em",
        ".icon": {
            margin: "0",
        },
    })),
}));
const AttachedLabel = ({ attached, children, type, }) => (_jsx(StyledAttachedLabel, Object.assign({ type: type, attached: attached }, { children: children })));
AttachedLabel.defaultProps = {
    attached: "bottom left",
    type: ATTACHED_LABEL_TYPES.category,
};
export default AttachedLabel;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from "react";
import { FormField, Radio, FormGroup } from "semantic-ui-react";
const FormRadioGroup = ({ id, label, options, value, onChange, required, }) => {
    const changeFn = (_, data) => {
        onChange(id, data.value);
    };
    return (_jsxs(FormGroup, Object.assign({ id: id, required: required, grouped: true }, { children: [label && _jsx("label", Object.assign({ htmlFor: id }, { children: label })), options.map((optionProps) => (_jsx(FormField, { children: _jsx(Radio, { label: optionProps.label, name: optionProps.name, value: optionProps.value, disabled: optionProps.disabled, checked: optionProps.value === value, onChange: changeFn }) }, optionProps.name)))] })));
};
export default memo(FormRadioGroup);

import styled from "styled-components";
import { Form } from "semantic-ui-react";

export const StyledForm = styled(Form)`
  &&& {
    margin-left: 10px;
  }
`;

export const StyledWrapper = styled.div`
  &&& {
    text-align: right;
  }
`;

import React, { useState, useEffect } from "react";
import { Loader } from "semantic-ui-react";

import { GetTildeTranslation } from "../../../api/tildeApi";
import useGlobalContext from "../../../hooks/useGlobalContext";

export interface TildeTranslateProps {
  text: string;
  translate?: boolean;
}

const TildeTranslate: React.FC<TildeTranslateProps> = ({ text, translate }) => {
  const [loading, setLoading] = useState(true);
  const [translation, setTranslation] = useState(text);
  const {
    locale: { translationLocale },
  } = useGlobalContext();
  useEffect(() => {
    const doTildeTranslation = async () => {
      if (translate) {
        const tildeTranslation = await GetTildeTranslation(
          text,
          translationLocale,
        );
        setTranslation(tildeTranslation);
      }
      setLoading(false);
    };
    doTildeTranslation();
  }, [translation, text, translate, translationLocale]);

  if (translate) {
    if (loading)
      return (
        <>
          <Loader active inline="centered" size="mini" />
        </>
      );
    return <>{translation}</>;
  }
  return <>{text}</>;
};

export default TildeTranslate;

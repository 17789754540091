import { IconProps } from "semantic-ui-react";
import slugify from "slugify";

import { IndexSignature, ISearchQuery } from "../interfaces/index";
import { IFilters } from "../api";
import { s3Url } from "../api/ImageApi";

export const ImageCloudUrl = "https://d2uxl5q68iycnn.cloudfront.net";

export async function doesImageExist(path: string) {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = path;
    img.onload = () => resolve(true);
    img.onerror = () => resolve(false);
  });
}
export function openSocialMedia(url: string) {
  if (url === "Facebook") window.open("https://www.facebook.com/");
  else if (url === "Instagram") window.open("https://www.instagram.com/");
}

export const readBufferFromFile = async (file: File): Promise<Buffer> => {
  const result = await new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;

    reader.readAsArrayBuffer(file);
  });

  if (result instanceof ArrayBuffer) {
    return Buffer.from(new Uint8Array(result));
  }

  throw new Error("result is not a buffer :(");
};

export function formatDate(date: any) {
  if (!date) return null!;
  const parseDate = date.toString().substring(0, 16);
  return parseDate;
}

export function openShareDialog(
  e: any,
  provider: { icon: IconProps["name"]; url: string; name: string },
) {
  window.open(provider.url, "share-dialog", "width=626,height=436,toolbar=0");
  e.preventDefault();
}

export function YouTubeParser(url: string) {
  // eslint-disable-next-line no-useless-escape
  const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  const match = url.match(regExp);
  if (match && match[2].length === 11) {
    return match[2];
  }

  throw new Error("Not a valid youtube url");
}

export const readStringFromFile = async (
  file: File | undefined | Blob,
): Promise<string | undefined> => {
  if (file === undefined) return undefined;

  const result = await new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onabort = () => {
      reject(new Error("file reading has aborted"));
    };
    reader.onerror = () => {
      reject(new Error("file reading has failed"));
    };
    reader.onload = () => {
      resolve(reader.result);
    };
  });

  if (typeof result === "string") return result;

  throw new Error("readStringFromFile received invalid result");
};

export const slugifyWord = (word: string) => {
  const newWord = word.replace(/[*+~.()'"!#$?:@]/g, "");
  return slugify(newWord, { strict: true, lower: true });
};
export function PasswordMatchesRules(password: string) {
  // match 1 Capital letter, 1 normal letter, 1 number, at least 7 length
  const regexMatch = password.match(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d].{7,}$/);
  return regexMatch && password.length > 6;
}

export function CheckUniqueKeyWord(source: string[], target: string[]) {
  target.forEach((t) => {
    if (!source.includes(t)) {
      source.push(t);
    }
  });
}

export function QueryStringBuilder(category: string, subcategories?: string[]) {
  const base = `?category=${category}`;
  if (subcategories) {
    return `${base}&subcategory=${subcategories.join(",")}`;
  }
  return base;
}

export const imageDimensions = async (
  link: string,
): Promise<{ width: number; height: number }> =>
  new Promise((resolve, reject) => {
    const img = new Image();

    // the following handler will fire after the successful parsing of the image
    img.onload = () => {
      const { naturalWidth: width, naturalHeight: height } = img;
      resolve({ width, height });
    };

    // and this handler will fire if there was an error with the image (like if it's not really an image or a corrupted one)
    img.onerror = () => {
      reject("There was some problem with the image.");
    };

    img.src = link;
  });

export async function findBestThumbnailFromYoutubeLink(link: string) {
  // export async function findBestThumbnailFromYoutubeLink(link: string) {
  let youtubeValue = "";
  try {
    youtubeValue = YouTubeParser(link);
  } catch (err) {
    youtubeValue = "";
  }

  const googleAPI = `https://www.googleapis.com/youtube/v3/videos?key=${process.env.REACT_APP_YOUTUBE_API_KEY}&part=snippet&id=${youtubeValue}`;

  const response = await fetch(googleAPI);
  const video = await response.json();

  // https://developers.google.com/youtube/v3/docs/videos#snippet.thumbnails
  // default – The default thumbnail image. The default thumbnail for a video – or a resource that refers to a video, such as a playlist item or search result – is 120px wide and 90px tall. The default thumbnail for a channel is 88px wide and 88px tall.
  // medium – A higher resolution version of the thumbnail image. For a video (or a resource that refers to a video), this image is 320px wide and 180px tall. For a channel, this image is 240px wide and 240px tall.
  // high – A high resolution version of the thumbnail image. For a video (or a resource that refers to a video), this image is 480px wide and 360px tall. For a channel, this image is 800px wide and 800px tall.
  // standard – An even higher resolution version of the thumbnail image than the high resolution image. This image is available for some videos and other resources that refer to videos, like playlist items or search results. This image is 640px wide and 480px tall.
  // maxres – The highest resolution version of the thumbnail image. This image size is available for some videos and other resources that refer to videos, like playlist items or search results. This image is 1280px wide and 720px tall.
  let { url } = video.items[0].snippet.thumbnails.default;
  if (video.items[0].snippet.thumbnails.maxres?.url) {
    url = video.items[0].snippet.thumbnails.maxres.url;
  } else if (video.items[0].snippet.thumbnails.high.url) {
    url = video.items[0].snippet.thumbnails.high.url;
  }
  return url || "https://img.youtube.com/vi/default.jpg";
}

export function queryStringBuilder(filters?: IFilters) {
  if (filters) {
    const f: string[] = [];
    Object.entries(filters).forEach(([filterKey, filterValues]) => {
      const key = filterKey as string;
      const values = filterValues as string[] | string;
      if (Array.isArray(values) && values.length > 0)
        f.push(`${key}=${values.join(",")}`);
      else if (values !== undefined && values !== "")
        f.push(`${key}=${values}`);
    });
    return f.length ? `?${f.join("&")}` : "";
  }

  return "";
}
export function getThumbnailLink(link?: string) {
  if (!link) return undefined;
  if (!link.includes(s3Url)) return link;
  const imgKey = link.split("/").pop();
  return `${ImageCloudUrl}/fit-in/450x220/filters:quality(80)/${imgKey}`;
}

export function scrollToTop() {
  return window.scrollTo({ top: 0, behavior: "smooth" });
}

export const MapIframeFieldsToFilter = (state: IndexSignature) => {
  const newFilter: IFilters = {
    limit: 12,
    offset: 0,
  };
  Object.keys(state).forEach((key) => {
    const value = state[key];
    if (key === "country" && value.length > 0) newFilter.countries = value;
    else if (key === "type" && value.length > 0) newFilter.type = value;
    else if (key === "amount") newFilter.limit = value;
    else if (key === "category" && value.length > 0) newFilter.category = value;
    else if (key === "special") {
      if (value === "izcelts") newFilter.highlighted = "true";
      else if (value === "izcelts-sakumlapa") {
        newFilter.highlighted = "true";
        newFilter.displayInHomePage = "true";
      } else if (value === "radit-sakumlapa")
        newFilter.displayInHomePage = "true";
      else if (value === "izcelts-valsti")
        newFilter.highlightedInCountry = "true";
    } else if (key === "language") newFilter.lng = value;
    else if (key === "showAllRecords" && value)
      newFilter.showAllRecords = "true";
  });

  const query = queryStringBuilder(newFilter);
  return `${query}`;
};

export const hasWhiteSpace = (s: string) => {
  return /\s/g.test(s);
};

export function ParseQueryValues(attributes: string[]) {
  const keys: IndexSignature = {};
  attributes.forEach((att) => {
    const keyValuePair = att.split("=");
    const key = keyValuePair[0];
    const value = keyValuePair[1];
    keys[key] = value;
  });

  const query: ISearchQuery = {
    queryValue: keys.query,
    limit: Number(keys.limit || 12),
    offset: Number(keys.offset) || 0,
  };
  return query;
}

export function QueryBuilder(query: ISearchQuery) {
  const { limit, offset, queryValue } = query;

  return `?query=${queryValue}&limit=${limit}&offset=${offset}`;
}

export const sortByKey = (list: IndexSignature[], key: string) => {
  return list.sort((a, b) => (a[key] > b[key] ? 1 : -1));
};

export enum ORG_CATEGORY {
  alliance = "apvieniba",
  society = "biedriba",
  community = "kopiena",
  religion = "religija",
  education = "izglitiba",
  culture = "kultura-maksla",
  sport = "sports",
  latvians = "latviesu-centrs",
  media = "mediji-reklama",
  government = "valsts-parvalde",
  other = "cits",
  company = "uznemums",
  service = "pakalpojumi",

  embassy = "vestnieciba",
  organization = "organizacijas",

  // association = "asociacija",
  // club = "klubs",
  // defense = "tieslietas-aizsardziba",
  // nvo = "nvo",
  // federation = "federacija",
  // foundation = "fonds",
  // union = "savieniba",
  // charity = "labdaribas-organizacija",
  // tradeUnion = "arodbiedriba",
  // veterans = "veteranu-organizacija",
  // tourism = "vide-turisms",
  // studentCorporation = "studentu-korporacija",
  // entertainment = "izklaide",
  // health = "labklajiba-veseliba",
  // economics = "finanses-ekonomika",
  // retired = "pensionaru-organizacija",
  // youthOrganization = "jaunatnes-organizacija",
  // it = "it-telekomunikacijas",
  // trade = "tirdznieciba",
  // logistics = "transports-logistika",
  // representation = "parstavnieciba",
}

export const ORG_CATEGORY_TITLE: { [key in ORG_CATEGORY]: string } = {
  apvieniba: "Apvienība",
  biedriba: "Biedrība",
  kopiena: "Kopiena",
  religija: "Reliģija",
  izglitiba: "Izglītība / Skoliņas",
  "kultura-maksla": "Kultūra / Māksla",
  sports: "Sports / Aktīvā atpūta",
  "latviesu-centrs": "Latviešu centrs",
  "mediji-reklama": "Mediji",
  "valsts-parvalde": "Valsts iestādes",
  cits: "Cits",
  uznemums: "Uzņēmums",
  pakalpojumi: "Pakalpojumi",

  vestnieciba: "Vēstniecība",
  organizacijas: "Organizācija",

  // asociacija: "Asociācija",
  // klubs: "Klubs",
  // "tieslietas-aizsardziba": "Aizsardzība / Iekšlietas",
  // nvo: "Nevalstiska organizācija",
  // federacija: "Federācija",
  // fonds: "Fonds",
  // savieniba: "Savienība",
  // "labdaribas-organizacija": "Labdarības organizācija",
  // arodbiedriba: "Arodbiedrība",
  // "veteranu-organizacija": "Veterānu organizācija",
  // "vide-turisms": "Vide / Tūrisms",
  // "studentu-korporacija": "Studentu korporācija",
  // izklaide: "Izklaide",
  // "labklajiba-veseliba": "Veselība / Medicīna",
  // "finanses-ekonomika": "Finanses / Ekonomika",
  // "pensionaru-organizacija": "Pensionāru organizācija",
  // "jaunatnes-organizacija": "Jaunatnes organizācija",
  // "it-telekomunikacijas": "IT / Telekomunikācijas",
  // tirdznieciba: "Tirdzniecība / Pārdošana",
  // "transports-logistika": "Transports / Loģistika",
  // parstavnieciba: "Pārstāvniecība",
};

interface ICategoryOpts {
  text: string;
  value: ORG_CATEGORY;
  key: string;
}
export const OrganizationCategoryOpts: Array<ICategoryOpts> = Object.entries(
  ORG_CATEGORY,
).map(([, value]) => ({
  text: ORG_CATEGORY_TITLE[value],
  value,
  key: value,
}));

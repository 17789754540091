import React, { ReactElement } from "react";
import { ButtonProps, Button } from "semantic-ui-react";
import moment from "moment-timezone";

export const renderGridElements = (
  col: number | undefined,
  element: ReactElement,
  keyName: string,
) => {
  const generatedArray = Array(col)
    .map((_, i) => i * i)
    .fill(1);
  return generatedArray.map((val, idx) => {
    const key = `${idx}-${keyName}-loader`;
    return <React.Fragment key={key}>{element}</React.Fragment>;
  });
};

export const SemanticButtonCP: React.FC<ButtonProps> = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  initialPadding,
  children,
  ...props
}) => {
  return <Button {...props}>{children}</Button>;
};

export const ConvertDateToUTC = (date: any, format?: string) => {
  const newFormat = format || "DD. MMM. YYYY HH:mm";
  const jsDate = new Date(date);
  return moment.utc(jsDate).format(`${newFormat}`);
};

import React, { useRef } from "react";
import styled from "styled-components";
import ContentEditable, { ContentEditableEvent } from "react-contenteditable";

interface Props {
  value: string;
  onFocus?: React.FocusEventHandler<HTMLElement> | undefined;
  onBlur?: React.FocusEventHandler<HTMLElement> | undefined;
  onKeyDown?: React.KeyboardEventHandler<HTMLDivElement> | undefined;
  onChange: (event: ContentEditableEvent) => void;
  placeholder: string;
}

const StyledFigcaption = styled.figcaption(({ theme }) => ({
  "[contenteditable=true]:empty:before": {
    fontFamily: '"Ubuntu", sans-serif', // Roboto
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "20px",
    ...(theme?.typography?.captions || {}), // Overwriting with theme, if available.

    content: "attr(placeholder)",
    display: "block",
    color: "#b3b3b1",
  },
  div: {
    outline: "none",
  },
  ":hover": {
    cursor: "text",
  },
}));

// im not sure why, but types went nuts on me, should i be worried?
const MultilineCaptionInput: React.FC<Props> = ({
  value,
  onFocus,
  onBlur,
  onKeyDown,
  onChange,
  placeholder,
}) => {
  const inputRef = useRef<any>(null);

  const onPaste = (event: any) => {
    event.preventDefault();
    const text = event.clipboardData.getData("text/plain");
    document.execCommand("insertHTML", false, text);
  };

  return (
    <StyledFigcaption onBlur={onBlur} onFocus={onFocus}>
      <ContentEditable
        className="figcaption-text"
        ref={inputRef}
        onKeyDown={onKeyDown}
        html={value} // innerHTML of the editable div
        disabled={false} // use true to disable editing
        onChange={onChange} // handle innerHTML change
        placeholder={placeholder}
        onPaste={onPaste}
      />
    </StyledFigcaption>
  );
};

export default MultilineCaptionInput;

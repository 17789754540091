import React from "react";
import { useDropzone } from "react-dropzone";
import { Dimmer, Icon, Button } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

import DropzoneContainer from "./DropzoneContainer";

import Typography from "../Typography/Typography";

export interface MultiImageDropzoneProps {
  isLoading: boolean;
  onDrop: (acceptedFiles: File[]) => void;
}

const MultiImageDropzone: React.FC<MultiImageDropzoneProps> = ({
  isLoading,
  onDrop,
  children,
}) => {
  const { t } = useTranslation();
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    accept: "image/jpeg, image/png",
  });

  return (
    <DropzoneContainer isLoading={isLoading}>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <Icon name="cloud upload" size="massive" />
        <Typography as="p" align="center">
          {t("Forms.DropImageHere")}
        </Typography>
        <Typography as="p" align="center">
          {t("Forms.Or")}
        </Typography>
        <p />
        <Button type="button" onClick={open}>
          {t("Forms.BrowseFilesAction")}
        </Button>
        <Dimmer active={isDragActive}>
          <Icon name="arrow down" size="huge" />
          <Typography>{t("Forms.OnImageDrop")}</Typography>
        </Dimmer>
      </div>
      <div>{children}</div>
    </DropzoneContainer>
  );
};

export default MultiImageDropzone;

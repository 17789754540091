import React, { useCallback, memo } from "react";
import { useTranslation } from "react-i18next";

import FormTextInput from "./FormTextInput";

import { definedMedia } from "../../atoms/Buttons/MediaButton";

interface FormSocialFieldsProps {
  id: string;
  value: Partial<Record<definedMedia, string>>;
  onChange: (id: string, value: Partial<Record<definedMedia, string>>) => void;
}
const FormSocialFields: React.FC<FormSocialFieldsProps> = ({
  id,
  value,
  onChange,
}) => {
  const { t } = useTranslation();

  const onSocialsChange = useCallback(
    (fieldId: string, val: string) => {
      const newVals = { ...value };
      newVals[fieldId as definedMedia] = val;
      onChange(id, newVals);
    },
    [onChange, value, id],
  );

  const { facebook, linkedin, twitter, youtube, instagram } = value;

  return (
    <>
      <FormTextInput
        id="facebook"
        value={facebook}
        label={t("Forms.Titles.Facebook")}
        onChange={onSocialsChange}
      />
      <FormTextInput
        id="linkedin"
        value={linkedin}
        label={t("Forms.Titles.LinkedIn")}
        onChange={onSocialsChange}
      />
      <FormTextInput
        id="twitter"
        value={twitter}
        label={t("Forms.Titles.Twitter")}
        onChange={onSocialsChange}
      />
      <FormTextInput
        id="youtube"
        value={youtube}
        label={t("Forms.Titles.Youtube")}
        onChange={onSocialsChange}
      />
      <FormTextInput
        id="instagram"
        value={instagram}
        label={t("Forms.Titles.Instagram")}
        onChange={onSocialsChange}
      />
    </>
  );
};

export default memo(FormSocialFields);

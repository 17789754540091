import Cognito from "api/cognito/Cognito";
import moment from "moment";
import { convertFromRaw } from "draft-js";

import { slugifyWord } from "../helpers/globalFunctions";
import { availableContentForms } from "../components/organisms/Forms/ContentForm";
import { CATEGORIES } from "../constants/categories";
import { ContentStatusTypes, definedRouteTypes } from "../api/api";
import { uploadImageFromFile } from "../api/ImageApi";
import { definedAuthorTypes } from "../components/molecules/FormFields/FormAuthorFields";

export default async function ContentPostModel(
  form: availableContentForms,
): Promise<availableContentForms> {
  const {
    id,
    title,
    description,
    slug,
    category,
    subCategories,
    countries,
    address,
    imagePointer,
    authorType,
    author,
    creator,
    creatorName,
    organizationId,
    anotherAuthor,
    type,
    createdAt,
    toBePostedAt,
    highlighted,
    highlightedInCountry,
    show,
    imageUrl,
    imageAuthor,
    customLabel,
    exclusive,
    source,
    displayInHomePage,
    online,
    organizationInfo,
    homepageRank,
    region,
  } = form;

  let newImageUrl = imageUrl;
  if (imagePointer) {
    const token = Cognito.getIdTokenFromStorage();
    newImageUrl = await uploadImageFromFile(token, imagePointer);
  }

  const newSlug = slug !== "" ? slug : slugifyWord(title);
  const seoDescription = description
    ? convertFromRaw(description).getPlainText().slice(0, 320)
    : "";

  const newSubCategories = subCategories ? [...subCategories] : [];

  // Due to elastic search category must also be placed in subcategories.
  const allCategories = Object.values(CATEGORIES);
  // eslint-disable-next-line no-unused-expressions
  subCategories?.forEach((sub, idx) => {
    if (allCategories.find((cat) => sub === cat))
      newSubCategories.splice(idx, 1);
  });

  if (category) newSubCategories.push(category);

  if (
    type === definedRouteTypes.offers &&
    !newSubCategories?.includes("piedavajumi")
  ) {
    newSubCategories.push("piedavajumi");
  }

  let newType = type;
  if (
    type === definedRouteTypes.offers &&
    authorType === definedAuthorTypes.organization
  ) {
    newType = organizationInfo?.diasporeMedia
      ? definedRouteTypes.diasporeNews
      : definedRouteTypes.organizationNews;
  }

  let newStatus = ContentStatusTypes.active;
  if (toBePostedAt && moment().isBefore(toBePostedAt)) {
    newStatus = ContentStatusTypes.toBePosted;
  }

  return {
    id,
    slug: newSlug,
    imageUrl: newImageUrl,
    imageAuthor,
    createdAt,
    toBePostedAt: toBePostedAt || moment(),
    title,
    countries,
    address,
    description,
    category,
    subCategories: newSubCategories,
    seoDescription,
    authorType,
    author,
    creator,
    creatorName,
    organizationId,
    anotherAuthor,
    customLabel,
    highlighted,
    highlightedInCountry,
    show,
    exclusive,
    type: newType,
    source,
    displayInHomePage,
    online,
    status: newStatus,
    organizationInfo,
    homepageRank,
    region,
  };
}

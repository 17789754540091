import { IBaseForm } from "./index.d";

import { IApiSettings } from "../interfaces/index";
import RollbarErrorTracking from "../helpers/RollbarErrorLog";

// TODO: Move to environment variables @Dainis
export const AWSurl =
  "https://1kgmk968nl.execute-api.eu-central-1.amazonaws.com/v1/api";
export const AWSRootUrl =
  "https://1kgmk968nl.execute-api.eu-central-1.amazonaws.com/v1";

export const singleData = "single?createdAt=";

export class ApiError extends Error {
  body: any;

  constructor(body: any) {
    super(`Fetch did not return OK status : ${JSON.stringify(body)}`);
    this.body = body;
  }
}

export enum definedRouteTypes {
  upload = "upload",
  editorialNews = "jaunumi",
  diasporeNews = "mediju-jaunumi",
  organizationNews = "organizaciju-jaunumi",
  events = "notikumi",
  pastEvents = "aizvaditie-notikumi",
  imageGallery = "attelu-galerijas",
  videoNews = "video-galerijas",
  offers = "piedavajumi",
  organizations = "organizacijas",
  search = "search",
  dynamic = "dynamic",
  footer = "static",
  newsletter = "newsletter",
  orgSlugs = "orgSlugs",
  pictures = "pictures",
  companies = "uznemumi",
  selfEmployed = "profesionali",
  companyNews = "uznemumu-jaunumi",

  SOURCE_MEDIA = "mediju-avoti",
  LINK_MEDIA = "mediju-saites",
}

export enum definedHomePageRoutes {
  news = "home-news",
  organizations = "home-orgs",
  events = "home-events",
}

export enum definedUnapprovedRoutes {
  news = "new-news",
  events = "new-events",
  organizations = "new-orgs",
  links = "new-media-links",
}

export enum definedFooterRoutes {
  contacts = "kontakti",
  about = "par-mums",
  tos = "lietosanas-noteikumi",
  forOrganizations = "organizacijam",
  partners = "atbalstitaji",
  nightingale = "lakstigala",
  instructions = "lietosanas-pamaciba",
}

export enum ContentStatusTypes {
  active = "active",
  toBePosted = "toBePosted",
  archived = "archived",
}

export enum definedPictureType {
  type = "type",
}

// TODO: Fix (body:any) @Dainis
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PostSettings = (body: any, token: string) => ({
  method: "POST",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: token,
  },
  body: JSON.stringify(body),
});

// TODO: Fix (body:any) @Dainis
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PutSettings = (body: any, token: string) => ({
  method: "PUT",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: token,
  },
  body: JSON.stringify(body),
});

// TODO: Fix (body:any) @Dainis
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const DeleteSettings = (body: any, token: string) => ({
  method: "DELETE",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: token,
  },
  body: JSON.stringify(body),
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GetSettings = (headers: Record<string, string> = {}) => ({
  method: "GET",
  headers: {
    Accept: "*/*",
    ...headers,
  },
});

export enum definedErrors {
  slugExists = "SLUG exists",
  noImagesInGallery = "Gallery should contain at least one image.",
}

// eslint-disable-next-line consistent-return
export async function handleApi(
  url: string,
  settings: IApiSettings,
  skipResponseStream = false,
) {
  try {
    const response = await fetch(url, settings);

    if (skipResponseStream) {
      // this is necessary for presignS3 fetches
      if (response.status !== 200) {
        throw new ApiError(response);
      }

      return response;
    }
    if (response.status !== 200) {
      RollbarErrorTracking.logErroInfo(
        `Fetch did not return OK status : ${JSON.stringify(response)}`,
      );
      RollbarErrorTracking.logErrorInRollbar(
        `Fetch did not return OK status : ${JSON.stringify(response)}`,
      );
    }
    const data = await response.json();
    if (data.slug) return data; // for single data without status code
    if (data.body === definedErrors.slugExists) {
      RollbarErrorTracking.logErroInfo(data.body);
      RollbarErrorTracking.logErrorInRollbar(data.body);
    }
    if (data.errorType || data.errorMessage) {
      RollbarErrorTracking.logErroInfo(data.errorMessage);
      RollbarErrorTracking.logErrorInRollbar(`Failed to fetch.`);
    }
    return data;
  } catch (err) {
    RollbarErrorTracking.logErroInfo("Caught an error!");
    RollbarErrorTracking.logErrorInRollbar(err);
  }
}

export function handleApiGet(
  url: string,
  headers: Record<string, string> = {},
) {
  return handleApi(url, GetSettings(headers));
}
// TODO: Fix (body:any) @Dainis
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function handleApiPost(url: string, idToken: string, body: any) {
  const settings = PostSettings(body, idToken);
  return handleApi(url, settings);
}

export async function handleApiDelete(
  url: string,
  idToken: string,
  slug: string,
  imageUrl?: string,
) {
  const settings = DeleteSettings({ slug, imageUrl }, idToken);
  return handleApi(url, settings);
}

export async function handleApiDeletePicture(
  url: string,
  idToken: string,
  body: any,
) {
  const settings = DeleteSettings(body, idToken);
  return handleApi(url, settings);
}

// TODO: Fix (body:any) @Dainis
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function handleApiPut(url: string, idToken: string, body: any) {
  const settings = PutSettings(body, idToken);
  return handleApi(url, settings);
}

/**
 * Dynamic api calls
 */
export function getHomePageData() {
  const homepageData = `${AWSRootUrl}/homepage`;
  return handleApiGet(homepageData);
}

export const updateHomepageRank = (
  idToken: string,
  body: IBaseForm,
): Promise<Array<IBaseForm>> => {
  const homepageData = `${AWSRootUrl}/homepage/ranks`;
  return handleApiPut(homepageData, idToken, body);
};

import EventsPostModel from "./EventsPostModel";
import GalleryFormModel from "./GalleryNewsModel";
import ContentPostModel from "./ContentPostModel";
import VideoNewsModel from "./VideoNewsModel";
import EntityPostModel from "./EntityPostModel";

import { definedRouteTypes } from "../api/api";

export default async function DynamicModel(form: any): Promise<any> {
  const { type } = form;
  if (type === definedRouteTypes.events) return EventsPostModel(form);
  if (type === definedRouteTypes.imageGallery) return GalleryFormModel(form);
  if (type === definedRouteTypes.videoNews) return VideoNewsModel(form);
  if (type === definedRouteTypes.organizations) return EntityPostModel(form);
  if (type === definedRouteTypes.selfEmployed) return EntityPostModel(form);
  if (type === definedRouteTypes.companies) return EntityPostModel(form);
  return ContentPostModel(form);
}

import styled, {
  ThemedStyledInterface,
  CSSProperties,
} from "styled-components";

const theme = {
  space: {
    0: "0em",
    1: "0.5em", // 8px
    2: "1em", // 16px
    3: "1.5em", // 24px
    4: "2em", // 32px
    5: "2.5em",
    6: "3em",
  },
  palette: {
    primary: {
      main: "#bd1a2c",
    },
    mono: {
      white: "#FFFFFF",
      lightest: "#FAFAFA", // dropzone Background Color
      lighter: "#E7E7E7", // dropzone Border Color
      light: "#BBBBBB", // footer text, dropzone Text Color
      base: "#A7A7A7", // underlined inactive tabs
      dark: "#7F7F7F", // unemphasized menu items
      darker: "#464646", // Footer background
      darkest: "#333333", // caption text
      black: "#000000", // regular text
    },
    error: {
      light: "#e57373",
      main: "#f44336",
      dark: "#d32f2f",
    },
    warning: {
      light: "#ffb74d",
      main: "#ff9800",
      dark: "#f57c00",
    },
    info: {
      light: "#64b5f6",
      main: "#2196f3",
      dark: "#1976d2",
    },
    success: {
      light: "#81c784",
      main: "#4caf50",
      dark: "#388e3c",
    },
    // any color that you think is needed can go here,
    // Agnese will tidy it up later
    special: {
      link: "#0067C5",
    },
  },
  typography: {
    fontFamily: "'Raleway', sans-serif",
    fontWeight: {
      light: 300,
      regular: 400,
      medium: 500,
      semiBold: 600,
      bold: 700,
    },
    h1: {
      // Izceltās ziņas virsraksts
      fontFamily: "'Raleway', sans-serif",
      fontWeight: 600,
      fontSize: "2rem", // 23px no Alises
      lineHeight: 1.2,
      color: "#000000",
      margin: "0.625em",
      marginLeft: "0",
    },
    h2: {
      // Parastas ziņas virsraksts
      fontFamily: "'Raleway', sans-serif",
      fontWeight: 600,
      fontSize: "1.5rem", // 14px no Alises
      lineHeight: 1.2,
      color: "#000000",
      margin: "0.625em",
      textTransform: "capitalize" as CSSProperties["textTransform"],
    },
    h3: {
      // Menu itemu virsraksti
      fontFamily: "'Raleway', sans-serif",
      fontWeight: 500,
      fontSize: "1.1rem", // 12px no Alises
      lineHeight: 1.2,
      color: "#000000",
      letterSpacing: 1,
      margin: "0.625em",
    },
    title1: {
      // Sadaļu virsraksti
      fontFamily: "'Raleway', sans-serif",
      fontWeight: 600,
      fontSize: "1.1rem", // 12 px no Alises
      lineHeight: 1,
      letterSpacing: "0.1em",
      textTransform: "uppercase" as CSSProperties["textTransform"], // TODO fix type widening
      color: "#000000",
    },
    title2: {
      // Menu Sadaļas virsraksts
      fontFamily: "'Raleway', sans-serif",
      fontWeight: 700,
      fontSize: "1.1rem", // 12 px no Alises
      lineHeight: 1,
      letterSpacing: "0.1em",
      textTransform: "uppercase" as CSSProperties["textTransform"],
      color: "#7F7F7F",
    },
    title3: {
      // Video, galerijas sadaļas visraksts
      fontFamily: "'Raleway', sans-serif",
      fontWeight: 700,
      fontSize: "1.5rem", // 12 px no Alises
      lineHeight: 1,
      letterSpacing: "0.1em",
      textTransform: "uppercase" as CSSProperties["textTransform"],
      color: "#FFFFFF",
    },
    title4: {
      // Reklāmas sadaļas virsraksts
      fontFamily: "'Raleway', sans-serif",
      fontWeight: 400,
      fontSize: "0.9rem", // 9px no Alises
      lineHeight: 1,
      textTransform: "uppercase" as CSSProperties["textTransform"],
      color: "#333333",
    },
    caption: {
      fontFamily: "'Raleway', sans-serif",
      fontWeight: 400,
      fontSize: "1rem", // 9px no Alises
      lineHeight: 1,
      color: "#333333",
    },
    smallCaption: {
      fontFamily: "'Raleway', sans-serif",
      fontWeight: 400,
      fontSize: "0.8rem",
      lineHeight: 1,
      color: "#333333",
    },
    body1: {
      // Galvenais teksts
      // TODO I know Raleway sucks as long text font.
      fontFamily: "'Raleway', sans-serif",
      fontWeight: 400,
      fontSize: "1.2rem",
      lineHeight: 1,
      color: "#000000",
    },
    button1: {
      fontFamily: "'Raleway', sans-serif",
      fontWeight: 500,
      fontSize: "1.1rem",
      lineHeight: 1,
      color: "#000000",
      letterSpacing: 1,
    },
    button2: {
      fontFamily: "'Raleway', sans-serif",
      fontWeight: 700,
      fontSize: "1.1rem",
      lineHeight: 1,
      textTransform: "uppercase" as CSSProperties["textTransform"],
      color: "#FFFFFF",
      letterSpacing: 0,
    },
    basicButton: {
      fontFamily: "'Raleway', sans-serif",
      fontWeight: 400,
      fontSize: "0.9rem",
      lineHeight: 1,
      textTransform: "uppercase" as CSSProperties["textTransform"],
      color: "#FFFFFF",
      letterSpacing: 0,
    },
  },
};

export type Theme = typeof theme;
// TODO reexport all usage of styled through this.
export const styledWithTheme = styled as ThemedStyledInterface<Theme>;

export default theme;

export const fallbackImage =
  "https://latviesi-post-images.s3.eu-central-1.amazonaws.com/L.svg";

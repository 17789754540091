// TODO fix any
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, ReactNode, useEffect, useRef } from "react";
import {
  useTable,
  usePagination,
  useExpanded,
  useFilters,
  useSortBy,
} from "react-table";
import { Loader, Table } from "semantic-ui-react";

import Paginator from "../../molecules/TableFragments/Paginator";
import DimmerLoader from "../../atoms/Loaders/DimmerLoader";
import PageSizeSelect from "../../molecules/TableFragments/PageSizeSelect";
import SortIcon from "../../atoms/Icons/SortIcon";
import { columnMaker, definedAccessors } from "../../../helpers/tableHelpers";
import { IColumn, ITable } from "../../../interfaces/tables";

export interface FetchMoreTableProps<T extends object = {}> {
  data: T[];
  columns: definedAccessors[];
  actionColumn?: Partial<IColumn<T>>;
  loading?: boolean;
  expanderComponent?: React.FC<any>;
  fetchMore: (skipPageReset: () => void) => Promise<void>;
  canFetchMore: boolean;
  disableFilters?: boolean;
}

// Reason why React.FC type is not used is explained here
// https://stackoverflow.com/questions/53958028/how-to-use-generics-in-props-in-react-in-a-functional-component
const FetchMoreTable = <T extends object = {}>(
  props: FetchMoreTableProps<T> & { children?: ReactNode },
): ReactElement => {
  const {
    data,
    loading,
    expanderComponent: Expander,
    columns,
    actionColumn,
    fetchMore,
    canFetchMore,
    disableFilters,
  } = props;
  const skipPageResetRef = useRef(false);

  const maxColspan = columns.length + 1;

  // By react-table documentation, columns must be memoized!
  const cols = React.useMemo(() => columnMaker(columns, actionColumn, {disableFilters}), [
    columns,
    actionColumn,
    disableFilters,
  ]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    setPageSize,
    rows,
    state: { pageSize, pageIndex },
  } = useTable<any>(
    {
      columns: cols,
      data,
      autoResetPage: !skipPageResetRef.current,
      autoResetExpanded: !skipPageResetRef.current,
      autoResetGroupBy: !skipPageResetRef.current,
      autoResetSelectedRows: !skipPageResetRef.current,
      autoResetSortBy: !skipPageResetRef.current,
      autoResetFilters: !skipPageResetRef.current,
      autoResetRowState: !skipPageResetRef.current,
      initialState: {
        pageIndex: 0,
        pageSize: 20,
      },
    } as any,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
  ) as ITable<T>;

  useEffect(() => {
    if (!canFetchMore) return;
    function skip() {
      skipPageResetRef.current = true;
    }
    if (pageSize * (pageIndex + 1) >= rows.length) {
      fetchMore(skip);
    }
  });

  useEffect(() => {
    skipPageResetRef.current = false;
  });

  return (
    <DimmerLoader>
      <Table {...getTableProps()}>
        <Table.Header>
          {headerGroups.map((headerGroup) => (
            <Table.Row {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <Table.HeaderCell {...column.getHeaderProps()}>
                  <div {...column.getSortByToggleProps()}>
                    {column.render("Header")}
                    {column.canSort && <SortIcon desc={column.isSortedDesc} />}
                  </div>
                  <div>{column.canFilter && column.render("Filter")}</div>
                </Table.HeaderCell>
              ))}
            </Table.Row>
          ))}
        </Table.Header>
        <Table.Body {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            const { key, ...rowProps } = row.getRowProps();
            return (
              <React.Fragment key={key}>
                <Table.Row {...rowProps}>
                  {row.cells.map((cell) => (
                    <Table.Cell {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </Table.Cell>
                  ))}
                </Table.Row>
                {(row as any).isExpanded && Expander && (
                  <Table.Row>
                    <Table.Cell colSpan={maxColspan}>
                      <Expander data={row.original} />
                    </Table.Cell>
                  </Table.Row>
                )}
              </React.Fragment>
            );
          })}
          {loading && (
            <Table.Row>
              <Table.Cell colSpan={maxColspan}>
                <Loader active inline="centered" />
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.Cell colSpan={maxColspan}>
              <Paginator
                disabled={loading}
                pageIndex={pageIndex}
                goToPrevPage={previousPage}
                goToNextPage={nextPage}
                isPrevAvailable={canPreviousPage}
                isNextAvailable={canFetchMore || canNextPage}
              />
              {!fetchMore && (
                <PageSizeSelect pageSize={pageSize} setPageSize={setPageSize} />
              )}
            </Table.Cell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </DimmerLoader>
  );
};

export default React.memo(FetchMoreTable);

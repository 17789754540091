import React from "react";
import { Dimmer, Loader } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

import FormSegment from "../../templates/Wrappers/FormSegment";
import Typography from "../Typography/Typography";
import {
  StyledDropzoneContainer,
  StyledDropzoneArea,
} from "../../../styles/DropzoneStyles";

export interface DropzoneContainerProps {
  isLoading: boolean;
}

const DropzoneContainer: React.FC<DropzoneContainerProps> = ({
  children,
  isLoading,
}) => {
  const { t } = useTranslation();
  return (
    <FormSegment>
      <Dimmer active={isLoading}>
        <Loader>
          <Typography>{t("Forms.LoadingImage")}</Typography>
        </Loader>
      </Dimmer>
      <StyledDropzoneContainer>
        <StyledDropzoneArea>{children}</StyledDropzoneArea>
      </StyledDropzoneContainer>
    </FormSegment>
  );
};

export default DropzoneContainer;

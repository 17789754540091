import { useContext } from "react";

import TypeTableContext, {
  ITypeTableContext,
} from "../context/TypeTableContext";

export enum definedTableActions {
  add = "add",
  remove = "remove",
  update = "update",
}

const useTypeTableContext = (): ITypeTableContext | null => {
  return useContext(TypeTableContext);
};

export default useTypeTableContext;

/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable camelcase */
import React, { useEffect } from "react";
import styled from "styled-components";
import { ContentState } from "draft-js";
import useScriptContext from "hooks/useScriptContext";

interface Props {
  blockProps: any;
  contentState: ContentState;
}

const StyledEmbed = styled.div({
  outline: "none",
  width: "100%",
  height: "auto",
  display: "flex",
  justifyContent: "center",
  margin: "0 auto 1em",
  "&&&& blockquote": {
    background: "none",
    borderLeft: "none",
    margin: "0",
    padding: "0",
  },
});

const Embed: React.FC<Props> = (props) => {
  const {
    theme,
    block,
    blockProps,
    customStyleMap,
    customStyleFn,
    decorator,
    forceSelection,
    offsetKey,
    selection,
    tree,
    contentState,
    blockStyleFn,
    style,
    className,
    ...elementProps
  } = props as any;
  const ctxt = useScriptContext();

  const { provider_name, html } = contentState
    .getEntity(block.getEntityAt(0))
    .getData();

  useEffect(() => {
    ctxt.addOrReloadScript(provider_name);
  }, [ctxt, provider_name]);

  if (html) {
    return (
      <StyledEmbed
        {...elementProps}
        className={className}
        contentEditable={false}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: html }}
        style={{ ...style }}
      />
    );
  }

  return null;
};

export default Embed;

import { EditorState } from "draft-js";
import { useTranslation } from "react-i18next";
import React, { useState, ChangeEvent } from "react";
import {
  Button,
  ButtonGroup,
  Divider,
  Form,
  Input,
  Popup,
} from "semantic-ui-react";
import styled from "styled-components";
import getImageDimensions from "helpers/getImageDimensions";

import DropzoneLoader from "../../Dropzone/Fragments/DropzoneLoader";
import SingleImageDropzone from "../../Dropzone/SingleImageDropzone";

const StyledWrapper = styled(Form)({
  "&&&": {
    display: "flex",
    wrap: "no-wrap",
    alignItems: "stretch",
    ".input": {
      marginRight: "5px",
    },
  },
});
const LabelText = styled.span({
  "&&&": {
    fontSize: ".92857143em",
    fontWeight: 700,
    margin: "0 0 .28571429rem 0",
  },
});

const UrlForm = ({ onChange, value, onSubmit, onCancel }: any) => {
  const { t } = useTranslation();
  return (
    <StyledWrapper onSubmit={onSubmit}>
      <Input
        type="text"
        placeholder={t("CommonComponents.PasteImageUrlPlaceholder")}
        onChange={onChange}
        value={value}
        id="add-image-input"
        autoFocus
      />
      <ButtonGroup>
        <Button onClick={onSubmit} icon="checkmark" id="confirm-img-url" />
        <Button onClick={onCancel} icon="close" id="cancel-img-url" />
      </ButtonGroup>
    </StyledWrapper>
  );
};

interface Props {
  editorState: EditorState;
  onChange: (value: EditorState) => void;
  modifier: (
    editorState: EditorState,
    url: string,
    extraData: Record<string, unknown>,
  ) => EditorState;
  uploadImageApi?: (file: File, fileName: string) => Promise<string>;
}

const ImageToolbar: React.FC<Props> = ({
  editorState,
  onChange,
  modifier,
  uploadImageApi,
}) => {
  const { t } = useTranslation();
  const [loadingURL, setLoadingURL] = useState(false);

  const [url, setURL] = useState("");
  const [open, setOpen] = useState(false);

  const openPopover = () => {
    setLoadingURL(false);
    setOpen(true);
  };

  const closePopover = () => {
    setOpen(false);
    setURL("");
  };

  const addFile = async (file: File) => {
    if (!uploadImageApi || !file) return;
    const URL = await uploadImageApi(file, `rtEntities-${file.name}`);
    let width;
    let height;
    try {
      ({ width, height } = await getImageDimensions(URL));
    } finally {
      onChange(
        modifier(editorState, URL, {
          captions: "",
          alignment: "center",
          width,
          height,
        }),
      );
    }
  };

  const addImage = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setLoadingURL(true);
    const trimmed = url?.trim();
    if (trimmed) {
      let width;
      let height;
      try {
        ({ width, height } = await getImageDimensions(trimmed)); // ensuring that bad images are not added
        onChange(
          modifier(editorState, trimmed, {
            captions: "",
            alignment: "center",
            width,
            height,
          }),
        );
      } finally {
        closePopover();
      }
    }
  };

  const changeUrl = (evt: ChangeEvent<HTMLInputElement>) => {
    setURL(evt.target.value);
  };

  const onClose = (e: React.SyntheticEvent) => {
    e.preventDefault();
    onChange(
      EditorState.forceSelection(editorState, editorState.getSelection()),
    ); // returning focus back to place
    closePopover();
  };

  return (
    <Popup
      positionFixed
      trigger={
        <ButtonGroup>
          <Button active={open} icon="image" id="add-image-btn" />
        </ButtonGroup>
      }
      on="click"
      open={open}
      onClose={onClose}
      onOpen={openPopover}
      position="bottom left"
    >
      {uploadImageApi && (
        <>
          <LabelText>{t("CommonComponents.Dropzone.UploadImage")}</LabelText>
          <SingleImageDropzone onSubmit={addFile} onSuccess={closePopover}>
            <DropzoneLoader isLoading={loadingURL} />
            <Divider horizontal>{t("CommonComponents.Dropzone.Or")}</Divider>
            <LabelText>{t("CommonComponents.Dropzone.ImageURL")}</LabelText>
            <UrlForm
              value={url}
              onSubmit={addImage}
              onChange={changeUrl}
              onCancel={onClose}
            />
          </SingleImageDropzone>
        </>
      )}
      {!uploadImageApi && (
        <UrlForm
          value={url}
          onSubmit={addImage}
          onChange={changeUrl}
          onCancel={closePopover}
        />
      )}
    </Popup>
  );
};
export default ImageToolbar;

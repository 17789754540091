// TODO ENVS
const s3Url = "https://latviesi-media.s3.eu-central-1.amazonaws.com";
const ImageCloudUrl = "https://d2uxl5q68iycnn.cloudfront.net";
export function getThumbnailLink(link) {
    if (!link)
        return undefined;
    if (!link.includes(s3Url))
        return link;
    const imgKey = link.split("/").pop();
    return `${ImageCloudUrl}/fit-in/450x220/filters:quality(80)/${imgKey}`;
}
export function getLargeThumbnailLink(link) {
    if (!link)
        return undefined;
    if (!link.includes(s3Url))
        return link;
    const imgKey = link.split("/").pop();
    return `${ImageCloudUrl}/fit-in/650x420/filters:quality(80)/${imgKey}`;
}
export function getSquaredThumbnailLink(link) {
    if (!link)
        return undefined;
    if (!link.includes(s3Url))
        return link;
    const imgKey = link.split("/").pop();
    return `${ImageCloudUrl}/fit-in/400x400/filters:quality(80)/${imgKey}`;
}
export function getOptimizedImage(link) {
    if (!link)
        return undefined;
    if (!link.includes(s3Url))
        return link;
    const imgKey = link.split("/").pop();
    return `${ImageCloudUrl}/fit-in/850x500/filters:quality(80)/${imgKey}`;
}

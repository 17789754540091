import { EditorState } from "draft-js";

import getCurrentBlock from "./getCurrentBlock";

const canIAddBlock = (editorState: EditorState, entityType: string) => {
  const selectionState = editorState.getSelection();

  if (!selectionState.isCollapsed()) {
    return false;
  }
  const currentBlock = getCurrentBlock(editorState);

  if (
    !currentBlock ||
    currentBlock.getLength() !== 0 ||
    currentBlock.getType() === entityType
  ) {
    return false;
  }

  return true;
};

export default canIAddBlock;

import React from "react";
import { Dropdown, DropdownProps } from "semantic-ui-react";

export interface Props {
  pageSize: number;
  setPageSize: (value: number) => void;
  opts?: number[];
}

const DEFAULT_OPTS = [10, 20, 50, 100];
const PageSizeSelect: React.FC<Props> = ({ pageSize, setPageSize, opts }) => {
  const options = (opts || DEFAULT_OPTS).map((size) => ({
    text: `Rādīt ${size} ierakstus`,
    value: size,
    key: `size-${size}`,
  }));

  return (
    <Dropdown
      selection
      value={pageSize}
      onChange={(_, v: DropdownProps) => setPageSize(v.value as number)}
      options={options}
    />
  );
};

export default PageSizeSelect;

import React from "react";
import { NavLink } from "react-router-dom";

import Typography from "../Typography/Typography";
import { definedTypographyTypes } from "../Typography/TypographyStyles";
import { styledWithTheme } from "../../../styles/theme";

interface Props {
  title: string;
  borderColor?: string;
  route: string;
}

const StyledCardHeading = styledWithTheme.div(({ theme }) => ({
  h1: {
    fontSize: "1.5rem",
    borderBottom: `0.1em solid ${theme.palette.primary.main}`,
    display: "inline",
    "@media only screen and (max-width: 600px)": {
      fontSize: "1em",
    },
  },
}));

const CardHeading: React.FC<Props> = ({ title, route }) => {
  return (
    <NavLink to={route}>
      <StyledCardHeading>
        <Typography type={definedTypographyTypes.h1}>{title}</Typography>
      </StyledCardHeading>
    </NavLink>
  );
};

export default CardHeading;

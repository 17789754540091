import React from "react";
import { List, Image } from "semantic-ui-react";

import { IKeyRow } from "../../pages/NewsletterMakerPage";

interface Props {
  selected: IKeyRow[];
}

const ReadOnlyRSSList: React.FC<Props> = ({ selected }) => {
  return (
    <List divided verticalAlign="middle" ordered>
      {selected.map((row, idx) => {
        return (
          <List.Item key={row.id}>
            <List.Content floated="right">{row.type}</List.Content>
            <List.Content>
              <Image src={row.imageUrl} alt="image" size="tiny" inline />
              <span>
                &nbsp;<b>{row.title}</b>
              </span>
            </List.Content>
          </List.Item>
        );
      })}
    </List>
  );
};

export default ReadOnlyRSSList;

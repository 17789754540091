import { EditorPlugin } from "@draft-js-plugins/editor";
import { ContentBlock, EditorState } from "draft-js";
import { ComponentType } from "react";

import createDecorator from "./createDecorator";

export interface CaptionsPluginConfig {
  decorator?(component: ComponentType<any>): ComponentType<any>;
}

const createSetCaptionData = (
  contentBlock: ContentBlock,
  {
    getEditorState,
    setEditorState,
  }: {
    getEditorState(): EditorState;
    setEditorState(state: EditorState): void;
  },
) => (data: Record<string, unknown>) => {
  const entityKey = contentBlock.getEntityAt(0);
  if (entityKey) {
    const editorState = getEditorState();
    const contentState = editorState.getCurrentContent();
    contentState.mergeEntityData(entityKey, { ...data });
    setEditorState(
      EditorState.forceSelection(editorState, editorState.getSelection()),
    );
  }
};

export interface CaptionsPlugin extends EditorPlugin {
  decorator: ReturnType<typeof createDecorator>;
}
export default function createCaptionsPlugin(
  // placeholder for future configuration
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  config: CaptionsPluginConfig = {},
): CaptionsPlugin {
  return {
    decorator: createDecorator({}),
    blockRendererFn: (
      contentBlock,
      { getEditorState, setEditorState, setReadOnly },
    ) => {
      const entityKey = contentBlock.getEntityAt(0);
      const contentState = getEditorState().getCurrentContent();
      const captionData = entityKey
        ? contentState.getEntity(entityKey).getData()
        : {};
      return {
        props: {
          setReadOnly,
          captionData,
          setCaptionData: createSetCaptionData(contentBlock, {
            getEditorState,
            setEditorState,
          }),
        },
      };
    },
  };
}

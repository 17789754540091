import * as React from "react";
import { Placeholder, Segment } from "semantic-ui-react";
import styled from "styled-components";

const StylePlaceholder = styled(Placeholder)({
  height: "14em",
});

const CardLoader: React.FC = () => (
  <Segment raised>
    <Placeholder>
      <StylePlaceholder>
        <Placeholder.Image />
      </StylePlaceholder>
      <Placeholder.Paragraph>
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
      </Placeholder.Paragraph>
    </Placeholder>
  </Segment>
);

export default CardLoader;

import React from "react";
import { Button } from "semantic-ui-react";

import { IRow } from "../../../interfaces/tables";
import { IOrganizationDisplay } from "../../../api";
import EditModal from "../../organisms/Modal/EditModal";
import DeleteModal from "../../organisms/Modal/DeleteModal";

interface Props {
  cell: { row: IRow<IOrganizationDisplay> };
}

const NewsActionCell: React.FC<Props> = ({ cell: { row } }) => {
  const { original } = row;

  return (
    <Button.Group>
      <EditModal data={original} />
      <DeleteModal data={original} />
    </Button.Group>
  );
};

export default NewsActionCell;

/* eslint-disable react/destructuring-assignment */
import React, { ReactElement } from "react";
import { EditorPlugin } from "@draft-js-plugins/editor";
import { EditorState } from "draft-js";

import * as types from "./constants";
import Embed from "./components/Embed";
import Embedder from "./components/Embedder";
import EmbedButton from "./components/EmbedButton";
import addEmbedder from "./modifiers/addEmbedder";
import addEmbed from "./modifiers/addEmbed";

import isBlockWithEntityType from "../../../utils/isBlockWithEntityType";
import removeBlock from "../../../utils/removeBlock";
import getCurrentBlock from "../../../utils/getCurrentBlock";

const defaultOptions = {
  placeholder: "Iekopē Youtube, Vimeo vai kādu citu saiti un spied Enter",
  handleOnReturn: false,
  handleOnPaste: false,
};
export interface VideoPlugin extends EditorPlugin {
  EmbedButton: (props: any) => ReactElement;
  addEmbedder: (editorState: EditorState, data?: any) => EditorState;
  addEmbed: (editorState: EditorState, data?: any) => EditorState;
  types: typeof types;
}

export default function createVideoPlugin({
  options = {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getOEmbedApi: async (url: string) => undefined as any,
  },
  editable = false,
} = {}): VideoPlugin {
  const DecoratedEmbedButton = (props: any): ReactElement => (
    <EmbedButton
      entityType={types.OEMBED_ADD_FORM_TYPE}
      addEmbedder={addEmbedder}
      {...props}
    />
  );
  const pluginOptions = { ...defaultOptions, ...options };
  return {
    blockRendererFn: (
      block,
      { getEditorState, setEditorState, setReadOnly },
    ) => {
      // Add Embedded content
      if (
        isBlockWithEntityType(
          getEditorState(),
          block,
          types.OEMBED_CONTENT_TYPE,
        )
      ) {
        return {
          component: Embed,
          editable,
        };
      }

      // Embedding component
      if (block.getType() === types.OEMBED_ADD_FORM_TYPE) {
        return {
          component: Embedder,
          editable: false,
          props: {
            placeholder: pluginOptions.placeholder,
            setReadOnly,

            onCancel: (currBlock: any) => {
              setEditorState(removeBlock(getEditorState(), currBlock.key));
            },

            onRequest: async (currBlock: any, text: string) => {
              let editorState = removeBlock(getEditorState(), currBlock.key);
              if (text) {
                const data = await pluginOptions.getOEmbedApi(text);
                if (data?.html) editorState = addEmbed(editorState, data);
              }

              setEditorState(editorState);
            },
          },
        };
      }

      return null;
    },
    // ! can i give promise here?
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    handlePastedText: async (text, html, editorState, { setEditorState }) => {
      if (!pluginOptions.handleOnPaste) {
        return "not-handled";
      }

      const data = await pluginOptions.getOEmbedApi(text.trim());

      if (data) {
        setEditorState(addEmbed(editorState, data));
        return "handled";
      }

      return "not-handled";
    },

    // ! can i give promise here?
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    handleReturn: async (event, editorState, { setEditorState }) => {
      if (!pluginOptions.handleOnReturn) return "not-handled";

      const contentBlock = getCurrentBlock(editorState);
      const data = await pluginOptions.getOEmbedApi(
        contentBlock.getText().trim(),
      );

      if (data) {
        setEditorState(addEmbed(editorState, data));
        return "handled";
      }

      return "not-handled";
    },
    EmbedButton: DecoratedEmbedButton,

    addEmbedder,
    addEmbed,
  };
}

import React from "react";
import { Grid, Divider } from "semantic-ui-react";

import Typography, { definedTypography } from "../atoms/Typography/Typography";

interface FormGridProps {
  label: string;
}

const FormGrid: React.FC<FormGridProps> = ({ children, label }) => {
  return (
    <>
      <Grid divided>
        <Grid.Row>
          <Grid.Column width={4}>
            <Typography type={definedTypography.title1}>{label}</Typography>
          </Grid.Column>
          <Grid.Column width={12}>{children}</Grid.Column>
        </Grid.Row>
      </Grid>
      <Divider />
    </>
  );
};
export default FormGrid;

import React from "react";
import { Flag } from "semantic-ui-react";

import { COUNTRY } from "../../../constants/country";

interface Props {
  cell: { value?: COUNTRY[] };
}

const CountryCell: React.FC<Props> = ({ cell: { value } }) => {
  return (
    <>
      {value && value.length ? (
        value.map((country) => <Flag key={country} name={country} />)
      ) : (
        <div>Bez valsts</div>
      )}
    </>
  );
};

export default CountryCell;

import React from "react";
import { List, Button, Image } from "semantic-ui-react";

import { IKeyRow } from "../../pages/NewsletterMakerPage";

interface Props {
  selected: IKeyRow[];
  loading: boolean;
  removeItem: (idx: number) => void;
  moveItem: (idx: number, up?: boolean) => void;
}

const RSSList: React.FC<Props> = ({
  selected,
  loading,
  removeItem,
  moveItem,
}) => {
  return (
    <List divided verticalAlign="middle" ordered>
      {selected.map((row, idx) => {
        return (
          <List.Item key={row.id}>
            <List.Content floated="right">
              <Button
                icon="angle down"
                onClick={() => moveItem(idx)}
                disabled={loading}
              />
              <Button
                icon="angle up"
                onClick={() => moveItem(idx, true)}
                disabled={loading}
              />
              <Button
                icon="trash alternate outline"
                onClick={() => removeItem(idx)}
                disabled={loading}
              />
            </List.Content>
            <List.Content floated="right">{row.type}</List.Content>
            <List.Content>
              <Image src={row.imageUrl} alt="image" size="tiny" inline />
              <span>
                &nbsp;<b>{row.title}</b>
              </span>
            </List.Content>
          </List.Item>
        );
      })}
    </List>
  );
};

export default RSSList;

import React, { useState } from "react";
import { Modal, Button } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import useAuthorizedContext from "hooks/useAuthorizedContext";

import { EntityForm, isCompany, isOrganization } from "../../../api/index.d";
import useTypeTableContext, {
  definedTableActions,
} from "../../../hooks/useTypeTableContext";
import CreateHomepageForm from "../Forms/CreateHomepageForm";
import ITemplateSite from "../../../interfaces/templateSite/site";
import HomepagePostModel from "../../../models/HomepagePostModel";
import { updateOrganizations } from "../../../api/OrganizationsApi";
import { updateCompanies } from "../../../api/CompaniesApi";

interface DeleteModalProps {
  data: EntityForm;
}

const AddHomepageModal: React.FC<DeleteModalProps> = ({ data }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const typeTableContext = useTypeTableContext();
  const { token } = useAuthorizedContext();

  const submitFormQuery = async (form: ITemplateSite) => {
    try {
      const act = definedTableActions.update;
      const postForm = await HomepagePostModel(data, form);
      const idToken = await token();
      if (isOrganization(postForm)) {
        await updateOrganizations(idToken, postForm);
      } else if (isCompany(postForm)) {
        await updateCompanies(idToken, postForm);
      } else {
        throw new Error("unsupported type given to homepage creation modal");
      }

      if (typeTableContext) typeTableContext.updateTableEntry(postForm, act);
      toast.success(t("Forms.Messages.HomepageSuccess"));
    } catch (err) {
      toast.error(t("Toasts.GenericError"));
      console.error("Homepage adding failed", err);
    }
  };

  return (
    <Modal
      open={open}
      trigger={<Button icon="plus" onClick={() => setOpen(true)} />}
      closeOnEscape={false}
      closeOnDimmerClick={false}
    >
      <Modal.Header>Izveidot jaunu organizācijas mājaslapu</Modal.Header>
      <Modal.Content>
        <CreateHomepageForm
          organization={data}
          onSubmit={submitFormQuery}
          onCancel={() => setOpen(false)}
        />
      </Modal.Content>
    </Modal>
  );
};

export default AddHomepageModal;

import { handleApiPut, handleApiDeletePicture, handleApiPost } from "./api";
import { BackendUrl } from "./newBackendApi";
import { IPictureEntityForm } from "./types/PictureEntity";

interface IFilterOpts {
  query?: string;
  author?: string;
  businessEntity?: string;
  countries?: string[];
  showAll?: boolean;
  startKey?: object;
  limit?: number;
}

const omitEmptyVals = (opts: Record<string, any>) => {
  const filtered: Record<string, any> = {};
  Object.keys(opts).forEach((key) => {
    if (opts[key] instanceof Array) {
      if (!opts[key].length) return;
    }
    if (typeof opts[key] === "boolean") {
      if (opts.showAll !== true) return;
    }
    filtered[key] = opts[key];
  });
  return filtered;
};

const PICTURE_DB_URL = `${BackendUrl}/admin/picture-db`;
export const FilterPictureDbApi = async (
  idToken: string,
  opts: IFilterOpts,
  ensureLimit = 0,
) => {
  const filtered = omitEmptyVals(opts);

  const promise = async (
    limit = filtered.limit,
    startKey = filtered.startKey,
  ) =>
    handleApiPost(`${PICTURE_DB_URL}/filter`, idToken, {
      ...filtered,
      limit,
      startKey,
    });

  if (!ensureLimit) return promise();

  // TODO force limit in BE
  let startKey: any;
  const res = [];
  do {
    // eslint-disable-next-line no-await-in-loop
    const { Items, LastEvaluatedKey } = await promise(1000, startKey);
    res.push(...Items);
    if (startKey?.key === LastEvaluatedKey?.key) break;
    startKey = LastEvaluatedKey;
  } while (startKey && res.length < ensureLimit);

  return { Items: res };
};

export const UpdatePictureDbApi = (
  idToken: string,
  opts: IPictureEntityForm,
) => {
  return handleApiPut(`${PICTURE_DB_URL}/update`, idToken, opts);
};

export const DeletePictureDbApi = (idToken: string, key: string) => {
  return handleApiDeletePicture(
    `${PICTURE_DB_URL}/delete?key=${key}`,
    idToken,
    {},
  );
};

import React from "react";

import { styledWithTheme } from "../../../styles/theme";
import Typography, {
  definedTypography,
} from "../../atoms/Typography/Typography";

// TODO: @Agense groom
export interface SimpleHeaderProps {
  type?: string;
}

export enum definedHeaderTypes {
  emphasize = "emphasize",
  default = "default",
}

const StyledDiv = styledWithTheme.div<{ type?: string }>(({ type, theme }) => ({
  margin: `${theme.space["4"]} 0`,
  "> *": {
    paddingBottom: "0.5em",
    borderBottom: `0.1em solid ${theme.palette.mono.dark}`,
    display: "inline",
    borderColor:
      type === definedHeaderTypes.emphasize
        ? theme.palette.primary.main
        : "none",
  },
}));

const SimpleHeader: React.FC<SimpleHeaderProps> = ({ children, type }) => {
  return (
    <StyledDiv type={type}>
      <Typography type={definedTypography.title1}>{children}</Typography>
    </StyledDiv>
  );
};

SimpleHeader.defaultProps = {
  type: definedHeaderTypes.default,
};

export default SimpleHeader;
